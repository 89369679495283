import { type FieldProps } from "formik"
import { type DropdownChangeEvent, type DropdownProps, Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import type { AnyObject } from "yup/lib/types"

import { FormField } from "./FormField"

const DropdownField = ({
  field,
  options,
  label,
  className,
  inputClassName,
  horizontal,
  disabled,
  optionLabel = "label",
  optionValue = "code",
  filterBy,
  showClear,
  panelClassName,
  showDefaultValue = false,
  validation,
  handleChange,
  ...dropdownProps
}: Props) => (
  <FormField field={field} className={className} horizontal={horizontal} label={label} validation={validation}>
    {({ field: { name, value, onChange }, form: { setFieldValue }, meta: { touched, error } }: FieldProps) => (
      <Dropdown
        id={name}
        name={name}
        optionLabel={optionLabel}
        optionValue={optionValue}
        options={options}
        onChange={
          handleChange
            ? (e) => {
                handleChange(e)
                onChange(e)
              }
            : onChange
        }
        value={showDefaultValue && !value ? setFieldValue(name, options?.[0]?.[optionValue]) : value}
        disabled={disabled}
        showClear={showClear}
        filter={filterBy !== undefined && options && options.length > 0}
        filterBy={filterBy}
        className={classNames(
          "p-inputtext-sm h-10",
          { "p-invalid": touched && error, horizontal: horizontal },
          inputClassName,
        )}
        panelClassName={panelClassName}
        {...dropdownProps}
      />
    )}
  </FormField>
)

type Props = {
  field: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any
  label?: string
  className?: string
  disabled?: boolean
  optionLabel?: string
  optionValue?: string
  inputClassName?: string
  horizontal?: boolean
  showClear?: boolean
  showDefaultValue?: boolean
  filterBy?: string
  panelClassName?: string
  validation?(value: AnyObject | string): void
  handleChange?(e: DropdownChangeEvent): void
} & DropdownProps

export { DropdownField }
