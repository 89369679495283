import type { ResourceObject } from "fhir"
import type { FormikValues } from "formik"
import { classNames } from "primereact/utils"
import { useMemo, type Key } from "react"

import { type CommonProps, type StackedListItemProps, type StackedListProps, StackedListItem } from "./StackedListItem"

const StackedListContainer = <T extends ResourceObject | FormikValues>({
  data,
  itemModelBuilder,
  rowHover = true,
  itemPadding,
  itemsClassName,
  className,
  withoutDivider,
  itemProps,
  keyGenerator,
  checkable,
  checkedItems,
  onItemCheck,
  getItemId,
}: Props<T>) => {
  const checkedItemsSet = useMemo(
    () => new Set(checkedItems?.map((item) => getItemId?.(item) ?? item.id) ?? []),
    [checkedItems],
  )

  return (
    <ul
      className={classNames("@container divide-gray-200", className, {
        "space-y-4": withoutDivider,
        "divide-y": !withoutDivider,
      })}
    >
      {data?.map((item, index) => {
        const itemChecked = checkedItemsSet.has(getItemId?.(item) ?? item.id)

        return (
          <StackedListItem
            key={keyGenerator?.(item) ?? item?.id ?? index}
            modelData={itemModelBuilder(item, index)}
            rowHover={rowHover}
            itemPadding={itemPadding}
            className={typeof itemsClassName === "function" ? itemsClassName(item) : itemsClassName}
            {...itemProps}
            checkable={checkable}
            checked={itemChecked}
            onCheck={() => {
              onItemCheck?.({ item, checked: !itemChecked })
            }}
          />
        )
      })}
    </ul>
  )
}

type Props<T> = CommonProps & {
  data: T[]
  itemsClassName?: string | ((item: T) => string)
  itemModelBuilder(item: T, itemIndex: number): StackedListItemProps
  className?: string
  withoutDivider?: boolean
  itemProps?: Omit<StackedListProps, "modelData">
  keyGenerator?: (item: T) => Key | undefined
  checkable?: boolean
  checkedItems?: T[]
  onItemCheck?({ item, checked }: { item: T; checked: boolean }): void
  getItemId?(_item: T): string
}

export { StackedListContainer }
