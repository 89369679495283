import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { type FC } from "react"

import { ModulesId, StackedListItem } from "commons"
import { proceduresItemModel } from "procedures"
import { useChartContext } from "chart-view"

import { WrapUpSection } from "../components/details/WrapUpSection"
import type { EncounterData } from "../types"

const ProceduresSection: FC<Props> = ({ procedures, total, icon }) => {
  const { showModule } = useChartContext()

  const goToDetails = (procedureId: string) => {
    showModule({ module: ModulesId.PROCEDURES, moduleParams: { procedureId } })
  }

  return (
    <WrapUpSection
      isEmpty={!total}
      icon={icon}
      emptyMesage="No procedures activated during this encounter"
      className="w-full self-end text-sm"
      sectionTitle="Procedures"
    >
      {procedures?.map((procedureData, index) => (
        <StackedListItem
          key={procedureData.procedure.id ?? index}
          className="!pl-0 py-4"
          modelData={proceduresItemModel({
            procedureData,
            onShowDetails: () => goToDetails(procedureData.procedure.id as string),
          })}
        />
      ))}
    </WrapUpSection>
  )
}

type Props = {
  total?: number
  icon: IconDefinition
} & Pick<EncounterData, "procedures">

export { ProceduresSection }
