import { useQuery } from "@tanstack/react-query"
import {
  type Coverage,
  type Organization,
  type Patient,
  type Reference,
  asReference,
  getResources,
  getResourcesByTypeAsIndex,
  humanNameAsString,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { coverageQueryKeys } from "../query-key"
import type { CoverageData } from "../types"

const useCoverages = (patientId: string) => {
  const { search } = useClient()
  const queryKey = coverageQueryKeys.list(patientId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _include: "subscriber,beneficiary,payor",
        status: "active",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Coverage`, filters, signal })

      const coverages = getResources<Coverage>(bundle, "Coverage")
      const patients = getResourcesByTypeAsIndex<Patient>(bundle, "Patient")
      const payors = getResourcesByTypeAsIndex<Organization>(bundle, "Organization")

      return { coverages, patients, payors }
    },
    meta: { context: { queryKey, patientId } },
  })

  const { coveragesList, coverageRefs, coverages } = useMemo(() => {
    const coveragesList = data?.coverages?.map((coverage) => coverage?.payor?.[0]?.display).join(", ")
    const coverageRefs = data?.coverages.map<Reference>((coverage) => asReference(coverage))
    const coverages = data?.coverages.reduce((acc, coverage) => {
      const subscriber = data?.patients?.[coverage.subscriber?.id as string]
      const beneficiary = data?.patients?.[coverage.beneficiary?.id as string]
      const payor = data?.payors?.[coverage.payor?.[0]?.id as string]

      return [
        ...acc,
        {
          ...coverage,
          subscriber: {
            ...coverage.subscriber,
            display: subscriber?.name?.[0] ? humanNameAsString(subscriber.name[0]) : coverage.subscriber?.display,
          },
          beneficiary: {
            ...coverage.beneficiary,
            display: beneficiary?.name?.[0] ? humanNameAsString(beneficiary.name[0]) : coverage.beneficiary?.display,
          },
          payor: [
            {
              ...coverage.payor?.[0],
              address: payor.address?.[0],
            },
          ],
        },
      ]
    }, Array<CoverageData>())
    return { coveragesList, coverageRefs, coverages }
  }, [data?.coverages, data?.patients])

  return {
    isLoading,
    coverages,
    coveragesList,
    coverageRefs,
  }
}

export { useCoverages }
