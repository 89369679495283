import { faCalendarDays } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { humanNameAsString } from "fhir"
import { useId } from "react"

import {
  DataContainerWithFormSlideoverDetail,
  InfiniteScroll,
  ModulesId,
  SkeletonLoader,
  useCrudReducer,
} from "commons"
import { useAppModuleContext } from "internals"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { useCreateAppointment, usePatientAppointments, useUnbookAppointment, useUpdateAppointment } from "../hooks"
import type { AppointmentFormData } from "../types.ts"
import { getAppointmentType } from "../utils"
import { AppointmentForm } from "./AppointmentForm"
import { INITIAL_VALUES, appointmentValidationSchema, initialValues, sanitize } from "./validations"
import { AgendaListItem } from "./AgendaListItem"

const PatientAppointments = () => {
  const { location } = useOrganizationContext()
  const { patient, patientId, patientRef } = usePatientContext()
  const { appModules } = useAppModuleContext()

  const { initialValue, showSlide, slideContent, isNew, removeItem, reset, add, remove } = useCrudReducer({
    defaultEntity: initialValues(
      { ...INITIAL_VALUES, participant: [{ actor: patientRef, status: "tentative" }] },
      location,
    ),
  })

  const loaderKey = useId()

  const { appointments, isLoading, hasNextPage, fetchNextPage, count } = usePatientAppointments(patientId as string)

  const { createAppointment } = useCreateAppointment(reset)
  const { updateAppointment } = useUpdateAppointment(reset, reset)
  const { unbookAppointment, isUnbooking } = useUnbookAppointment({
    onSettled: () => {
      reset()
      remove()
    },
  })

  const unbook = (appointment: AppointmentFormData) => {
    remove(appointment)
  }

  const onSubmit = (appointment: AppointmentFormData) =>
    isNew ? createAppointment(sanitize(appointment)) : updateAppointment(sanitize(appointment))

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="list" />

  if (isLoading) return loader()

  if (!appointments?.length) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <FontAwesomeIcon icon={faCalendarDays} size="2x" className="text-slate-400" />
        <h3 className="mt-2 text-sm font-semibold text-gray-900">No appointments found</h3>
      </div>
    )
  }

  return (
    <DataContainerWithFormSlideoverDetail
      hasData={count > 0}
      showForm={showSlide && slideContent === "form"}
      formTitle="Appointment"
      iconDataNotFound={appModules[ModulesId.CALENDAR].getIcon()}
      formInitialValue={initialValue}
      validationSchema={appointmentValidationSchema}
      onSubmit={onSubmit}
      onCancel={reset}
      form={<AppointmentForm hidePatientField isEditing={!isNew} />}
      onButtonAddClick={add}
      deleteSupport={{
        deleteActionText: "Unbook",
        confirmDeleteText: `Are you sure you want to unbook ${removeItem?.description}?`,
        isDeleting: isUnbooking,
        showDelete: !!removeItem,
        onConfirmDelete: () => unbookAppointment(removeItem?.id),
        onCancelDelete: remove,
      }}
    >
      <div className="overflow-auto h-full">
        <InfiniteScroll loadMore={() => fetchNextPage()} hasMore={hasNextPage} loader={loader()}>
          <div className="overflow-hidden bg-white">
            <ul className="divide-y divide-gray-200">
              {appointments.map((item) => (
                <AgendaListItem
                  key={item.id}
                  appointment={item}
                  patientName={patient?.name ? humanNameAsString(patient?.name[0]) : ""}
                  patientAvatar={patient?.photo?.[0]?.url}
                  appointmentType={getAppointmentType(item, item.description)}
                  patientId={patientId}
                  onUnbook={() => unbook(item as AppointmentFormData)}
                />
              ))}
            </ul>
          </div>
        </InfiniteScroll>
      </div>
    </DataContainerWithFormSlideoverDetail>
  )
}

export { PatientAppointments }
