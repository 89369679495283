import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { coverageQueryKeys } from "../query-key"

const useCancelCoverage = (hideForm: () => void) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const removeFn = async (coverageId: string) =>
    operationRequest({ endpoint: "Coverage", method: "POST", operation: "cancel-coverage", id: coverageId })

  const { mutate: cancelCoverage, isPending: isCanceling } = useMutation({
    mutationFn: removeFn,
    onError: (error: CustomError, id) => {
      displayNotificationError(registerErrorTrace(error, { coverageId: id }))
    },
    onSuccess: async () => {
      displayNotificationSuccess("Insurance deleted successfully!")
      // datadogLogs.logger.info("Insurance deleted successfully!", { data })
      await queryClient.invalidateQueries({ queryKey: coverageQueryKeys.all })
    },
    onSettled: hideForm,
  })

  return { cancelCoverage, isCanceling }
}

export { useCancelCoverage }
