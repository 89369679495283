import { faPlay } from "@fortawesome/pro-solid-svg-icons"
import { type FC, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { Button, ModulesId } from "commons"
import { usePatientContext } from "patients"
import { useCPOEContext } from "orders"

import { useOpenEncounter } from "../hooks"
import { EncounterConfig } from "./EncounterConfig"
import { EncounterFinishButton } from "./EncounterFinishButton"

const EncounterActions: FC = () => {
  const { patientId, patientRef } = usePatientContext()
  const { openEncounter, isLoading } = useOpenEncounter(patientId)
  const { ordersCount } = useCPOEContext()

  const [searchParams, setSearchParams] = useSearchParams()
  const [startEncounter, setStartEncounter] = useState(false)

  const returnToChart = () => {
    const params = new URLSearchParams({
      ...(searchParams.has("kp") ? { kp: searchParams.get("kp") as string } : {}),
    })

    setSearchParams(params)
  }

  const showEncounterDetails = () => {
    const params = new URLSearchParams({
      ...(searchParams.has("kp") ? { kp: searchParams.get("kp") as string } : {}),
      view: ModulesId.ENCOUNTER,
    })

    setSearchParams(params)
  }

  const configureEncounter = () => {
    setStartEncounter(true)
  }

  return (
    <div>
      {!openEncounter ? (
        <>
          <Button
            label="Start encounter"
            loading={isLoading}
            onClick={configureEncounter}
            icon={faPlay}
            font="font-normal"
            className="button-banner button-primary"
          />

          <EncounterConfig
            visible={startEncounter}
            patientId={patientId}
            patientRef={patientRef}
            onHide={() => setStartEncounter(false)}
          />
        </>
      ) : (
        <EncounterFinishButton
          patientId={patientId}
          patientRef={patientRef}
          encounter={openEncounter}
          onEncounterFinished={returnToChart}
          buttonClassName="button-banner bg-transparent rounded-lg"
          onStopClicked={showEncounterDetails}
          showAssignCheckoutAction={ordersCount > 0}
        />
      )}
    </div>
  )
}

export { EncounterActions }
