import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import { useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { useEffect, type FC, type PropsWithChildren } from "react"
import { Tooltip } from "primereact/tooltip"
import { formatDate } from "date-fns"

import { ModulesId, NumberField } from "commons"
import { vitalSignCodes, vitalSignUnits } from "data"
import { useAppModuleContext } from "internals"
import { calculateBMI } from "utils"

import type { ColData, VitalsFormType } from "../types"
import { getVitalName } from "../utils"
import { VitalChart } from "./VitalChart"

const VitalsForm: FC<Props> = ({ vitals, isTableEmpty, showChart }) => {
  const { appModules } = useAppModuleContext()

  return (
    <div
      className={classNames("relative p-fluid grid flex-1 grow min-w-full", {
        "w-max": !showChart,
      })}
    >
      <div className="sticky left-0 -top-1 bg-white z-[5] w-48 h-9" />
      {isTableEmpty && (
        <div className="text-center absolute top-[50%] w-full flex flex-col justify-center pl-[25%]">
          <FontAwesomeIcon icon={appModules[ModulesId.VITALS].getIcon()} size="2x" className="text-slate-400" />
          <h3 className="mt-2 text-sm font-semibold text-slate-700">No vitals found</h3>
          <p className="mt-2 text-sm text-slate-500">Get started by creating new vitals</p>
        </div>
      )}
      {Object.keys(vitalSignCodes).flatMap(
        (key, i) =>
          key !== "bloodPressure" && (
            <VitalItem
              key={key}
              vital={key as keyof ColData}
              label={getVitalName(key as keyof ColData)}
              field={key as keyof VitalsFormType}
              disabled={key === "bmi"}
              helpText={key === "bmi" ? "weight*703 / [height in inches]ˆ2" : undefined}
              bordered={!isTableEmpty}
            >
              {showChart ? (
                <VitalChart
                  vitalName={key as keyof ColData}
                  vitalRecords={vitals?.[key as keyof ColData]}
                  className="min-h-60 transition-all ease-linear"
                />
              ) : (
                vitals?.[key as keyof ColData]?.map(({ date, value }, index) => (
                  <div
                    key={`${date}_${index}`}
                    className="group relative flex flex-col p-2 text-gray-500 w-28 justify-center items-center odd:bg-[#FBF9FA] pt-6"
                  >
                    {i === 0 && (
                      <span className="p-2 pt-3 absolute -top-10 left-0 right-0 whitespace-nowrap group-odd:bg-[#FBF9FA] rounded-t-2xl text-sm text-center">
                        {formatDate(new Date(date as string), "MM/dd/yyyy")}
                      </span>
                    )}
                    <span className="text-sm text-gray-800">{value?.value ? `${value.value} ${value.unit}` : " "}</span>
                  </div>
                ))
              )}
            </VitalItem>
          ),
      )}
    </div>
  )
}

const VitalItem: FC<PropsWithChildren<VitalItemType>> = ({
  label,
  field,
  vital,
  className,
  disabled,
  helpText,
  children,
  bordered,
}) => {
  const { values, setFieldValue } = useFormikContext<VitalsFormType>()

  useEffect(() => {
    if (values.height && values.weight) {
      setFieldValue("bmi", calculateBMI(values.weight, values.height))
    } else {
      setFieldValue("bmi", 0)
    }
  }, [values])

  return (
    <div className={classNames("grid grid-flow-col-dense w-full relative", className)}>
      <div className="h-full bg-white z-10 col-span-1 sticky left-0 flex items-center pt-6 w-fit">
        <div
          className={classNames(
            "flex flex-row w-48 items-center border-2 h-fit self-center border-slate-200 rounded-lg p-2 md:p-3 lg:p-4 relative",
            { "border-slate-500": !!values[field] },
          )}
        >
          <div className="p-inputgroup flex-1 relative">
            <NumberField
              label={label}
              className="w-full"
              labelClassName={!values[field] ? "text-gray-400/80" : undefined}
              field={field}
              placeholder="0.00"
              showButtons={false}
              minFractionDigits={2}
              maxFractionDigits={2}
              disabled={disabled}
              inputClassName="pr-10"
            />
            <span className="p-inputgroup-addon absolute right-0 bottom-[22%] bg-transparent border-none text-xs h-8">
              {vitalSignUnits[vital as keyof typeof vitalSignUnits]?.toUpperCase()}
            </span>
          </div>
          {helpText && (
            <div className="absolute text-gray-400 top-4 right-5">
              <FontAwesomeIcon icon={faInfoCircle} className={`text-primary vital-help-text-${field}`} />
              <Tooltip
                className="text-xs p-0 m-0"
                target={`.vital-help-text-${field}`}
                position="top"
                event="hover"
                autoHide={false}
              >
                <span>{helpText}</span>
              </Tooltip>
            </div>
          )}
        </div>
      </div>

      <div
        className={classNames("col-span-4 h-full scroll-smooth ml-3 grid grid-flow-col gap-3", {
          "border-b border-[#E5E7EB]": bordered,
        })}
      >
        {children}
      </div>
    </div>
  )
}

type VitalItemType = {
  label: string
  field: keyof VitalsFormType
  vital: keyof ColData
  className?: string
  disabled?: boolean
  helpText?: string
  bordered?: boolean
}

type Props = {
  vitals?: ColData
  isTableEmpty?: boolean
  showChart?: boolean
}

export { VitalsForm }
