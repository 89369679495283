import { AutocompleteCodeableConceptField, CodeableConceptField, MentionTextAreaField, ValueSetIds } from "commons"
import { conditionCategoryCodes, conditionClinicalStatusCodes, conditionSeverityCodes } from "data"

const ConditionForm = ({ isEditing }: Props) => (
  <div className="relative p-fluid grid gap-4 mb-3">
    <AutocompleteCodeableConceptField field="code" label="Code" valueSetId={ValueSetIds.ICD10} readonly={isEditing} />
    <CodeableConceptField field="category[0]" label="Category" codes={conditionCategoryCodes} />
    <fieldset className="grid grid-flow-col-dense gap-2 w-full">
      <CodeableConceptField field="clinicalStatus" label="Clinical status" codes={conditionClinicalStatusCodes} />
      <CodeableConceptField field="severity" label="Severity" codes={conditionSeverityCodes} />
    </fieldset>
    <MentionTextAreaField autoResize field="note[0].text" label="Note" rows={5} />
  </div>
)

type Props = {
  isEditing?: boolean
}

export { ConditionForm }
