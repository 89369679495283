import type { Reference } from "fhir"
import { type FieldProps, useFormikContext } from "formik"
import { type DropdownProps, Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import { type FC, useEffect } from "react"

import { FormField } from "./FormField"

const ReferenceDropdownField: FC<Props> = ({
  field,
  options,
  label,
  className,
  horizontal,
  inputClassName,
  inputContainerClassName,
  optionLabel = "display",
  readonly,
  disabled,
  isClearable,
  filterBy = "display",
  showFilter = true,
  placeholder,
  assignDefaultValue = true,
  labelClassName,
  validation,
  handleChange,
  preferredReference,
  ...props
}) => {
  const { setFieldValue, getFieldMeta } = useFormikContext()
  const fieldMeta = getFieldMeta(field)?.value as Reference | undefined

  const optionKeys = new Set(options?.flatMap(({ id }) => id))

  useEffect(() => {
    const initialValue = getFieldMeta(field)?.initialValue as Reference | undefined
    if (preferredReference && assignDefaultValue && !initialValue?.id && !isClearable && !readonly && !disabled) {
      setFieldValue(
        field,
        options?.find(({ id }) => preferredReference.id === id),
      )
    } else if (assignDefaultValue && !initialValue?.id && !isClearable && !readonly && !disabled && options?.length) {
      setFieldValue(field, options?.[0])
    }
  }, [])

  useEffect(() => {
    if (fieldMeta?.id && !optionKeys.has(fieldMeta?.id ?? "")) {
      setFieldValue(field, undefined)
    }
  }, [Array.from(optionKeys).toString()])

  return (
    <FormField field={field} className={className} horizontal={horizontal} label={label} validation={validation}>
      {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
        <Dropdown
          id={name}
          name={name}
          optionLabel={optionLabel}
          options={options}
          filter={showFilter && filterBy !== undefined && options && options.length > 0}
          filterBy={filterBy}
          resetFilterOnHide={true}
          placeholder={placeholder}
          onChange={(e) => {
            onChange(e)
            handleChange?.(e.value)
          }}
          dataKey="id"
          value={value}
          showClear={isClearable}
          readOnly={readonly}
          disabled={disabled}
          className={classNames(
            "p-inputtext-sm",
            {
              "p-invalid": touched && error,
              horizontal: horizontal,
            },
            inputClassName,
          )}
          {...props}
        />
      )}
    </FormField>
  )
}

type Props = {
  field: string
  options?: Reference[]
  label?: string
  className?: string
  filterBy?: string
  showFilter?: boolean
  horizontal?: boolean
  inputClassName?: string
  inputContainerClassName?: string
  labelClassName?: string
  optionLabel?: string
  readonly?: boolean
  disabled?: boolean
  isClearable?: boolean
  placeholder?: string
  validation?(value: Reference): void
  handleChange?(value: Reference): void
  preferredReference?: Reference
  assignDefaultValue?: boolean
} & DropdownProps

export { ReferenceDropdownField }
