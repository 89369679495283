import { useQuery } from "@tanstack/react-query"

import { useClient } from "api"

import { settingsQueryKeys } from "../../query-keys"

const useCheckLabComboExists = (title: string, orgId: string) => {
  const { search } = useClient()
  const queryKey = settingsQueryKeys.checkComboExists(title)

  const { data, isFetching, refetch, isSuccess } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        "title:exact": title,
        "pd-by-org": orgId,
        _summary: "count",
      })
      const bundle = await search({ endpoint: "PlanDefinition", filters, signal })
      const isDuplicateCombo = bundle.total! > 0

      return { isDuplicateCombo }
    },
    meta: { context: { queryKey, title } },
    enabled: false,
  })

  return {
    isDuplicateCombo: data?.isDuplicateCombo,
    isChecking: isFetching,
    checkComboExists: refetch,
    isChecked: isSuccess,
  }
}

export { useCheckLabComboExists }
