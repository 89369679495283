import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  type Address,
  type ChargeItemDefinition,
  type Coverage,
  type Money,
  type RequestGroup,
  type ServiceRequest,
  asReference,
} from "fhir"
import { type FC, useCallback, useEffect, useMemo } from "react"

import { Accordion, SkeletonLoader } from "commons"
import { getSanitizedOrderCoverage, useUpdateLabOrder } from "commons/labs"
import { getProductKey } from "commons/utils"
import type { BILLING_TYPES_CODES } from "data"
import { useOrganizationContext } from "organization"
import { getBasePrice, getCoverage } from "utils"

import { useCPOERequestsContext, useExtrasPrices, useShippingsAndDiscounts, useUpdateCpoeCids } from "../hooks"
import { type ActionGroupCode, type CpoeLaboratoryPanel, type CpoeRequest, ACTION_GROUP_CODES } from "../types"
import { defaultCoveragesByType, getSectionName, SECTION_KEY_ORDER } from "../utils"
import { CheckoutSection } from "./CheckoutSection"
import { OrdersPayment } from "./OrdersPayment"

const CheckoutConfig: FC = () => {
  const { currentOrganization } = useOrganizationContext()
  const {
    requestGroup,
    selectedRequests,
    selectedDiscounts,
    selectedRefillDiscounts,
    selectedShippingMethods,
    productPrices,
    patient,
    actions,
    coverageByType,
    updateDiscounts,
    updateRefillDiscounts,
    updateShippingMethods,
    setCoverageByType,
    updateRequests,
    updateRG,
    setIsProcessingActions,
    readerAccount,
    activeRequestsInfo: { hasMedsRequest, hasLabsRequest, hasOnlyDFONutras },
    checkoutAddressInfo: { nutrasShippingAddress },
    isProcessingActions,
    isFetchingData,
    ordersCount,
    resetCheckout,
  } = useCPOERequestsContext()

  const { updateCpoeCidsAD } = useUpdateCpoeCids({
    onMutate: () => setIsProcessingActions(true),
    onSuccess: updateRG,
    onSettled: () => setIsProcessingActions(false),
    onError: resetCheckout,
  })

  const itemList = useMemo(
    () =>
      selectedRequests.map(({ resource: { resource } }) => ({
        resourceType: resource?.resourceType ?? "",
        id: resource?.id ?? "",
      })),
    [selectedRequests],
  )
  const medicationAddresses = useMemo(
    () =>
      selectedRequests.reduce((acc, req) => {
        const address =
          req.type === ACTION_GROUP_CODES.PHARMA ? req.medicationData?.dispenseRequest?.shippingAddress : undefined
        return [...acc, ...(address ? [address] : [])]
      }, Array<Address>()),
    [selectedRequests],
  )

  const {
    nutraShippingMethods,
    medsShippingMethods,
    medsDiscounts,
    nutraDiscounts,
    labsDiscounts,
    isLoadingExtrasPrices,
    isPending: isPendingLoadingExtraPrices,
  } = useExtrasPrices(
    itemList,
    {
      ...(nutrasShippingAddress ? { nutraAddress: [nutrasShippingAddress] } : {}),
      ...{ medsAddress: medicationAddresses },
    },
    !isProcessingActions && !!ordersCount,
  )

  const shippingMethods = useMemo(
    () => [...(medsShippingMethods ?? []), ...(nutraShippingMethods ?? [])],
    [medsShippingMethods, nutraShippingMethods],
  )

  const validShippingMethods = useMemo(
    () =>
      selectedShippingMethods &&
      selectedShippingMethods.filter((cid) => shippingMethods.some((method) => method.id === cid.id)),
    [shippingMethods, selectedShippingMethods],
  )

  const discounts = useMemo(
    () => [...(medsDiscounts ?? []), ...(nutraDiscounts ?? []), ...(labsDiscounts ?? [])],
    [medsDiscounts, nutraDiscounts, labsDiscounts],
  )

  const validDiscounts = useMemo(
    () => selectedDiscounts && selectedDiscounts.filter((cid) => discounts.some((discount) => discount.id === cid.id)),
    [discounts, selectedDiscounts],
  )
  const validRefillDiscounts = useMemo(
    () =>
      selectedRefillDiscounts &&
      selectedRefillDiscounts.filter((cid) => discounts.some((discount) => discount.id === cid.id)),
    [discounts, selectedRefillDiscounts],
  )

  const hasInvalidShippingsOrDiscounts = useMemo(
    () =>
      (!!selectedShippingMethods?.length && !validShippingMethods) ||
      (!!selectedDiscounts?.length && !validDiscounts) ||
      (!!selectedRefillDiscounts?.length && !validRefillDiscounts),
    [validDiscounts, validShippingMethods],
  )

  const { nutraShippingMethod, nutraDiscount, medsShippingMethod, medsDiscount, labsDiscount } =
    useShippingsAndDiscounts({
      selectedDiscounts,
      selectedShippings: selectedShippingMethods,
      nutraShippings: nutraShippingMethods,
      medsShippings: medsShippingMethods,
      labsDiscounts,
      medsDiscounts,
      nutraDiscounts,
    })

  const defaultNutraShipping = useMemo(
    () =>
      !hasOnlyDFONutras && !nutraShippingMethod && !!nutraShippingMethods?.length ? nutraShippingMethods[0] : undefined,
    [nutraShippingMethod, nutraShippingMethods?.length, hasOnlyDFONutras],
  )

  useEffect(() => {
    if (!isLoadingExtrasPrices && !isPendingLoadingExtraPrices && !isFetchingData && !isProcessingActions) {
      if (hasInvalidShippingsOrDiscounts || defaultNutraShipping) {
        updateCpoeCidsAD({
          shippingMethod: [...(validShippingMethods ?? []), ...(defaultNutraShipping ? [defaultNutraShipping] : [])],
          discounts: validDiscounts,
          refillDiscounts: validRefillDiscounts,
          requestGroup: requestGroup as RequestGroup,
          readerAccount,
          coverageByType: coverageByType ?? defaultCoveragesByType,
        })
      }
      updateDiscounts(validDiscounts ?? [])
      updateRefillDiscounts(validRefillDiscounts ?? [])
      updateShippingMethods([...(validShippingMethods ?? []), ...(defaultNutraShipping ? [defaultNutraShipping] : [])])
    }
  }, [
    isLoadingExtrasPrices,
    isPendingLoadingExtraPrices,
    hasInvalidShippingsOrDiscounts,
    isProcessingActions,
    requestGroup?.meta?.versionId,
    defaultNutraShipping,
    isFetchingData,
  ])

  const handleChangeDiscounts = useCallback(
    (updatedCID: ChargeItemDefinition, type: ActionGroupCode) => {
      let filtered: ChargeItemDefinition[] = []
      switch (type) {
        case ACTION_GROUP_CODES.NUTRA:
          filtered =
            selectedDiscounts?.filter(
              ({ id }) => updatedCID.id !== id && !nutraDiscounts?.some((cid) => cid.id === id),
            ) ?? []
          break
        case ACTION_GROUP_CODES.PHARMA:
          filtered =
            selectedDiscounts?.filter(
              ({ id }) => updatedCID.id !== id && !medsDiscounts?.some((cid) => cid.id === id),
            ) ?? []
          break
        case ACTION_GROUP_CODES.LAB:
          filtered =
            selectedDiscounts?.filter(
              ({ id }) => updatedCID.id !== id && !labsDiscounts?.some((cid) => cid.id === id),
            ) ?? []
          break
      }
      const toUnselect = selectedDiscounts?.some(({ id }) => updatedCID.id === id)

      updateCpoeCidsAD({
        shippingMethod: !hasMedsRequest ? undefined : selectedShippingMethods,
        requestGroup: requestGroup as RequestGroup,
        coverageByType: !hasMedsRequest ? undefined : coverageByType,
        readerAccount,
        discounts: toUnselect ? filtered : [...filtered, updatedCID],
      })
      updateDiscounts(toUnselect ? filtered : [...filtered, updatedCID])
      updateRefillDiscounts([])
    },
    [nutraDiscount, labsDiscounts, medsDiscounts, selectedDiscounts, requestGroup],
  )

  const handleChangeRefillDiscounts = useCallback(
    (updatedCID: ChargeItemDefinition, type: ActionGroupCode) => {
      let filtered: ChargeItemDefinition[] = []
      switch (type) {
        case ACTION_GROUP_CODES.NUTRA:
          filtered =
            selectedRefillDiscounts?.filter(
              ({ id }) => updatedCID.id !== id && !nutraDiscounts?.some((cid) => cid.id === id),
            ) ?? []
          break
        case ACTION_GROUP_CODES.PHARMA:
          filtered =
            selectedRefillDiscounts?.filter(
              ({ id }) => updatedCID.id !== id && !medsDiscounts?.some((cid) => cid.id === id),
            ) ?? []
          break
        case ACTION_GROUP_CODES.LAB:
          filtered =
            selectedRefillDiscounts?.filter(
              ({ id }) => updatedCID.id !== id && !labsDiscounts?.some((cid) => cid.id === id),
            ) ?? []
          break
      }
      const toUnselect = selectedRefillDiscounts?.some(({ id }) => updatedCID.id === id)

      updateCpoeCidsAD({
        shippingMethod: !hasMedsRequest ? undefined : selectedShippingMethods,
        requestGroup: requestGroup as RequestGroup,
        coverageByType: !hasMedsRequest ? undefined : coverageByType,
        readerAccount,
        refillDiscounts: toUnselect ? filtered : [...filtered, updatedCID],
        discounts: selectedDiscounts,
      })
      updateRefillDiscounts(toUnselect ? filtered : [...filtered, updatedCID])
    },
    [nutraDiscount, labsDiscounts, medsDiscounts, selectedRefillDiscounts, requestGroup],
  )

  const handleChangeShippingMethod = useCallback(
    (updatedCID: ChargeItemDefinition, type: ActionGroupCode, withoutPersist?: boolean) => {
      let filtered: ChargeItemDefinition[] = []
      switch (type) {
        case ACTION_GROUP_CODES.NUTRA:
          filtered =
            selectedShippingMethods?.filter(
              ({ id }) => updatedCID.id !== id && !nutraShippingMethods?.some((cid) => cid.id === id),
            ) ?? []
          break
        case ACTION_GROUP_CODES.PHARMA:
          filtered =
            selectedShippingMethods?.filter(
              ({ id }) => updatedCID.id !== id && !medsShippingMethods?.some((cid) => cid.id === id),
            ) ?? []
          break
      }

      const toUnselect = selectedShippingMethods?.some(({ id }) => updatedCID.id === id)

      if (!withoutPersist) {
        updateCpoeCidsAD({
          coverageByType: !hasMedsRequest ? undefined : coverageByType,
          requestGroup: requestGroup as RequestGroup,
          readerAccount,
          discounts: !hasMedsRequest && !hasLabsRequest ? undefined : selectedDiscounts,
          refillDiscounts: !hasMedsRequest && !hasLabsRequest ? undefined : selectedRefillDiscounts,
          shippingMethod: toUnselect ? filtered : [...filtered, updatedCID],
        })
      }
      updateShippingMethods(toUnselect ? filtered : [...filtered, updatedCID])
    },
    [medsShippingMethods, nutraShippingMethods, selectedShippingMethods, requestGroup],
  )

  const { updateLabOrder } = useUpdateLabOrder(() => setIsProcessingActions(false))

  const handleChangeLabCoverage = useCallback(
    (item: CpoeRequest, billingType: BILLING_TYPES_CODES, sr: ServiceRequest) => {
      const actionIndex = actions.findIndex(({ resource }) => resource.resource?.id === item.resource.resource?.id)

      const newAction = { ...actions[actionIndex] }
      if (newAction.laboratoryData) {
        newAction.laboratoryData.billingType = billingType
        newAction.laboratoryData.serviceRequest = sr

        const panels: CpoeLaboratoryPanel[] = []
        let totalValue = 0
        let currency = "USD"

        for (const panel of newAction.laboratoryData.panels) {
          const cid = productPrices?.[getProductKey({ code: panel.profile.code?.coding, billingType })]
          const price = getBasePrice(cid?.propertyGroup?.[0].priceComponent)?.amount

          panels.push({ ...panel, price })
          totalValue += price?.value ?? 0
          currency = price?.currency ?? currency
        }

        const totalPrice: Money = {
          value: totalValue,
          currency: currency,
        }

        newAction.laboratoryData.panels = panels
        newAction.unitPrice = totalPrice
      }

      updateRequests(actions.toSpliced(actionIndex, 1, newAction))
    },
    [actions],
  )

  const handleSelectCoverage = useCallback(
    (billingType: string, productType: ActionGroupCode, item?: CpoeRequest) => {
      const coverage: Coverage = getCoverage(
        billingType as BILLING_TYPES_CODES,
        asReference(patient),
        asReference(currentOrganization),
      )
      if (productType !== ACTION_GROUP_CODES.LAB) {
        setCoverageByType((coverages) => {
          return {
            ...(coverages ?? defaultCoveragesByType),
            [productType]: coverage,
          }
        })
        updateCpoeCidsAD({
          shippingMethod: !hasMedsRequest ? undefined : selectedShippingMethods,
          discounts: !hasMedsRequest && !hasLabsRequest ? undefined : selectedDiscounts,
          refillDiscounts: !hasMedsRequest && !hasLabsRequest ? undefined : selectedRefillDiscounts,
          requestGroup: requestGroup as RequestGroup,
          readerAccount,
          coverageByType: {
            ...(coverageByType ?? defaultCoveragesByType),
            [productType]: coverage,
          },
        })
      } else if (item?.laboratoryData?.serviceRequest) {
        setIsProcessingActions(true)
        updateLabOrder(
          getSanitizedOrderCoverage(item.laboratoryData.serviceRequest, billingType, asReference(currentOrganization)),
          {
            onSuccess(data) {
              handleChangeLabCoverage(item, billingType as BILLING_TYPES_CODES, data)
            },
          },
        )
      }
    },
    [requestGroup, coverageByType],
  )

  const activeRequestByType = useMemo(
    () =>
      selectedRequests.reduce(
        (acc, request) => {
          const type = request.type
          return { ...acc, [type]: [...(acc[type] ?? []), request] }
        },
        {} as Record<ActionGroupCode, CpoeRequest[]>,
      ),
    [selectedRequests],
  )

  const sections = useMemo(
    () => Object.keys(activeRequestByType).sort((a, b) => (SECTION_KEY_ORDER[a] ?? 0) - (SECTION_KEY_ORDER[b] ?? 0)),
    [activeRequestByType],
  )

  const getDiscountBySection = useCallback(
    ({
      section,
      getSelectedDiscount,
      getSelectedRefillDiscount,
    }: {
      section: ActionGroupCode | string
      getSelectedDiscount?: boolean
      getSelectedRefillDiscount?: boolean
    }) => {
      switch (section) {
        case ACTION_GROUP_CODES.NUTRA:
          return getSelectedRefillDiscount
            ? selectedRefillDiscounts?.filter((cid) => cid.id === nutraDiscount?.id)
            : getSelectedDiscount
              ? nutraDiscount
                ? [nutraDiscount]
                : undefined
              : nutraDiscounts

        case ACTION_GROUP_CODES.PHARMA:
          return getSelectedRefillDiscount
            ? selectedRefillDiscounts?.filter((cid) => cid.id === medsDiscount?.id)
            : getSelectedDiscount
              ? medsDiscount
                ? [medsDiscount]
                : undefined
              : medsDiscounts

        case ACTION_GROUP_CODES.LAB:
          return getSelectedRefillDiscount
            ? selectedRefillDiscounts?.filter((cid) => cid.id === labsDiscount?.id)
            : getSelectedDiscount
              ? labsDiscount
                ? [labsDiscount]
                : undefined
              : labsDiscounts
      }
    },
    [labsDiscount, medsDiscount, nutraDiscount, labsDiscounts, medsDiscounts, nutraDiscounts, selectedRefillDiscounts],
  )

  const getShippingByType = useCallback(
    (type: ActionGroupCode | string, selectedShipping?: boolean) => {
      switch (type) {
        case ACTION_GROUP_CODES.NUTRA:
          return selectedShipping ? (nutraShippingMethod ? [nutraShippingMethod] : undefined) : nutraShippingMethods

        case ACTION_GROUP_CODES.PHARMA:
          return selectedShipping ? (medsShippingMethod ? [medsShippingMethod] : undefined) : medsShippingMethods
      }
    },
    [medsShippingMethod, nutraShippingMethod, medsShippingMethods, nutraShippingMethods],
  )

  const totalItemsCost = useMemo(
    () => selectedRequests.reduce((total, { unitPrice }) => total + (unitPrice.value ?? 0), 0),
    [selectedRequests],
  )

  if (isLoadingExtrasPrices) return <SkeletonLoader loaderType="two-lines" extraLine repeats={sections.length * 3} />
  return (
    <>
      <Accordion
        activeIndex={[...sections.flatMap((_, index) => index)]}
        multiple
        data={sections}
        headerTemplate={(section) => (
          <h3 className="font-medium capitalize text-sm text-gray-700 px-5">{getSectionName(section)}</h3>
        )}
        contentTemplate={(section) => (
          <CheckoutSection
            sectionRequestsType={section as ActionGroupCode}
            requests={activeRequestByType[section as ActionGroupCode]}
            handleSelectDiscount={handleChangeDiscounts}
            handleSelectShippingMethod={handleChangeShippingMethod}
            shippingMethods={getShippingByType(section)}
            selectedShippingMethod={getShippingByType(section, true)?.[0]}
            discounts={getDiscountBySection({ section })}
            selectedDiscount={getDiscountBySection({ section, getSelectedDiscount: true })?.[0]}
            nutrasShippingAddress={nutrasShippingAddress}
            totalItemsCost={totalItemsCost}
            handleSelectCoverage={handleSelectCoverage}
            coverage={coverageByType?.[section as ActionGroupCode]}
            selectedRefillDiscount={getDiscountBySection({ section, getSelectedRefillDiscount: true })?.[0]}
            handleSelectRefillDiscount={handleChangeRefillDiscounts}
          />
        )}
        expandIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" className="p-accordion-toggle-icon mr-0" />}
        collapseIcon={<FontAwesomeIcon icon={faChevronUp} size="sm" className="p-accordion-toggle-icon mr-0" />}
      />
      <OrdersPayment />
    </>
  )
}

export { CheckoutConfig }
