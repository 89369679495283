import { faCalendarCheck, faCalendarDays, faLoader, faRotateRight } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type Task, codeableConceptAsString } from "fhir"
import { Chip } from "primereact/chip"
import { classNames } from "primereact/utils"
import type { FC } from "react"
import { useNavigate } from "react-router-dom"
import type { QueryKey } from "@tanstack/react-query"

import { taskPriorityCodes } from "data"
import { useOrganizationContext } from "organization"
import { getTaskDate, getTaskStatusReason, taskDateToString, usePatchTask } from "tasks"
import { strCapitalize } from "utils"

import { ListItem } from "../../components/ListItem"
import { isDispenseTask } from "../utils"

const MedicationOrderTaskListItem: FC<Props> = ({ task, queryKeysToInvalidate, enableRetry }) => {
  const { currentOrganizationId } = useOrganizationContext()
  const navigate = useNavigate()

  const { updateTask, isUpdating } = usePatchTask({
    queryKeysToInvalidate,
    successMessage: "Task retried successfully!",
  })

  const handleRetryTask = () => updateTask({ id: task.id, status: "ready" })

  const getPriorityColor = (priority: string) => {
    return {
      "is-danger": priority === taskPriorityCodes[3].code,
      "is-warning": priority === taskPriorityCodes[2].code,
      "is-info": priority === taskPriorityCodes[1].code,
      "is-secondary": priority === taskPriorityCodes[0].code,
    }
  }

  const getStatusColor = (status: string) => {
    return {
      "is-info": status === "ready",
      "is-secondary": status === "requested",
      "is-warning": status === "on-hold",
      "is-draft": status === "entered-in-error",
      "is-success": status === "completed",
      "is-danger": status === "cancelled",
    }
  }

  const Head = (
    <>
      {task.code && (
        <p title="Code" className="whitespace-nowrap overflow-hidden text-ellipsis">
          {codeableConceptAsString(task.code)}
        </p>
      )}
      {task.status && (
        <span title="Status">
          <Chip
            label={task.status}
            className={classNames("ml-2 custom-chip custom-chip", getStatusColor(task.status))}
          />
        </span>
      )}
      {task.priority && (
        <span title="Priority">
          <Chip label={task.priority} className={classNames("ml-2 custom-chip", getPriorityColor(task.priority))} />
        </span>
      )}
    </>
  )

  const Details = (
    <>
      <span title="Authored on" className="flex items-center">
        <FontAwesomeIcon icon={faCalendarDays} className="mr-1.5 text-gray-400" />
        Authored on: {taskDateToString(task.authoredOn as string)}
      </span>
      <span title="Executed" className="flex items-center pl-2">
        <FontAwesomeIcon icon={faCalendarCheck} className="mr-1.5 text-gray-400" />
        {strCapitalize(task.status === "on-hold" ? "scheduled" : task.status)} on: {getTaskDate(task)}
      </span>
      {!!task.statusReason && task.status === "failed" && (
        <span title="Status reason" className="flex flex-wrap whitespace-break-spaces items-center w-full !border-none">
          {getTaskStatusReason(task)}
        </span>
      )}
    </>
  )

  return (
    <ListItem
      header={Head}
      subHeader={Details}
      onContentClick={() => {
        navigate(`/orgs/${currentOrganizationId}/tasks?id=${task.id}`)
      }}
      actionItem={
        enableRetry && task.status === "failed" && isDispenseTask(task)
          ? {
              label: "Retry",
              icon: <FontAwesomeIcon icon={isUpdating ? faLoader : faRotateRight} spin={isUpdating} size="sm" />,
              command: handleRetryTask,
            }
          : undefined
      }
    />
  )
}

type Props = {
  task: Task
  queryKeysToInvalidate?: QueryKey[]
  enableRetry?: boolean
}

export { MedicationOrderTaskListItem }
