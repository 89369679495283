import { DialogFormContainer } from "commons"
import { MED_FEE_TYPE } from "data"

import { useEditMK } from "../../hooks/meds/useEditMK"
import { type MedItem, type MedParams, MEDICATIONS_SECTION_TYPE } from "../../types"
import { MedicationForm } from "./MedForm"

const MedicationFormContainer = ({ med, medType, onHide }: Props) => {
  const initialValue = med ?? {
    fee: [{ value: 0 }],
    ...(medType === MEDICATIONS_SECTION_TYPE.MEDICATIONS_RX_SECTION && { practicePrice: 0 }),
    ...(medType === MEDICATIONS_SECTION_TYPE.MEDICATIONS_NUTRACEUTICALS_SECTION && { patientPrice: 0 }),
    id: "",
    feeType: medType === MEDICATIONS_SECTION_TYPE.MEDICATIONS_RX_SECTION ? MED_FEE_TYPE.Fixed : MED_FEE_TYPE.Normal,
  }

  const { editMK } = useEditMK(onHide)
  const onSubmit = (data: MedParams) => {
    editMK(data)
  }

  const isEditing = !!(initialValue as MedItem).mk?.id

  return (
    <DialogFormContainer
      onSubmit={onSubmit}
      onCancel={onHide}
      showForm={true}
      title="Edit medication"
      saveLabel="Update"
      initialValue={{
        ...initialValue,
      }}
      useFormik
      isLoadingData={false}
    >
      <MedicationForm medType={medType} isEditing={isEditing} />
    </DialogFormContainer>
  )
}

type Props = {
  med?: MedItem
  medType: MEDICATIONS_SECTION_TYPE
  onHide(): void
}

export { MedicationFormContainer }
