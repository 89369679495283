import { Fragment, useMemo } from "react"

import { AuditEventsView } from "audit-events"
import { MasterDetailSection, SectionItem } from "commons"
import { usePrefetchNoteSnippets } from "commons/hooks"
import { ModulesId } from "commons/Module"
import { PRACTITIONER_ROLE } from "data"
import { useAppModuleContext } from "internals"
import { useOrganizationContext } from "organization"

import { AppointmentTypesContainer } from "./appointments/AppointmentTypesContainer"
import { Devices } from "./Devices"
import { InventoryContainer } from "./inventory/InventoryContainer"
import { LabsContainer } from "./labs/LabsContainer"
import { MedicationsContainer } from "./meds/MedicationsContainer"
import { OperatingHours } from "./operatingHours/OperatingHours"
import { PaymentContainer } from "./PaymentContainer"
import { PractitionersContainer } from "./practitioners/PractitionersContainer"
import { Rooms } from "./rooms/Rooms"
import { SettingsView } from "./SettingsView"
import { NoteSnippetsSectionContainer } from "./snippets/SnippetsNoteSectionContainer"

const AdministrationView = () => {
  const { isModuleActive } = useAppModuleContext()
  const { practitionerRolesIndexedByCode, currentOrganizationId } = useOrganizationContext()

  const { prefetch } = usePrefetchNoteSnippets({ authorId: currentOrganizationId })

  const AdministrationSections = useMemo(() => {
    const sections = [
      {
        id: "settings",
        roles: [PRACTITIONER_ROLE.ROOT_ADMIN],
        title: "Settings",
        className: "flex-1 overflow-auto",
        element: <SettingsView />,
      },
      {
        id: "snippets-note",
        roles: [PRACTITIONER_ROLE.ALL],
        title: "Snippet notes",
        className: "flex-col overflow-auto",
        element: <NoteSnippetsSectionContainer />,
        onMouseEnter: prefetch,
      },
      {
        id: "aes",
        roles: [PRACTITIONER_ROLE.ALL],
        title: "Audit Log",
        className: "flex-col overflow-auto",
        element: <AuditEventsView />,
      },
      {
        id: "payment",
        roles: [PRACTITIONER_ROLE.ALL],
        title: "Payment",
        className: "overflow-auto",
        element: <PaymentContainer />,
      },
      {
        id: "practitioners",
        roles: [PRACTITIONER_ROLE.ROOT_ADMIN],
        title: "Practitioners",
        className: "overflow-hidden flex flex-col flex-1 w-full",
        element: <PractitionersContainer />,
      },
    ]

    if (isModuleActive(ModulesId.CALENDAR)) {
      sections.push(
        {
          id: "hours-op",
          roles: [PRACTITIONER_ROLE.ALL],
          title: "Practice Hours",
          className: "overflow-hidden",
          element: <OperatingHours />,
        },
        {
          id: "rooms",
          roles: [PRACTITIONER_ROLE.ALL],
          title: "Rooms",
          className: "flex-1 overflow-auto",
          element: <Rooms />,
        },
        {
          id: "devices",
          roles: [PRACTITIONER_ROLE.ALL],
          title: "Devices",
          className: "flex-1 overflow-auto",
          element: <Devices />,
        },
        {
          id: "appointment-types",
          roles: [PRACTITIONER_ROLE.ALL],
          title: "Appointment Types",
          className: "flex-col overflow-hidden grow",
          element: <AppointmentTypesContainer />,
        },
      )
    }

    if (isModuleActive(ModulesId.LABSORDER)) {
      sections.push({
        id: "labs",
        roles: [PRACTITIONER_ROLE.ALL],
        title: "Lab Configuration",
        className: "overflow-auto",
        element: <LabsContainer />,
      })
    }

    if (isModuleActive(ModulesId.MEDICATIONR) || isModuleActive(ModulesId.EPRESCRIBE)) {
      sections.push({
        id: "medications",
        roles: [PRACTITIONER_ROLE.ALL],
        title: "Medication Configuration",
        className: "overflow-auto",
        element: (
          <MedicationsContainer
            isRxActive={isModuleActive(ModulesId.EPRESCRIBE)}
            isNutrasActive={isModuleActive(ModulesId.MEDICATIONR)}
          />
        ),
      })
    }

    if (isModuleActive(ModulesId.MEDICATIONR) && isModuleActive(ModulesId.CHECKOUT_INVENTORY)) {
      sections.push({
        id: "inventory",
        roles: [PRACTITIONER_ROLE.ALL],
        title: "Inventory",
        className: "flex-col overflow-hidden grow",
        element: <InventoryContainer />,
      })
    }

    return sections
  }, [isModuleActive])

  return (
    <>
      <div className="px-6 py-3 border-b shadow-sm">
        <h6 className="font-medium text-lg">Administration</h6>
        <p className="text-sm text-gray-500">Current organization administration</p>
      </div>
      <MasterDetailSection>
        {AdministrationSections.map(({ roles, element, id, ...sectionItemProps }, index) =>
          roles.includes(PRACTITIONER_ROLE.ALL) || roles.some((role) => !!practitionerRolesIndexedByCode[role]) ? (
            <SectionItem key={id} id={id} {...sectionItemProps}>
              {element}
            </SectionItem>
          ) : (
            <Fragment key={index} />
          ),
        )}
      </MasterDetailSection>
    </>
  )
}

export { AdministrationView }
