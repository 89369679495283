import { useInfiniteQuery } from "@tanstack/react-query"
import { compareDesc } from "date-fns"
import type { Observation, ObservationValue, Quantity, ResourceObject } from "fhir"
import { useMemo, useReducer } from "react"

import { useClient } from "api"
import type { LatestVitals } from "commons/types"
import { vitalSignCodes } from "data"
import { calculateBMI, getQuantity } from "utils"

import { vitalsQueryKeys } from "../query-keys"
import type { ColData, VitalRecord } from "../types"

const useVitals = (patientId: string, encounter?: string) => {
  const { operationRequest } = useClient()
  const queryKey = vitalsQueryKeys.list(patientId, encounter)

  const { data, isFetching, isFetchingNextPage, hasNextPage, isLoading, fetchNextPage } = useInfiniteQuery<
    VitalsQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        patient: `${patientId}`,
        page: `${pageParam}`,
        count: "20",
        ...(encounter ? { encounter } : {}),
      })
      const results = await operationRequest<AidboxQueryData>({
        endpoint: "",
        method: "GET",
        operation: "query/vitals-history-record",
        filters,
        signal,
      })

      const maxResultsFetched = (pageParam as number) * 20

      const next = results.total > maxResultsFetched ? (pageParam as number) + 1 : undefined
      return { vitalHistory: results, total: results.total, next: next }
    },
    getNextPageParam: (lastPage) => lastPage.next,
    initialPageParam: 1,
    meta: { context: { queryKey, patientId } },
  })

  const { vitals, latestVitals, totalData } = useMemo(() => {
    const vitalsData: ColData = {
      height: undefined,
      weight: undefined,
      bmi: undefined,
      heartRate: undefined,
      respiratoryRate: undefined,
      oxygenSaturation: undefined,
      temperature: undefined,
      bloodPressureDiastolic: undefined,
      bloodPressureSystolic: undefined,
    }

    const newData = data?.pages?.flatMap((page) => (page.vitalHistory as AidboxQueryData)?.data)
    const total = data?.pages.reduce((val, page) => val + page.total, 0)

    newData?.forEach((vital) => {
      for (const key in vitalSignCodes) {
        const value = vitalSignCodes[key as keyof typeof vitalSignCodes]

        if (key === "bloodPressure") {
          const component = vital.resources.find(
            (obj) => obj.code.coding?.[0].code === vitalSignCodes["bloodPressure"].coding[0].code,
          )?.component

          const systolicValue = component?.find(
            (bpType) => bpType.code.coding?.[0].code === vitalSignCodes.bloodPressureSystolic.coding[0].code,
          )?.value
          const diastolicValue = component?.find(
            (bpType) => bpType.code.coding?.[0].code === vitalSignCodes.bloodPressureDiastolic.coding[0].code,
          )?.value

          vitalsData["bloodPressureSystolic"] = [
            ...(vitalsData["bloodPressureSystolic"] ?? []),
            { value: systolicValue?.Quantity, date: vital.effective_date },
          ]
          vitalsData["bloodPressureDiastolic"] = [
            ...(vitalsData["bloodPressureDiastolic"] ?? []),
            { value: diastolicValue?.Quantity, date: vital.effective_date },
          ]
        } else if (key !== "effective_date" && key !== "bloodPressureDiastolic" && key !== "bloodPressureSystolic") {
          const vitalValue = vital.resources
            .sort(({ effective: e1 }, { effective: e2 }) => compareDesc(e1?.dateTime ?? "", e2?.dateTime ?? ""))
            .find((obj) => obj.code.coding?.[0].code === value.coding[0].code)?.value
          vitalsData[key as keyof ColData] = [
            ...(vitalsData[key as keyof ColData] ?? []),
            {
              value: vitalValue?.Quantity,
              date: vital.effective_date,
            },
          ]
        }
      }
    })

    const bloodPressureSystolic = getLatestRecordedVitalData(vitalsData.bloodPressureSystolic)
    const bloodPressureDiastolic = getLatestRecordedVitalData(vitalsData.bloodPressureDiastolic)

    const latestVitals: LatestVitals = {
      height: getLatestRecordedVitalData(vitalsData.height),
      weight: getLatestRecordedVitalData(vitalsData.weight),
      heartRate: getLatestRecordedVitalData(vitalsData.heartRate),
      respiratoryRate: getLatestRecordedVitalData(vitalsData.respiratoryRate),
      oxygenSaturation: getLatestRecordedVitalData(vitalsData.oxygenSaturation),
      temperature: getLatestRecordedVitalData(vitalsData.temperature),
      bloodPressure: {
        value: getQuantity([bloodPressureSystolic?.value as Quantity, bloodPressureDiastolic?.value as Quantity]),
        behavior: (bloodPressureSystolic?.behavior ?? 0) + (bloodPressureDiastolic?.behavior ?? 0),
      },
    }

    return { vitals: vitalsData, latestVitals, totalData: total }
  }, [data?.pages])

  vitals.bmi = []
  vitals.weight?.forEach((weight, index) => {
    const height = vitals.height?.[index]
    vitals.bmi = [
      ...(vitals.bmi ?? []),
      {
        date: weight.date,
        value: {
          unit: "",
          value: calculateBMI(weight.value?.value as number, height?.value?.value as number),
        },
      },
    ]
  })
  return { vitals, latestVitals, isFetching, isFetchingNextPage, hasNextPage, isLoading, totalData, fetchNextPage }
}

const getBehavior = (records: VitalRecord[]) => {
  const getObservationValue = (record: VitalRecord) => (record.value?.value ?? 0) as number

  let behavior = 0

  if (records[1]) {
    if (getObservationValue(records[0]) > getObservationValue(records[1])) behavior = 1
    if (getObservationValue(records[0]) < getObservationValue(records[1])) behavior = -1
  }

  return behavior
}

const getLatestRecordedVitalData = (records?: VitalRecord[]) => {
  if (!records) return undefined

  const recordedData = records.filter((record) => record?.value?.value !== undefined)
  const latestRecordData = recordedData[0]

  return {
    value: latestRecordData?.value,
    behavior: getBehavior(recordedData),
  }
}

const vitalsReducer = (state: VitalsState, action: ActionType): VitalsState => {
  switch (action.type) {
    case "updateVitals": {
      let count = 0
      const newData = action.payload?.reduce(
        (prev, current) => {
          const newVitals = prev?.vitalHistory.concat(current.vitalHistory)
          count += current.vitalHistory.length

          return { vitalHistory: newVitals, next: current.next, total: current.total }
        },
        { vitalHistory: [], next: 0, total: 0 },
      )

      return {
        vitalHistory: newData?.vitalHistory,
        lastValue: newData?.vitalHistory[0]?.value,
        count,
      }
    }
    case "changeVitalValue":
      return {
        ...state,
        vitalHistory: state.vitalHistory?.map((vital, index) => {
          if (action.payload.vitalIndex === index) {
            if (action.payload.vitalComponentIndex !== undefined) {
              return {
                ...vital,
                component: vital.component?.map((component, index) =>
                  index === action.payload.vitalComponentIndex
                    ? {
                        ...component,
                        value: {
                          ...component.value,
                          Quantity: { ...component.value?.Quantity, value: action.payload.value },
                        },
                      }
                    : component,
                ),
              }
            }
            return { ...vital, value: { Quantity: { ...vital.value?.Quantity, value: action.payload.value } } }
          }
          return vital
        }),
      }

    default:
      return state
  }
}

const initialState: VitalsState = {
  vitalHistory: undefined,
  lastValue: undefined,
  count: 0,
}

const useVitalsReducer = () => {
  const [{ vitalHistory, lastValue, count }, dispatch] = useReducer(vitalsReducer, initialState)

  const updateVitals = (payload: UpdateVitalsPayload) => {
    dispatch({ type: "updateVitals", payload })
  }

  const changeVitalValue = (value: number, vitalIndex: number, vitalComponentIndex?: number) => {
    dispatch({ type: "changeVitalValue", payload: { value, vitalIndex, vitalComponentIndex } })
  }

  return { vitalHistory, lastValue, count, updateVitals, changeVitalValue }
}

type VitalsQueryData = {
  vitalHistory: ResourceObject | undefined
  next: number | undefined
  total: number
}

type ChangeVitalPayload = { value: number; vitalIndex: number; vitalComponentIndex?: number }

type ActionType =
  | {
      type: "updateVitals"
      payload: UpdateVitalsPayload
    }
  | { type: "changeVitalValue"; payload: ChangeVitalPayload }

type VitalsState = {
  vitalHistory: Observation[] | undefined
  lastValue: ObservationValue | undefined
  count: number
}

interface AidboxQueryData extends ResourceObject {
  data: Array<{ effective_date: string; resources: Observation[] }>
  query: Array<string>
  total: number
}

type UpdateVitalsPayload = {
  vitalHistory: Observation[]
  next: number | undefined
  total: number
}[]

export { useVitals, useVitalsReducer }
