import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type Medication, type MedicationKnowledge, type MedicationRequest, isCarePlan, isMedication } from "fhir"
import { ConfirmDialog } from "primereact/confirmdialog"
import { Dialog } from "primereact/dialog"
import { useId, useMemo, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"

import {
  Button,
  EmptyMessage,
  FooterActions,
  InfiniteScroll,
  MedicationKnowledgeDetails,
  ModulesId,
  SkeletonLoader,
  Slideover,
  StackedListContainer,
} from "commons"
import { type MedicationRequestData, MedicationRequestDetails, useMedicationRequests } from "commons/meds"
import { getMedsProductConfigurations, getPriceByCode } from "commons/utils"
import { BILLING_TYPES_CODES } from "data"
import { useAppModuleContext } from "internals"
import { useOrganizationContext } from "organization"
import { getBillingTypeCode } from "utils"

import { useProductPrices } from "commons/hooks"
import { GENERIC_BILLING_TYPE } from "commons/types"
import {
  useCompleteMrOrder,
  useDeleteMedicationRequest,
  useMedicationRequestDataBind,
  useStopMrOrder,
} from "../../hooks"
import type { MedicationRequestFormData, MedicationRequestInfo } from "../../types"
import { discardMR } from "../helpers"
import { MedicationDetails } from "../MedicationDetails"
import { prescriptionItemModel } from "./prescriptionItemModel"
import { PrescriptionsBulkActions } from "./PrescriptionsBulkActions"
import { getRenewedMR } from "../../utils/transformers"

const PrescriptionList = ({ searchFilter, statusFilter, onEdit, onRenew }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { patientId } = useParams()
  const { appModules } = useAppModuleContext()
  const { currentOrganizationId, hasCreditCard } = useOrganizationContext()
  const { medicationRequests, medicationKnowledges, medicationDispenses, isLoading, hasNextPage, fetchNextPage } =
    useMedicationRequests({
      patientId: patientId as string,
      category: "medication",
      statusFilter: statusFilter ?? [],
      perPage: 100,
      page: 1,
      searchText: searchFilter,
    })

  const [selectedMK, setSelectedMK] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()
  const [selectedMed, setSelectedMed] = useState<Medication>()
  const [validationMessage, setValidationMessage] = useState<string | undefined>(undefined)

  const { billToPracticeOrInsuranceMrs, billToPatientMrs } = medicationRequests?.reduce(
    (acc, mr) => {
      const billingTypeCode = getBillingTypeCode(mr)

      return billingTypeCode === undefined
        ? {
            billToPracticeOrInsuranceMrs: [...acc.billToPracticeOrInsuranceMrs, mr],
            billToPatientMrs: [...acc.billToPatientMrs, mr],
          }
        : billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT
          ? {
              billToPracticeOrInsuranceMrs: acc.billToPracticeOrInsuranceMrs,
              billToPatientMrs: [...acc.billToPatientMrs, mr],
            }
          : {
              billToPracticeOrInsuranceMrs: [...acc.billToPracticeOrInsuranceMrs, mr],
              billToPatientMrs: acc.billToPatientMrs,
            }
    },
    { billToPracticeOrInsuranceMrs: [] as MedicationRequest[], billToPatientMrs: [] as MedicationRequest[] },
  ) ?? { billToPracticeOrInsuranceMrs: [], billToPatientMrs: [] }

  const billToPracticeOrInsuranceProductConfigurations = billToPracticeOrInsuranceMrs
    ? getMedsProductConfigurations({
        meds: billToPracticeOrInsuranceMrs,
        specifiedQuantity: true,
        billingType: GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE,
      })
    : []

  const billToPatientProductConfigurations = billToPatientMrs
    ? getMedsProductConfigurations({
        meds: billToPatientMrs,
        specifiedQuantity: true,
        billingType: GENERIC_BILLING_TYPE.BILL_PATIENT,
      })
    : []

  const { productPrices } = useProductPrices({
    organizationId: currentOrganizationId,
    productsConfigurations: [...billToPracticeOrInsuranceProductConfigurations, ...billToPatientProductConfigurations],
  })

  const { medicationRequestData: mrDataWithoutPrices } = useMedicationRequestDataBind({
    medicationRequests,
    medicationKnowledges,
    medicationDispenses,
  })

  const [selectedPrescriptions, setSelectedPrescriptions] = useState<MedicationRequestData[]>([])

  const { deleteMedicationRequest } = useDeleteMedicationRequest()
  const { stopMedicationRequest } = useStopMrOrder()
  const { completeMedicationRequest } = useCompleteMrOrder()

  const medicationRequestData: MedicationRequestData[] = mrDataWithoutPrices.map((item) => {
    const billingTypeCode = getBillingTypeCode(item?.medicationRequestInfo)
    const patientPrice = getPriceByCode({
      productPrices,
      medCoding: item.medicationKnowledge?.code?.coding,
      quantity: item.medicationRequestInfo?.dispenseRequest?.quantity?.value,
      billingType: GENERIC_BILLING_TYPE.BILL_PATIENT,
    })
    const practicePrice = getPriceByCode({
      productPrices,
      medCoding: item.medicationKnowledge?.code?.coding,
      quantity: item.medicationRequestInfo?.dispenseRequest?.quantity?.value,
      billingType: GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE,
    })

    const priceInfo =
      billingTypeCode === undefined
        ? { patientPrice, practicePrice }
        : billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT
          ? { patientPrice }
          : { practicePrice }

    return {
      ...item,
      ...priceInfo,
    }
  })

  const selectedMedicationRequestData = useMemo(() => {
    const mrId = searchParams.get("mrId")
    return medicationRequestData.find(({ medicationRequestInfo }) => medicationRequestInfo.id === mrId)
  }, [searchParams, medicationRequestData])

  const navToAddPrescriptions = () => {
    searchParams.set("subview", ModulesId.EPRESCRIBE_NEW)
    setSearchParams(searchParams)
  }

  const navToPrescriptionsDraft = () => {
    searchParams.set("subview", ModulesId.EPRESCRIBE_DRAFT)
    setSearchParams(searchParams)
  }

  const hasOutidePlanDraftMr = medicationRequestData.some(
    ({ medicationRequestInfo }) =>
      medicationRequestInfo.status === "draft" && !medicationRequestInfo?.basedOn?.some(isCarePlan),
  )

  const addOptions = useMemo(
    () => [
      {
        id: "finish-drafts",
        label: "Finish Drafts",
        style: "default",
        command: navToPrescriptionsDraft,
        disabled: !hasOutidePlanDraftMr,
      },
      {
        id: "create-prescription",
        label: "Create Prescription",
        command: navToAddPrescriptions,
      },
    ],
    [appModules, hasOutidePlanDraftMr],
  )

  const discard = (mrId: string) => {
    discardMR(mrId, () => {
      deleteMedicationRequest(mrId)
    })
  }
  const stop = (mrId: string) => {
    stopMedicationRequest(mrId)
  }
  const complete = (mrId: string) => {
    completeMedicationRequest(mrId)
  }

  const previewMedOrMK = (mrData: MedicationRequestData) => {
    if (mrData.medicationRequestInfo.contained?.[0] && isMedication(mrData.medicationRequestInfo.contained[0])) {
      setSelectedMed(mrData.medicationRequestInfo.contained[0])
      return
    }

    setSelectedMK({ mk: mrData.medicationKnowledge, mr: mrData.medicationRequestInfo })
  }

  const showMRDetails = (mr: MedicationRequestInfo) => {
    if (mr.id) {
      searchParams.set("mrId", mr.id)
      setSearchParams(searchParams)
    }
  }

  const hideMRDetails = () => {
    searchParams.delete("mrId")
    setSearchParams(searchParams)
  }

  const renew = async (mr: MedicationRequestInfo, mk?: MedicationKnowledge) => {
    onRenew(getRenewedMR({ ...mr }, mk))
  }

  const hideRefillValidationDialog = () => {
    setValidationMessage(undefined)
  }

  const handleItemCheck = ({ item, checked }: { item: MedicationRequestData; checked: boolean }) => {
    if (checked) {
      setSelectedPrescriptions((prescriptions) => [...prescriptions, item])
      return
    }
    setSelectedPrescriptions((prescriptions) =>
      prescriptions.filter(({ medicationRequestInfo }) => medicationRequestInfo.id !== item.medicationRequestInfo.id),
    )
  }

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  if (isLoading) {
    return loader()
  }

  if (medicationRequestData.length === 0) {
    return (
      <EmptyMessage
        icon={appModules[ModulesId.EPRESCRIBE].getIcon()}
        itemTitle="Prescription"
        actionText="Create Prescription"
        action={navToAddPrescriptions}
        actionId="create-prescription"
      />
    )
  }

  return (
    <>
      <div className="h-full overflow-auto">
        <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
          <StackedListContainer
            data={medicationRequestData}
            keyGenerator={({ medicationRequestInfo }) => medicationRequestInfo.id}
            itemModelBuilder={(item) =>
              prescriptionItemModel({
                mrData: item,
                edit: onEdit,
                discard,
                stop,
                complete,
                renew,
                preview: previewMedOrMK,
                showDetails: showMRDetails,
                hiddenPrices: true,
                hideOrganizationPrice: !hasCreditCard,
              })
            }
            checkable
            checkedItems={selectedPrescriptions}
            onItemCheck={handleItemCheck}
            getItemId={({ medicationRequestInfo }) => medicationRequestInfo.id!}
          />
        </InfiniteScroll>
      </div>
      <FooterActions actions={addOptions} className="pl-3">
        <PrescriptionsBulkActions
          selectedItems={selectedPrescriptions}
          toggleSelectAll={() => {
            setSelectedPrescriptions((prescriptions) => (prescriptions.length ? [] : medicationRequestData))
          }}
        />
      </FooterActions>
      <ConfirmDialog />
      <MedicationKnowledgeDetails
        selectedMK={selectedMK?.mk}
        onHide={() => setSelectedMK(undefined)}
        showImgFallback={false}
        mr={selectedMK?.mr}
      />
      <MedicationDetails selectedMed={selectedMed} setSelectedMed={setSelectedMed} />
      <Slideover
        showSlide={!!selectedMedicationRequestData}
        onHide={hideMRDetails}
        title="Medication Request Details"
        showCancel
        cancelLabel="Close"
        dismissable
        showCloseIcon
      >
        {selectedMedicationRequestData && (
          <MedicationRequestDetails
            medicationRequest={selectedMedicationRequestData.medicationRequestInfo}
            medicationDispense={selectedMedicationRequestData.medicationDispense}
          />
        )}
      </Slideover>
      <Dialog
        modal
        visible={!!validationMessage}
        onHide={hideRefillValidationDialog}
        style={{ width: "30vw" }}
        header="Refill not allowed"
        footer={
          <div className="flex justify-end">
            <Button label="Accept" onClick={hideRefillValidationDialog} />
          </div>
        }
      >
        <div className="flex items-center gap-3">
          <FontAwesomeIcon icon={faTriangleExclamation} size="lg" />
          {validationMessage}
        </div>
      </Dialog>
    </>
  )
}

type Props = {
  statusFilter?: string[]
  searchFilter?: string
  onEdit(mrData: MedicationRequestFormData): void
  onRenew(mrData: MedicationRequestFormData): void
}

export { PrescriptionList }
