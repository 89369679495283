import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { Composition } from "fhir"

import { useClient } from "api"
import { commonsQueryKeys, type CustomError } from "commons"
import type { NoteSnippetsQueryResponse } from "commons/hooks"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

const useUpdateSnippetsNote = ({ onSuccess, onSettled }: { onSuccess?: () => void; onSettled?: () => void } = {}) => {
  const { patch } = useClient()
  const queryClient = useQueryClient()

  const updateSnippetsNoteMutationFn = (snippet: Composition) => patch("Composition", snippet.id as string, snippet)

  const { mutate: updateSnippetsNote, isPending: isAdding } = useMutation({
    mutationFn: updateSnippetsNoteMutationFn,
    onMutate: async (snippet) => {
      const queryKey = commonsQueryKeys.snippetNotes(snippet.author?.[0]?.id as string)

      await queryClient.cancelQueries({
        queryKey,
      })

      const previousNoteSnippets = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, (old: NoteSnippetsQueryResponse) => {
        const pageIndex = old.pages.findIndex(({ compositions }) => compositions.some(({ id }) => id === snippet.id))

        if (pageIndex === -1) {
          return { previousNoteSnippets, queryKey }
        }

        const snippetIndex = old.pages[pageIndex].compositions.findIndex(({ id }) => id === snippet.id)

        if (snippetIndex === -1) {
          return { previousNoteSnippets, queryKey }
        }

        const updatedCompositions = [...old.pages[pageIndex].compositions]
        updatedCompositions[snippetIndex] = snippet

        return {
          ...old,
          pages: old.pages.map((page, idx) =>
            idx === pageIndex
              ? {
                  ...page,
                  compositions: updatedCompositions,
                }
              : page,
          ),
        }
      })

      return { previousNoteSnippets, queryKey }
    },
    onSuccess: onSuccess,
    onError: (error: CustomError, newSnippet, context) => {
      const { previousNoteSnippets, queryKey } = context ?? { previousNoteSnippets: [], queryKey: [""] }

      queryClient.setQueryData(queryKey, previousNoteSnippets)
      displayNotificationError(registerErrorTrace(error, newSnippet))
    },
    onSettled: (_, __, ___, context) => {
      const { queryKey } = context ?? { previousNoteSnippets: [], queryKey: [""] }

      queryClient.invalidateQueries({ queryKey })
      onSettled?.()
    },
  })

  return { updateSnippetsNote, isAdding }
}
export { useUpdateSnippetsNote }
