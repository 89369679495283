import type { DatesSetArg, EventContentArg, EventInput, PluginDef } from "@fullcalendar/core/index.js"
import FullCalendar from "@fullcalendar/react"
import { type FC, type RefObject } from "react"
import DayGridPlugin from "@fullcalendar/daygrid"
import TimeGridPlugin from "@fullcalendar/timegrid"

const CalendarContainer: FC<Props> = ({
  calendarRef,
  events,
  AgendaPlugin,
  handleUpdateDateRange,
  renderEvent,
  onShowFilters,
}) => (
  <FullCalendar
    ref={calendarRef}
    plugins={[DayGridPlugin, TimeGridPlugin, AgendaPlugin]}
    initialView="dayGridMonth"
    events={events}
    eventTimeFormat={{
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }}
    customButtons={{
      filterButton: {
        text: "Filters",
        click: onShowFilters,
      },
    }}
    headerToolbar={{
      left: "title",
      right: "filterButton prev,today,next dayGridMonth timeGridWeek agenda",
    }}
    buttonText={{ month: "Month", week: "Week", agenda: "Agenda" }}
    height="100%"
    datesSet={handleUpdateDateRange}
    eventContent={renderEvent}
  />
)

type Props = {
  calendarRef: RefObject<FullCalendar>
  AgendaPlugin: PluginDef
  events: EventInput[]
  renderEvent(eventInfo: EventContentArg): JSX.Element
  onShowFilters(): void
  handleUpdateDateRange(_: DatesSetArg): void
}

export { CalendarContainer }
