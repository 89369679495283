import { faBarcode, faHouseBuilding } from "@fortawesome/pro-regular-svg-icons"
import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type Composition, type MedicationKnowledge, codeableConceptAsString } from "fhir"
import cloneDeep from "lodash/cloneDeep"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { classNames } from "primereact/utils"
import { type FC, useId, useMemo, useReducer } from "react"

import { InfiniteScroll, SkeletonLoader } from "commons"
import { MEDICATION_CATALOG, MED_FEE_TYPE } from "data"
import { useOrganizationContext } from "organization"
import { getCommonCode, substractPrice } from "utils"

import { useMksByCategory } from "../../hooks"
import {
  type MedItem,
  type MedicationsAdvanceFilter,
  type ReferencePriceItem,
  MEDICATIONS_SECTION_TYPE,
} from "../../types"
import { actionsBodyTemplate } from "../../utils/templates"
import { MedicationFormContainer } from "./MedicationFormContainer"

const MedicationsNutraceuticals: FC<Props> = ({ filters, isLoadingCatalogs, catalogsById }) => {
  const { currentOrganizationId } = useOrganizationContext()

  const { medsWithCID, isLoading, hasNextPage, fetchNextPage } = useMksByCategory({
    organizationId: currentOrganizationId,
    category: MEDICATION_CATALOG.NUTRA,
    filters,
    enabled: !isLoadingCatalogs,
    specifyReferencePriceQuantities: true,
  })

  const { edit, showForm, reset, med } = useReducerState()

  const medDatas = useMemo(
    () =>
      medsWithCID.map((med) => {
        // TODO: remove this once change to give fee separately is done
        const { sub: patientPrice, num2: fee } = substractPrice(med?.patientPrice ?? 0, med.fee[0]?.value)
        const { mk } = med
        const sku = getCommonCode({ codes: mk.code?.coding })
        const display = codeableConceptAsString(mk.code)
        const catalogId = mk.catalogHeader?.[0]?.id ?? ""
        const catalog = catalogsById[catalogId]?.title
        const referencePrices = med.referencePrices

        return {
          medData: { display, sku, catalog, mk, referencePrices },
          inventory: med.inventory?.qty?.value,
          patientPrice: patientPrice.toNumber(),
          practicePrice: med.practicePrice,
          fee: fee.toNumber(),
          total: (med?.patientPrice ?? 0).toFixed(2),
          referencePrices,
          externalAction: [
            {
              label: "Edit",
              icon: <FontAwesomeIcon icon={faEdit} size="sm" />,
              command: () =>
                edit(
                  cloneDeep({
                    ...med,
                    patientPrice: patientPrice.toNumber(),
                    catalog,
                    display,
                    sku,
                    fee: [{ value: fee.toNumber() }],
                    feeType: MED_FEE_TYPE.Normal,
                  }),
                ),
            },
          ],
        }
      }),
    [medsWithCID],
  )

  const medDetailsBodyTemplate = ({
    medData,
  }: {
    medData: {
      display: string
      catalog: string
      sku: string
      mk: MedicationKnowledge
      referencePrices: ReferencePriceItem[]
    }
  }) => {
    const strength = medData.mk?.ingredient?.[0]?.strength?.numerator?.unit
    const packaging = medData.mk?.packaging?.type?.coding?.[0]?.display
    const doseForm = codeableConceptAsString(medData.mk.doseForm)

    const header = (
      <>
        {medData.catalog && (
          <p
            title="Medication"
            className="mr-2 flex items-baseline gap-2 font-medium truncate max-w-sm lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl 3xl:max-w-full"
          >
            <span className="min-w-0 truncate" title={medData.display}>
              {medData.display}
            </span>
            {strength && (
              <span className="min-w-0 text-gray-500 truncate" title={strength}>
                {strength}
              </span>
            )}
          </p>
        )}
      </>
    )

    const details = (
      <div className="mt-2 flex">
        <div className="flex items-center text-xs text-gray-400 divide-x divide-gray-400 gap-2 space-around">
          {medData.catalog && (
            <span title="catalog" className="flex items-center">
              <FontAwesomeIcon icon={faHouseBuilding} className="mr-1.5 text-gray-400" />
              {medData.catalog}
            </span>
          )}
          {medData.sku && (
            <span title="code" className="flex items-center">
              <FontAwesomeIcon icon={faBarcode} className="mr-1.5 ml-1.5 text-gray-400" />
              {medData.sku}
            </span>
          )}
          {medData?.mk?.doseForm?.coding?.[0]?.display && (
            <span title="form" className="flex items-center pl-1.5">
              {`Dose form: ${medData?.mk?.doseForm?.coding?.[0]?.display}`}
            </span>
          )}
          {medData.mk?.packaging?.type?.coding?.[0]?.display && medData.mk?.packaging?.quantity?.value && (
            <span title="packaging" className="flex items-center pl-1.5">
              {`Pkg: ${medData.mk?.packaging?.type?.coding?.[0]?.display}/${medData?.mk?.packaging?.quantity?.value}`}
            </span>
          )}
        </div>
      </div>
    )

    const referencePrices = !medData.referencePrices.length ? null : (
      <div className="mt-2 flex gap-1 text-xs text-gray-400">
        {`${packaging} Price:`}
        <div className="flex items-center divide-x divide-gray-400 gap-2 space-around">
          {medData.referencePrices.map(({ qty, price }, index) => (
            <div key={qty} className={classNames({ "pl-2": !!index })}>
              {[`${qty} ${doseForm}`, `$${price?.toFixed(2)}`].join(" - ")}
            </div>
          ))}
        </div>
      </div>
    )

    return (
      <span className="block">
        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="flex items-center w-full">
            <div className="flex">
              <div className="truncate">
                <div className="flex text-sm">{header}</div>
                {details}
                {referencePrices}
              </div>
            </div>
          </div>
        </div>
      </span>
    )
  }

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <>
      <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
        <DataTable
          value={medDatas}
          loading={isLoading || isLoadingCatalogs}
          scrollable
          scrollHeight="calc(100vh - 300px)"
        >
          <Column field="medData" body={medDetailsBodyTemplate} rowSpan={6} headerClassName="bg-transparent" />
          <Column field="inventory" header="Inventory" bodyClassName="text-xs" headerClassName="default-header" />
          <Column
            field="practicePrice"
            header="Practice Price"
            bodyClassName="text-xs"
            headerClassName="default-header"
          />
          <Column
            field="patientPrice"
            header="Patient Price"
            bodyClassName="text-xs"
            headerClassName="default-header"
          />
          <Column field="fee" header="Fee" bodyClassName="text-xs" headerClassName="default-header" />
          <Column field="total" header="Total" bodyClassName="text-xs" headerClassName="default-header" />
          <Column field="external_action" headerClassName="bg-transparent" body={actionsBodyTemplate} />
        </DataTable>
      </InfiniteScroll>
      {showForm && (
        <MedicationFormContainer
          medType={MEDICATIONS_SECTION_TYPE.MEDICATIONS_NUTRACEUTICALS_SECTION}
          med={med}
          onHide={reset}
        />
      )}
    </>
  )
}

const initialState = {
  showForm: false,
} as State

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: "reset" | "edit"
    payload?: MedItem | string | undefined
  },
) => {
  switch (type) {
    case "reset":
      return { ...initialState }
    case "edit":
      return { ...state, showForm: true, med: payload as MedItem }
    default:
      return state
  }
}

const useReducerState = () => {
  const state = initialState
  const [{ showForm, med }, dispatch] = useReducer(reducer, state)

  const reset = () => {
    dispatch({ type: "reset" })
  }

  const edit = (payload: MedItem) => {
    dispatch({ type: "edit", payload })
  }

  return {
    showForm,
    med,
    reset,
    edit,
  }
}

type State = {
  med?: MedItem
  showForm: boolean
  isClone: boolean
}

type Props = {
  filters: MedicationsAdvanceFilter & { searchText?: string }
  catalogsById: Record<string, Composition>
  filter(filters: MedicationsAdvanceFilter): void
  isLoadingCatalogs?: boolean
}

export { MedicationsNutraceuticals }
