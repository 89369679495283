import { useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { ModulesId } from "commons"
import { type PLAN_SUBVIEWS, type Plan, PlanProvider } from "commons/care-plans"
import { useAppModuleContext } from "internals"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { PlanListContainer } from "./PlanListContainer"

const PlansContainer = () => {
  const { currentOrganizationId: organizationId } = useOrganizationContext()
  const { appSubModules } = useAppModuleContext()
  const { patient } = usePatientContext()
  const { setActiveSubModule, activeSubModule, setSearchData, showModule } = useChartContext()
  const [params] = useSearchParams()

  const [selectedPlan, setSelectedPlan] = useState<Plan>()

  const updateSubview = useCallback(
    (subviewId?: PLAN_SUBVIEWS, planId?: string) => {
      if (subviewId)
        showModule({ module: ModulesId.PLANS, moduleParams: { subview: subviewId, ...(planId ? { planId } : {}) } })
      else {
        setSelectedPlan(undefined)
        showModule({ module: ModulesId.PLANS })
      }

      setSearchData({ showSearch: false })
    },
    [showModule, setSearchData],
  )

  useEffect(() => {
    let subview = null
    const subviewParam = params.get("subview")
    if (params.get("view") === ModulesId.PLANS) {
      if (subviewParam) {
        subview = appSubModules["plan"][subviewParam]

        if (!subview) setSelectedPlan(undefined)
      }
      setActiveSubModule(subview)
    }
  }, [params])

  return (
    <PlanProvider
      patient={patient}
      currentOrganizationId={organizationId}
      plan={selectedPlan}
      updateCurrentPlan={(plan) => setSelectedPlan(plan)}
      navigateToSubview={updateSubview}
    >
      {activeSubModule ? activeSubModule.renderContent() : <PlanListContainer />}
    </PlanProvider>
  )
}

export { PlansContainer }
