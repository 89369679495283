import {
  type CarePlan,
  type CarePlanActivityArray,
  type MedicationRequest,
  type Patient,
  type PlanDefinition,
  type QuestionnaireResponse,
  type QuestionnaireResponseItemArrayItemArray,
  type Reference,
  type ResourceObject,
  type ServiceRequest,
  type Specimen,
  asReference,
} from "fhir"
import { v4 } from "uuid"
import * as Yup from "yup"

import {
  BILLING_TYPES_CODES,
  HEALTH_GORILLA_VALUE_CHECK,
  MC_ACTIVITY_TYPE,
  ServiceRequestCategory,
  formatsByTypes,
  mrCategoryCodes,
} from "data"
import { SYSTEM_VALUES } from "system-values"
import { formatDate, getServiceRequestBillingType } from "utils"

import { getLabOrderInitialValues, getSanitizedOrderCoverage } from "../../labs"
import type { PractitionerInfo } from "../../types"
import type { ComboDefinition, PlanData } from "../types"
import { getAdhocActivity } from "../utils"

const getPlanInitialValues = (
  patient: Patient,
  plan?: PlanData,
  openEncounterRef?: Reference,
  practitionerRoleRef?: Reference,
) => {
  const initialData = { order: undefined, srPanels: [], srCombos: [], rx: undefined, nutraceutical: undefined } as {
    srPanels: ServiceRequest[]
    srCombos: ServiceRequest[]
    order?: ServiceRequest
    specimen?: Specimen
    nutraceutical?: MedicationRequest[]
    rx?: MedicationRequest[]
  }

  const { srCombos, srPanels, order, specimen, nutraceutical, rx } =
    plan?.carePlan?.contained?.reduce((acc, resource) => {
      let srCombo: ServiceRequest | undefined
      let srPanel: ServiceRequest | undefined
      let srOrder: ServiceRequest | undefined
      if ((resource as ResourceObject).resourceType === "ServiceRequest") {
        const currentResCode = (resource as ServiceRequest).category?.find(({ coding }) =>
          coding?.some(({ system }) => system === SYSTEM_VALUES.SERVICE_REQUEST_TYPE),
        )?.coding?.[0]?.code

        switch (currentResCode) {
          case ServiceRequestCategory.LAB_ORDER_COMBO:
            srCombo = resource as ServiceRequest
            break

          case ServiceRequestCategory.LAB_ORDER:
            srOrder = resource as ServiceRequest
            break

          case ServiceRequestCategory.LAB_ORDER_PANEL:
            srPanel = resource as ServiceRequest
            break
        }

        return {
          ...acc,
          srCombos: [...acc.srCombos, ...(srCombo ? [srCombo] : [])],
          srPanels: [...acc.srPanels, ...(srPanel ? [srPanel] : [])],
          order: srOrder ?? acc.order,
        }
      } else if ((resource as ResourceObject).resourceType === "Specimen") {
        return { ...acc, specimen: resource as Specimen }
      } else if ((resource as ResourceObject).resourceType === "MedicationRequest") {
        const isNutra = (resource as MedicationRequest).category?.some(({ coding }) =>
          coding?.some(
            ({ code, system }) =>
              code === mrCategoryCodes.nutraceutical.code && system === mrCategoryCodes.nutraceutical.system,
          ),
        )
        const isRX = (resource as MedicationRequest).category?.some(({ coding }) =>
          coding?.some(
            ({ code, system }) =>
              code === mrCategoryCodes.medication.code && system === mrCategoryCodes.medication.system,
          ),
        )
        if (isNutra) return { ...acc, nutraceutical: [...(acc.nutraceutical ?? []), resource as MedicationRequest] }
        if (isRX) return { ...acc, rx: [...(acc.rx ?? []), resource as MedicationRequest] }
        else return { ...acc }
      } else return { ...acc }
    }, initialData) ?? initialData

  const billingType = getServiceRequestBillingType(order)
  const requester = order?.requester ?? rx?.[0]?.requester ?? nutraceutical?.[0]?.requester ?? practitionerRoleRef

  return {
    ...plan,
    order:
      order ??
      getLabOrderInitialValues({
        id: v4(),
        patient,
        encounter: openEncounterRef,
        practitionerRoleRef: requester,
        reasonCode: plan?.icd10,
      }),
    billingType: !order ? BILLING_TYPES_CODES.BILL_PATIENT : billingType,
    panels: srPanels.flatMap((sr) => sr.instantiatesCanonical),
    combo: srCombos?.[0]?.instantiatesCanonical?.[0],
    appointment: { ...plan?.appointment, start: plan?.appointment?.start ?? undefined },
    performer: order?.performer?.[0],
    mcActivity:
      plan?.mcActivity ?? plan?.algorithmActivity?.[0]?.outcomeReference?.[0]
        ? plan?.algorithmActivity?.[0]
        : undefined,
    specimenDate: plan?.specimenDate ?? specimen?.receivedTime,
    bloodDrawnInOffice: !!(plan?.specimenDate || specimen?.receivedTime),
    nutraceutical,
    rx,
    requester,
  } as PlanData
}

const getPanelInitialValues = ({
  pdUrl,
  patient,
  practitionerRole,
  performer,
  pd,
  isCombo = false,
  canonicalQrs,
}: {
  pdUrl: string
  patient: Reference
  practitionerRole?: Reference
  performer?: Reference
  pd?: PlanDefinition
  isCombo?: boolean
  canonicalQrs?: Record<string, Reference>
}): ServiceRequest => {
  // Support link QRs with sr panels
  const panelQuestionnaireCanonicals = pd?.action
    ?.filter(({ definition }) => definition?.canonical?.includes("Questionnaire"))
    .flatMap(({ definition }) => definition?.canonical as string)

  const supportingInfo = panelQuestionnaireCanonicals
    ?.map((canonical) => canonicalQrs?.[canonical] as Reference)
    ?.filter((ref) => ref !== undefined)

  return {
    ...(pd
      ? {
          code: {
            coding: pd.identifier?.map((ids) => ({
              code: ids.value,
              system: ids.system,
              display: pd.title ?? pd.name ?? "Unspecified",
            })),
            text: pd.title,
          },
        }
      : {}),
    id: v4(),
    intent: "order",
    category: [
      {
        coding: [
          {
            system: SYSTEM_VALUES.SERVICE_REQUEST_TYPE,
            code: isCombo ? "lab-order-combo" : "lab-order-panel",
            display: isCombo ? "Lab Order combo" : "Lab Order Test",
          },
        ],
        text: isCombo ? "Lab Order combo" : "Lab Order Test",
      },
    ],
    status: "draft",
    authoredOn: new Date().toISOString(),
    subject: patient,
    requester: practitionerRole,
    instantiatesCanonical: [pdUrl],
    ...(performer ? { performer: [performer] } : {}),
    // basedOn: undefined, //for combos
    resourceType: "ServiceRequest",
    supportingInfo: supportingInfo?.length ? supportingInfo : undefined,
  }
}

const sanitizeCP = (
  planData: PlanData,
  patientRef: Reference,
  orgRef: Reference,
  combos?: ComboDefinition[],
  encounter?: Reference,
) => {
  let comboPanel: ServiceRequest | undefined
  let comboPanels: ServiceRequest[] | undefined
  const comboDef = combos?.find((c) => c.canonical === planData.combo)
  let specimen: Specimen | undefined
  let specimenAdhocActivity: CarePlanActivityArray | undefined
  let qRespAndAdhocActivities: { activity: CarePlanActivityArray; qr: QuestionnaireResponse }[] | undefined
  let nutras: MedicationRequest[] | undefined
  let rxs: MedicationRequest[] | undefined
  let order: ServiceRequest | undefined
  let qrsCanonicalRefs: Record<string, Reference> = {}

  const updatedCP: Partial<CarePlan> = {
    contained: [],
    activity: [],
    encounter,
  }

  if (planData.mcActivity?.detail?.kind !== MC_ACTIVITY_TYPE.MC_SURVEY) {
    // Handle blood draw logic. Add Specimen and QRs to the CP
    if (planData.bloodDrawnInOffice) {
      const localRef = v4()
      specimen = {
        receivedTime: planData.specimenDate ?? formatDate(new Date(), formatsByTypes.ISO_8601_DATETIME),
        resourceType: "Specimen",
        subject: patientRef,
        id: localRef,
      }

      specimenAdhocActivity = getAdhocActivity(asReference(specimen))

      if (planData.questionnairesAnswered?.length) {
        qRespAndAdhocActivities = planData.questionnairesAnswered.reduce((acc, qData) => {
          const qItem = qData.questionnaire.item?.[0]
          const qCanonical = `${qData.questionnaire.url}|${qData.questionnaire.version}`

          const qrResponseItem: QuestionnaireResponseItemArrayItemArray = {
            text: qItem?.text,
            answer: [
              {
                value: { string: qItem?.answerOption?.[qData?.response ?? 0].value?.Coding?.display },
              },
            ],
            linkId: qItem?.code?.[0]?.code as string,
          }

          const localRef = v4()
          const newQR = {
            id: localRef,
            questionnaire: qCanonical,
            status: "completed",
            resourceType: "QuestionnaireResponse",
            item: [qrResponseItem],
            subject: order?.subject,
            authored: formatDate(new Date(), formatsByTypes.ISO_8601_DATETIME),
            encounter,
          } as QuestionnaireResponse

          const newAct = getAdhocActivity(asReference(newQR))
          qrsCanonicalRefs = {
            ...qrsCanonicalRefs,
            [qCanonical]: {
              localRef,
              display: qCanonical,
              resourceType: "QuestionnaireResponse",
            },
          }

          return [...acc, { activity: newAct, qr: newQR }]
        }, Array<{ activity: CarePlanActivityArray; qr: QuestionnaireResponse }>())
      }
    }

    if (planData.combo) {
      comboPanels = comboDef?.panels.map((panel) =>
        getPanelInitialValues({
          pdUrl: `${panel.url}|${panel.version}`,
          patient: patientRef,
          practitionerRole: planData.requester,
          performer: comboDef?.performer,
          pd: panel,
          canonicalQrs: qrsCanonicalRefs,
        }),
      )

      comboPanel = {
        ...getPanelInitialValues({
          pdUrl: planData.combo,
          patient: patientRef,
          practitionerRole: planData.requester,
          performer: comboDef?.performer,
          pd: comboDef?.definition,
          isCombo: true,
          canonicalQrs: qrsCanonicalRefs,
        }),
        basedOn: comboPanels?.map((panel) => ({ localRef: panel.id, resourceType: "ServiceRequest" }) as Reference),
      }
    }

    // Filter all cannonicals that are NOT in combo panels and NOT a combo
    const extraPanels =
      planData.panels
        ?.filter((p) => p !== planData.combo && !comboDef?.panels.some(({ url, version }) => `${url}|${version}` === p))
        .map<ServiceRequest>((pdUrl) =>
          getPanelInitialValues({
            pdUrl,
            patient: patientRef,
            practitionerRole: planData.requester,
            performer: planData.performer,
            // Use the extra PDs saved in PlanData to craft panel codes
            pd: planData.extraPlanDefinition?.find((d) => `${d.url}|${d.version}` === pdUrl),
            canonicalQrs: qrsCanonicalRefs,
          }),
        ) ?? []

    const extraPanelIds = extraPanels.map(
      (panel) => ({ localRef: panel.id, resourceType: "ServiceRequest" }) as Reference,
    )
    order =
      comboPanel || extraPanelIds.length
        ? ({
            ...planData.order,
            requester: planData.requester,
            basedOn: comboPanel
              ? [{ localRef: comboPanel.id, resourceType: "ServiceRequest" }, ...extraPanelIds]
              : [...extraPanelIds],
            performer: [planData.performer],
            encounter,
          } as ServiceRequest)
        : undefined

    order = order && getSanitizedOrderCoverage(order, planData.billingType ?? BILLING_TYPES_CODES.BILL_PATIENT, orgRef)

    // Workaround to get the code of the extra panels already configured in the CP
    const newExtraPanels = extraPanels.reduce((acc, panel) => {
      if (!panel.code) {
        const planCode = (planData.carePlan.contained as ServiceRequest[])?.find(
          (item) => item.instantiatesCanonical?.[0] === panel.instantiatesCanonical?.[0],
        )?.code

        return [...acc, { ...panel, code: planCode } as ServiceRequest]
      }
      return [...acc, panel]
    }, Array<ServiceRequest>())

    //Handle save contained Nutras
    if (planData.nutraceutical?.length) {
      nutras = planData.nutraceutical.reduce((acc, mrInfo) => {
        return [...acc, { id: v4(), ...mrInfo, requester: planData.requester }]
      }, Array<MedicationRequest>())
    }

    //Handle save contained Rx
    if (planData.rx?.length) {
      rxs = planData.rx.reduce((acc, mrInfo) => {
        return [...acc, { id: v4(), ...mrInfo, requester: planData.requester }]
      }, Array<MedicationRequest>())
    }

    // Linking Speciment with SR lab order
    if (order && specimen?.id)
      order.specimen = [{ localRef: specimen.id, display: specimen.receivedTime, resourceType: "Specimen" }]

    updatedCP.contained = comboPanel
      ? [
          ...(order ? [order] : []),
          comboPanel,
          ...(comboPanels ?? []),
          ...newExtraPanels,
          ...(specimen ? [specimen] : []),
          ...(qRespAndAdhocActivities ? [...qRespAndAdhocActivities.map(({ qr }) => qr)] : []),
          ...(nutras ? [...nutras] : []),
          ...(rxs ? [...rxs] : []),
        ]
      : [
          ...(order ? [order] : []),
          ...newExtraPanels,
          ...(specimen ? [specimen] : []),
          ...(qRespAndAdhocActivities ? [...qRespAndAdhocActivities.map(({ qr }) => qr)] : []),
          ...(nutras ? [...nutras] : []),
          ...(rxs ? [...rxs] : []),
        ]
  }

  const sanitizedActivities = sanitizePlanActivity(planData.carePlan.activity)

  updatedCP.activity = [
    ...(planData.mcActivity ? [planData.mcActivity] : []),
    ...(sanitizedActivities?.filter(
      ({ outcomeCodeableConcept }) =>
        !["adhoc-resource", "request-mc"].includes(outcomeCodeableConcept?.[0]?.coding?.[0]?.code as string),
    ) ?? []),
    ...(specimenAdhocActivity ? [specimenAdhocActivity] : []),
    ...(qRespAndAdhocActivities ? qRespAndAdhocActivities.map(({ activity }) => activity) : []),
  ]

  /* Returns a modified CP activities and contained with Order, Combo, Combo Panels, Extra Panels, QRs and Specimen  */
  return updatedCP
}

const planValidationSchema = Yup.object().shape({
  mcActivity: Yup.object().nullable(),
  billingType: Yup.string().required("Billing type is required"),
  specimenDate: Yup.date().when("bloodDrawnInOffice", {
    is: true,
    then: Yup.date().required("Specimen date is required"),
  }),
})

const validatePlanRequester = ({
  value,
  practitionersInfo,
  requiresLabs,
  requiresRX,
  isRequired = true,
}: {
  value: Reference | undefined
  practitionersInfo: PractitionerInfo[]
  requiresLabs?: boolean
  requiresRX?: boolean
  isRequired?: boolean
}) => {
  if (isRequired) {
    if (!value?.id) return "Practitioner is required"

    const selectedPractInfo = practitionersInfo.find(({ practitionerRoleRef }) => practitionerRoleRef?.id === value?.id)

    if (!selectedPractInfo) return "A valid practitioner is required"
    if (
      requiresLabs &&
      !selectedPractInfo?.practitioner.identifier?.some(({ value }) => value?.includes(HEALTH_GORILLA_VALUE_CHECK))
    ) {
      return "This practitioner can't request labs order. Missing Health Gorilla ID"
    }

    if (
      requiresRX &&
      !selectedPractInfo?.practitionerRole?.identifier?.some(
        ({ system }) => system === SYSTEM_VALUES.LIFEFILE_PRACTITIONER,
      )
    ) {
      return "This practitioner doesn't have a valid Lifefile ID"
    }
  }
}

const sanitizePlanActivity = (activity?: CarePlanActivityArray[]) =>
  activity?.reduce((acc, act) => {
    const outcomeReference = act.outcomeReference?.reduce((acc, oRef) => {
      const ref = oRef?.id && oRef.resourceType ? asReference(oRef) : undefined
      return [...acc, ...(ref ? [ref] : [])]
    }, Array<Reference>())

    delete act.outcomeReference

    return [
      ...acc,
      {
        ...act,
        ...(outcomeReference?.length ? { outcomeReference } : {}),
      },
    ]
  }, Array<CarePlanActivityArray>())

export { getLabOrderInitialValues, getPlanInitialValues, planValidationSchema, sanitizeCP, validatePlanRequester }
