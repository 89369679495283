import type { CodeableConcept, Coding } from "fhir"
import { type FieldProps } from "formik"
import { type DropdownChangeEvent, type DropdownProps, Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import type { FC } from "react"

import { FormField } from "./FormField"

const CodeableConceptField: FC<Props> = ({
  field,
  codes,
  label,
  className,
  autoSelect,
  horizontal,
  inputClassName,
  readOnly,
  disabled,
  validation,
  handleChange,
  ...dropdownProps
}) => {
  const firstOption = autoSelect ? codes[0] : undefined

  return (
    <FormField field={field} className={className} horizontal={horizontal} label={label} validation={validation}>
      {({ field: { name, value }, form: { setFieldValue }, meta: { touched, error } }: FieldProps) => (
        <Dropdown
          id={name}
          name={name}
          optionLabel="display"
          options={codes}
          readOnly={readOnly}
          disabled={disabled}
          onChange={(e) => {
            handleChange?.(e)
            setFieldValue(name, { coding: [e.value], text: e.value.display } as CodeableConcept)
          }}
          value={value?.coding?.[0] ?? firstOption}
          className={classNames(
            "p-inputtext-sm",
            {
              "p-invalid": touched && error,
              horizontal: horizontal,
            },
            inputClassName,
          )}
          {...dropdownProps}
        />
      )}
    </FormField>
  )
}

type Props = {
  field: string
  codes: Coding[]
  label?: string
  className?: string
  autoSelect?: boolean
  horizontal?: boolean
  inputClassName?: string
  readOnly?: boolean
  disabled?: boolean
  validation?(code: CodeableConcept): void
  handleChange?(e: DropdownChangeEvent): void
} & DropdownProps

export { CodeableConceptField }
