import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { asReference } from "fhir"
import type { FormikHelpers } from "formik"
import { type FC, useEffect, useId, useMemo, useState } from "react"

import { useChartContext } from "chart-view"
import {
  DataContainerForm,
  GroupedList,
  InfiniteScroll,
  ModulesId,
  PanelQuestionnairesModal,
  SkeletonLoader,
  SplitButton,
  StackedListItem,
  useCrudReducer,
  useShowSignedUrlDocument,
} from "commons"
import {
  type LaboratoryOrder,
  type LaboratoryQuestionnaireData,
  labOrderItemModelBuilder,
  useReleaseLabResult,
} from "commons/labs"
import { useActiveConditions } from "conditions"
import {
  BILLING_TYPES_CODES,
  HEALTH_GORILLA_VALUE_CHECK,
  laboratoryOrderStatusCodes,
  requestCommonsStatusCodes,
} from "data"
import { useOpenEncounter } from "encounter"
import { useAppModuleContext } from "internals"
import { CPOE_STEPS, useCPOEContext, useUpdateAllCpoeRequests } from "orders"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { usePatientContext } from "patients"
import { getPatientDefaultPractitioner } from "utils"

import { useCreateLabOrder, useLaboratoryOrders, useRevokeLabOrder } from "../hooks"
import { LaboratoryOrderForm } from "./LaboratoryOrderForm"
import { LabOrderCancelConfirmation } from "./LabOrderCancelConfirmation"
import { getInitialValues, laboratoryOrderValidationSchema, sanitize } from "./validations"

const LaboratoryOrderList: FC = () => {
  const loaderKey = useId()
  const { patientId, patient } = usePatientContext()
  const {
    currentOrganizationId,
    loggedInPractitionerRole,
    currentOrganization,
    isExemptLabPayment,
    laboratoryLocation,
    isAdmin,
  } = useOrganizationContext()
  const { appModules, appSubModules } = useAppModuleContext()
  const { organizationPractitionersInfo: practitioners } = useOrganizationPractitioners({
    organizationId: currentOrganizationId,
    filterByIdentifier: HEALTH_GORILLA_VALUE_CHECK,
  })
  const [BDIOOrder, setBDIOOrder] = useState<LaboratoryOrder | undefined>()
  const [formikHelpers, setFormikHelpers] = useState<FormikHelpers<LaboratoryOrder> | undefined>()
  const [scopedItem, updateScopedItem] = useState<string>()

  const { openEncounterRef } = useOpenEncounter(patientId)
  const { conditionCodes } = useActiveConditions(patientId)

  const { searchData, currentModuleData, showSubModule, setSearchData } = useChartContext<LaboratoryOrder>()
  const { showCpoeOverlay } = useCPOEContext()

  const orderRequester = getPatientDefaultPractitioner(practitioners, patient, loggedInPractitionerRole)

  const statusOptions = [...requestCommonsStatusCodes, ...laboratoryOrderStatusCodes]

  const defaultOrderData = useMemo(() => currentModuleData?.form?.initialState, [currentModuleData])

  const {
    state: { orderToCheckout },
    initialValue,
    showSlide: showForm,
    isNew,
    reset,
    add,
    edit,
    deleteIndex,
    setDeleteIndex,
    selectItem,
    selectedItem,
    dispatch,
  } = useCrudReducer({
    defaultEntity: defaultOrderData ?? getInitialValues(patient, orderRequester, openEncounterRef, conditionCodes),
    extraState: { orderToCheckout: undefined } as { orderToCheckout: LaboratoryOrder | undefined },
    extraStateReducer: (state, { type, payload }) => {
      if (type === "checkout") return { ...state, orderToCheckout: payload as LaboratoryOrder | undefined }

      return state
    },
  })

  const setOrderToCheckout = (val: LaboratoryOrder | undefined) => dispatch({ type: "checkout", payload: val })

  useEffect(() => {
    if (defaultOrderData?.order?.id) edit(defaultOrderData)
    else if (defaultOrderData) add()
  }, [defaultOrderData])

  useEffect(() => {
    if (!showForm)
      setSearchData({
        placeholder: "Search orders",
        selectedStatus: statusOptions.reduce((prev, { code }) => {
          if (code !== "revoked") {
            return [...prev, code]
          }

          return prev
        }, [] as string[]),
        statusOptions,
      })
    else setSearchData({ showSearch: false })

    return () => {
      setSearchData({ showSearch: false })
    }
  }, [showForm])

  const { laboratoryOrdersByDate, hasNextPage, isLoading, fetchNextPage, count } = useLaboratoryOrders({
    organizationId: currentOrganizationId,
    patientId,
    statusFilter: searchData.selectedStatus ?? [],
    searchText: searchData.filter,
    enabled: !showForm,
    isAdminUser: isAdmin,
  })

  const { showDocument } = useShowSignedUrlDocument()

  const showLabDocument = async (orderId: string, docUrl: string) => {
    updateScopedItem(orderId)
    await showDocument(docUrl).finally(() => updateScopedItem(undefined))
  }

  const { updateAllCpoeRequests, isUpdatingAllRequests } = useUpdateAllCpoeRequests(
    patientId,
    () => {
      showCpoeOverlay(CPOE_STEPS.FINISH)
    },
    () => {
      reset()
      setOrderToCheckout(undefined)
    },
  )

  const { createLabOrder, isCreating } = useCreateLabOrder(
    () => {
      if (!orderToCheckout) reset()
      setBDIOOrder(undefined)
    },
    (newOrder) => orderToCheckout && updateAllCpoeRequests([newOrder]),
  )

  const { revokeLabOrder, isRevoking } = useRevokeLabOrder(() => {
    reset()
    setDeleteIndex()
  })

  const revoke = (labOrder: LaboratoryOrder) => {
    setDeleteIndex(1)
    selectItem(labOrder)
  }

  const goToOrderDetails = (orderId: string) => {
    showSubModule({
      subModule: appSubModules["laboratory"]?.[ModulesId.LABORATORY_ORDER_DETAILS],
      subModulesData: orderId,
    })
  }

  const onSubmit = (labOrder: LaboratoryOrder, formikHelpers: FormikHelpers<LaboratoryOrder>) => {
    if (labOrder) {
      const sanitizedOrder = sanitize(
        { ...labOrder, order: { ...labOrder.order, encounter: openEncounterRef } },
        asReference(currentOrganization),
      )
      if (labOrder.bloodDrawnInOffice) {
        const hasQsToAnswer = [...(sanitizedOrder.panels ?? []), ...(sanitizedOrder.combo?.panels ?? [])].some(
          (panel) => !!panel.questionnaires?.length,
        )
        if (hasQsToAnswer) {
          setFormikHelpers(formikHelpers)
          setBDIOOrder(sanitizedOrder)
        } else {
          createLabOrder(sanitizedOrder)
        }
      } else {
        createLabOrder(sanitizedOrder)
      }
    }
  }

  const createDBIOLabOrder = (questionnaireData: LaboratoryQuestionnaireData[]) => {
    createLabOrder({
      ...(BDIOOrder as LaboratoryOrder),
      questionnaireData,
      bloodDrawnInOffice: true,
    })
  }

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  const BDIOPanels = useMemo(
    () => [...(BDIOOrder?.panels ?? []), ...(BDIOOrder?.combo?.panels ?? [])],

    [BDIOOrder],
  )

  const { releaseResult } = useReleaseLabResult(undefined, () => updateScopedItem(undefined))

  const releaseToPatient = async (orderId: string, drId: string) => {
    updateScopedItem(orderId)
    releaseResult(drId)
  }

  return (
    <>
      {isLoading ? (
        loader()
      ) : (
        <DataContainerForm
          hasData={count > 0}
          showForm={showForm}
          formTitle="Configure Order"
          itemTitle="Order"
          iconDataNotFound={appModules[ModulesId.LABSORDER].getIcon()}
          formInitialValue={initialValue}
          customAddButtonText="Create New Laboratory Order"
          customAddButtonId="create-lab-order"
          validationSchema={laboratoryOrderValidationSchema}
          onSubmit={onSubmit}
          onCancel={reset}
          form={
            <LaboratoryOrderForm
              practitioners={practitioners}
              isEditing={!isNew}
              labLocations={laboratoryLocation}
              patient={patient}
            />
          }
          onButtonAddClick={add}
          cancelButtonLabel="Close"
          customSaveButton={({ validate, isSubmitting, values }) => (
            <SplitButton
              type="submit"
              actions={[
                {
                  label: "Place Order",
                  description: "Saves the new order and continue checkout",
                  onSelectClick: async () => {
                    const errors = await validate()
                    if (!Object.keys(errors).length) {
                      setOrderToCheckout(values)
                    }
                  },
                },
                {
                  label: "Save & Add to Cart",
                  description: "Saves the new order as draft",
                },
              ]}
              autoUpdateMainAction
              loading={isSubmitting || isUpdatingAllRequests || isCreating}
              disabled={isSubmitting || isUpdatingAllRequests || isCreating}
            />
          )}
        >
          <div className="bg-white h-full overflow-auto ml-3">
            <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
              <GroupedList
                className="grow"
                groups={laboratoryOrdersByDate}
                renderItem={(labData) => (
                  <StackedListItem
                    modelData={labOrderItemModelBuilder({
                      data: labData,
                      goToOrderDetails,
                      edit,
                      revoke,
                      showDoc: showLabDocument,
                      checkIsLoading: (orderId) => orderId === scopedItem,
                      showPrice: !isExemptLabPayment && labData.billingType !== BILLING_TYPES_CODES.INSURANCE,
                      release: releaseToPatient,
                    })}
                  />
                )}
                renderDivider={(key) => (
                  <div className="sticky top-0 z-10 bg-white pt-4">
                    <div className="border-t">
                      <div className="text-xs text-gray-500 bg-white w-fit pr-1 -translate-y-2">{key}</div>
                    </div>
                  </div>
                )}
                renderEmptyState={() => (
                  <div className="text-center m-auto mt-20">
                    <FontAwesomeIcon
                      icon={appModules[ModulesId.LABSORDER].getIcon()}
                      size="2x"
                      className="text-slate-400"
                    />
                    <h3 className="mt-2 text-sm font-semibold text-gray-900">No laboratory found</h3>
                  </div>
                )}
              />
            </InfiniteScroll>
          </div>
        </DataContainerForm>
      )}
      {deleteIndex !== undefined && (
        <LabOrderCancelConfirmation
          laboratoryOrder={selectedItem as LaboratoryOrder}
          isCancelling={isRevoking}
          onHide={() => setDeleteIndex()}
          onCancel={revokeLabOrder}
        />
      )}
      {!!BDIOPanels.length && (
        <PanelQuestionnairesModal
          onHide={() => {
            setBDIOOrder(undefined)
            formikHelpers?.setSubmitting(false)
          }}
          panels={BDIOPanels}
          onSave={createDBIOLabOrder}
          isSaving={isCreating}
        />
      )}
    </>
  )
}

export { LaboratoryOrderList }
