const tasksQueryKeys = {
  all: ["organization/tasks"] as const,
  list: (
    organizationId: string,
    filter?: string,
    code?: string,
    assignedTo?: string,
    status?: string,
    performerType?: string,
  ) => [...tasksQueryKeys.all, organizationId, filter, code, assignedTo, status, performerType],
  details: (taskId: string) => ["task", taskId],
}

export { tasksQueryKeys }
