import { type FieldProps, type FormikValues, ErrorMessage, Field } from "formik"
import { classNames } from "primereact/utils"
import type { ReactNode } from "react"

const FormField = <T extends FormikValues | string | number>({
  field,
  label,
  children,
  className,
  horizontal,
  containerClassName,
  showInvalidState,
  headerButtons,
  labelClassName = "text-sm font-medium text-gray-700",
  labelContainerClassName,
  labelAlign = "items-center",
  floatLabel,
  validation,
}: FormFieldProps<T>) => (
  <Field name={field} validate={validation}>
    {(props: FieldProps<T>) => (
      <div
        className={classNames(
          "field relative",
          horizontal && "inline-flex justify-between",
          floatLabel && "float-label relative block mt-1",
          !horizontal && !floatLabel && "flex flex-col",
          className,
        )}
      >
        {label && !floatLabel && (
          <div
            className={classNames(
              "flex justify-between mb-2",
              { "mr-3 mb-0 mt-2 ": horizontal },
              labelContainerClassName,
              labelAlign,
            )}
          >
            <label
              htmlFor={props.field.name}
              className={labelClassName}
              title={typeof label === "string" ? label : undefined}
            >
              {label}
            </label>
            {headerButtons}
          </div>
        )}
        <div className={classNames("flex flex-col w-full", { "flex-1": horizontal })}>
          <div
            className={classNames("flex flex-col flex-1", containerClassName, {
              "ring-1 ring-red-500 rounded-md overflow-hidden":
                showInvalidState && props.meta.error && props.meta.touched,
            })}
          >
            {typeof children === "function" ? children(props) : children}
          </div>
          {label && floatLabel && (
            <label htmlFor={field} className="text-sm text-gray-400 left-3 top-2 absolute transition-all ease-in-out">
              {label}
            </label>
          )}

          <div className="flex items-start p-error field-error-spacing">
            <ErrorMessage name={field}>
              {(msg) => <small id={`errorMessage.${field}`}>{typeof msg === "string" ? msg : msg[field]}</small>}
            </ErrorMessage>
          </div>
        </div>
      </div>
    )}
  </Field>
)

export type FormFieldProps<T = FormikValues> = {
  field: string
  label?: string | ReactNode
  className?: string
  horizontal?: boolean
  containerClassName?: string
  labelContainerClassName?: string
  showInvalidState?: boolean
  children: ReactNode | ((props: FieldProps) => ReactNode)
  headerButtons?: ReactNode | ReactNode[]
  validation?(value?: T): void
  labelClassName?: string
  labelAlign?: "items-center" | "items-start"
  floatLabel?: boolean
}

export type FormFieldBaseProps<T = FormikValues> = Omit<FormFieldProps<T>, "children" | "showInvalidState">

export { FormField }
