import { useMutation, useQueryClient } from "@tanstack/react-query"
import { type Parameters, type Task } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { encounterQueryKeys } from "../query-keys"

const useAssignCheckoutTask = (onSuccess?: () => void, onSettled?: () => void) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const assignCheckout = async ({ encounterId, practitionerId }: AssignCheckoutParams) => {
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "practitioner",
          value: {
            Reference: {
              id: practitionerId,
              resourceType: "Practitioner",
            },
          },
        },
      ],
    }

    const task = await operationRequest<Task>({
      endpoint: `Encounter/${encounterId}`,
      method: "POST",
      operation: "assign-checkout-task",
      parameters: practitionerId ? parameters : undefined,
    })

    return task
  }

  const { mutate: assignCheckoutTask, isPending: isAssigning } = useMutation({
    mutationFn: assignCheckout,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { encounterId }) => {
      await queryClient.invalidateQueries({ queryKey: encounterQueryKeys.details(encounterId) })
      displayNotificationSuccess("Checkout task assigned successfully!")
      onSuccess?.()
    },
    onSettled,
  })

  return { assignCheckoutTask, isAssigning }
}

type AssignCheckoutParams = {
  encounterId: string
  practitionerId?: string
}

export { useAssignCheckoutTask }
