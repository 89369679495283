import { useQuery, type QueryFunction } from "@tanstack/react-query"
import { getResources, type Bundle, type Composition } from "fhir"

import { useClient, type SearchArgs } from "api"

import { commonsQueryKeys } from "../query-keys"

const mentionsSuggestionsQueryFn =
  ({
    authorsStringified,
    search,
    searchQuery = "",
  }: {
    authorsStringified: string
    search: ({ endpoint, filters, operation, signal }: SearchArgs) => Promise<Bundle>
    searchQuery?: string
  }):
    | QueryFunction<
        {
          compositions: Composition[]
        },
        string[],
        number
      >
    | undefined =>
  async ({ signal }) => {
    const filters = new URLSearchParams({
      _sort: "-date",
      _elements: "author, date, r5_name, r5_note",
      author: authorsStringified,
      name: searchQuery,
      type: "snippets-note",
    })

    const bundle: Bundle = await search({ endpoint: "Composition", filters, signal })

    const compositions = getResources(bundle) as Composition[]

    return { compositions, total: bundle?.total ?? 0 }
  }

const useMentionsSuggestions = ({ authors, searchQuery = "" }: { authors: string[]; searchQuery?: string }) => {
  const { search } = useClient()

  const authorsStringified = authors.join(",")
  const queryKey = commonsQueryKeys.mentionsSuggestions(authorsStringified, searchQuery)

  const {
    data: { suggestions } = { suggestions: [] },
    refetch,
    isLoading,
  } = useQuery({
    queryKey,
    queryFn: mentionsSuggestionsQueryFn({ authorsStringified, searchQuery, search }),
    select(data) {
      return {
        suggestions: data.compositions?.map(({ r5_name, r5_note }) => ({ key: r5_name, value: r5_note?.text })) ?? [],
      }
    },
    enabled: false,
    meta: { context: { queryKey, authors } },
    throwOnError: true,
  })

  return {
    suggestions,
    isLoading,
    refetch,
  }
}

type MentionsSuggestionsQueryResponse = {
  compositions: Composition[]
}

export { mentionsSuggestionsQueryFn, useMentionsSuggestions, type MentionsSuggestionsQueryResponse }
