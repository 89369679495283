import { type FieldProps } from "formik"
import { Editor, type EditorProps } from "primereact/editor"
import { classNames } from "primereact/utils"
import type { FC } from "react"

import { FormField } from "./FormField"

const EditorField: FC<Props> = ({
  field,
  label,
  className,
  inputClassName,
  placeholder,
  disabled,
  readOnly,
  labelClassName,
  ...editorProps
}) => (
  <FormField field={field} className={className} label={label}>
    {({ field: { name, value }, meta: { touched, error }, form: { setFieldValue } }: FieldProps) => (
      <Editor
        id={name}
        name={name}
        onTextChange={(e) => setFieldValue(name, e.htmlValue)}
        value={value}
        className={classNames(
          "p-inputtext-sm flex flex-col min-h-20",
          { "p-invalid": touched && error },
          inputClassName,
        )}
        pt={{ content: { className: "w-full" }, toolbar: { className: "w-full" } }}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        {...editorProps}
      />
    )}
  </FormField>
)

// More at https://quilljs.com/docs/modules/toolbar
/* const toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote"],
  [{ header: 1 }, { header: 2 }, { header: 3 }],
  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  ["clean"],
] */

type Props = {
  field: string
  label?: string
  className?: string
  inputClassName?: string
  labelClassName?: string
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
} & EditorProps

export { EditorField }
