import { faChartColumn, faListTimeline } from "@fortawesome/pro-solid-svg-icons"
import type { FormikHelpers } from "formik"
import { useState } from "react"
import { classNames } from "primereact/utils"

import { Button, FormContainer, InfiniteScroll, LoadingView } from "commons"
import { useOpenEncounter } from "encounter"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { useCreateVitals, useVitals } from "../hooks"
import type { VitalsFormType } from "../types"
import { getVitals, validationSchema, sanitizeVitalsFormValues, initialValues } from "./validations"
import { VitalsForm } from "./VitalsForm"

const VitalsContainer = () => {
  const { patientId, patientRef } = usePatientContext()
  const [isChartView, setIsChartView] = useState(false)
  const { vitals, isLoading: isLoadingData, hasNextPage, totalData, fetchNextPage } = useVitals(patientId)
  const { loggedInPractitionerRoleRef } = useOrganizationContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { createVitals, isCreating } = useCreateVitals()

  const onSubmit = (values: VitalsFormType, formikHelpers?: FormikHelpers<VitalsFormType>) => {
    createVitals(getVitals(sanitizeVitalsFormValues(values), patientRef, loggedInPractitionerRoleRef, openEncounterRef))
    formikHelpers?.resetForm()
  }

  if (isLoadingData) return <LoadingView />

  return (
    <div className="flex flex-col flex-1 overflow-hidden w-full">
      <div className="flex justify-end w-full p-2">
        <Button
          icon={faListTimeline}
          disabled={isLoadingData || !totalData}
          onClick={() => setIsChartView(false)}
          buttonStyle="text"
          title="Show table view"
          iconClassName={classNames("text-lg", { "text-gray-400": isChartView })}
        />
        <Button
          icon={faChartColumn}
          disabled={isLoadingData || !totalData}
          onClick={() => setIsChartView(true)}
          buttonStyle="text"
          title="Show chart view"
          iconClassName={classNames("text-lg", { "text-gray-400": !isChartView })}
        />
      </div>
      <div className="h-96 grow">
        <FormContainer
          title=""
          subTitle=""
          initialValue={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          customSaveButton={<Button label="Save" buttonStyle="primary" size="lg" type="submit" loading={isCreating} />}
          enableReinitialize
          innerContainerClassName="pb-6 overflow-auto grow ml-3"
          showCancel={false}
        >
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} useScrollHorizontal={true}>
            <VitalsForm vitals={vitals} isTableEmpty={!totalData} showChart={isChartView} />
          </InfiniteScroll>
        </FormContainer>
      </div>
    </div>
  )
}

export { VitalsContainer }
