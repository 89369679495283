import { type FieldProps } from "formik"
import { classNames } from "primereact/utils"
import type { FC } from "react"
import { InputMask } from "primereact/inputmask"
import { FormField } from "./FormField"

const PhoneField: FC<Props> = ({
  field,
  label,
  mask,
  unmask,
  horizontal,
  floatLabel,
  className,
  inputClassName,
  validation,
  disabled,
  country = "US",
}) => {
  const selectedCountry = phoneCountryData.find((pdata) => pdata.country === country)

  return (
    <FormField field={field} className={className} floatLabel={floatLabel} label={label} validation={validation}>
      {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
        <InputMask
          type="tel"
          mask={mask ? mask : selectedCountry?.mask}
          unmask={unmask}
          id={name}
          name={name}
          onChange={onChange}
          value={value}
          disabled={disabled}
          className={classNames("p-inputtext-sm", {
            "p-invalid": touched && error,
            horizontal: horizontal,
            inputClassName,
          })}
        />
      )}
    </FormField>
  )
}

const phoneCountryData = [
  {
    country: "US",
    prefix: "1",
    mask: "+1 (999) 999-9999",
  },
]

type Props = {
  field: string
  label?: string
  mask?: string
  unmask?: boolean
  className?: string
  horizontal?: boolean
  floatLabel?: boolean
  inputClassName?: string
  validation?(value: string): void
  disabled?: boolean
  fieldName?: string
  country?: string
}

export { PhoneField }
