import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faSearch } from "@fortawesome/pro-light-svg-icons"
import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import type { FormikHelpers, FormikValues } from "formik"
import type { PropsWithChildren, ReactNode } from "react"
import type { ObjectSchema } from "yup"

import { ConfirmDialog } from "../components/ConfirmDialog"
import { EmptyMessage } from "../components/EmptyMessage"
import { FooterActions } from "../components/FooterActions"
import { Slideover } from "../components/Slideover"
import { DetailsContainer } from "./DetailsContainer"
import { FormContainer } from "./FormContainer"

const DataContainerWithFormSlideoverDetail = <T extends FormikValues>({
  showSlide,
  showForm,
  hasData,
  iconDataNotFound = faSearch,
  itemTitleNotFound,
  messageDataNotFound,
  subMessageDataNotFound,
  form,
  formTitle,
  formSubTitle,
  formInitialValue = {} as T,
  validationSchema,
  onSubmit,
  onCancel,
  customSaveButton,
  customEmptyAddButton,
  customEmptyAddButtonText,
  customAddButton,
  customAddIcon,
  customAddButtonText,
  disabledAddButton,
  onButtonAddClick,
  detail,
  detailTitle,
  onHideDetail,
  width = "35%",
  height = "calc(100% - 9.5rem)",
  deleteSupport,
  children,
  formContainerClassName,
  addButtonClassName,
}: PropsWithChildren<Props<T>>) => (
  <div className="flex flex-col flex-1 overflow-hidden bg-white">
    {showForm ? (
      <FormContainer
        title={formTitle}
        subTitle={formSubTitle}
        initialValue={formInitialValue}
        validationSchema={validationSchema}
        onSubmit={onSubmit ?? (() => ({}))}
        onCancel={onCancel}
        customSaveButton={customSaveButton}
        enableReinitialize
        innerContainerClassName={formContainerClassName}
      >
        {showForm && form}
      </FormContainer>
    ) : hasData ? (
      <>
        {children}
        {customAddButton ?? (
          <FooterActions
            actions={[
              {
                label: customAddButtonText ?? `Create new ${formTitle?.toLowerCase() ?? "item"}`,
                icon: customAddIcon ?? faPlus,
                command: () => onButtonAddClick?.(),
                disabled: disabledAddButton,
              },
            ]}
          />
          /*<FloatingButton
            icon={customAddIcon ?? faPlus}
            label={customAddButtonText ?? `Create New ${formTitle?.toLowerCase() ?? "item"}`}
            className={classNames("fixed bottom-6 right-6", addButtonClassName)}
            onClick={onButtonAddClick}
          />*/
        )}
      </>
    ) : (
      <EmptyMessage
        icon={iconDataNotFound}
        itemTitle={itemTitleNotFound ?? formTitle}
        message={messageDataNotFound}
        subMessage={subMessageDataNotFound}
        customEmptyAddButton={customEmptyAddButton}
        actionText={customAddButtonText ?? customEmptyAddButtonText ?? `Create ${formTitle?.toLowerCase() ?? "item"}`}
        action={onButtonAddClick}
        disabled={showForm || disabledAddButton}
        addButtonClassName={addButtonClassName}
      />
    )}

    <Slideover showSlide={showSlide ?? false} onHide={() => onHideDetail?.()} width={width} height={height}>
      <DetailsContainer title={detailTitle as string} onClose={() => onHideDetail?.()}>
        {showSlide && detail}
      </DetailsContainer>
    </Slideover>

    {deleteSupport && (
      <ConfirmDialog
        confirmText={
          deleteSupport.confirmDeleteText ??
          `Are you sure you want to remove this ${formTitle?.toLowerCase() ?? "item"}?`
        }
        actionName={deleteSupport.deleteActionText ?? "Remove"}
        visible={deleteSupport.showDelete || deleteSupport.isDeleting}
        isLoading={deleteSupport.isDeleting}
        onConfirm={() => deleteSupport.onConfirmDelete?.()}
        hideDialog={() => deleteSupport.onCancelDelete?.()}
      />
    )}
  </div>
)

type Props<T> = {
  showSlide?: boolean
  showForm: boolean
  slideContent?: "detail"
  hasData: boolean
  iconDataNotFound?: IconDefinition
  itemTitleNotFound?: string
  messageDataNotFound?: string
  subMessageDataNotFound?: string
  form: ReactNode
  formTitle?: string
  formSubTitle?: string
  formInitialValue?: T
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema?: ObjectSchema<any>
  onSubmit?(data: T, formikHelpers?: FormikHelpers<T>): void
  onCancel(): void
  customSaveButton?: ReactNode
  customAddButton?: ReactNode
  customEmptyAddButton?: ReactNode
  disabledAddButton?: boolean
  customEmptyAddButtonText?: string
  customAddIcon?: IconDefinition
  customAddButtonText?: string
  addButtonClassName?: string
  onButtonAddClick?(): void
  detail?: ReactNode
  detailTitle?: string
  width?: string
  height?: string
  deleteSupport?: DeleteSupportProps
  onHideDetail?: () => void
  formContainerClassName?: string
}

type DeleteSupportProps = {
  showDelete?: boolean
  confirmDeleteText?: string
  deleteActionText?: string
  isDeleting?: boolean
  onConfirmDelete?(): void
  onCancelDelete?(): void
}

export { DataContainerWithFormSlideoverDetail }
