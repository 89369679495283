import type { Reference, Task } from "fhir"
import { useEffect, useId } from "react"
import { useSearchParams } from "react-router-dom"

import { InfiniteScroll, SkeletonLoader, StackedListContainer, ValueSetIds, ViewContainerWithFilters } from "commons"
import { practitionerRolesCodes, taskStatusCodes } from "data"
import { useValueSet } from "value-set"

import { usePatchTask, useTasks } from "../hooks"
import { tasksQueryKeys } from "../query-keys"
import { TaskEmptyState } from "./TaskEmptyState"
import { taskModelBuilder } from "./taskModelBuilder"

const TaskList = ({ organizationId, practitioners, onAdd, onEditTask }: Props) => {
  const loaderKey = useId()
  const [params, setParams] = useSearchParams()
  const { codes: taskCodes } = useValueSet({ valueSetId: ValueSetIds.TASK_CODE })

  const status = params.get("status") ?? undefined
  const code = params.get("code") ?? undefined
  const assignedTo = params.get("assigned") ?? undefined
  const performerType = params.get("performertype") ?? undefined
  const searchText = params.get("search") ?? undefined

  const { tasks, isLoading, isFetchingNextPage, count, total, hasNextPage, fetchNextPage } = useTasks(
    organizationId,
    searchText,
    code,
    assignedTo,
    status,
    performerType,
  )

  const { updateTask, isUpdating } = usePatchTask({
    successMessage: "Task completed successfully",
    queryKeysToInvalidate: [tasksQueryKeys.all],
  })

  const setFilters = (
    searchText?: string,
    code?: string[],
    assignedTo?: string,
    status?: string[],
    performerType?: string[],
  ) => {
    searchText ? params.set("search", searchText) : params.delete("search")
    code ? params.set("code", code.map((code) => code).join(",")) : params.delete("code")
    assignedTo ? params.set("assigned", assignedTo) : params.delete("assigned")
    status ? params.set("status", status.map((status) => status).join(",")) : params.delete("status")
    performerType
      ? params.set("performertype", performerType.map((performerType) => performerType).join(","))
      : params.delete("performertype")
    setParams(params)
  }

  const goToTaskDetails = (taskId: string) => {
    params.append("id", taskId)
    setParams(params)
  }

  useEffect(() => {
    if (practitioners.length && !practitioners.find((ref) => ref.id === params.get("assigned"))) {
      params.delete("assigned")
      setParams(params)
    }
  }, [practitioners, assignedTo])

  const onCompleteTask = (taskId: string) => updateTask({ id: taskId, status: "completed" })

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <ViewContainerWithFilters
      isLoading={isLoading || isFetchingNextPage}
      itemTitle="task"
      itemCount={count}
      itemTotal={total}
      addButtonText="Create New Task"
      addButtonOnClick={onAdd}
      initialFilters={{
        code: code ? code.split(",") : undefined,
        assignedTo,
        performerType: performerType ? performerType.split(",") : undefined,
        status: status ? status.split(",") : undefined,
      }}
      filtersData={[
        {
          label: "Code",
          field: "code",
          data: taskCodes ?? [],
          type: "multiselect",
        },
        {
          label: "Assigned to",
          field: "assignedTo",
          data: practitioners ?? [],
          type: "select",
        },
        {
          label: "Assign to role",
          field: "performerType",
          data: practitionerRolesCodes,
          type: "multiselect",
        },
        {
          label: "Status",
          field: "status",
          data: taskStatusCodes,
          type: "multiselect",
        },
      ]}
      onFilter={(filters) =>
        setFilters(filters?.searchText, filters?.code, filters?.assignedTo, filters?.status, filters?.performerType)
      }
    >
      {isLoading ? (
        loader()
      ) : !tasks?.length ? (
        <TaskEmptyState onAdd={onAdd} />
      ) : (
        <div className="flex flex-col overflow-auto grow">
          <InfiniteScroll
            loadMore={() => {
              fetchNextPage?.()
            }}
            hasMore={hasNextPage}
            loader={loader()}
            initialLoad={false}
          >
            <StackedListContainer
              data={tasks}
              itemModelBuilder={(item) =>
                taskModelBuilder({
                  task: item,
                  onShowTask: () => goToTaskDetails(item.id as string),
                  onEditTask: () => onEditTask(item),
                  onCompleteTask: () => onCompleteTask(item.id as string),
                  isCompletingTask: isUpdating,
                })
              }
            />
          </InfiniteScroll>
        </div>
      )}
    </ViewContainerWithFilters>
  )
}

type Props = {
  organizationId: string
  practitioners: Reference[]
  onAdd(): void
  onEditTask(task: Task): void
}

export { TaskList }
