import type { QueryKey } from "@tanstack/react-query"
import type { Annotation, Reference } from "fhir"
import { classNames } from "primereact/utils"

import { InlineNoteContainer } from "./InlineNoteContainer"

const Notes = ({
  notes,
  className,
  label = "Notes",
  editProps,
}: {
  notes?: Annotation[]
  className?: string
  editProps?: EditProps
  label?: string
}) => {
  const itemNotes = notes?.filter(({ text }) => !!text)
  return (
    <>
      <div className={classNames("flex flex-col text-sm mb-2 relative", className)}>
        <span className="font-medium text-gray-400 inline-flex w-full items-center mb-2">
          {label}
          <hr className="flex-1 ml-1" />
        </span>

        {editProps ? (
          <InlineNoteContainer
            orderNote={itemNotes}
            parentId={editProps.parentId}
            context={editProps.context}
            queriesToInvalidate={editProps.queriesToInvalidate}
            contentClassName={className}
            inputRows={3}
            buttonClassName="absolute -top-1 right-0 z-10 bg-white"
            notShowFullDataNotFound={editProps.notShowFullDataNotFound}
            encounter={editProps.encounter}
          />
        ) : (
          itemNotes?.map(({ text }, index) => <p key={index}>{text}</p>)
        )}
      </div>
    </>
  )
}

type EditProps = {
  parentId: string
  context: NotesUseContext
  queriesToInvalidate: QueryKey
  notShowFullDataNotFound?: boolean
  encounter?: Reference
}

const NotesUseContext = {
  NUTRA: "MedicationRequest",
  RX: "MedicationRequest",
  PROCEDURE: "Procedure",
  ORDER: "ServiceRequest",
  OBSERVATION: "Observation",
} as const

type NotesUseContext = (typeof NotesUseContext)[keyof typeof NotesUseContext]

export { Notes, type EditProps, NotesUseContext }
