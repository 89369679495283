import { useReducer } from "react"

import { procedureStatus } from "../data"

const initialState = {
  statusFilter: procedureStatus.map(({ code }) => code),
  searchFilter: undefined,
} as State

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: "active-filters" | "update-search-filter"
    payload: Array<string> | string | undefined
  },
) => {
  switch (type) {
    case "active-filters":
      return {
        ...state,
        statusFilter: payload as string[],
      }
    case "update-search-filter":
      return {
        ...state,
        searchFilter: payload as string,
      }
    default:
      return state
  }
}

const useProcedureReducerState = (init?: Partial<State>) => {
  const [{ statusFilter, searchFilter }, dispatch] = useReducer(reducer, {
    ...initialState,
    ...init,
  })

  const updateFilters = (filters: string[]) => {
    dispatch({ type: "active-filters", payload: filters })
  }

  const updateSearchText = (filter: string) => {
    dispatch({ type: "update-search-filter", payload: filter })
  }

  return {
    statusFilter,
    searchFilter,
    updateFilters,
    updateSearchText,
  }
}

type State = {
  statusFilter: Array<string>
  searchFilter?: string
}

export { useProcedureReducerState }
