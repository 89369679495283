import { useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"

import { commonsQueryKeys } from "../query-keys"
import { snippetsNoteQueryFn } from "./useSnippetsNote"

const usePrefetchNoteSnippets = ({ authorId }: { authorId: string }) => {
  const { search } = useClient()
  const queryClient = useQueryClient()

  const queryKey = commonsQueryKeys.snippetNotes(authorId)

  const prefetch = async () =>
    await queryClient.prefetchInfiniteQuery({
      queryKey,
      queryFn: snippetsNoteQueryFn({ authorId, search }),
      initialPageParam: 1,
    })

  return { prefetch }
}

export { usePrefetchNoteSnippets }
