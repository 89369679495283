import { faProcedures } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, AppModule } from "commons/Module"

class ProceduresModule extends AppModule {
  constructor(workspaceElement: JSX.Element, kpElement: JSX.Element) {
    super()
    this.id = ModulesId.PROCEDURES
    this.display = "Procedures"
    this.icon = faProcedures
    this.hideBySetting = true
    this.workspaceElement = workspaceElement
    this.kpElement = kpElement
  }
}

export { ProceduresModule }
