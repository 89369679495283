import { type FieldProps } from "formik"
import { InputSwitch } from "primereact/inputswitch"
import { classNames } from "primereact/utils"
import type { FC } from "react"

import { FormField } from "./FormField"

const SwitchField: FC<Props> = ({ field, label, className, trueValue = true, falseValue = false }) => (
  <FormField field={field} className={className} label={label}>
    {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
      <InputSwitch
        id={name}
        name={name}
        onChange={onChange}
        checked={value}
        className={classNames("h-8 min-w-12", { "p-invalid": touched && error })}
        trueValue={trueValue}
        falseValue={falseValue}
      />
    )}
  </FormField>
)

type Props = {
  field: string
  label?: string
  className?: string
  rows?: number
  trueValue?: string | number | object
  falseValue?: string | number | object
}

export { SwitchField }
