import { useState, type FC } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faClock,
  faLocationDot,
  faCalendar,
  faStethoscope,
  faLaptopMedical,
  faUser,
} from "@fortawesome/pro-regular-svg-icons"
import { Tooltip } from "primereact/tooltip"
import { differenceInMinutes, format, isAfter, isValid, parseISO } from "date-fns"
import { asReference } from "fhir"

import { ConfirmDialog, Button, Badge } from "commons"
import { getBadgeColor, getCodingBySystem } from "utils"
import { APPOINTMENT_TYPE_DEFAULT_COLOR, formatsByTypes } from "data"
import type { CalendarAppointment } from "appointments"
import { SYSTEM_VALUES } from "system-values"
import { EncounterConfig } from "encounter"

import { StartEncounterAction } from "./StartEncounterAction"

const AppointmentPopup: FC<Props> = ({ isUnbooking, appointmentInfo, onUnbook }) => {
  const [showUnbookDialog, setShowUnbookDialog] = useState(false)
  const [startClicked, setStartClicked] = useState(false)

  const { appointment, appointmentType, healthcareService, patientName, practitionerName, devices, location, patient } =
    appointmentInfo

  const locationName = location?.name

  const duration =
    !!appointment.start &&
    !!appointment.end &&
    isValid(parseISO(appointment.start.toString())) &&
    isValid(parseISO(appointment.end.toString())) &&
    differenceInMinutes(appointment.end as Date, appointment.start as Date)

  const isFutureOrProximAppt = appointment.end ? isAfter(parseISO(appointment.end.toString()), new Date()) : false

  const appointmentDevices = devices?.filter(({ deviceName }) => !!deviceName?.[0]?.name)

  const color =
    getCodingBySystem(healthcareService?.characteristic, SYSTEM_VALUES.HEALTHCARESERVICE_CHARACTERISTIC_COLOR_SYSTEM)
      ?.code ?? APPOINTMENT_TYPE_DEFAULT_COLOR

  const patientId = patient?.id

  return (
    <>
      <Tooltip
        target={`#appt_${appointment.id}`}
        pt={{
          arrow: { className: "hidden" },
          text: { className: "bg-white !p-0 pb-1 border border-gray-200 shadow-md min-w-56" },
        }}
        position="mouse"
        autoHide={false}
        showDelay={100}
      >
        <div className="flex items-center justify-between p-4 border-b">
          <h2 className="text-base font-semibold text-gray-700 inline-flex items-center">
            <div className="rounded-full h-2 w-2 mr-2" style={{ backgroundColor: color }} />
            {appointmentType}
          </h2>
          <Badge {...getBadgeColor(appointment.status)} />
        </div>

        <div className="p-6 grid grid-cols-2 gap-x-8 gap-y-4">
          <div className="space-y-2">
            <div className="flex items-center gap-2 text-gray-500">
              <FontAwesomeIcon icon={faCalendar} className="h-3 w-3" />
              Date
            </div>
            <span className="text-sm font-normal text-gray-700">
              {format(appointment.start as Date, formatsByTypes.SHORT_DATE)}
            </span>
          </div>

          {locationName && (
            <div className="space-y-2">
              <div className="flex items-center gap-2 text-gray-500">
                <FontAwesomeIcon icon={faLocationDot} className="h-3 w-3" />
                Location
              </div>
              <span className="text-sm font-normal text-gray-700">{locationName}</span>
            </div>
          )}

          <div className="space-y-2">
            <div className="flex items-center gap-2 text-gray-500">
              <FontAwesomeIcon icon={faStethoscope} className="h-3 w-3" />
              Practitioner
            </div>
            <span className="text-sm font-normal text-gray-700">{practitionerName}</span>
          </div>

          <div className="space-y-2">
            <div className="flex items-center gap-2 text-gray-500">
              <FontAwesomeIcon icon={faUser} className="h-3 w-3" />
              Patient
            </div>
            <span className="text-sm font-normal text-gray-700">{patientName}</span>
          </div>

          {duration && (
            <div className="space-y-2">
              <div className="flex items-center gap-2 text-gray-500">
                <FontAwesomeIcon icon={faClock} className="h-3 w-3" />
                Time
              </div>
              <span>
                <div className="text-sm font-normal text-gray-700">
                  {format(appointment.start as Date, formatsByTypes.SHORT_TIME)} -{" "}
                  {format(appointment.end as Date, formatsByTypes.TIME)}
                </div>
                <div className="text-gray-500">{duration} minutes</div>
              </span>
            </div>
          )}

          {!!appointmentDevices?.length && (
            <div className="space-y-2">
              <div className="flex items-center gap-2 text-gray-500">
                <FontAwesomeIcon icon={faLaptopMedical} className="h-3 w-3" />
                Devices
              </div>
              <span className="space-y-2">
                {appointmentDevices.map(({ id, deviceName }, index) => (
                  <div key={id ?? index} className="flex items-center gap-2">
                    <span className="text-sm font-normal text-gray-700">{deviceName?.[0]?.name}</span>
                  </div>
                ))}
              </span>
            </div>
          )}
        </div>

        <div className="flex items-center justify-end p-4 rounded-b-lg border-t bg-white space-x-3">
          {!!patientId && isFutureOrProximAppt && (
            <StartEncounterAction
              label="Start encounter"
              className="text-primary"
              patientId={patientId}
              command={() => setStartClicked(true)}
            />
          )}
          <Button
            buttonStyle="text"
            label="Unbook"
            className="text-slate-400"
            size="sm"
            onClick={() => setShowUnbookDialog(true)}
          />
        </div>
      </Tooltip>

      {!!patientId && (
        <EncounterConfig
          visible={startClicked}
          patientId={patientId}
          appointment={appointment}
          patientRef={asReference(patient)}
          onHide={() => setStartClicked(false)}
        />
      )}

      <ConfirmDialog
        visible={showUnbookDialog || isUnbooking}
        hideDialog={() => setShowUnbookDialog(false)}
        closable
        confirmText="Are you sure you want to unbook this appointment? This action cannot be undone."
        actionName="Unbook"
        onConfirm={onUnbook}
        isLoading={isUnbooking}
        waitToFinish
      />
    </>
  )
}

type Props = {
  isUnbooking?: boolean
  onUnbook: () => void
  appointmentInfo: CalendarAppointment & {
    patientName: string
    practitionerName: string
    appointmentType: string
  }
}

export { AppointmentPopup }
