import { faFilterSlash } from "@fortawesome/pro-regular-svg-icons"
import type { Coding, Composition, Reference } from "fhir"
import { Form, Formik, type FormikProps, type FormikValues } from "formik"
import pluralize from "pluralize"
import type { CalendarPropsMultiple, CalendarPropsRange, CalendarPropsSingle } from "primereact/calendar"
import type { DropdownChangeEvent } from "primereact/dropdown"
import { Skeleton } from "primereact/skeleton"
import { Fragment, type ReactNode } from "react"

import { AutoCompletePatientField, DateField, DropdownField, InputField, MultiSelectField } from "../forms"
import { Button } from "./Buttons"
import { SearchInput } from "./SearchInput"

const ViewContainerWithFilters = <T extends FormikValues>({
  isLoading,
  initialFilters,
  onFilter,
  onTextFilter,
  filtersData,
  searchPlaceholder = "Search",
  showClearButton = true,
  children,
  itemTitle,
  itemCount,
  itemTotal,
  showCount = itemCount !== undefined && itemTotal !== undefined,
  addButtonText,
  addButtonOnClick,
}: Props<T>) => {
  const filterNone = !initialFilters || Object.values(initialFilters).every((f) => f === undefined || f === "")
  const filter = async (filters?: SearchTextProps & T) => {
    onFilter?.(filters)
  }

  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      <div className="flex justify-between items-center px-6 py-4 border-b drop-shadow">
        <SearchInput
          className="grow max-w-96"
          search={(searchText) => {
            initialFilters ? onFilter?.({ ...initialFilters, searchText }) : onTextFilter?.(searchText)
          }}
          isLoading={isLoading}
          placeholder={searchPlaceholder}
        />
        <div className="flex items-center gap-6">
          {showCount && isLoading ? (
            <div className="w-60">
              <Skeleton />
            </div>
          ) : (
            <span className="text-sm text-gray-500">
              Showing {itemCount} {pluralize(itemTitle ?? "item", itemCount)} of {itemTotal} found
            </span>
          )}
          {addButtonText && addButtonOnClick && <Button label={addButtonText} onClick={addButtonOnClick} />}
        </div>
      </div>
      <div className="flex flex-1 p-4 gap-4 bg-light-gray overflow-hidden">
        {filtersData && initialFilters && (
          <Formik initialValues={initialFilters} onSubmit={filter} enableReinitialize>
            {({ isSubmitting }: FormikProps<T>) => (
              <Form className="flex flex-col bg-white p-4 gap-2 w-1/4 max-w-96 rounded-2xl overflow-y-auto max-h-max">
                <>
                  {filtersData.map((filterProp, index) => (
                    <Fragment key={index}>
                      {filterProp.type === "multiselect" ? (
                        <MultiSelectField
                          label={filterProp.label}
                          field={filterProp.field}
                          options={filterProp.data}
                          optionLabel={filterProp.optionLabel ?? "display"}
                          optionValue={filterProp.optionValue ?? "code"}
                          showClear
                          inputClassName="text-sm"
                        />
                      ) : filterProp.type === "select" ? (
                        <DropdownField
                          label={filterProp.label}
                          field={filterProp.field}
                          options={filterProp.data}
                          optionLabel={filterProp.optionLabel ?? "display"}
                          optionValue={filterProp.optionValue ?? "id"}
                          showClear
                          inputClassName="text-sm"
                          handleChange={filterProp.handleChange}
                        />
                      ) : filterProp.type === "date" ? (
                        <DateField
                          label={filterProp.label}
                          field={filterProp.field}
                          minDate={filterProp.minDate}
                          maxDate={filterProp.maxDate}
                          selectionMode={filterProp.dateSelectionMode}
                          readOnlyInput
                          inputClassName="text-sm"
                        />
                      ) : filterProp.type === "patient" ? (
                        <AutoCompletePatientField
                          label={filterProp.label}
                          field={filterProp.field}
                          inputClassName="text-sm"
                        />
                      ) : (
                        filterProp.type === "text" && (
                          <InputField
                            label={filterProp.label}
                            field={filterProp.field}
                            type={filterProp.textType}
                            inputClassName="text-sm"
                            clearable
                            blockScapeChar
                            onClear={() => onFilter?.({ ...initialFilters, [filterProp.field]: "" } as T)}
                          />
                        )
                      )}
                    </Fragment>
                  ))}
                  <div className="flex flex-shrink-0 justify-end px-4 py-4">
                    {showClearButton && (
                      <Button
                        label="Clear"
                        buttonStyle="default"
                        size="lg"
                        className="mr-3"
                        icon={faFilterSlash}
                        disabled={isSubmitting || filterNone}
                        onClick={() => filter(undefined)}
                      />
                    )}
                    <Button type="submit" label="Search" size="lg" loading={isSubmitting || isLoading} />
                  </div>
                </>
              </Form>
            )}
          </Formik>
        )}
        <div className="flex flex-col flex-1 rounded-2xl bg-white p-4 overflow-y-auto">{children}</div>
      </div>
    </div>
  )
}

export type Props<T> = {
  itemTitle?: string
  itemCount?: number
  itemTotal?: number
  showCount?: boolean
  addButtonText?: string
  addButtonOnClick?(): void
  searchPlaceholder?: string
  onFilter?(filters?: SearchTextProps & T): void
  onTextFilter?(searchText?: string): void
  showClearButton?: boolean
  isLoading: boolean
  initialFilters?: T
  filtersData?: FilterProps[]
  children: ReactNode
}

type SearchTextProps = {
  searchText?: string
}

type FilterProps =
  | {
      label: string
      field: string
      type: "patient"
    }
  | {
      label: string
      field: string
      type: "date"
      minDate?: Date
      maxDate?: Date
      dateSelectionMode?: CalendarPropsRange & CalendarPropsMultiple & CalendarPropsSingle
    }
  | {
      label: string
      field: string
      data: Reference[] | Coding[] | Composition[]
      optionLabel?: string
      optionValue?: string
      type: "select" | "multiselect"
      handleChange?(e: DropdownChangeEvent): void
    }
  | {
      label: string
      field: string
      optionLabel?: string
      optionValue?: string
      type: "text"
      textType?: string
    }

export { ViewContainerWithFilters }
