import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faSearch } from "@fortawesome/pro-light-svg-icons"
import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import type { FormikHelpers, FormikValues } from "formik"
import type { PropsWithChildren, ReactNode } from "react"
import type { ObjectSchema } from "yup"
import { classNames } from "primereact/utils"

import { ConfirmDialog } from "../components/ConfirmDialog"
import { EmptyMessage } from "../components/EmptyMessage"
import { FooterActions } from "../components/FooterActions"
import { FormContainer } from "./FormContainer"
import type { FormContainerProps } from "./types"

const DataContainerForm = <T extends FormikValues>({
  showForm,
  hasData,
  iconDataNotFound = faSearch,
  itemTitleNotFound,
  messageDataNotFound,
  subMessageDataNotFound,
  form,
  itemTitle,
  formTitle,
  formSubTitle,
  formInitialValue = {} as T,
  validationSchema,
  onSubmit,
  onCancel,
  showSave = true,
  disableSave,
  customSaveButton,
  customEmptyAddButton,
  customEmptyAddButtonText,
  customAddButton,
  customAddIcon,
  customAddButtonText,
  disabledAddButton,
  onButtonAddClick,
  deleteSupport,
  mutableForm,
  children,
  containerClassName,
  emptyAddButtonStyle,
  customAddButtonId,
  ...props
}: PropsWithChildren<Props<T>>) => {
  return (
    <div className={classNames("flex flex-col flex-1 overflow-hidden bg-white", containerClassName)}>
      {showForm ? (
        <FormContainer
          title={formTitle}
          subTitle={formSubTitle}
          initialValue={formInitialValue}
          validationSchema={validationSchema}
          onSubmit={onSubmit ?? (() => ({}))}
          onCancel={onCancel}
          showSave={showSave}
          customSaveButton={customSaveButton}
          disableSave={disableSave}
          mutable={mutableForm}
          enableReinitialize
          {...props}
        >
          {form}
        </FormContainer>
      ) : !hasData ? (
        <EmptyMessage
          icon={iconDataNotFound}
          itemTitle={itemTitleNotFound ?? itemTitle ?? formTitle}
          message={messageDataNotFound}
          subMessage={subMessageDataNotFound}
          customEmptyAddButton={customEmptyAddButton}
          actionText={
            customAddButtonText ?? customEmptyAddButtonText ?? `Create New ${itemTitle ?? formTitle ?? "item"}`
          }
          action={onButtonAddClick}
          disabled={showForm}
          className="min-h-96 content-center"
          addButtonStyle={emptyAddButtonStyle}
          actionId={customAddButtonId}
        />
      ) : (
        <>
          {children}
          {customAddButton ?? (
            <FooterActions
              actions={[
                {
                  id: customAddButtonId,
                  label: customAddButtonText ?? `Create new ${(itemTitle ?? formTitle)?.toLowerCase() ?? "item"}`,
                  icon: customAddIcon ?? faPlus,
                  command: () => onButtonAddClick?.(),
                  disabled: disabledAddButton,
                },
              ]}
            />
          )}
        </>
      )}

      {deleteSupport && (
        <ConfirmDialog
          confirmText={
            deleteSupport.confirmDeleteText ??
            `Are you sure you want to remove this ${(itemTitle ?? formTitle)?.toLowerCase() ?? "item"}?`
          }
          actionName={deleteSupport.deleteActionText ?? "Remove"}
          visible={deleteSupport.showDelete || deleteSupport.isDeleting}
          isLoading={deleteSupport.isDeleting}
          onConfirm={() => deleteSupport.onConfirmDelete?.()}
          hideDialog={() => deleteSupport.onCancelDelete?.()}
        />
      )}
    </div>
  )
}

type Props<T extends FormikValues> = {
  showForm: boolean
  hasData: boolean
  iconDataNotFound?: IconDefinition
  itemTitleNotFound?: string
  messageDataNotFound?: string
  subMessageDataNotFound?: string
  form: ReactNode
  itemTitle?: string
  formTitle?: string
  formSubTitle?: string
  formInitialValue?: T
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema?: ObjectSchema<any>
  onSubmit?(data: T, formikHelpers?: FormikHelpers<T>): void
  onCancel(): void
  showSave?: boolean
  disableSave?: boolean
  disabledAddButton?: boolean
  customAddButton?: ReactNode
  customEmptyAddButton?: ReactNode
  customEmptyAddButtonText?: string
  customAddIcon?: IconDefinition
  customAddButtonText?: string
  customAddButtonId?: string
  onButtonAddClick?(): void
  deleteSupport?: DeleteSupportProps
  mutableForm?: boolean
  containerClassName?: string
} & Omit<FormContainerProps<T>, "initialValue">

type DeleteSupportProps = {
  showDelete?: boolean
  confirmDeleteText?: string
  deleteActionText?: string
  isDeleting?: boolean
  onConfirmDelete?(): void
  onCancelDelete?(): void
}

export { DataContainerForm }
