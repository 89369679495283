import { faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { faEye, faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type MedicationKnowledge, type Organization, codeableConceptAsString, isMedicationKnowledge } from "fhir"
import { type FieldProps, ErrorMessage, Field, getIn, useField, useFormikContext } from "formik"
import { type AutoCompleteSelectEvent, AutoComplete } from "primereact/autocomplete"
import { Button } from "primereact/button"
import { classNames } from "primereact/utils"
import { type FC, type HTMLAttributes, useDeferredValue, useEffect, useRef, useState } from "react"
import type { AnyObject } from "yup/lib/object"

import { MedicationKnowledgeDetails } from "commons"
import { getMKDisplayText, useMedicationKnowledge } from "commons/meds"
import { getPriceByCode } from "commons/utils"
import { MEDICATION_CATALOG } from "data"
import { useOrganizationContext } from "organization"
import { SYSTEM_VALUES } from "system-values"
import { getCommonCode } from "utils"

//import { MedicationForm } from "./MedicationForm"

import { useProductPrices } from "commons/hooks"
import { NO_FOUND_MEDICATIONS } from "../../data"
import { usePrescriptionValidator } from "../../hooks"
import type { MedicationRequestFormData } from "../../types"
import { getDosageInitialValues } from "../../utils/transformers"
import { MEDICATION_REQUIRED_ERROR } from "../../utils/validations"
import "./AutocompleteMedicationField.css"

//const addAsWriteInMessage = "Add as write-in"
const loadingMessage = "Loading..."

const AutocompleteMedicationField: FC<Props> = ({
  field,
  label,
  className,
  readonly,
  disabled,
  allowedMedicationCodes,
  skipValidation,
  onChange,
  onProceedAnyway,
}) => {
  const [filter, setFilter] = useState<string>("")
  const deferredFilter = useDeferredValue(filter)
  const { currentOrganizationId } = useOrganizationContext()
  const { medicationKnowledge, mkProductConfigurations, isLoading, catalogs } = useMedicationKnowledge({
    filter: deferredFilter,
    category: MEDICATION_CATALOG.RX,
    medCodes: allowedMedicationCodes?.join(","),
    hasCIDInOrg: currentOrganizationId,
  })

  const { productPrices, isLoading: isLoadingPrice } = useProductPrices({
    organizationId: currentOrganizationId,
    productsConfigurations: mkProductConfigurations,
  })
  const [suggestionList, setSuggestionList] = useState<(MedicationKnowledge | string)[]>([loadingMessage])
  const [selectedMK, setSelectedMK] = useState<MedicationKnowledge>()
  const [{ value: fieldValue }] = useField(field)
  const isMKSelected = isMedicationKnowledge(fieldValue)
  // const isMSelected = isMedication(fieldValue)
  //const [showMedicationForm, setShowMedicationForm] = useState(false)
  const { setFieldValue, setFieldTouched, errors } = useFormikContext<MedicationRequestFormData>()
  const fieldError = getIn(errors, field)
  const autoCompleteRef = useRef(null)

  const { validatePrescription, isCheckingPrescription, hasBeenChecked, lastChecked } = usePrescriptionValidator()

  const validate = async (mk?: MedicationKnowledge) => {
    const isSameValue = getCommonCode({ codes: lastChecked?.coding }) === getCommonCode({ codes: mk?.code?.coding })

    if (hasBeenChecked && isSameValue) return fieldError

    const { isValid, message: validationFeedback } = await validatePrescription({ mkCode: mk?.code })

    if (!isValid) return validationFeedback

    return undefined
  }

  const itemTemplate = (item: MedicationKnowledge | string) => {
    if (!isMedicationKnowledge(item)) return <div className="text-sm">{item}</div>

    const { code, manufacturer, doseForm, packaging, ingredient, intendedRoute, amount } = item
    const lfID = code?.coding?.find((c) => c.system === SYSTEM_VALUES.LIFEFILE_MEDICATION)?.code
    const ndc = code?.coding?.find((c) => c.system === SYSTEM_VALUES.MEDICATION_NDC)?.code
    const route = intendedRoute?.[0]?.coding?.[0]?.display?.replace(/use|route/, "")?.trim()

    return (
      <div className="flex justify-between items-center gap-x-2 relative w-full">
        <div className="flex flex-col gap-1 flex-1 overflow-hidden">
          <div className="flex items-baseline gap-2 truncate">
            <span className="inline font-semibold">{codeableConceptAsString(code)}</span>
            {ingredient?.length === 1 && ingredient[0]?.strength?.numerator?.unit && (
              <span title={ingredient[0].strength.numerator.unit} className="min-w-0 truncate">
                {ingredient[0].strength.numerator.unit}
              </span>
            )}
          </div>
          <div className="flex flex-wrap divide-x gap-1 text-sm font-light text-gray-400">
            {manufacturer?.display && (
              <span className="pl-1 first:pl-0">{`Pharmacy: ${manufacturer.display.replace("Farmakeio", "FarmaKeio")}`}</span>
            )}
            {doseForm?.coding?.[0]?.display && (
              <span className="pl-1 first:pl-0 inline-flex space-x-1">
                {`Drug Form: ${doseForm?.coding?.[0]?.display}`}
              </span>
            )}
            {amount?.value && amount?.unit && (
              <span className="pl-1 first:pl-0 inline-flex space-x-1">
                {`Amount: ${amount?.value} ${amount?.unit}`}
              </span>
            )}
            {packaging?.type?.coding?.[0]?.display && (
              <span className="pl-1 first:pl-0">Pkg: {packaging.type.coding[0].display}</span>
            )}
            {lfID && <span className="pl-1 first:pl-0">ID: {lfID}</span>}
            {ndc && <span className="pl-1 first:pl-0">NDC: {ndc}</span>}
            {route && <span className="pl-1 first:pl-0">{route}</span>}
          </div>
        </div>
        <PreviewButton onClick={() => setSelectedMK(item)} />
      </div>
    )
  }

  /*const addWriteInItemTemplate = (message: string) => {
    return (
      <div className="flex items-center">
        <FontAwesomeIcon icon={faPlus} size="xs" title="Preview" />
        <span className="ml-2 whitespace-nowrap overflow-hidden text-ellipsis max-w-xs font-bold text-sm">
          {message}
        </span>
      </div>
    )
  }*/

  const handleSelect = (e: AutoCompleteSelectEvent) => {
    if (isMedicationKnowledge(e.value)) {
      const value = { ...e.value, textDisplayedInField: getMKDisplayText(e.value) }
      setFieldValue(field, value)
      resetSigFields(setFieldValue, setFieldTouched)
      prefillPrescriptionFormData(setFieldValue, e.value, catalogs)

      setFieldTouched(field, true)
    } else {
      setFieldValue(field, null)
    }

    /*else {
      setShowMedicationForm(true)
    }*/
    onChange?.(e.value)
  }

  useEffect(() => {
    if (autoCompleteRef.current) {
      const inputElement: HTMLInputElement = autoCompleteRef.current
      inputElement.setAttribute("spellcheck", "false")
    }
  }, [])

  useEffect(() => {
    if (isLoading || isLoadingPrice) {
      setSuggestionList([loadingMessage])
      return
    }

    if (!medicationKnowledge.length) {
      setSuggestionList([])
      return
    }

    setSuggestionList(
      medicationKnowledge.filter((mk) => {
        const mkPrice = getPriceByCode({
          productPrices,
          medCoding: mk.code?.coding,
        })
        return !!mkPrice
      }),
    )
  }, [medicationKnowledge, isLoading, isLoadingPrice, productPrices, suggestionList.length])

  return (
    <>
      <Field name={field} validate={skipValidation ? undefined : validate}>
        {({ field: { name, value }, form: { setFieldValue }, meta: { touched, error } }: FieldProps) => {
          return (
            <div className={classNames("field flex flex-col relative", className)}>
              {label && (
                <label htmlFor={name} className="text-sm font-medium text-gray-700 mb-2">
                  {label}
                </label>
              )}
              <AutoComplete
                id={name}
                name={name}
                aria-autocomplete="none"
                autoComplete="false"
                inputRef={autoCompleteRef}
                field="textDisplayedInField"
                className={classNames("mk-autocomplete", "p-inputtext-sm", { "p-invalid": touched && error })}
                disabled={disabled}
                readOnly={readonly}
                suggestions={/*suggestionList.length ? suggestionList : [addAsWriteInMessage]*/ suggestionList}
                emptyMessage={NO_FOUND_MEDICATIONS}
                showEmptyMessage
                delay={400}
                itemTemplate={
                  /*suggestionList.length ? itemTemplate : addWriteInItemTemplate*/
                  itemTemplate
                }
                dropdown={!disabled}
                dropdownMode="current"
                dropdownIcon={<FontAwesomeIcon icon={faPlus} className="bg-white" />}
                value={value}
                completeMethod={(e) => {
                  setFilter(e.query.split("-")[0].trim())
                  setSuggestionList((list) => [...list])
                }}
                onChange={(e) => {
                  /*if (e.value !== addAsWriteInMessage) setFieldValue(name, e.value)*/
                  setFieldValue(name, e.value)
                  resetSigFields(setFieldValue, setFieldTouched)
                }}
                onSelect={handleSelect}
                appendTo="self"
                panelClassName="w-full"
              />
              {isMKSelected && (
                <PreviewButton
                  id="autocomplete-med-button"
                  onClick={() => setSelectedMK(value)}
                  className={classNames("absolute top-[2.4rem] cursor-pointer text-slate-500 bg-white", {
                    "right-4": disabled,
                    "right-10": !disabled,
                  })}
                />
              )}

              <div className="flex items-start p-error h-2 my-1">
                {isCheckingPrescription ? (
                  <span className="text-slate-500 field-error-spacing">
                    <FontAwesomeIcon icon={faSpinner} spin />
                    <span className="ml-1">checking availability</span>
                  </span>
                ) : (
                  <ErrorMessage name={name}>
                    {(msg) => (
                      <div className="flex items-center w-full">
                        <small title={msg} className="truncate">
                          {msg.endsWith(".", msg.length - 1) ? msg : msg + "."}
                        </small>
                        {msg !== MEDICATION_REQUIRED_ERROR && onProceedAnyway && (
                          <Button
                            link
                            size="small"
                            severity="danger"
                            className="ml-1 text-sm w-fit text-red-500 !p-0"
                            label="Proceed anyway?"
                            onClick={() => onProceedAnyway(msg)}
                          />
                        )}
                      </div>
                    )}
                  </ErrorMessage>
                )}
              </div>
            </div>
          )
        }}
      </Field>
      <MedicationKnowledgeDetails
        selectedMK={selectedMK}
        onHide={() => setSelectedMK(undefined)}
        showImgFallback={false}
      />
      {/*<MedicationForm
        initialValue={fieldValue as Medication}
        showForm={showMedicationForm}
        setShowForm={setShowMedicationForm}
      />*/}
    </>
  )
}

const PreviewButton: FC<HTMLAttributes<SVGSVGElement>> = ({ onClick, className }) => (
  <FontAwesomeIcon
    className={className}
    icon={faEye}
    title="Preview"
    onClick={(e) => {
      e.stopPropagation()
      onClick?.(e)
    }}
  />
)

// const WriteInButton: FC<HTMLAttributes<SVGSVGElement>> = ({ onClick }) => (
//   <FontAwesomeIcon
//     className="absolute top-1/4 cursor-pointer text-[#6c757d] right-12"
//     icon={faFilePen}
//     title="Add write-in"
//     onClick={(e) => {
//       e.stopPropagation()
//       onClick?.(e)
//     }}
//   />
// )

const prefillPrescriptionFormData = (
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void,
  mk: MedicationKnowledge,
  catalogPerformers: Record<string, Organization>,
) => {
  const performer = catalogPerformers?.[mk?.catalogHeader?.[0].id as string]
  setFieldValue("dosages", [getDosageInitialValues(mk)])
  setFieldValue("dispenseRequest.performer", performer)
  setFieldValue("administrationGuideline", undefined)
  setFieldValue("dispenseRequest.quantity.value", 1)
  setFieldValue("note[0].text", mk.preparationInstruction)
}

const resetSigFields = (
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void,
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
) => {
  setFieldValue("dosages", [getDosageInitialValues()])
  setFieldTouched("dosages[0].doseQuantity", false)
  setFieldTouched("dosages[0].instructionText", false)
  setFieldTouched("dosages[0].medicationQuantity", false)
  setFieldTouched("dosages[0].treatmentFrequency", false)
  setFieldTouched("dosages[0].treatmentRoute", false)
  setFieldTouched("dosages[0].prescriptionDuration", false)
  setFieldTouched("dosages[0].prescriptionDurationUnit", false)
}

type Props = {
  field: string
  label?: string
  className?: string
  readonly?: boolean
  disabled?: boolean
  allowedMedicationCodes?: string[]
  skipValidation?: boolean
  onChange?(value: AnyObject | number | null | undefined): void
  onProceedAnyway?(error: string): void
}

export { AutocompleteMedicationField }
