import { faXmark } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type FieldProps, ErrorMessage, Field } from "formik"
import { InputMask } from "primereact/inputmask"
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"
import { type LegacyRef, type PropsWithChildren, forwardRef } from "react"
import type { AnyObject } from "yup/lib/types"

const InputField = forwardRef<HTMLInputElement, PropsWithChildren<Props>>(function InputField(
  {
    field,
    label,
    type,
    mask,
    unmask,
    horizontal,
    horizontalReverse,
    floatLabel,
    className,
    inputClassName,
    placeholder,
    validation,
    disabled,
    autocomplete,
    children,
    truncate = true,
    clearable,
    onClear,
    blockScapeChar,
    spellcheck,
    renderValidationFeedback,
    onChange: onValueChange,
    onBlur,
  },
  inputRef,
) {
  return (
    <Field name={field} validate={validation}>
      {({ field: { name, value, onChange }, meta: { touched, error }, form: { setFieldValue } }: FieldProps) => (
        <div
          className={classNames(
            "field space-y-2 relative",
            horizontal && "inline-flex justify-between",
            horizontalReverse && "inline-flex flex-row-reverse justify-end gap-2 items-center",
            floatLabel && "float-label relative block mt-1",
            !horizontal && !horizontalReverse && !floatLabel && "flex flex-col",
            className,
          )}
        >
          {children}
          {label && !floatLabel && (
            <label
              htmlFor={name}
              title={label}
              className={classNames("text-sm font-medium text-gray-700" + (truncate ? " truncate" : ""), {
                "mr-3 mb-0 mt-2": horizontal,
              })}
            >
              {label}
            </label>
          )}
          <div
            className={classNames("flex flex-col", {
              "w-full": !horizontalReverse,
              "relative w-1/3": horizontalReverse,
            })}
          >
            {clearable && (
              <div
                className="absolute flex justify-center items-center right-2 bottom-6 z-20 w-4 h-4 rounded-full hover:bg-gray-200 hover:ring-1 ring-primary cursor-pointer"
                title="Remove item"
                onClick={() => {
                  onClear?.() ?? setFieldValue(name, undefined)
                }}
              >
                <FontAwesomeIcon icon={faXmark} className="text-xs" />
              </div>
            )}
            {mask ? (
              <InputMask
                type={type}
                mask={mask}
                unmask={unmask}
                id={name}
                name={name}
                onChange={(e) => {
                  onChange(e)
                  onValueChange?.(e.value)
                }}
                value={value}
                disabled={disabled}
                className={classNames(
                  "p-inputtext-sm",
                  { "p-invalid": touched && error, horizontal: horizontal },
                  { "pr-5": clearable },
                  inputClassName,
                )}
                ref={inputRef as LegacyRef<InputMask>}
              />
            ) : (
              <InputText
                autoComplete={autocomplete}
                spellCheck={spellcheck}
                aria-autocomplete="none"
                type={"text"}
                id={name}
                name={name}
                placeholder={placeholder}
                onChange={(e) => {
                  if (blockScapeChar)
                    e.currentTarget.value = e.currentTarget.value.replaceAll(specialCharRegex, "").trimStart()
                  onChange?.(e)
                  onValueChange?.(e.target.value)
                }}
                onBlur={() => onBlur?.()}
                value={value}
                disabled={disabled}
                className={classNames(
                  "p-inputtext-sm h-10",
                  { "p-invalid": touched && error, horizontal: horizontal },
                  { "pr-5": clearable },
                  inputClassName,
                )}
                ref={inputRef}
              />
            )}

            {label && floatLabel && (
              <label htmlFor={name} className="text-sm text-gray-400 left-3 top-2 absolute transition-all ease-in-out">
                {label}
              </label>
            )}
            <div className="flex items-start p-error field-error-spacing">
              {touched && error && <ErrorMessage name={field}>{(msg) => <small>{msg}</small>}</ErrorMessage>}
            </div>
            {renderValidationFeedback?.()}
          </div>
        </div>
      )}
    </Field>
  )
})

const specialCharRegex = /[$]*\\*/g

type Props = {
  field: string
  label?: string
  type?: string
  mask?: string
  unmask?: boolean
  className?: string
  horizontal?: boolean
  horizontalReverse?: boolean
  floatLabel?: boolean
  inputClassName?: string
  placeholder?: string
  validation?(value: string): void
  renderValidationFeedback?: () => JSX.Element
  disabled?: boolean
  autocomplete?: string
  spellcheck?: boolean
  fieldName?: string
  truncate?: boolean
  clearable?: boolean
  onClear?(): void
  blockScapeChar?: boolean
  onChange?(value: AnyObject | number | string | null | undefined): void
  onBlur?(): void
}

export { InputField }
