import { faExternalLink } from "@fortawesome/pro-duotone-svg-icons"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "primereact/button"
import { Tooltip } from "primereact/tooltip"
import { classNames } from "primereact/utils"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { type AppModule, ModulesId } from "commons"
import { useAppModuleContext } from "internals"

const KnowledgePanel: React.FC = () => {
  const { activeKpModules } = useAppModuleContext()
  const { showModule } = useChartContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeContent, setActiveContent] = useState(-1)

  const currentViewId = searchParams.get("kp")

  useEffect(() => {
    if (!searchParams.get("kp")) {
      searchParams.set("kp", ModulesId.PATIENT)
      setSearchParams(searchParams)
    }
  }, [])

  useEffect(() => {
    setActiveContent(activeKpModules.findIndex((i) => i.getId() === currentViewId))
  }, [currentViewId])

  const navToView = (view: string, subView?: string) => {
    showModule({ module: view, ...(subView ? { moduleParams: { subview: subView } } : {}) })
  }

  const updateView = (viewId?: string) => {
    if (viewId) searchParams.set("kp", viewId)
    else searchParams.delete("kp")

    setSearchParams(searchParams)
  }

  return (
    <div
      className={classNames(
        "flex overflow-hidden",
        searchParams.has("kp") && activeContent !== -1 ? "w-2/5  border-r-2" : "min-w-[4rem]",
      )}
    >
      <div className="left-menu border-r w-24 3xl:w-20 flex flex-col pt-6 divide-y">
        <div className="flex flex-col flex-1 w-24 3xl:w-20 overflow-y-auto">
          {activeKpModules.map((module, index) => {
            const id = module.getId()
            const isSelected = searchParams.get("kp") === id

            return <KPItem key={index} module={module} isSelected={isSelected} onClick={() => updateView(id)} />
          })}
        </div>
      </div>
      {activeContent !== -1 && (
        <div className="flex flex-col flex-1 overflow-hidden">
          <div className="flex flex-row justify-between px-5 items-center pt-7">
            <div className="text-lg tracking-tight font-normal">{activeKpModules[activeContent].getDisplayForKP()}</div>
            <div className="flex items-center">
              {activeKpModules[activeContent].getParent() && (
                <Button
                  className="close-left-panel p-button-text"
                  icon={<FontAwesomeIcon icon={faExternalLink} />}
                  onClick={
                    activeKpModules[activeContent].getParent()
                      ? () =>
                          navToView(activeKpModules[activeContent].getParent(), activeKpModules[activeContent].getId())
                      : undefined
                  }
                />
              )}
              <Button
                className="close-left-panel p-button-text"
                title="Close panel"
                icon={<FontAwesomeIcon icon={faTimes} />}
                onClick={() => updateView()}
              />
            </div>
          </div>
          <div className="flex flex-col flex-1 overflow-hidden">{activeKpModules[activeContent].renderKP()}</div>
        </div>
      )}
    </div>
  )
}

const KPItem = ({ module, isSelected, onClick }: KPItemProps) => {
  const id = module.getId()

  return (
    <>
      <Tooltip target={`#left-menu-item-${id}`} />
      <div
        id={`left-menu-item-${id}`}
        className={classNames(
          "left-menu-item flex justify-center items-center hover:bg-gray-300 cursor-pointer border-b border-transparent",
          { "bg-gray-200": isSelected },
        )}
        data-pr-tooltip={module.getDisplayForKP()}
        onClick={onClick}
      >
        <div
          className={classNames("w-full h-full flex items-center my-3 justify-center", {
            "text-slate-500": !isSelected,
            "text-primary": isSelected,
          })}
        >
          <FontAwesomeIcon
            icon={module.getIcon()}
            className="fa-fw lg:text-lg xl:text-xl 2xl:text-2xl cursor-pointer"
          />
        </div>
      </div>
    </>
  )
}

type KPItemProps = {
  module: AppModule
  isSelected: boolean
  onClick(): void
}

export { KnowledgePanel }
