import { faLocationDot } from "@fortawesome/pro-regular-svg-icons"
import { type IconDefinition, faPencil, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { Address, Patient } from "fhir"
import { type FormikHelpers, useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { type FC, useMemo } from "react"

import {
  type StackedListItemProps,
  AddressField,
  AddressVerificationFeedback,
  ConfirmDialog,
  DataContainerSlideoverForm,
  StackedListContainer,
  addressTypes,
  sanitizeAddress,
  useCrudReducer,
} from "commons"
import { useSmartyAddressVerification, useSmartyAddressVerificationContext } from "commons/hooks"
import { emptyAddress } from "data"
import { usePatchPatient, usePatientContext } from "patients"
import { areAddressesSimilars, strCapitalize } from "utils"

import { getArrangedPatientAddress, getEffectivesAddressess } from "../utils"
import { addressValidationSchema } from "./validations"

const EFFECTIVE_ADDRESSES_COUNT_ALLOWED = 3

const PatientAddresses: FC = () => {
  const {
    patient: { address = [] },
    patient,
  } = usePatientContext()

  const effectiveAddresses = getEffectivesAddressess(address)

  const { isNew, showSlide, initialValue, deleteIndex, add, editIndex, editWithIndex, reset, setDeleteIndex } =
    useCrudReducer({
      defaultEntity: emptyAddress,
    })

  const { patchPatient, isPatching } = usePatchPatient(reset)
  const { checkAddress, clearVerificationInfo } = useSmartyAddressVerification(true)

  const handleSubmit = async (addressData: Address, formikHelpers?: FormikHelpers<Address>) => {
    const isSameAddressThatIsBeingEdited =
      editIndex !== undefined && areAddressesSimilars(addressData, address[editIndex])

    if (isSameAddressThatIsBeingEdited) {
      clearVerificationInfo()
      reset()
      return
    } else {
      await checkAddress(addressData, formikHelpers, () => {
        clearVerificationInfo()

        const newAddress = getArrangedPatientAddress(patient, addressData, editIndex)

        patchPatient({
          patientId: patient.id as string,
          patientData: { ...(sanitizeAddress({ ...patient, address: newAddress }) as Patient), meta: patient.meta },
        })
      })
    }
  }

  const onDelete = () => {
    const newAddress = [...(patient.address ?? [])]
    newAddress.splice(deleteIndex as number, 1)

    patchPatient({ patientId: patient.id as string, patientData: { address: newAddress, meta: patient.meta } })
  }

  return (
    <DataContainerSlideoverForm
      messageDataNotFound="No addresses found"
      subMessageDataNotFound={false}
      hasData={!!address?.length}
      showSlide={showSlide}
      formTitle="Address"
      formInitialValue={initialValue}
      validationSchema={addressValidationSchema()}
      onSubmit={handleSubmit}
      onCancel={() => {
        clearVerificationInfo()
        reset()
      }}
      form={<PatientAddressForm address={address} isEditing={!isNew} />}
      customAddButtonText="Add Address"
      onButtonAddClick={add}
      iconDataNotFound={faLocationDot}
      showFab={effectiveAddresses.length !== EFFECTIVE_ADDRESSES_COUNT_ALLOWED}
    >
      <div className="bg-white h-full overflow-auto">
        <StackedListContainer
          data={effectiveAddresses}
          itemModelBuilder={(item) =>
            addressModelBuilder(
              item,
              () =>
                editWithIndex(
                  address[item.index]?.type === undefined
                    ? { ...address[item.index], type: "home" }
                    : address[item.index],
                  item.index,
                ),
              () => setDeleteIndex(item.index),
              isPatching,
            )
          }
        />
      </div>
      <ConfirmDialog
        confirmText="Are you sure you want to remove this address?"
        actionName="Remove"
        visible={deleteIndex !== undefined}
        onConfirm={onDelete}
        hideDialog={() => setDeleteIndex(undefined)}
      />
    </DataContainerSlideoverForm>
  )
}

const PatientAddressForm: FC<PatientAddressFormProps> = ({ address, isEditing = false }) => {
  const validAddressTypes = useMemo(
    () =>
      addressTypes.filter(
        ({ code }) => !address.some(({ type, use }) => (type === undefined ? "home" : type) === code && use === "home"),
      ),
    [address],
  )
  const { setFieldValue } = useFormikContext()
  const { addressVerificationInfo, autoCompleteRecommendedAddress, bypassAddressValidation } =
    useSmartyAddressVerificationContext()

  return (
    <div className="flex flex-col justify-between divide-y divide-gray-300">
      <div className="relative p-fluid grid gap-4">
        <fieldset className="relative p-fluid grid grid-cols-2 gap-4 p-3 mb-5">
          <AddressField validAddressTypes={isEditing ? undefined : validAddressTypes} />
        </fieldset>
      </div>
      <AddressVerificationFeedback
        addressVerificationInfo={addressVerificationInfo}
        handleAutoCompleteRecommendedAddress={() => autoCompleteRecommendedAddress?.(setFieldValue)}
        handleBypassAddressValidation={bypassAddressValidation}
      />
    </div>
  )
}

type PatientAddressFormProps = {
  address: Address[]
  isEditing: boolean
}

const addressModelBuilder = (
  address: PatientAddressItem,
  onEdit: () => void,
  onDelete: () => void,
  isPatching?: boolean,
): StackedListItemProps => {
  return {
    leftData: [
      {
        lineItems: [
          {
            name: "Address",
            component: <PatientAddressItem {...address} />,
          },
        ],
      },
    ],
    menu: [
      {
        label: "Edit",
        icon: <FontAwesomeIcon icon={faPencil} />,
        command: onEdit,
      },
      {
        label: "Delete",
        icon: <FontAwesomeIcon icon={faTrashCan} />,
        command: onDelete,
      },
    ],
    isLoading: isPatching,
  }
}

const PatientAddressItem = ({ icon, use, display, className, isKP = false }: PatientAddressItem) => (
  <div
    className={classNames("flex flex-row mb-1", isKP && "items-baseline")}
    title={isKP ? `${strCapitalize(use)} Address` : ""}
  >
    <FontAwesomeIcon icon={icon} className={classNames("pr-2 fa-fw", isKP ? "text-gray-500" : "mt-1")} />
    <div className={classNames("flex flex-col", className)}>
      {!isKP && <span className="text-sm font-semibold text-gray-900">{strCapitalize(use)}</span>}
      <span className={classNames("text-sm text-gray-500", className)}>{display}</span>
    </div>
  </div>
)

type PatientAddressItem = {
  use: string
  display: string
  index: number
  icon: IconDefinition
  className?: string
  isKP?: boolean
}

export { PatientAddressItem, PatientAddresses }
