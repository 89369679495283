import type { ChargeItemDefinition, Duration } from "fhir"
import { classNames } from "primereact/utils"
import { Fragment, useMemo } from "react"

import { getFeeType } from "commons/meds"
import { getGenericBillingType, getPriceByCode } from "commons/utils"
import { BILLING_TYPES_CODES, MED_FEE_TYPE } from "data"
import { getCommonCode } from "utils"

import { type ActionGroupCode, type CpoeRequest, ACTION_GROUP_CODES } from "../types"
import { LaboratoryOrderListItem } from "./LaboratoryOrderListItem"
import { OrderListItem } from "./OrderListItem"
import { ProcedureOrderListItem } from "./ProcedureOrderListItem"

const CheckoutItemsSection = ({
  label,
  productPrices,
  requests,
  sectionRequestsType,
  editableRequests,
  onChangeRequestStatus,
  onChangeMed,
  onDeleteRequest,
  isDeleting,
  readonly,
  className,
  billingType = BILLING_TYPES_CODES.BILL_PATIENT,
}: Props) => {
  const filteredRequests = useMemo(() => {
    let filteredRequests = requests.filter((req) => req.type === sectionRequestsType)
    if (
      [ACTION_GROUP_CODES.PHARMA, ACTION_GROUP_CODES.NUTRA].includes(sectionRequestsType as ACTION_GROUP_CODES) &&
      productPrices
    ) {
      filteredRequests = filteredRequests.reduce((acc, cpoeRequest) => {
        const medCode = cpoeRequest?.medicationData?.medicationRequest?.medication?.CodeableConcept?.coding

        const skuCode = getCommonCode({
          codes: medCode,
        })

        const relatedCids =
          billingType === BILLING_TYPES_CODES.BILL_PATIENT
            ? Object.entries(productPrices)
                .filter(([key]) => key.includes(skuCode))
                .flatMap(([, cid]) => cid)
            : []

        const feeType = getFeeType(relatedCids)

        return [
          ...acc,
          {
            ...cpoeRequest,
            unitPrice: getPriceByCode({
              productPrices,
              medCoding: medCode,
              quantity: cpoeRequest?.medicationData?.dispenseRequest?.quantity?.value,
              ...(sectionRequestsType === ACTION_GROUP_CODES.PHARMA && {
                specifyMedFee: true,
                ...(billingType === BILLING_TYPES_CODES.BILL_PATIENT && {
                  ...(feeType === MED_FEE_TYPE.ByFrequency && {
                    productFrequency: cpoeRequest?.medicationData?.dispenseRequest?.dispenseInterval,
                  }),
                }),
              }),
              billingType: getGenericBillingType(billingType),
            }) ?? {
              currency: "USD",
              value: 0,
            },
          },
        ]
      }, [] as CpoeRequest[])
    }

    return filteredRequests
  }, [requests, sectionRequestsType, billingType])

  if (!filteredRequests.length) return null

  return (
    <div className={classNames("pl-3", className)}>
      {!!label && (
        <h3 className="text-sm font-medium inline-flex justify-between w-full items-center space-x-3">
          <span className="text-gray-700">{label}</span> <hr className="border flex-1" />
        </h3>
      )}

      <div className="flex flex-col">
        {filteredRequests.map((item, index) => (
          <Fragment key={item.resource.resource?.id ?? index}>
            {sectionRequestsType === ACTION_GROUP_CODES.LAB ? (
              <LaboratoryOrderListItem
                labRequest={item}
                onChange={onChangeRequestStatus}
                editable={!!editableRequests}
                onDelete={onDeleteRequest}
                isDeleting={isDeleting}
                readonly={readonly}
              />
            ) : sectionRequestsType === ACTION_GROUP_CODES.PROCEDURE ? (
              <ProcedureOrderListItem
                item={item}
                onChange={onChangeRequestStatus}
                editable={!!editableRequests}
                onDelete={onDeleteRequest}
                isDeleting={isDeleting}
                readonly={readonly}
              />
            ) : (
              <OrderListItem
                medRequest={item}
                onChange={(field, value) => onChangeMed?.(field, value, item.resource.resource?.id)}
                editable={!!editableRequests}
                onDelete={onDeleteRequest}
                isDeleting={isDeleting}
                readonly={readonly}
              />
            )}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

type Props = {
  label?: string
  productPrices?: Record<string, ChargeItemDefinition>
  requests: CpoeRequest[]
  sectionRequestsType: ActionGroupCode
  onDeleteRequest?(itemId: string, item?: CpoeRequest): void
  isDeleting?: boolean
  className?: string
  readonly?: boolean
  billingType?: BILLING_TYPES_CODES
} & EditableMode

type EditableMode =
  | {
      editableRequests?: false
      onChangeMed?(field: string, value: boolean | Duration, id?: string): void
      onChangeRequestStatus?(value: string, id?: string): void
    }
  | {
      editableRequests: true
      onChangeMed(field: string, value: boolean | Duration, id?: string): void
      onChangeRequestStatus(value: string, id?: string): void
    }

export { CheckoutItemsSection }
