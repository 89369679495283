import {
  type Bundle,
  type ChargeItemDefinition,
  type Coding,
  type Duration,
  type Identifier,
  type MedicationKnowledge,
  type MedicationRequest,
  type Money,
  type Parameters,
  type ParametersParameterArrayArray,
  type Quantity,
  getResources,
  isMedicationKnowledge,
  isMedicationRequest,
} from "fhir"
import type { FormikErrors, FormikValues } from "formik"

import { BILLING_TYPES_CODES, DEFAULT_BLOOD_DRAWN_PANELS_LIST, MED_FEE_TYPE } from "data"
import { SYSTEM_VALUES } from "system-values"

import { getBasePrice, getBillingTypeCode, getCommonCode, getCommonCoding } from "utils"
import { getFeeType } from "./meds"
import {
  type AddressValidationDataAddress,
  type AddressValidationResultData,
  type ComponentType,
  type EffectiveComponentType,
  type FieldErrorType,
  type PractitionerInfo,
  type ProductConfiguration,
  type ProductConfigurationToRequest,
  AddressValidationDataConfirmationLevels,
  AddressValidationDataGranularityLevels,
  GENERIC_BILLING_TYPE,
  PRODUCT_CONFIGURATION_BILLING_TYPE,
} from "./types"

const getPDSkuValue = (identifiers: Identifier[] | undefined) =>
  identifiers?.find((id) => id.system === SYSTEM_VALUES.SKU)?.value

const effectiveValidationAddressComponents: Record<ComponentType, string> = {
  route: "route",
  street_number: "street_number",
  locality: "locality",
  administrative_area: "administrative_area",
  postal_code: "postal_code",
  subpremise: "subpremise",
}

const effectivesAddressFormFieldsMapping = {
  line: {
    field: "line",
    message: ["This street number or route is suspicious"],
  },
  subpremise: {
    field: "line",
    message: ["", "This apartment or house number is suspicious"],
  },
  locality: {
    field: "city",
    message: "Wrong city",
  },
  administrative_area: {
    field: "state",
    message: "Wrong state",
  },
  postal_code: {
    field: "postalCode",
    message: "Wrong zip code",
  },
}

const getResultAbstractDataRepresentation = (result: AddressValidationResultData, isAddressWithSubpremise: boolean) => {
  const effectivesAddressComponents = getEffectivesAddressComponents(
    isAddressWithSubpremise
      ? result?.address?.addressComponents
      : result?.address?.addressComponents?.filter(
          ({ componentType }) => componentType !== effectiveValidationAddressComponents.subpremise,
        ),
  )
  const inferredAddressComponents = getInferredAddressComponents(effectivesAddressComponents)

  const effectiveUnconfirmedAddressComponentsTypes = effectivesAddressComponents
    ?.filter((component) => component.confirmationLevel.includes("UNCONFIRMED"))
    .flatMap((component) => component.componentType)

  const hasReplacedComponents = effectivesAddressComponents?.some((component) => component.replaced)

  const effectiveMissingAddressComponentsTypes = getEffectiveMissingAddressComponentsTypes(
    isAddressWithSubpremise
      ? result?.address?.missingComponentTypes
      : result?.address?.missingComponentTypes?.filter(
          (type) => type !== effectiveValidationAddressComponents.subpremise,
        ),
  )

  const isBypassUnconfirmedStreetNumber =
    (result?.address?.unconfirmedComponentTypes?.includes("street_number") ||
      result?.address?.missingComponentTypes?.includes("street_number")) &&
    effectiveUnconfirmedAddressComponentsTypes?.length === 0

  delete result?.verdict?.hasInferredComponents
  delete result?.verdict?.hasReplacedComponents
  delete result?.verdict?.hasUnconfirmedComponents

  const addressComplete =
    effectiveMissingAddressComponentsTypes?.length === undefined || effectiveMissingAddressComponentsTypes?.length === 0

  const effectiveVerdict = {
    ...result?.verdict,
    addressComplete: addressComplete,
    ...(effectiveUnconfirmedAddressComponentsTypes?.length && { hasUnconfirmedComponents: true }),
    ...(inferredAddressComponents?.length && { hasInferredComponents: true }),
    ...(hasReplacedComponents && { hasReplacedComponents: true }),
    validationGranularity:
      !!effectiveMissingAddressComponentsTypes?.length ||
      !!effectiveUnconfirmedAddressComponentsTypes?.length ||
      !!inferredAddressComponents?.length ||
      hasReplacedComponents ||
      !addressComplete ||
      !isBypassUnconfirmedStreetNumber
        ? result?.verdict?.validationGranularity
        : AddressValidationDataGranularityLevels.PREMISE,
  }

  delete result?.address?.missingComponentTypes
  delete result?.address?.unconfirmedComponentTypes

  const postalAddress = result?.address?.postalAddress
  const effectivePostalCode = postalAddress?.postalCode?.split("-")[0]
  const effectiveFormatedAddress = `${postalAddress?.addressLines?.join(" ")}, ${postalAddress?.locality}, ${postalAddress?.administrativeArea}, ${postalAddress?.regionCode}, ${effectivePostalCode}`

  const effectiveResult = {
    ...result,
    verdict: effectiveVerdict,
    address: {
      ...result?.address,
      addressComponents: effectivesAddressComponents,
      ...(effectiveMissingAddressComponentsTypes?.length && {
        missingComponentTypes: effectiveMissingAddressComponentsTypes,
      }),
      ...(effectiveUnconfirmedAddressComponentsTypes?.length && {
        unconfirmedComponentTypes: effectiveUnconfirmedAddressComponentsTypes,
      }),
      formattedAddress: effectiveFormatedAddress,
      postalAddress: {
        ...postalAddress,
        postalCode: effectivePostalCode,
      },
    },
  }

  return effectiveResult
}

const getEffectiveComponentType = (componentType: string) =>
  componentType.includes("administrative_area")
    ? effectiveValidationAddressComponents["administrative_area"]
    : componentType

const getEffectivesAddressComponents = (addressComponents: AddressValidationDataAddress["addressComponents"]) =>
  addressComponents?.reduce(
    (acc, component) => {
      const effectiveComponentType = getEffectiveComponentType(component.componentType)
      if (effectiveValidationAddressComponents[effectiveComponentType as ComponentType]) {
        return [
          ...(acc ?? []),
          {
            ...component,
            componentType: effectiveComponentType,
            confirmationLevel:
              component.confirmationLevel === AddressValidationDataConfirmationLevels.UNCONFIRMED_BUT_PLAUSIBLE &&
              component.componentType === "street_number"
                ? AddressValidationDataConfirmationLevels.CONFIRMED
                : component?.spellCorrected
                  ? AddressValidationDataConfirmationLevels.UNCONFIRMED_AND_SUSPICIOUS
                  : component.confirmationLevel,
          },
        ]
      }
      return acc
    },
    [] as AddressValidationDataAddress["addressComponents"],
  )

const getInferredAddressComponents = (addressComponents: AddressValidationDataAddress["addressComponents"]) =>
  addressComponents?.reduce(
    (inferredAddressComponents, component) => {
      if (component.inferred) {
        return [...(inferredAddressComponents ?? []), component]
      }

      return inferredAddressComponents
    },
    [] as AddressValidationDataAddress["addressComponents"],
  )

const getEffectiveMissingAddressComponentsTypes = (missingAddressComponentsTypes: string[] | undefined) =>
  missingAddressComponentsTypes?.reduce((missingAddressComponentsTypes, componentType) => {
    const effectiveComponentType = getEffectiveComponentType(componentType)

    if (effectiveValidationAddressComponents[effectiveComponentType as ComponentType]) {
      missingAddressComponentsTypes?.push(effectiveComponentType)
    }

    return missingAddressComponentsTypes
  }, [] as string[])

const isNeededToFixAddress = (verdict: AddressValidationResultData["verdict"]) => {
  if (
    verdict?.validationGranularity === AddressValidationDataGranularityLevels.OTHER ||
    !verdict?.addressComplete ||
    !!verdict?.hasReplacedComponents ||
    !!verdict?.hasUnconfirmedComponents
  ) {
    return true
  }

  return false
}

const isNeededToConfirmAddress = (verdict: AddressValidationResultData["verdict"]) => {
  if (
    verdict?.validationGranularity !== AddressValidationDataGranularityLevels.OTHER &&
    !!verdict?.addressComplete &&
    !!verdict?.hasInferredComponents
  ) {
    return true
  }

  return false
}

const isAcceptedAddress = (verdict: AddressValidationResultData["verdict"]) => {
  if (
    (verdict?.validationGranularity?.includes(AddressValidationDataGranularityLevels.PREMISE) ||
      verdict?.validationGranularity === AddressValidationDataGranularityLevels.GRANULARITY_UNSPECIFIED) &&
    ((!!verdict.addressComplete && !!verdict.hasReplacedComponents) ||
      ((verdict?.addressComplete === undefined || verdict.hasReplacedComponents === undefined) &&
        !verdict?.hasInferredComponents))
  ) {
    return true
  }

  return false
}

const getErrorField = ({
  effectiveComponentType,
  isMissing,
  missingComponentTypes,
  addressLines,
}: {
  effectiveComponentType: string
  isMissing: boolean
  missingComponentTypes?: string[]
  addressLines?: string[]
}) => {
  if (effectiveComponentType === effectiveValidationAddressComponents.subpremise) {
    const line = addressLines

    if (isMissing) {
      if (line?.[1] !== undefined && line[1] !== "") {
        return effectivesAddressFormFieldsMapping["subpremise"]
      }

      if (
        line?.[0] !== undefined &&
        line[0] !== "" &&
        missingComponentTypes?.filter(
          (type) =>
            type === effectiveValidationAddressComponents.route ||
            type === effectiveValidationAddressComponents.street_number,
        ).length
      ) {
        return effectivesAddressFormFieldsMapping["line"]
      }
    }
  }

  return effectiveComponentType === "route" || effectiveComponentType === "street_number"
    ? effectivesAddressFormFieldsMapping["line"]
    : effectivesAddressFormFieldsMapping[effectiveComponentType as EffectiveComponentType]
}

const normalizeComponentType = (componentType: string) => {
  const standardComponentType =
    componentType === effectiveValidationAddressComponents.postal_code
      ? "zip code"
      : componentType === effectiveValidationAddressComponents.locality
        ? "city"
        : componentType === effectiveValidationAddressComponents.administrative_area
          ? "state"
          : componentType === effectiveValidationAddressComponents.subpremise
            ? "line 2"
            : componentType
  const noUnderscores = standardComponentType.replace(/_/g, " ")

  const separatedCamelCase = noUnderscores.replace(/([a-z])([A-Z])/g, "$1 $2")

  return separatedCamelCase.toLowerCase()
}

const getNeededToConfirmedFields = (
  addressComponents: AddressValidationDataAddress["addressComponents"],
  missingComponentTypes?: string[],
  currentAddressLines?: string[],
) => {
  let neededToFixTypes: {
    fixable: {
      componentType: string
      value: string
    }[]
    wrong: string[]
    missing: string[]
  } = { fixable: [], wrong: [], missing: [] }

  const neededToConfirmMissingAddressComponents =
    missingComponentTypes?.reduce((prev, componentType) => {
      const { field, message } = getErrorField({
        effectiveComponentType: componentType,
        isMissing: true,
        missingComponentTypes,
        addressLines: currentAddressLines,
      })
      neededToFixTypes = {
        ...neededToFixTypes,
        missing: [...neededToFixTypes.missing, normalizeComponentType(componentType)],
      }
      return { ...prev, [field]: message }
    }, {} as FormikErrors<FormikValues>) ?? {}

  const neededToConfirmAddressComponents =
    addressComponents?.reduce((prev, component) => {
      const fixableButIncorrect = component?.inferred || component?.replaced || component?.spellCorrected
      if (fixableButIncorrect || component?.confirmationLevel.includes("UNCONFIRMED")) {
        const { field, message } = getErrorField({
          effectiveComponentType: component.componentType,
          isMissing: false,
          missingComponentTypes,
          addressLines: currentAddressLines,
        })
        neededToFixTypes = {
          ...neededToFixTypes,
          ...(fixableButIncorrect && {
            fixable: [
              ...(neededToFixTypes?.fixable ?? []),
              {
                componentType: normalizeComponentType(component.componentType),
                value: component?.componentName?.text as string,
              },
            ],
          }),
          wrong: [...neededToFixTypes.wrong, normalizeComponentType(component.componentType)],
        }
        return { ...prev, [field]: message }
      }

      return prev
    }, {} as FormikErrors<FormikValues>) ?? {}

  return {
    errors: { ...neededToConfirmAddressComponents, ...neededToConfirmMissingAddressComponents },
    neededToFixTypes: neededToFixTypes,
  }
}

const getFieldError = (fields: FieldErrorType[]): string => {
  const [key, value] = fields[0]
  if (typeof value === "string") return key

  if (Array.isArray(value)) {
    const index = value.findIndex((d) => d)
    return `${key}[${index}].${getFieldError(Object.entries(value[index]))}`
  }

  return `${key}.${getFieldError(Object.entries(value))}`
}

const isValidPractitioner = (practitionersInfo: PractitionerInfo[], practId?: string) =>
  practitionersInfo.some(
    ({ practitioner, practitionerRole }) =>
      practitioner.id === practId && practitioner.active !== false && practitionerRole?.active !== false,
  )

const isCanonicalOfDrawFee = (canonical: string) =>
  canonical
    .match(/([/][0-9]+[|])/gi)
    ?.some((match) => DEFAULT_BLOOD_DRAWN_PANELS_LIST.includes(match.replace("/", "").replace("|", "")))

const getPriceByCode = ({
  productPrices,
  medCoding,
  shippingAddressState,
  quantity = 1,
  productFrequency,
  specifyMedFee = false,
  billingType = GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE,
}: {
  productPrices: Record<string, ChargeItemDefinition> | undefined
  medCoding?: Coding[]
  shippingAddressState?: string
  quantity?: number
  productFrequency?: Duration
  specifyMedFee?: boolean
  billingType?: GENERIC_BILLING_TYPE
}): Money | undefined => {
  const code = getCommonCoding({ codes: medCoding, shippingAddressState })

  if (!code || !productPrices) return undefined

  const productKey = getProductKey({
    code,
    billingType,
    frequency: specifyMedFee && productFrequency ? productFrequency : undefined,
    quantity,
  })

  let cid = productPrices[productKey]

  if (!cid && specifyMedFee) {
    let medFee
    for (const productId in productPrices) {
      const product = productPrices[productId]
      if (product.code?.coding?.some((c) => c.code === code.code)) {
        medFee = getFeeType([product])
        break
      }
    }

    if (medFee) {
      const updatedProductKey = getProductKey({
        code,
        billingType,
        frequency: medFee === MED_FEE_TYPE.ByFrequency ? productFrequency : undefined,
        quantity,
      })
      cid = productPrices[updatedProductKey]
    }
  }

  const cost = getBasePrice(cid?.propertyGroup?.[0]?.priceComponent)?.amount
  return cost?.value ? { value: cost.value, currency: cost?.currency ?? "USD" } : undefined
}

const DEFAULT_QUANTITY = 1

type BaseProcessMedicationArgs = {
  specifiedQuantity?: boolean
  specifiedMedFrequency?: boolean
  billingType: PRODUCT_CONFIGURATION_BILLING_TYPE
  medsProductConfigurations: ProductConfigurationToRequest[]
  uniqueConfigurations: Set<string>
}

type ProcessMedicationRequestArgs = BaseProcessMedicationArgs & {
  med: MedicationRequest
  referenceMedFrequency?: Duration
}

type ProcessMedicationKnowledgeArgs = BaseProcessMedicationArgs & {
  med: MedicationKnowledge
  referenceMedFrequency?: Duration
  quantities: number[]
}

const configurationToKey = ({ code, billingType, quantity, frequency }: ProductConfigurationToRequest) =>
  `${code.code}-${billingType}-${quantity}-${frequency?.value}${frequency?.unit}`

const addUniqueConfiguration = (
  configuration: ProductConfigurationToRequest | undefined,
  uniqueConfigurations: Set<string>,
  medsProductConfigurations: ProductConfigurationToRequest[],
) => {
  if (configuration) {
    const key = configurationToKey(configuration)
    if (!uniqueConfigurations.has(key)) {
      uniqueConfigurations.add(key)
      medsProductConfigurations.push(configuration)
    }
  }
}

const processMedicationRequest = ({
  med,
  specifiedQuantity,
  specifiedMedFrequency,
  billingType,
  medsProductConfigurations,
  uniqueConfigurations,
}: ProcessMedicationRequestArgs) => {
  const embeddedBillingType = getBillingTypeCode(med)
  const configuration = buildProductConfigurationToRequest({
    code: med.medication?.CodeableConcept?.coding,
    billingType:
      embeddedBillingType && billingType !== PRODUCT_CONFIGURATION_BILLING_TYPE.BOTH
        ? getGenericBillingType(embeddedBillingType)
        : billingType,
    quantity: specifiedQuantity ? med?.dispenseRequest?.quantity?.value ?? DEFAULT_QUANTITY : undefined,
    frequency: specifiedMedFrequency ? (med?.dispenseRequest?.dispenseInterval as Quantity) : undefined,
  })

  addUniqueConfiguration(configuration, uniqueConfigurations, medsProductConfigurations)
}

const processMedicationKnowledge = ({
  med,
  specifiedQuantity,
  specifiedMedFrequency,
  referenceMedFrequency,
  billingType,
  medsProductConfigurations,
  quantities,
  uniqueConfigurations,
}: ProcessMedicationKnowledgeArgs) => {
  const frequency = specifiedMedFrequency && referenceMedFrequency ? referenceMedFrequency : undefined

  quantities.forEach((quantity) => {
    const configuration = buildProductConfigurationToRequest({
      code: med?.code?.coding,
      billingType,
      quantity: specifiedQuantity ? quantity : undefined,
      frequency,
    })

    addUniqueConfiguration(configuration, uniqueConfigurations, medsProductConfigurations)
  })
}

const getMedsProductConfigurations = ({
  meds,
  specifiedQuantity = false,
  referenceQuantities,
  specifiedMedFrequency = false,
  referenceMedFrequency,
  billingType = PRODUCT_CONFIGURATION_BILLING_TYPE.BOTH,
}: {
  meds?: Array<MedicationRequest | MedicationKnowledge>
  specifiedQuantity?: boolean
  referenceQuantities?: number[]
  specifiedMedFrequency?: boolean
  referenceMedFrequency?: Duration
  billingType?: PRODUCT_CONFIGURATION_BILLING_TYPE
}): ProductConfigurationToRequest[] => {
  if (!meds?.length) return []

  const quantities = [DEFAULT_QUANTITY, ...(referenceQuantities ?? [])]
  const uniqueConfigurations = new Set<string>()
  const medsProductConfigurations: ProductConfigurationToRequest[] = []

  meds.forEach((med) => {
    if (isMedicationRequest(med)) {
      processMedicationRequest({
        med,
        specifiedQuantity,
        specifiedMedFrequency,
        billingType,
        medsProductConfigurations,
        uniqueConfigurations,
      })
    } else if (isMedicationKnowledge(med)) {
      processMedicationKnowledge({
        med,
        specifiedQuantity,
        specifiedMedFrequency,
        referenceMedFrequency,
        billingType,
        medsProductConfigurations,
        quantities,
        uniqueConfigurations,
      })
    }
  })

  return medsProductConfigurations
}

// add more variables to this array
// to include extra product configuration options
const extraConfigurationData = [
  {
    variable: "quantity",
  },
  {
    variable: "frequency",
  },
]

const formatProductPricesResponse = (paramatersBundle: Parameters) => {
  const {
    [GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE]: practiceOrInsuranceChargeItemDefinitions,
    [GENERIC_BILLING_TYPE.BILL_PATIENT]: patientChargeItemDefinitions,
  } = getChargeItemDefinitions(paramatersBundle)

  const practiceOrInsuranceChargeItemDefinitionsByApplicability = indexChargeItemDefinitionsByApplicability(
    practiceOrInsuranceChargeItemDefinitions,
    GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE,
  )

  const patientChargeItemDefinitionsByApplicability = indexChargeItemDefinitionsByApplicability(
    patientChargeItemDefinitions,
    GENERIC_BILLING_TYPE.BILL_PATIENT,
  )

  return {
    ...practiceOrInsuranceChargeItemDefinitionsByApplicability,
    ...patientChargeItemDefinitionsByApplicability,
  }
}

const NOT_FOUND_KEY = "NOT_PRODUCT_CODE"
const indexChargeItemDefinitionsByApplicability = (
  chargeItemDefinitions: ChargeItemDefinition[],
  billingType: GENERIC_BILLING_TYPE,
) => {
  if (!chargeItemDefinitions.length) return {}

  let chargeItemDefinitionsByApplicability: Record<string, ChargeItemDefinition> = {}

  chargeItemDefinitions.forEach((chargeItemDefinition) => {
    const kvStore = productToKVStore(chargeItemDefinition, billingType)
    if (!kvStore) return
    chargeItemDefinitionsByApplicability = {
      ...chargeItemDefinitionsByApplicability,
      ...kvStore,
    }
  })

  return chargeItemDefinitionsByApplicability
}

const productToKVStore = (
  chargeItemDefinition: ChargeItemDefinition,
  billingType: GENERIC_BILLING_TYPE,
): undefined | Record<string, ChargeItemDefinition> => {
  const genericKey = getCommonCode({ codes: chargeItemDefinition.code?.coding })
  if (genericKey === "no-code") return undefined

  const baseKey = `${genericKey}|${billingType}`

  const hasApplicability = chargeItemDefinition.propertyGroup?.some((propertyGroup) => propertyGroup.applicability)

  if (!hasApplicability) return { [baseKey]: chargeItemDefinition }

  const kvStore: Record<string, ChargeItemDefinition> = {}

  chargeItemDefinition.propertyGroup?.forEach((propertyGroup) => {
    const applicabilityKey = applicabilityToKey(propertyGroup.applicability)
    if (applicabilityKey) {
      const effectiveChargeItemDefinition = {
        ...chargeItemDefinition,
        propertyGroup: [propertyGroup],
      }
      kvStore[`${baseKey}|${applicabilityKey}`] = effectiveChargeItemDefinition
    }
  })

  return kvStore
}

const applicabilityToKey = (applicability: ChargeItemDefinition["applicability"]) => {
  if (!applicability?.length) return undefined

  const applicabilityMap = new Map()
  for (const item of applicability) {
    if (item.description && item.expression) {
      applicabilityMap.set(item.description, item.expression.replaceAll(" ", ""))
    }
  }

  const result = []
  for (const { variable } of extraConfigurationData) {
    const expression = applicabilityMap.get(variable)
    if (expression) {
      result.push(expression)
    }
  }

  return result.join("|")
}

const getChargeItemDefinitionsBundle = (paramatersBundle: Parameters, billingType: GENERIC_BILLING_TYPE) => {
  return paramatersBundle?.parameter?.find((p) => p.name === billingType)?.resource as Bundle
}

const getChargeItemDefinitions = (paramatersBundle: Parameters) => {
  const chargeItemDefinitionsByGenericBillingType: Record<GENERIC_BILLING_TYPE, ChargeItemDefinition[]> = {
    [GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE]: [],
    [GENERIC_BILLING_TYPE.BILL_PATIENT]: [],
  }

  const buildToPracticeOrInsuranceBundle = getChargeItemDefinitionsBundle(
    paramatersBundle,
    GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE,
  )

  const buildToPatientBundle = getChargeItemDefinitionsBundle(paramatersBundle, GENERIC_BILLING_TYPE.BILL_PATIENT)

  if (buildToPracticeOrInsuranceBundle) {
    const buildToPracticeOrInsuranceChargeItemDefinitions = getResources<ChargeItemDefinition>(
      buildToPracticeOrInsuranceBundle,
      "ChargeItemDefinition",
    )

    chargeItemDefinitionsByGenericBillingType[GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE].push(
      ...buildToPracticeOrInsuranceChargeItemDefinitions,
    )
  }

  if (buildToPatientBundle) {
    const buildToPatientChargeItemDefinitions = getResources<ChargeItemDefinition>(
      buildToPatientBundle,
      "ChargeItemDefinition",
    )

    chargeItemDefinitionsByGenericBillingType[GENERIC_BILLING_TYPE.BILL_PATIENT].push(
      ...buildToPatientChargeItemDefinitions,
    )
  }

  return chargeItemDefinitionsByGenericBillingType
}

const buildProductPricesParametersTemplate = (): Parameters => ({
  resourceType: "Parameters",
  parameter: [
    {
      name: "order-info",
      part: [],
    },
    {
      name: "order-info",
      part: [],
    },
  ],
})

const generateProductPricesParameters = (
  productsConfigurations: ProductConfigurationToRequest[],
  includeAllFees: boolean,
): Parameters => {
  const orderInfoParts = buildOrderInfo(productsConfigurations)
  const hasPracticeOrInsuranceBilling = orderInfoParts[GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE].length > 0
  const hasPatientBilling = orderInfoParts[GENERIC_BILLING_TYPE.BILL_PATIENT].length > 0

  const parameters: Parameters = buildProductPricesParametersTemplate()

  if (hasPracticeOrInsuranceBilling) {
    parameters.parameter![0].part = orderInfoParts[GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE]
  }

  if (hasPatientBilling) {
    parameters.parameter![1].part = [
      ...orderInfoParts[GENERIC_BILLING_TYPE.BILL_PATIENT],
      {
        name: "order-type",
        value: {
          string: "bill-patient",
        },
      },
    ]
  }

  parameters.parameter?.push({
    name: "include-fee",
    value: {
      boolean: includeAllFees,
    },
  })

  return parameters
}

type generateOrderInfoPartsOutput = {
  [GENERIC_BILLING_TYPE.BILL_PATIENT]: ParametersParameterArrayArray[]
  [GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE]: ParametersParameterArrayArray[]
}

const buildOrderInfo = (productsConfigurations: ProductConfigurationToRequest[]): generateOrderInfoPartsOutput => {
  const orderInfo: generateOrderInfoPartsOutput = {
    [GENERIC_BILLING_TYPE.BILL_PATIENT]: [],
    [GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE]: [],
  }

  const productInfoMap: Record<string, ParametersParameterArrayArray> = {}

  for (const item of productsConfigurations) {
    const effectiveCode = getCommonCode({ codes: item.code })
    if (effectiveCode === "no-code") continue

    const billingTypes = getBillingTypes(item.billingType)
    addProductInfoToOrderInfo(item, effectiveCode, billingTypes, orderInfo, productInfoMap)
  }

  return orderInfo
}

const needsExtraConfiguration = (item: ProductConfigurationToRequest) =>
  extraConfigurationData.some(({ variable }) => Boolean(item[variable as keyof ProductConfigurationToRequest]))

const addProductInfoToOrderInfo = (
  item: ProductConfigurationToRequest,
  effectiveCode: string,
  billingTypes: GENERIC_BILLING_TYPE[],
  orderInfo: generateOrderInfoPartsOutput,
  productInfoMap: Record<string, ParametersParameterArrayArray>,
) => {
  const requiresExtraConfiguration = needsExtraConfiguration(item)
  billingTypes.forEach((billingType) => {
    const key = `${billingType}-${effectiveCode}`
    const productInfo = getOrCreateProductInfo(key, item.code, productInfoMap, requiresExtraConfiguration)

    if (productInfo.part?.[1]?.part && requiresExtraConfiguration) {
      productInfo.part[1].part.push(getProductConfig(item))
    }

    if (!productInfoMap[key]) {
      productInfoMap[key] = productInfo
      orderInfo[billingType].push(productInfo)
    }
  })
}

const getBillingTypes = (billingType: PRODUCT_CONFIGURATION_BILLING_TYPE): GENERIC_BILLING_TYPE[] => {
  return billingType === PRODUCT_CONFIGURATION_BILLING_TYPE.BOTH
    ? [GENERIC_BILLING_TYPE.BILL_PATIENT, GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE]
    : [billingType]
}

const getOrCreateProductInfo = (
  key: string,
  code: Coding,
  productInfoMap: Record<string, ParametersParameterArrayArray>,
  requiresExtraConfiguration: boolean,
): ParametersParameterArrayArray =>
  productInfoMap[key] ?? {
    name: "product-info",
    part: [
      { name: "code", value: { Coding: code } },
      ...(requiresExtraConfiguration ? [{ name: "product-configs", part: [] }] : []),
    ],
  }

const getProductConfig = ({ quantity, frequency, billingType }: Omit<ProductConfigurationToRequest, "code">) =>
  ({
    name: "config-details",
    part: [
      {
        name: "quantity",
        value: { decimal: quantity },
      },
      ...(frequency && billingType === PRODUCT_CONFIGURATION_BILLING_TYPE.BILL_PATIENT
        ? [
            {
              name: "frequency",
              value: { Quantity: frequency },
            },
          ]
        : []),
    ],
  }) as ParametersParameterArrayArray

const getGenericBillingType = (billingType: BILLING_TYPES_CODES): GENERIC_BILLING_TYPE =>
  billingType === BILLING_TYPES_CODES.BILL_PATIENT
    ? GENERIC_BILLING_TYPE.BILL_PATIENT
    : GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE

const productConfigurationToKey = (productConfiguration: ProductConfiguration) => {
  const { billingType, code, quantity, frequency } = productConfiguration

  const genericKey = code
  if (!genericKey) return NOT_FOUND_KEY

  let baseKey = `${genericKey}|${billingType}`

  if (quantity) baseKey = `${baseKey}|${quantity}`

  if (frequency) baseKey = `${baseKey}|${frequency}`

  return baseKey
}

type BuildProductConfigurationBaseArgs = {
  code: Coding | Coding[] | undefined
  quantity?: number
  frequency?: Duration
}

type BuildProductConfigurationArgs = BuildProductConfigurationBaseArgs & {
  billingType?: BILLING_TYPES_CODES
}

const buildProductConfiguration = ({
  code,
  billingType = BILLING_TYPES_CODES.BILL_PRACTICE,
  quantity,
  frequency,
}: BuildProductConfigurationArgs): ProductConfiguration | undefined => {
  const effectiveCode = getCommonCode({ codes: code })
  if (effectiveCode === "no-code") return undefined

  return {
    code: effectiveCode,
    billingType: getGenericBillingType(billingType),
    quantity,
    frequency: frequency ? `${frequency.value}${frequency.unit}` : undefined,
  }
}

type BuildProductConfigurationToRequestArgs = BuildProductConfigurationBaseArgs & {
  billingType: PRODUCT_CONFIGURATION_BILLING_TYPE
}

const buildProductConfigurationToRequest = ({
  code,
  billingType,
  quantity,
  frequency,
}: BuildProductConfigurationToRequestArgs): ProductConfigurationToRequest | undefined => {
  const effectiveCoding = getCommonCoding({ codes: code })
  if (!effectiveCoding) return undefined

  return {
    code: effectiveCoding,
    billingType,
    quantity,
    frequency,
  }
}

const buildBulkProductConfigurationsToRequest = ({
  codes,
  billingType = BILLING_TYPES_CODES.BILL_PRACTICE,
}: {
  codes: Coding[]
  billingType?: BILLING_TYPES_CODES | "both"
}): ProductConfigurationToRequest[] => {
  const result: ProductConfigurationToRequest[] = []
  const uniqueConfigurations = new Set<string>()

  for (const code of codes) {
    const productConfig = buildProductConfigurationToRequest({
      code,
      billingType:
        billingType === PRODUCT_CONFIGURATION_BILLING_TYPE.BOTH ? billingType : getGenericBillingType(billingType),
    })

    if (productConfig) {
      const key = configurationToKey(productConfig)
      if (!uniqueConfigurations.has(key)) {
        uniqueConfigurations.add(key)
        result.push(productConfig)
      }
    }
  }

  return result
}

const NOT_FOUND_PRODUCT_KEY = "NOT_FOUND_PRODUCT"
const getProductKey = ({
  code,
  billingType = BILLING_TYPES_CODES.BILL_PRACTICE,
  quantity = DEFAULT_QUANTITY,
  frequency,
}: BuildProductConfigurationArgs) => {
  const productConfiguration = buildProductConfiguration({ code, billingType, quantity, frequency })
  if (!productConfiguration) return NOT_FOUND_PRODUCT_KEY

  return productConfigurationToKey(productConfiguration)
}

export {
  buildBulkProductConfigurationsToRequest,
  buildProductConfiguration,
  formatProductPricesResponse,
  generateProductPricesParameters,
  getFieldError,
  getGenericBillingType,
  getMedsProductConfigurations,
  getNeededToConfirmedFields,
  getPDSkuValue,
  getPriceByCode,
  getProductKey,
  getResultAbstractDataRepresentation,
  isAcceptedAddress,
  isCanonicalOfDrawFee,
  isNeededToConfirmAddress,
  isNeededToFixAddress,
  isValidPractitioner,
}
