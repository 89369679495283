import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { Encounter } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { encounterQueryKeys } from "../query-keys"

const useFinishEncounter = (patientId: string, onSucess?: () => void, onSettled?: () => void) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const finishEncounter = async (openEncounter: Encounter) =>
    operationRequest({ endpoint: "Encounter", method: "POST", operation: "finish", id: openEncounter.id })

  const { mutate, isPending } = useMutation({
    mutationFn: finishEncounter,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: encounterQueryKeys.list(patientId, "KP") })
      await queryClient.invalidateQueries({ queryKey: encounterQueryKeys.details(patientId), refetchType: "all" })
      displayNotificationSuccess("Encounter finished successfully!")
      // datadogLogs.logger.info("Encounter finished successfully!", { data })
      onSucess?.()
    },
    onSettled,
  })

  return { finishEncounter: mutate, isFinishing: isPending }
}

export { useFinishEncounter }
