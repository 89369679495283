import { useState } from "react"

import { useChartContext } from "chart-view"
import { ConfirmDialog, KnowledgeListContainer, ModulesId, useSendToPatient } from "commons"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"

import { invoiceModelBuilder } from "../components/invoiceModelBuilder"
import { useInvoices } from "../hooks"

const InvoiceKnowledge = () => {
  const { appModules } = useAppModuleContext()
  const { patientId } = usePatientContext()
  const { showModule } = useChartContext()
  const { invoiceData, isLoading } = useInvoices({ patientId, statusFilter: ["issued"], key: "KP" })
  const { sendOrderToPatient, isSendingToPatient } = useSendToPatient()
  const [invoiceToSend, setInvoiceToSend] = useState("")

  const sendToPatientInvoice = (invoiceId: string) => {
    setInvoiceToSend(invoiceId)
  }

  const showSelectedInvoice = (invoiceId: string) => {
    showModule({ module: ModulesId.INVOICE, moduleParams: { invoiceId } })
  }

  return (
    <>
      <KnowledgeListContainer
        isLoading={isLoading}
        dataTitle="invoice"
        customEmptyMessage="No issued invoices found"
        data={invoiceData}
        iconDataNotFound={appModules[ModulesId.INVOICE].getIcon()}
        itemModelBuilder={(item) =>
          invoiceModelBuilder({
            invoiceData: item,
            showInvoice: () => showSelectedInvoice(item.invoice.id as string),
            showAsExternal: false,
            sendToPatientInvoice: () => sendToPatientInvoice(item.invoice.id as string),
          })
        }
      />

      <ConfirmDialog
        confirmText="Are you sure you want to send this invoice to the patient?"
        actionName="Send"
        visible={!!invoiceToSend || isSendingToPatient}
        isLoading={isSendingToPatient}
        onConfirm={() => {
          sendOrderToPatient(invoiceToSend)
        }}
        hideDialog={() => {
          setInvoiceToSend("")
        }}
      />
    </>
  )
}

export { InvoiceKnowledge }
