import { useQuery } from "@tanstack/react-query"
import type { Parameters } from "fhir"

import { useClient } from "api"

import { ordersQueryKeys } from "../query-keys"

const useCountCpoeOrders = (patientId: string) => {
  const { operationRequest } = useClient()
  const queryKey = ordersQueryKeys.count.withPatientId(patientId)

  const { data, refetch } = useQuery({
    queryKey,
    queryFn: async () => {
      const result = await operationRequest<Parameters>({
        endpoint: `Patient/${patientId}/cpoe`,
        method: "POST",
        operation: "count",
      })

      return { medicationRequestsCount: result.parameter?.find((param) => param.name === "Total")?.value?.integer ?? 0 }
    },
    meta: { context: { queryKey, patientId } },
    refetchOnWindowFocus: true,
  })

  return {
    ordersCount: data?.medicationRequestsCount ?? 0,
    refreshCpoeCount: refetch,
  }
}

export { useCountCpoeOrders }
