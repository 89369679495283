import { faCalendarDays, faEye, faPlay, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parseISO } from "date-fns/parseISO"
import { Chip } from "primereact/chip"
import { useNavigate } from "react-router-dom"
import { isAfter } from "date-fns/isAfter"
import { format } from "date-fns/format"
import { type FC, useState } from "react"
import type { Appointment } from "fhir"

import { EncounterConfig, useOpenEncounter } from "encounter"
import { formatsByTypes } from "data"
import { useOrganizationContext } from "organization"

import { AvatarImage } from "../../commons/components/AvatarImage"
import { AvatarListItem } from "../../commons/components/AvatarListItem"

const AgendaListItem: FC<Props> = ({
  patientAvatar,
  patientName,
  appointment,
  appointmentType,
  color,
  patientId,
  action,
  onUnbook,
}) => {
  const { currentOrganizationId } = useOrganizationContext()
  const navigate = useNavigate()
  const { openEncounterRef } = useOpenEncounter(patientId as string)

  const [startEncounter, setStartEncounter] = useState(false)

  const appointmentEnd = appointment.end
  const appointmentStart = appointment.start
  const appointmentStatus = appointment.status
  const appointmentDescription = appointment.description

  const isFutureOrProximAppt = appointmentEnd ? isAfter(parseISO(appointmentEnd.toString()), new Date()) : false

  const redirectToPatient = () => {
    navigate(`/orgs/${currentOrganizationId}/patients/${patientId}`)
  }

  const handleStartEncounter = () => {
    setStartEncounter(true)
  }

  const dropdownItems = [
    ...(patientId && !openEncounterRef && isFutureOrProximAppt
      ? [
          {
            label: "Start encounter",
            icon: <FontAwesomeIcon icon={faPlay} size="sm" className="mr-2" />,
            command: handleStartEncounter,
          },
        ]
      : []),
    {
      label: "Unbook",
      icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />,
      disabled: appointmentStatus === "cancelled",
      command: onUnbook,
    },
    {
      label: "View patient",
      icon: <FontAwesomeIcon icon={faEye} size="sm" className="mr-2" />,
      command: () => redirectToPatient(),
    },
  ]

  const head = (
    <>
      <span title="Patient">{patientName}</span>
      {appointmentStatus && (
        <span title="Status">
          <Chip label={appointmentStatus} className="ml-2 custom-chip" />
        </span>
      )}
    </>
  )

  const details = (
    <>
      <span className="flex items-center">
        <FontAwesomeIcon icon={faCalendarDays} className="mr-1 text-gray-400" />
        <span title="Start">
          {appointmentStart ? format(appointmentStart, formatsByTypes.LONG_DATETIME) : "No date"}
        </span>
      </span>
      {appointmentDescription && (
        <span title="Description" className="pl-2">
          {appointmentDescription}
        </span>
      )}
      {appointmentType && (
        <span title="Type" className="font-bold capitalize pl-2" style={{ color }}>
          {appointmentType}
        </span>
      )}
    </>
  )

  return (
    <>
      <AvatarListItem
        avatarImg={<AvatarImage name={patientName} photoUrl={patientAvatar} className="h-10 w-10 rounded-full" />}
        header={head}
        subHeader={details}
        dropdownMenuItems={dropdownItems}
        onContentClick={action}
      />
      <EncounterConfig
        patientId={patientId as string}
        patientRef={{ id: patientId, display: patientName, resourceType: "Patient" }}
        appointment={appointment}
        visible={startEncounter}
        onHide={() => setStartEncounter(false)}
      />
    </>
  )
}

type Props = {
  appointment: Appointment
  patientAvatar?: string
  patientName: string
  patientId?: string
  appointmentType?: string
  color?: string
  action?(): void
  onUnbook?(): void
}

export { AgendaListItem }
