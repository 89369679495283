import { Checkbox } from "primereact/checkbox"
import { classNames } from "primereact/utils"
import { type Dispatch, type FC, type SetStateAction, useMemo } from "react"

import { MEDICATION_CATALOG } from "data"
import { getCommonCode } from "utils"

import { SkeletonLoader } from "../../../../components/SkeletonLoader"
import { useProductPrices } from "../../../../hooks"
import { MedSupplement, useDraftMedicationRequests, useMedicationKnowledge } from "../../../../meds"
import { getMedsProductConfigurations } from "../../../../utils"
import { useSupplementDataBind } from "../../../hooks"
import type { Supplement } from "../../../types"
import { getSupplementId } from "../../../utils"

const SupplementRecomendations: FC<Props> = ({
  supplements,
  patientId,
  organizationId,
  selectedSupplements,
  triads,
  itemClassName,
  containerClassName,
  handleSelectSupplement,
}) => {
  const { medicationKnowledges: draftNutras, isLoading: isLoadingMRs } = useDraftMedicationRequests({
    patientId,
    category: "nutraceutical",
  })

  const medsProductConfigurations = getMedsProductConfigurations({
    meds: supplements.flatMap(({ mk }) => mk),
    specifiedQuantity: false,
  })

  const medTokens = useMemo(
    () => medsProductConfigurations?.map(({ code }) => `${code.system}|${code.code}`)?.join(",") ?? "",
    [medsProductConfigurations],
  )

  const { mksBySku: currentCatalogMKs, catalogs: performerByCatalog } = useMedicationKnowledge({
    medCodes: medTokens,
    category: MEDICATION_CATALOG.NUTRA,
    hasCIDInOrg: organizationId,
  })

  const { productPrices, isLoading: isLoadingPrices } = useProductPrices({
    organizationId: organizationId,
    productsConfigurations: medsProductConfigurations,
  })

  const { supplements: supplementsWithPrice } = useSupplementDataBind({
    supplements,
    mksBySku: currentCatalogMKs,
    medicationsCIDs: productPrices,
    performerByCatalog,
  })

  const availableSupplements = useMemo(
    () =>
      supplementsWithPrice.filter(
        (sup) => !draftNutras?.[getCommonCode({ codes: sup.mk.code?.coding })] && sup.mk.id && sup.price,
      ),
    [supplementsWithPrice, draftNutras],
  )

  const onSelect = (checked: boolean, supplement: Supplement) => {
    checked
      ? handleSelectSupplement([...selectedSupplements, supplement])
      : handleSelectSupplement(selectedSupplements.filter((s) => getSupplementId(s) !== getSupplementId(supplement)))
  }

  const isSupplementSelected = (supplement?: Supplement) => {
    return selectedSupplements.some((selected) => getSupplementId(selected) === getSupplementId(supplement))
  }
  const hideTimingHeaders = supplements.every(
    ({ mr }) => !mr.dosageInstruction?.some(({ timing }) => !!timing?.repeat?.when),
  )

  if (isLoadingMRs || isLoadingPrices)
    return <SkeletonLoader loaderType="one-line" repeats={supplementsWithPrice?.length} />

  if (!supplementsWithPrice?.length) return null

  return (
    <div className={classNames("flex flex-col overflow-y-auto gap-3 py-6 grow", containerClassName)}>
      <div className="grid grid-cols-12 rounded-md">
        <div className="col-span-5 flex gap-2 font-medium text-sm">
          <Checkbox
            onChange={(e) => (e.checked ? handleSelectSupplement(availableSupplements) : handleSelectSupplement([]))}
            checked={selectedSupplements.length === availableSupplements.length && !!availableSupplements.length}
            disabled={!availableSupplements.length}
          />
          <span>Select all</span>
        </div>
        <div className="col-span-6 grid grid-cols-5 gap-2">
          {!hideTimingHeaders && (
            <>
              <div className="text-center text-sm">Morning</div>
              <div className="text-center text-sm">Noon</div>
              <div className="text-center text-sm">Afternoon</div>
              <div className="text-center text-sm">Dinner</div>
              <div className="text-center text-sm">Bed time</div>
            </>
          )}
        </div>
      </div>
      {supplementsWithPrice.map((sup, index) => (
        <MedSupplement
          key={index}
          suplement={sup}
          selected={isSupplementSelected(sup)}
          onChecked={(e) => onSelect(e, sup)}
          disabled={!!draftNutras?.[getCommonCode({ codes: sup.mk.code?.coding })] || !sup.mk.id || !sup.price}
          inUse={!!draftNutras?.[getCommonCode({ codes: sup.mk.code?.coding })]}
          triads={triads}
          className={itemClassName}
        />
      ))}
    </div>
  )
}

type Props = {
  organizationId: string
  patientId: string
  supplements: Supplement[]
  selectedSupplements: Supplement[]
  handleSelectSupplement: Dispatch<SetStateAction<Supplement[]>>
  triads?: Record<string, { img: string; borderColor: string; bgColor: string }>
  itemClassName?: string
  containerClassName?: string
}

export { SupplementRecomendations }
