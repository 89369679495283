import { faCalendar, faSyringe, faUserDoctor } from "@fortawesome/pro-regular-svg-icons"
import { faPenToSquare } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parseISO } from "date-fns/parseISO"
import { codeableConceptAsString, isCarePlan } from "fhir"

import { MenuStyles, type StackedListItemProps } from "commons"
import type { ProcedureData } from "commons/procedures"
import { formatsByTypes } from "data"
import { formatDate, getBadgeColor } from "utils"
import { defaultEditRemoveMenu } from "utils-components"

const proceduresItemModel = ({
  procedureData,
  onDelete,
  onEdit,
  onEditNote,
  onShowDetails,
  showAsExternalAction,
  externalAction,
}: {
  procedureData: ProcedureData
  onShowDetails?: () => void
  onEdit?: () => void
  onDelete?: () => void
  onEditNote?: () => void
  showAsExternalAction?: boolean
  externalAction?: StackedListItemProps["menu"]
}): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [{ name: "Type", value: codeableConceptAsString(procedureData.procedure.code) }],
    },
    {
      lineItems: [
        ...(procedureData.procedure.performed?.dateTime
          ? [
              {
                name: "Performed",
                value: formatDate(parseISO(procedureData.procedure.performed?.dateTime), formatsByTypes.LONG_DATE),
                icon: faCalendar,
              },
            ]
          : []),
        { name: "Performer", value: procedureData.performerName, icon: faUserDoctor },
        { name: "Medications", value: `${procedureData.configurationItem.length}`, icon: faSyringe },
      ],
    },
  ],
  badge: getBadgeColor(procedureData.procedure.status),
  onClick: onShowDetails,
  menuStyle: showAsExternalAction ? MenuStyles.ExternalAction : undefined,
  menu:
    procedureData.procedure.status === "preparation"
      ? defaultEditRemoveMenu(onEdit, !procedureData.procedure.basedOn?.some(isCarePlan) ? onDelete : undefined)
      : [
          ...(externalAction ?? []),
          ...(onEditNote
            ? [
                {
                  label: "Edit Note",
                  icon: <FontAwesomeIcon icon={faPenToSquare} size="sm" />,
                  command: onEditNote,
                },
              ]
            : []),
        ],
})

export { proceduresItemModel }
