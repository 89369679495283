import { useInfiniteQuery, type InfiniteData, type QueryFunction } from "@tanstack/react-query"
import { getResources, type Bundle, type Composition } from "fhir"

import { useClient, type SearchArgs } from "api"

import { commonsQueryKeys } from "../query-keys"

const snippetsNoteQueryFn =
  ({
    authorId,
    search,
  }: {
    authorId: string
    search: ({ endpoint, filters, operation, signal }: SearchArgs) => Promise<Bundle>
  }):
    | QueryFunction<
        {
          compositions: Composition[]
          next: number | undefined
          total: number
        },
        string[],
        number
      >
    | undefined =>
  async ({ pageParam = 1, signal }) => {
    const filters = new URLSearchParams({
      _count: "10",
      _page: `${pageParam}`,
      _sort: "-date",
      _elements: "author, date, r5_name, r5_note",
      author: authorId,
      type: "snippets-note",
    })

    const bundle: Bundle = await search({ endpoint: "Composition", filters, signal })

    const compositions = getResources(bundle) as Composition[]

    const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

    return { compositions, next, total: bundle?.total ?? 0 }
  }

const useSnippetsNote = ({ authorId }: { authorId: string }) => {
  const { search } = useClient()
  const queryKey = commonsQueryKeys.snippetNotes(authorId)

  const {
    data: { compositions, total } = { compositions: [], total: 0 },
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey,
    queryFn: snippetsNoteQueryFn({ authorId, search }),
    select(data) {
      return { compositions: data.pages.flatMap((page) => page.compositions), total: data.pages?.[0]?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, authorId } },
    throwOnError: true,
  })

  return {
    snippetNotes: compositions,
    isLoading,
    count: compositions?.length ?? 0,
    total,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type NoteSnippetsQueryResponse = InfiniteData<{
  compositions: Composition[]
  next: number | undefined
  total: number
}>

export { snippetsNoteQueryFn, useSnippetsNote, type NoteSnippetsQueryResponse }
