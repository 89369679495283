import { useMutation } from "@tanstack/react-query"
import type { DocumentReference } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { openLinkInNewTab, sanitizeURL } from "utils"

const usePrintEncounter = () => {
  const { operationRequest, getSignedUrl } = useClient()

  const { mutate, isPending, data } = useMutation({
    mutationFn: (encounterId: string) =>
      operationRequest<DocumentReference>({
        endpoint: "Encounter",
        method: "POST",
        operation: "pdfize",
        id: encounterId,
      }),
    onError: (error: CustomError, encounterId) => {
      displayNotificationError(registerErrorTrace(error, { encounterId }))
    },
    onSuccess: async (data) => {
      if (data.content[0].attachment.url) {
        const url = data.content[0].attachment.url.startsWith("/")
          ? data.content[0].attachment.url.slice(1)
          : data.content[0].attachment.url

        const signedUrl = await getSignedUrl(sanitizeURL(url))

        if (signedUrl.url) openLinkInNewTab(signedUrl.url)
      } else {
        displayNotificationError(registerErrorTrace(new Error("Wrong prescription url") as CustomError, { data }))
      }
    },
  })

  return { printEncounter: mutate, isPrintingEncounter: isPending, encounter: data }
}

export { usePrintEncounter }
