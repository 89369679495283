import { faArrowRight, faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type FieldProps, ErrorMessage, Field, useFormikContext } from "formik"
import { useDebounce } from "primereact/hooks"
import { IconField } from "primereact/iconfield"
import { InputIcon } from "primereact/inputicon"
import { InputText } from "primereact/inputtext"
import { ScrollPanel } from "primereact/scrollpanel"
import { classNames } from "primereact/utils"
import { type ChangeEvent, type FC, useRef, useEffect } from "react"
import type { Address } from "fhir"

import { type SuggestionsEntity, useSmartyAddressAutoCompletion, useSmartyAddressVerificationContext } from "../hooks"

const AutoCompleteAddressField: FC<Props> = ({
  field,
  label,
  placeholder,
  className,
  horizontal,
  inputClassName,
  isReadonly,
  onAutoCompleteAddress,
}) => {
  const {
    setFieldValue,
    values: { line },
  } = useFormikContext<Address>()

  const initialSearchParams = {
    searchText: line?.[0] ? line[0] : "",
    selected: undefined,
  }
  const containerRef = useRef<HTMLDivElement>(null)

  const [, debouncedSearchParams, setSearchParams] = useDebounce<
    { searchText?: string; selected?: string } | undefined
  >(initialSearchParams, 400)

  const { isOverlayVisible, showAutoCompleteOverlay, hideAutoCompleteOverlay } = useSmartyAddressVerificationContext()

  const { suggestions, isAutoCompleting } = useSmartyAddressAutoCompletion(debouncedSearchParams, !isReadonly)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!containerRef.current) return

      if (!containerRef.current.contains(event.target as Node)) {
        hideAutoCompleteOverlay()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const handleAutoCompleteChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (!value && !isReadonly) {
      hideAutoCompleteOverlay()
    } else if (!isOverlayVisible) {
      showAutoCompleteOverlay()
    }

    if (typeof value !== "string") return

    setSearchParams((prev) => ({ ...prev, searchText: value.trim() }))
    setFieldValue(field, value)
  }

  const handleAutoCompleteBlur = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isOverlayVisible) {
      const value = e.target.value
      if (!value) {
        setSearchParams((prev) => ({ ...prev, searchText: "" }))
        setFieldValue(field, "")
      }
      setSearchParams((prev) => ({ ...prev, selected: "" }))
    }
  }

  const handleAutoCompleteFocus = () => {
    showAutoCompleteOverlay()
  }

  const handleSuggestionSelect = ({
    suggestion,
    setFormFieldValue,
  }: {
    suggestion: SuggestionsEntity
    setFormFieldValue: () => void
  }) => {
    if (suggestion.entries > 1) {
      setSearchParams(() => ({
        searchText: suggestion.street_line + " " + suggestion.secondary,
        selected: buildAddress({ suggestion, needsEntriesClarification: true }),
      }))
    } else {
      setFormFieldValue()
      onAutoCompleteAddress?.(suggestion)
      hideAutoCompleteOverlay()
      setSearchParams(() => ({
        searchText: suggestion.street_line,
        selected: "",
      }))
    }
  }

  return (
    <Field name={field}>
      {({ field: { name, value }, meta: { touched, error } }: FieldProps) => (
        <div
          ref={containerRef}
          className={classNames(
            "field relative",
            horizontal ? "inline-flex justify-between" : "flex flex-col",
            className,
          )}
        >
          {label && (
            <label
              htmlFor={name}
              className={classNames("text-sm font-medium text-gray-700 mb-2", {
                "mr-3 mb-0 mt-2": horizontal,
              })}
            >
              {label}
            </label>
          )}

          <div className="flex flex-col w-full">
            <IconField className="relative">
              {isAutoCompleting && (
                <InputIcon>
                  <FontAwesomeIcon icon={faSpinner} spin />
                </InputIcon>
              )}

              <InputText
                value={value}
                onChange={handleAutoCompleteChange}
                onFocus={handleAutoCompleteFocus}
                onBlur={handleAutoCompleteBlur}
                placeholder={placeholder}
                className={classNames(
                  "p-inputtext-sm",
                  {
                    "p-invalid": touched && error,
                    horizontal: horizontal,
                  },
                  inputClassName,
                )}
              />
            </IconField>

            {suggestions.length > 0 && isOverlayVisible && (
              <ScrollPanel
                className="absolute border-2 surface-border rounded mt-1 z-10 shadow-sm bg-white top-16 max-h-52 overflow-scroll w-full"
                pt={{
                  content: {
                    className: "pb-1",
                  },
                }}
              >
                <ul>
                  {suggestions.map((suggestion: SuggestionsEntity, index) => (
                    <li
                      key={index}
                      className="flex justify-between items-center px-4 py-2 hover:bg-gray-200 cursor-pointer w-full"
                      onClick={(e) => {
                        e.preventDefault()
                        handleSuggestionSelect({
                          suggestion,
                          setFormFieldValue: () => setFieldValue(name, suggestion.street_line),
                        })
                      }}
                    >
                      <span>{buildAddress({ suggestion })}</span>
                      {suggestion.entries > 1 && (
                        <span className="ml-2 inline-flex items-center">
                          ({suggestion.entries}) entries <FontAwesomeIcon icon={faArrowRight} className="fa-fw" />
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </ScrollPanel>
            )}

            <div className="flex items-start p-error field-error-spacing mb-2">
              <ErrorMessage name={name}>{(msg) => <small>{msg}</small>}</ErrorMessage>
            </div>
          </div>
        </div>
      )}
    </Field>
  )
}

const buildAddress = ({
  suggestion,
  needsEntriesClarification = false,
}: {
  suggestion: SuggestionsEntity
  needsEntriesClarification?: boolean
}) => {
  let whiteSpace = ""
  if (suggestion.secondary) {
    whiteSpace = " "
    if (needsEntriesClarification) {
      suggestion.secondary += ` (${suggestion.entries})`
    }
  }
  return (
    suggestion.street_line +
    whiteSpace +
    suggestion.secondary +
    " " +
    suggestion.city +
    ", " +
    suggestion.state +
    " " +
    suggestion.zipcode
  )
}

type Props = {
  field: string
  label?: string
  placeholder?: string
  className?: string
  horizontal?: boolean
  inputClassName?: string
  onAutoCompleteAddress?: (value: SuggestionsEntity) => void
  isReadonly?: boolean
}

export { AutoCompleteAddressField }
