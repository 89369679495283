import type { FC } from "react"

import { SnippetsNoteContainer } from "administration"
import { asReference } from "fhir"
import { useLoginContext } from "security"

import { ProfileSection } from "../data"

const SnippetsNoteInformation: FC = () => {
  const { loggedInPractitioner } = useLoginContext()
  return (
    <div
      className="border rounded-lg shadow-sm scroll-mt-4 profile-card-section p-4 pb-0"
      id={ProfileSection.SNIPPETS_NOTE}
    >
      <h3 className="flex font-semibold text-lg text-gray-900 mb-2">Snippets note</h3>
      <SnippetsNoteContainer author={asReference(loggedInPractitioner)} />
    </div>
  )
}

export { SnippetsNoteInformation }
