import type { FC } from "react"
import pluralize from "pluralize"

import { ConfirmDialog } from "commons"

interface ConfirmDiscardDialogProps {
  visible: boolean
  loading: boolean
  selectedItemsCount: number
  onAccept(): void
  onCancel(): void
}

const ConfirmDiscardDialog: FC<ConfirmDiscardDialogProps> = ({
  visible,
  selectedItemsCount,
  loading,
  onAccept,
  onCancel,
}) => (
  <ConfirmDialog
    confirmElement={
      <>
        <p className="mb-4">{`Are you sure you want to discard ${pluralize("this", selectedItemsCount)} ${pluralize("prescription", selectedItemsCount)}?`}</p>
        <p className="text-sm text-gray-500 italic">*Note: Only draft medications can be discarded</p>
      </>
    }
    header="Confirmation"
    actionName="Discard"
    onConfirm={onAccept}
    visible={visible}
    isLoading={loading}
    hideDialog={onCancel}
  />
)

export { ConfirmDiscardDialog }
