import type {
  ChargeItemDefinition,
  MedicationDispense,
  MedicationKnowledge,
  MedicationRequest,
  ServiceRequest,
} from "fhir"
import { useMemo } from "react"

import { type MedicationRequestData, getFeeType } from "commons/meds"
import { getGenericBillingType, getPriceByCode } from "commons/utils"
import { getBillingTypeCode, getCommonCode } from "utils"

import { BILLING_TYPES_CODES, MED_FEE_TYPE } from "data"
import { durationCodeOptions } from "../data"
import type { MedicationDosage, MedicationRequestFormData } from "../types"
import { getTreatmentFrequency } from "../utils/transformers"

const useMedicationRequestDataBind = ({
  medicationDispenses,
  medicationKnowledges,
  medicationRequests,
  medicationsCIDs,
  serviceRequests,
}: {
  medicationRequests?: (MedicationRequest | MedicationRequestFormData)[]
  medicationKnowledges?: Record<string, MedicationKnowledge>
  medicationsCIDs?: Record<string, ChargeItemDefinition>
  medicationDispenses?: MedicationDispense[]
  serviceRequests?: ServiceRequest[]
}) => {
  const { data } = useMemo(() => {
    const data = (medicationRequests ?? []).reduce((acc, mr) => {
      const newMRD: MedicationRequestData = getMedicationData({
        medicationDispenses,
        medicationKnowledges,
        medicationsCIDs,
        medicationRequest: mr,
        serviceRequests,
      })

      return [...acc, newMRD]
    }, new Array<MedicationRequestData>())

    return { data }
  }, [medicationRequests, medicationKnowledges, medicationsCIDs, medicationDispenses, serviceRequests])

  return { medicationRequestData: data }
}

const getMedicationData = <T extends MedicationRequest>({
  medicationDispenses,
  medicationKnowledges,
  medicationRequest: mr,
  medicationsCIDs,
  serviceRequests,
}: DataProps<T>) => {
  const medicationDispense = medicationDispenses?.filter((md) => md.authorizingPrescription?.[0].id === mr.id)
  const mrCode = getCommonCode({ codes: mr?.medication?.CodeableConcept?.coding })

  const medicationKnowledge = medicationKnowledges?.[mrCode]

  const serviceRequest = serviceRequests?.find((sr) => sr.basedOn?.find((ref) => ref.id === mr.id))

  const billingType = getBillingTypeCode(mr) ?? BILLING_TYPES_CODES.BILL_PRACTICE

  let feeType

  if (billingType === BILLING_TYPES_CODES.BILL_PATIENT) {
    const medMatchingProductConfigurations: ChargeItemDefinition[] = []
    for (const cid of Object.values(medicationsCIDs ?? {})) {
      if (cid.code?.coding?.some((c) => c.code === mrCode)) {
        medMatchingProductConfigurations.push(cid)
        break
      }
    }
    feeType = getFeeType(medMatchingProductConfigurations)
  }

  const prescriptionDosages: MedicationDosage[] =
    mr.dosageInstruction?.map((dosageInstruction) => {
      const {
        code: dosageQuantityCode,
        system: dosageQuantitySystem,
        unit: dosageQuantityUnit,
        value: doseQuantityValue,
      } = dosageInstruction.doseAndRate?.[0]?.dose?.Quantity ?? {}

      const doseQuantity = doseQuantityValue ? String(doseQuantityValue) : undefined
      const prescriptionDuration = dosageInstruction?.timing?.repeat?.duration
      const prescriptionDurationValue = prescriptionDuration
        ? String(dosageInstruction?.timing?.repeat?.duration)
        : undefined
      const prescriptionDurationUnit = durationCodeOptions.find(
        (option) => option?.value?.code === dosageInstruction?.timing?.repeat?.durationUnit,
      )?.value

      const doseRange = dosageInstruction.doseAndRate?.[0]?.dose?.Range
      const doseRangeLowValue = doseRange?.low?.value
      const doseRangeHighValue = doseRange?.high?.value
      const doseRangeCode = doseRange?.low?.code
      const doseRangeSystem = doseRange?.low?.system
      const doseRangeUnit = doseRange?.low?.unit
      const doseRangeValue = `${doseRangeLowValue}-${doseRangeHighValue}`

      return {
        doseQuantity: doseRange ? doseRangeValue : doseQuantity,
        medicationQuantity: doseRange
          ? { code: doseRangeCode, system: doseRangeSystem, unit: doseRangeUnit }
          : { code: dosageQuantityCode, system: dosageQuantitySystem, unit: dosageQuantityUnit },
        treatmentFrequency: getTreatmentFrequency(dosageInstruction),
        treatmentRoute: dosageInstruction?.route?.coding?.[0],
        prescriptionDuration: prescriptionDurationValue,
        prescriptionDurationUnit,
        instructionText: dosageInstruction.text,
      }
    }) ?? []

  const { code, system, unit } = mr.dispenseRequest?.quantity ?? {}

  const newMRD: MedicationRequestData = {
    medicationRequestInfo: {
      ...mr,
      ...{
        prescriptionQuantity: { code, system, unit },
        dosages: prescriptionDosages,
      },
    },
    medicationKnowledge,
    medicationDispense,
    productPrice: getPriceByCode({
      productPrices: medicationsCIDs ?? {},
      medCoding: mr?.medication?.CodeableConcept?.coding,
      quantity: mr?.dispenseRequest?.quantity?.value,
      ...(billingType === BILLING_TYPES_CODES.BILL_PATIENT
        ? {
            specifyMedFee: true,
            ...(feeType === MED_FEE_TYPE.ByFrequency
              ? {
                  productFrequency: mr?.dispenseRequest?.dispenseInterval,
                }
              : {}),
          }
        : {}),
      billingType: getGenericBillingType(billingType),
    }),
    serviceRequest,
  }

  return newMRD
}

type DataProps<T extends MedicationRequest> = {
  medicationKnowledges?: Record<string, MedicationKnowledge>
  medicationsCIDs?: Record<string, ChargeItemDefinition>
  medicationDispenses?: MedicationDispense[]
  serviceRequests?: ServiceRequest[]
  medicationRequest: T
}

export { useMedicationRequestDataBind }
