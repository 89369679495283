import { useIsMutating } from "@tanstack/react-query"
import type { FormikHelpers, FormikValues } from "formik"
import { Sidebar } from "primereact/sidebar"
import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"

import { FormContainer, SplitButton } from "commons"
import { useSmartyAddressVerification } from "commons/hooks"
import { type Patient, asReference, humanNameAsString } from "fhir"
import { intercomTrackEvent } from "intercom"
import { useAppModuleContext } from "internals"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { useAuth, useLoginContext } from "security"

import { useCreatePatient } from "../hooks"
import type { PatientFormData } from "../types"
import { PatientForm } from "./PatientForm"
import { PatientList } from "./PatientList"
import { initialValues, newPatientValidationSchema, sanitize } from "./validations"

enum NAVIGATION_VIEW {
  PATIENT_INFO = "patient-information",
  P360 = "patient360",
}

const PatientListView = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { loggedInPractitionerName, loggedInPractitioner } = useLoginContext()
  const { currentOrganizationId, currentOrganization } = useOrganizationContext()
  const { isModuleActive } = useAppModuleContext()
  const { organizationPractitionerRefs, organizationPractitionersInfo } = useOrganizationPractitioners({
    organizationId: currentOrganizationId,
  })

  const isCheckingEmail = useIsMutating({ mutationKey: ["check-email-exists"], status: "pending" })

  const [showOverlayForm, setShowOverlayForm] = useState(false)
  const [validateAddress, setValidateAddress] = useState(false)
  const [navigationView, setNavigationView] = useState(NAVIGATION_VIEW.PATIENT_INFO)

  const closeForm = () => {
    setShowOverlayForm(false)
    setValidateAddress(false)
  }

  const onCreateSuccess = useCallback(
    (patient: Patient) => {
      const navView = `/${patient.id}?kp=patient-information${
        navigationView === "patient360" ? `&view=${navigationView}` : ``
      }`

      if (user) {
        intercomTrackEvent({
          user_id: user?.b2cUserId,
          email: user?.email,
          event: "user-invited",
          metadata: {
            user_invited: {
              url: `${window.location.href}${navView}`,
              value: humanNameAsString(patient.name?.[0]),
            },
            ...(patient.managingOrganization
              ? { invited_to: { url: window.location.href, value: patient.managingOrganization.display as string } }
              : {}),
            invited_by: loggedInPractitionerName,
            resource: JSON.stringify(patient),
          },
        })
      }

      // datadogLogs.logger.info(`Patient Created by ${loggedInPractitionerName}`)
      navigate(`/orgs/${currentOrganizationId}/patients${navView}`)
    },
    [navigationView],
  )

  const { createPatient, isAdding } = useCreatePatient(closeForm, onCreateSuccess)
  const { checkAddress, clearVerificationInfo } = useSmartyAddressVerification(true)

  const customSidebarTitlebar = (
    <>
      <span className="bg-white w-full">
        <h6 className="font-semibold">Create Patient</h6>
        <p className="text-slate-400 text-sm">Use a permanent email address where you can receive email</p>
      </span>
    </>
  )

  const onSubmit = (patient: PatientFormData, formikHelpers?: FormikHelpers<FormikValues>) => {
    if (validateAddress && patient.patient?.address?.[0] !== undefined) {
      checkAddress(patient.patient.address[0], formikHelpers, () => {
        clearVerificationInfo()

        const sanitizedData = sanitize(patient)
        createPatient(sanitizedData)
      })
    } else {
      const sanitizedData = sanitize(patient)
      createPatient(sanitizedData)
    }
  }

  return (
    <>
      <PatientList showForm={() => setShowOverlayForm(true)} />
      <Sidebar
        visible={showOverlayForm}
        position="right"
        style={{ minWidth: "40%" }}
        header={customSidebarTitlebar}
        onHide={closeForm}
      >
        <div className="relative h-full">
          <FormContainer
            validateOnChange
            initialValue={initialValues(undefined, currentOrganization, organizationPractitionersInfo, [])}
            onSubmit={onSubmit}
            onCancel={closeForm}
            validationSchema={newPatientValidationSchema(validateAddress)}
            customSaveButton={({ isSubmitting }) => (
              <SplitButton
                type="submit"
                size="lg"
                loading={isAdding}
                autoUpdateMainAction
                actions={[
                  {
                    label: "Save",
                    description: "Create a new patient and go to patient chart",
                    onSelectClick: () => setNavigationView(NAVIGATION_VIEW.PATIENT_INFO),
                    disabled: isSubmitting || isCheckingEmail > 0,
                  },
                  {
                    label: "Save & Pull",
                    description: "Create patient and go to Patient360 module to pull data from network",
                    disabled: !isModuleActive(NAVIGATION_VIEW.P360) || isSubmitting || isCheckingEmail > 0,
                    disabledReason: "Module Patient360 is disabled",
                    onSelectClick: () => setNavigationView(NAVIGATION_VIEW.P360),
                  },
                ]}
              />
            )}
          >
            <PatientForm
              practitioners={organizationPractitionerRefs}
              toggleValidateAddress={() => {
                setValidateAddress((prevState) => !prevState)
              }}
              preferredPractitioner={asReference(loggedInPractitioner)}
            />
          </FormContainer>
        </div>
      </Sidebar>
    </>
  )
}

export { PatientListView }
