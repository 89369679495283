import { type FormikValues, useFormikContext } from "formik"
import { type FC, useEffect } from "react"

import { useOrganizationContext } from "organization"

import { CheckBoxField } from "../CheckBoxField"
import { DateField } from "../DateField"
import type { FormFieldBaseProps } from "../FormField"

const BloodDrawField: FC<Props> = ({
  handleUpdateBDIO,
  handleUpdateSpecimenDate,
  label = "Draw blood in office",
  checkboxClassName,
  performerId,
  ...formFieldProps
}) => {
  const {
    values: { bloodDrawnInOffice },
    setFieldValue,
  } = useFormikContext<FormikValues & { bloodDrawnInOffice: boolean }>()

  const { performerLabsEnabled } = useOrganizationContext()

  useEffect(() => {
    const amProvider = performerLabsEnabled.find(({ lab }) => lab.identifier?.some(({ value }) => value === "am-lab"))
    if (performerId && amProvider?.ref?.id === performerId) {
      setFieldValue("bloodDrawnInOffice", true)
      handleUpdateBDIO?.(true)
    }
  }, [performerLabsEnabled, bloodDrawnInOffice, performerId])

  return (
    <>
      <CheckBoxField
        field="bloodDrawnInOffice"
        label={label}
        onCheck={handleUpdateBDIO}
        className={checkboxClassName}
        labelClassName="!text-sm !text-gray-500 !font-medium !leading-[1.0625rem]"
      />
      <DateField
        field="specimenDate"
        onChange={handleUpdateSpecimenDate}
        showTime
        disabled={!bloodDrawnInOffice}
        label="Specimen Collection Date"
        collectTimeZone
        validation={(value) => (!value && bloodDrawnInOffice ? "Specimen date is required" : undefined)}
        {...formFieldProps}
      />
    </>
  )
}

type Props = {
  label?: string
  checkboxClassName?: string
  handleUpdateBDIO?(bdio: boolean): void
  handleUpdateSpecimenDate?(sDate: Date): void
  performerId?: string
} & Omit<FormFieldBaseProps, "validation" | "field">

export { BloodDrawField }
