import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type Reference, asReference } from "fhir"
import { useFormikContext } from "formik"
import isEqual from "lodash/isEqual"
import { classNames } from "primereact/utils"
import { type FC, useCallback, useEffect, useMemo } from "react"

import { MEDICATION_CATALOG } from "data"
import {
  type MedicationRequestFormData,
  PrescriptionForm,
  PrescriptionSelectionItem,
  getMedicationFormData,
  getPractitionersInfoWithNPIAndLifefileId,
  getRxInitialValues,
  prescriptionValidationSchema,
  sanitize,
} from "eprescribe"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"
import { useLoginContext } from "security"

import { Accordion } from "../../../components/Accordion"
import { SkeletonLoader } from "../../../components/SkeletonLoader"
import { DialogFormContainer, FormField } from "../../../forms"
import { useCrudReducer } from "../../../hooks"
import { type MedicationGroup, getMedCommonCode, useMedicationKnowledge } from "../../../meds"
import type { PractitionerInfo } from "../../../types"
import { useMedsConfigData } from "../../hooks"
import { type PlanData, PLAN_ACTION_CODES } from "../../types"
import { getGroupedActionMedCodes } from "../../utils"
import "./AccordionRxFormSection.css"

const RxFormSection: FC<Props> = ({ practitionersInfo, openEncounter, contentClassName }) => {
  const { loggedInPractitioner } = useLoginContext()
  const { currentOrganizationId, loggedInPractitionerRole, currentOrganizationBillingAddress } =
    useOrganizationContext()
  const { patient } = usePatientContext()
  const practionersInfoWithValidNPIAndLifefileId = useMemo(
    () => getPractitionersInfoWithNPIAndLifefileId(practitionersInfo),
    [practitionersInfo],
  )

  const {
    values: { configureActions, rx, planActivityDefinitions, requester },
    setFieldValue,
    getFieldMeta,
    registerField,
    validateForm,
  } = useFormikContext<PlanData>()

  const { initialValue, editIndex, editWithIndex, reset, selectItemIndex, selectedItemIndex } = useCrudReducer({
    defaultEntity: getRxInitialValues({
      patient,
      loggedInPractitionerRole,
      practitionersInfo: practionersInfoWithValidNPIAndLifefileId,
      encounter: openEncounter,
      requester,
      fallbackShippingAddress: currentOrganizationBillingAddress,
      practitioner: asReference(loggedInPractitioner),
    }),
  })

  const medAction = configureActions[PLAN_ACTION_CODES.CONFIGURE_RXS]
  const { groups: medPacks, medCodes: allowedMedCodes, requiredMeds } = getGroupedActionMedCodes(medAction)

  const { isLoading, mksBySku, catalogs } = useMedicationKnowledge({
    enabled: !!allowedMedCodes.length,
    medCodes: allowedMedCodes.join(","),
    category: MEDICATION_CATALOG.RX,
    hasCIDInOrg: currentOrganizationId,
  })

  const { allRequiredConfigured, medRecommendedDosage, configuredMedCodes, allowMultiple } =
    useMedsConfigData<MedicationRequestFormData>(medAction, requiredMeds, rx ?? [], planActivityDefinitions)

  const medicationsByGroup = useMemo(() => {
    return medPacks.reduce((acc, pack) => {
      const meds = pack.sku.reduce((acc, sku) => {
        const mk = mksBySku?.[sku]
        const pharmacy = catalogs[pack.catalog ?? (mk.catalogHeader?.[0]?.id as string)]
        const preConfigMR = configuredMedCodes?.[sku]
        const newMr = preConfigMR
          ? getMedicationFormData({ medicationKnowledge: mk, medicationRequestInfo: preConfigMR })
          : getRxInitialValues({
              patient,
              loggedInPractitionerRole,
              practitionersInfo: practionersInfoWithValidNPIAndLifefileId,
              encounter: openEncounter,
              medicationKnowledge: mk,
              medRecommendedDosage,
              pharmacy: pharmacy && asReference(pharmacy),
              requester,
              fallbackShippingAddress: currentOrganizationBillingAddress,
              practitioner: asReference(loggedInPractitioner),
            })

        return [...acc, ...(mk && newMr ? [newMr] : [])]
      }, Array<MedicationRequestFormData>())

      const group: MedicationGroup<MedicationRequestFormData> = { ...pack, medications: meds }

      if (meds.length) return [...acc, group]

      return [...acc]
    }, Array<MedicationGroup<MedicationRequestFormData>>())
  }, [mksBySku, configuredMedCodes, medRecommendedDosage, practionersInfoWithValidNPIAndLifefileId])

  const onUpdateMR = useCallback(
    (mr: MedicationRequestFormData, index: number) => {
      const updatedMR = sanitize({ mr: { ...mr, encounter: openEncounter } })
      setFieldValue("rx", [...(rx ?? [])].toSpliced(index > 0 ? index : 0, 1, updatedMR)).finally(reset)
    },
    [rx],
  )

  const { error, touched } = getFieldMeta("rx")

  const activeMedPackIndex = useMemo(
    () => medPacks.findIndex(({ sku }) => sku?.some((code) => Object.keys(configuredMedCodes).includes(code))),
    [configuredMedCodes],
  )

  const validate = useCallback(
    () => (!allRequiredConfigured ? "Please configure required prescriptions" : ""),
    [allRequiredConfigured],
  )

  useEffect(() => {
    validateForm()
  }, [allRequiredConfigured])

  const handleSelectRX = useCallback(
    (med: MedicationRequestFormData) => {
      const code = getMedCommonCode(med)
      const selectedIndex = Object.keys(configuredMedCodes).indexOf(code)
      if (selectedIndex !== -1) {
        setFieldValue("rx", [...(rx?.toSpliced(selectedIndex, 1) ?? [])])
      } else {
        const updatedMR = sanitize({ mr: { ...med, encounter: openEncounter } })
        if (allowMultiple === true || (typeof allowMultiple === "number" && allowMultiple > (rx?.length ?? 0))) {
          setFieldValue("rx", [...(rx ?? []), updatedMR])
        } else {
          setFieldValue("rx", [updatedMR])
        }

        if (!updatedMR.dispenseRequest?.shippingAddress) {
          // Set the error avoiding replace by validate effect
          setTimeout(() => registerField("rx", { validate: () => "Invalid address for selected prescriptions" }))
        }
      }
    },
    [configuredMedCodes, allowMultiple, rx, openEncounter],
  )

  return (
    <>
      <FormField
        field="rx"
        showInvalidState
        label="Pharmaceuticals"
        labelAlign="items-start"
        labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-900 font-medium leading-[1.0625rem]"
        className={contentClassName?.full}
        validation={validate}
      >
        {isLoading ? (
          <SkeletonLoader loaderType="two-lines" repeats={medPacks.length} />
        ) : medPacks.length ? (
          <div className="flex flex-col grow">
            <Accordion
              data={medicationsByGroup}
              headerTemplate={({ name }) => (
                <h6 className="text-sm text-gray-500 font-medium leading-[1.0625rem]">{name}</h6>
              )}
              contentTemplate={({ medications }) => (
                <div className={classNames(contentClassName?.fullContentHalfItem, "gap-x-[1.4375rem] gap-y-4")}>
                  {medications?.map((item, itemIndex) => {
                    const selectedIndex = Object.keys(configuredMedCodes).indexOf(getMedCommonCode(item))
                    return (
                      <PrescriptionSelectionItem
                        key={item.id ?? itemIndex}
                        className={contentClassName?.half}
                        item={item}
                        edit={(data) =>
                          editWithIndex(
                            {
                              ...data,
                              dispenseRequest: {
                                ...data.dispenseRequest,
                                shippingAddress: data.dispenseRequest?.shippingAddress,
                              },
                            },
                            selectedIndex,
                          )
                        }
                        selected={selectedIndex !== -1}
                      />
                    )
                  })}
                </div>
              )}
              className={classNames("paddingless styled-accordion", contentClassName?.full)}
              expandIcon={
                <FontAwesomeIcon icon={faChevronDown} size="sm" className="p-accordion-toggle-icon !mr-0 !right-0" />
              }
              collapseIcon={
                <FontAwesomeIcon icon={faChevronUp} size="sm" className="p-accordion-toggle-icon !mr-0 !right-0" />
              }
              activeIndex={activeMedPackIndex}
            />
          </div>
        ) : (
          <div
            className={classNames("flex flex-col items-center justify-center w-full py-4", {
              "border border-red-600 rounded-lg": !!error && touched,
            })}
          >
            <FontAwesomeIcon icon={faSearch} size="lg" className="text-slate-500" />
            <p className="text-slate-500 text-xs pt-1">{`No prescriptions added`}</p>
          </div>
        )}
      </FormField>
      <DialogFormContainer
        showForm={editIndex !== undefined}
        onCancel={reset}
        onSubmit={(data: MedicationRequestFormData) => {
          const selectedIndex = Object.keys(configuredMedCodes).indexOf(getMedCommonCode(data))
          if (selectedIndex !== -1) {
            selectItemIndex(selectedIndex)
          }

          if (isEqual(data, initialValue)) {
            reset()
            return
          }

          handleSelectRX(data)
          onUpdateMR(data, editIndex ?? rx?.length ?? 0)
        }}
        useFormik
        initialValue={initialValue}
        validationSchema={prescriptionValidationSchema(practionersInfoWithValidNPIAndLifefileId)}
        saveLabel={editIndex === selectedItemIndex ? "Update" : "Confirm"}
        title="Prescription"
      >
        <PrescriptionForm
          practitionersInfo={practionersInfoWithValidNPIAndLifefileId}
          allowedMedicationCodes={allowedMedCodes}
          medRecommendedDosage={medRecommendedDosage}
          isEditing={editIndex !== undefined}
          hidePrescriberField
        />
      </DialogFormContainer>
    </>
  )
}

type Props = {
  practitionersInfo: PractitionerInfo[]
  openEncounter?: Reference
  contentClassName?: { full: string; half: string; fullContentHalfItem: string }
}

export { RxFormSection }
