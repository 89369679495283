import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { type Invoice, isCarePlan, type MedicationKnowledge, type MedicationRequest, type Reference } from "fhir"
import { classNames } from "primereact/utils"
import { type FC, Fragment, useState } from "react"

import { useChartContext } from "chart-view"
import { type EditProps, ModulesId, Notes, NotesUseContext } from "commons"
import { useProductPrices } from "commons/hooks"
import {
  MedicationKnowledgeDetails,
  type MedicationRequestData,
  medsQueryKeys,
  useMedicationRequestDataBind,
  useMedicationRequests,
  useMrOrdersResources,
} from "commons/meds"
import { MedicationRequestListItem } from "medication-requests"
import { useOrganizationContext } from "organization"
import { SYSTEM_VALUES } from "system-values"

import { useEncounterContext } from "../../hooks"
import { OrderItem } from "./OrderItem"
import { WrapUpSection } from "./WrapUpSection"

const NutrasSection: FC<Props> = ({ patientId, encounterId, icon }) => {
  const { showModule } = useChartContext()
  const { currentOrganizationId: organizationId } = useOrganizationContext()

  const [previewItem, setPreviewItem] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()
  const { planRefs } = useEncounterContext()

  const {
    mrOrderData = [],
    medsProductConfigurations = [],
    medicationKnowledges = {},
    medicationRequests = [],
    medicationDispenses = [],
    isLoading,
    total,
  } = useMrOrdersResources({
    patientId,
    subcategory: "nutraceutical-order",
    encounter: encounterId,
    statusFilter: ["active", "completed"],
  })

  const {
    medicationRequests: draftMedicationsRequests = [],
    medicationKnowledges: draftMedicationKnowledges = {},
    medicationDispenses: draftMedicationsDispenses = [],
    medsProductConfigurations: draftMedsProductConfigurations = [],
    isLoading: isLoadingDraftMedications,
    total: totalDraftMedications,
  } = useMedicationRequests({
    patientId,
    category: "nutraceutical",
    statusFilter: ["draft"],
    perPage: 100,
    page: 1,
    encounter: encounterId,
  })

  const goToOrderDetails = (order: string) => {
    showModule({
      module: ModulesId.MEDICATIONR,
      moduleParams: { order },
    })
  }

  const { productPrices } = useProductPrices({
    organizationId,
    productsConfigurations: [...medsProductConfigurations, ...draftMedsProductConfigurations],
  })

  const { medicationRequestData } = useMedicationRequestDataBind({
    medicationRequests: [...medicationRequests, ...draftMedicationsRequests],
    medicationKnowledges: { ...medicationKnowledges, ...draftMedicationKnowledges },
    medicationsCIDs: productPrices,
    serviceRequests: mrOrderData?.flatMap(({ serviceRequest }) => serviceRequest),
    medicationDispenses: [...medicationDispenses, ...draftMedicationsDispenses],
  })

  const renderDetails = ({
    className,
    medicationRequestData,
    editProps,
  }: {
    medicationRequestData?: MedicationRequestData[]
    className?: string
    editProps?: Omit<EditProps, "parentId" | "context">
  }) => (
    <>
      {!!medicationRequestData?.length && (
        <div className={classNames("flex flex-col text-sm mb-2", className)}>
          {medicationRequestData.map((item) => {
            const cp = planRefs?.[item.medicationRequestInfo.basedOn?.find(isCarePlan)?.id as string]
            const mr = item.medicationRequestInfo
            const notes = mr?.note

            return (
              <Fragment key={`${item.medicationRequestInfo.id}-${item.medicationRequestInfo.status}`}>
                <MedicationRequestListItem
                  medicationData={item}
                  showActions={false}
                  imageClassName="w-24 h-24"
                  itemClassName="!pl-0 !text-gray-400"
                  showPrice={false}
                  showDispenseInfo={false}
                  onSelectMK={(mk) => setPreviewItem({ mk, mr: item.medicationRequestInfo })}
                  badge={
                    cp
                      ? {
                          text: cp.display ?? "Plan based",
                          colorStyle: "gray",
                          title: cp.display,
                          className: "truncate max-w-24 !block",
                        }
                      : { text: "", className: "hidden" }
                  }
                  notShowDivider={true}
                />
                {mr?.id && (
                  <Notes
                    notes={notes}
                    editProps={{ ...(editProps as EditProps), parentId: mr.id, context: NotesUseContext.NUTRA }}
                    className={className}
                  />
                )}
              </Fragment>
            )
          })}
        </div>
      )}
    </>
  )

  const renderOrderItem = ({
    id,
    title,
    date,
    requester,
    status,
    invoice,
    medicationRequestData,
    editProps,
    showOrderDetails,
  }: {
    id?: string
    title: string
    date?: Date
    requester?: Reference
    status?: string
    invoice?: Invoice
    medicationRequestData: MedicationRequestData[]
    editProps?: Omit<EditProps, "parentId" | "context">
    showOrderDetails?: boolean
  }) => (
    <OrderItem
      key={id}
      requester={requester}
      status={status}
      invoice={invoice}
      title={title}
      date={date}
      seeOrderDetails={showOrderDetails ? () => id && goToOrderDetails(id) : undefined}
      showOrderDetails={showOrderDetails}
      className="first:mt-6"
    >
      {renderDetails({
        medicationRequestData,
        className: "text-sm pr-2",
        editProps,
      })}
    </OrderItem>
  )

  return (
    <WrapUpSection
      sectionTitle="Nutraceuticals"
      isLoading={isLoading || isLoadingDraftMedications}
      isEmpty={!total && !totalDraftMedications}
      icon={icon}
      emptyMesage="No nutraceutical's orders placed during this encounter"
      className="w-full self-end pb-2 gap-6"
    >
      {draftMedicationsRequests?.map(({ id, authoredOn }, index) =>
        renderOrderItem({
          id,
          title: index === 0 ? "Pending for checkout" : "",
          date: authoredOn ? new Date(authoredOn) : undefined,
          medicationRequestData: medicationRequestData.filter(
            ({ medicationRequestInfo }) => medicationRequestInfo?.id === id,
          ),
          editProps: {
            queriesToInvalidate: medsQueryKeys.medicationRequestList(
              patientId,
              "nutraceutical",
              ["draft"],
              undefined,
              100,
              1,
              encounterId,
            ),
            notShowFullDataNotFound: true,
          },
          showOrderDetails: false,
        }),
      )}

      {mrOrderData?.map(({ serviceRequest: sr, invoices }) => {
        const medOrderIdentifier = sr?.identifier?.find(({ system }) => system === SYSTEM_VALUES.MEDICATION_ORDER)

        return renderOrderItem({
          id: sr?.id,
          title: medOrderIdentifier?.value ?? "Unspecified number",
          date: sr?.authoredOn ? new Date(sr.authoredOn) : undefined,
          requester: sr?.requester,
          status: sr?.status,
          invoice: invoices?.[0],
          medicationRequestData: medicationRequestData.filter(({ serviceRequest }) => serviceRequest?.id === sr?.id),
          editProps: sr?.id
            ? {
                queriesToInvalidate: medsQueryKeys.ordersResources(
                  patientId,
                  "nutraceutical-order",
                  ["active", "completed"],
                  undefined,
                  encounterId,
                ),
                notShowFullDataNotFound: true,
              }
            : undefined,
          showOrderDetails: true,
        })
      })}
      <MedicationKnowledgeDetails
        selectedMK={previewItem?.mk}
        onHide={() => setPreviewItem(undefined)}
        mr={previewItem?.mr}
      />
    </WrapUpSection>
  )
}

type Props = {
  patientId: string
  encounterId: string
  icon: IconDefinition
}

export { NutrasSection }
