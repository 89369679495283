import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import { Menu } from "primereact/menu"
import type { MenuItem, MenuItemOptions } from "primereact/menuitem"
import { classNames } from "primereact/utils"
import { useEffect, useMemo, useRef, useState } from "react"

import { Button } from "./Buttons"
import { RoundedStyles } from "../types"

const SplitButton = ({
  type = "button",
  label,
  icon,
  buttonStyle = "primary",
  size = "md",
  className,
  buttonClassName,
  menuButtonClassName,
  onClick,
  disabled,
  loading,
  actions,
  autoUpdateMainAction,
  defaultActionIndex,
}: Props) => {
  const [selectedActionIndex, setAction] = useState<number>(defaultActionIndex ?? getFirstEnabledActionIndex(actions))

  const menuRef = useRef<Menu>(null)

  const selectedAction = useMemo(
    () => actions?.[selectedActionIndex >= actions.length ? 0 : selectedActionIndex],
    [selectedActionIndex, actions],
  )

  useEffect(() => {
    if (defaultActionIndex !== undefined && defaultActionIndex !== selectedActionIndex) {
      if (actions[defaultActionIndex]) setAction(defaultActionIndex)
    }
    if (actions.length < selectedActionIndex) setAction(0)
  }, [actions, autoUpdateMainAction, defaultActionIndex, selectedActionIndex])

  const menu = actions.map<MenuItem>((action, index) => ({
    label: action.label,
    command: action.onClick ?? (autoUpdateMainAction ? () => setAction(index) : undefined),
    ...(action.icon ? { icon: <FontAwesomeIcon icon={action.icon} /> } : {}),
    template: (_: MenuItem, options: MenuItemOptions) => (
      <div
        className={classNames(
          "px-6 py-2",
          action.disabled ? "cursor-default bg-gray-50" : "cursor-pointer hover:bg-gray-100",
        )}
        onClick={!action.disabled ? options.onClick : undefined}
      >
        <div className={classNames("font-bold", { "text-gray-400": action.disabled })}>
          {action.icon && <FontAwesomeIcon icon={action.icon} className="fa-fw" />} {action.label}
        </div>
        {(action.description || (action.disabled && action.disabledReason)) && (
          <div className="text-sm text-gray-400">
            {action.disabled && action.disabledReason ? action.disabledReason : action.description}
          </div>
        )}
      </div>
    ),
  }))

  return (
    <div className={classNames("inline-flex", className)}>
      <Button
        {...{ buttonStyle, size, loading, disabled }}
        type={selectedAction?.onSelectType ?? type}
        label={selectedAction?.label ?? label}
        icon={selectedAction?.icon || icon}
        disabled={selectedAction?.disabled || disabled}
        title={selectedAction?.disabled ? selectedAction?.disabledReason : undefined}
        onClick={!loading ? selectedAction?.onSelectClick ?? onClick : undefined}
        iconClassName="h-4 w-4"
        roundedStyle={!autoUpdateMainAction || actions.length !== 1 ? RoundedStyles.Left : RoundedStyles.Both}
        className={classNames("focus:z-10", buttonClassName)}
      />
      {(!autoUpdateMainAction || actions.length !== 1) && (
        <>
          <Button
            {...{ buttonStyle, size }}
            icon={faChevronDown}
            disabled={loading || disabled}
            iconClassName="h-4 w-4"
            roundedStyle={RoundedStyles.Right}
            className={classNames("-ml-px", menuButtonClassName)}
            onClick={(event) => event && menuRef.current && menuRef.current.toggle(event)}
          />
          <Menu model={menu} popup ref={menuRef} id="popup_menu" className="w-80" />
        </>
      )}
    </div>
  )
}

const getFirstEnabledActionIndex = (actions: ActionProps[]) => actions.findIndex((action) => !action.disabled)

type Props = {
  /**
   * The type for the main button. Can be "submit" or "button"
   * @default "button"
   */
  type?: "submit" | "button"
  /**
   * The label for the main button
   */
  label?: string
  /**
   * The FontAwesome icon to display on the main button
   */
  icon?: IconDefinition
  /**
   * The style of the main button. Can be "default" or "primary"
   * @default "primary"
   */
  buttonStyle?: "default" | "primary"
  /**
   * The size of the main button. Can be "xs", "sm", "md", "lg", or "xl"
   * @default "md"
   */
  size?: "xs" | "sm" | "md" | "lg" | "xl"
  /**
   * Additional CSS class names for the component container
   */
  className?: string
  /**
   * Additional CSS class names for the main button
   */
  buttonClassName?: string
  /**
   * Additional CSS class names for the dropdown menu button
   */
  menuButtonClassName?: string
  /**
   * The click event handler for the main button
   */
  onClick?(): void
  /**
   * Whether the main button is disabled
   */
  disabled?: boolean
  /**
   * Whether the main button is in a loading state
   */
  loading?: boolean
  /**
   * An array of action objects for the dropdown menu
   */
  actions: ActionProps[]
  /**
   * Whether to automatically update the main button with the selected action
   * @default false
   */
  autoUpdateMainAction?: boolean
  defaultActionIndex?: number
}

export type ActionProps = {
  /**
   * The label for the dropdown menu item
   */
  label: string
  /**
   * The description for the dropdown menu item
   */
  description?: string
  /**
   * The FontAwesome icon to display on the dropdown menu item
   */
  icon?: IconDefinition
  /**
   * Whether the dropdown menu item is disabled
   */
  disabled?: boolean
  /**
   * The reason why the dropdown menu item is disabled
   */
  disabledReason?: string
  /**
   * The click event handler for the dropdown menu item
   */
  onClick?(): void
  /**
   * The click event handler for the main button when the dropdown menu item is selected
   */
  onSelectClick?(): void
  /**
   * The type for the main button when the dropdown menu item is selected
   */
  onSelectType?: "submit" | "button"
}

export { SplitButton }
