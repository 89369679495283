import { useMutation } from "@tanstack/react-query"
import type { CodeableConcept, Coding, Identifier, Money } from "fhir"

import type { BILLING_TYPES_CODES } from "data"
import { convertIdentifiersToCodings, getBasePrice } from "utils"

import { buildBulkProductConfigurationsToRequest, getProductKey } from "../utils"
import { useProductPricesQueryFunction } from "./useProductPrices"

const useLoadProductsPrice = (
  organizationId: string,
  billingType: BILLING_TYPES_CODES,
  category: string = "product",
  onSuccess: (data: Product[], variables: Props) => void,
) => {
  const fetchProductPrices = useProductPricesQueryFunction()
  const { mutate: mapProductsPrice, isPending } = useMutation({
    mutationKey: ["load-products-price", organizationId, billingType, category],
    mutationFn: async ({ products }) => {
      const codes = getProductsCode(products)
      const productPrices = await fetchProductPrices({
        organizationId,
        productsConfigurations: buildBulkProductConfigurationsToRequest({
          codes,
          billingType,
        }),
      })

      return products.reduce((acc, prod) => {
        const code = getProductsCode([prod])
        const productKey = getProductKey({ code, billingType })
        const cid = productPrices?.[productKey]

        const price = getBasePrice(cid?.propertyGroup?.[0]?.priceComponent)?.amount
        return [...acc, { ...prod, price }]
      }, Array<Product>())
    },
    onSuccess,
  })

  return { mapProductsPrice, isPending }
}

const getProductsCode = (products: Product[]) =>
  products.reduce<Array<Coding>>(
    (acc, { definition, planDefinition }) => [
      ...acc,
      ...(definition
        ? definition?.code?.coding ?? convertIdentifiersToCodings([definition])
        : planDefinition
          ? planDefinition?.code?.coding ?? convertIdentifiersToCodings([planDefinition])
          : []),
    ],
    [],
  )

type Product = {
  definition?: { identifier?: Identifier[]; code?: CodeableConcept }
  planDefinition?: { identifier?: Identifier[]; code?: CodeableConcept }
  price?: Money
}

type Props = {
  products: Product[]
}

export { useLoadProductsPrice }
