import { confirmDialog } from "primereact/confirmdialog"

import { displayNotificationSuccess } from "utils"

const discardMR = (medReqId: string, onAcceptCliked: (mrId: string) => void) => {
  confirmDialog({
    message: "Are you sure you want to delete this prescription?",
    header: "Confirmation",
    acceptLabel: "Delete",
    rejectLabel: "Cancel",
    rejectClassName: "button-default p-button-sm",
    acceptClassName: "button-primary p-button-sm",
    accept: () => onAcceptCliked(medReqId),
  })
}

const getStatusClass = (status: string) => {
  switch (status) {
    case "active":
      return "is-success"
    case "stopped":
      return "is-danger"
    case "completed":
      return "is-info"
    case "cancelled":
      return "is-warning"
    case "draft":
      return "is-warning"
    default:
      return ""
  }
}

const showBulkDiscardNotification = (selectedItemsCount: number, itemsIdsToBeDiscardedCount: number) => {
  let message = "Some prescriptions were discarded successfully, while others were skipped due to validation rules"

  if (!itemsIdsToBeDiscardedCount) {
    message = "No prescriptions were discarded due to validation rules"
  } else if (selectedItemsCount === itemsIdsToBeDiscardedCount) {
    message = "Prescriptions discarded successfully!"
  }

  displayNotificationSuccess(message)
}

export { discardMR, getStatusClass, showBulkDiscardNotification }
