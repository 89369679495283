import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getFirstEmail, type Invoice, type ServiceRequest } from "fhir"
import { Button } from "primereact/button"
import type { MenuItem } from "primereact/menuitem"
import type { FC } from "react"
import { useNavigate } from "react-router-dom"

import { useChartContext } from "chart-view"
import { srCategoryCodes } from "data"
import { SYSTEM_VALUES } from "system-values"
import { getInvoicesInfo, getMoneyCurrencyAlt, getOrderType } from "utils"
import { useOrganizationContext } from "organization"

import { DropdownMenu } from "../../components/DropdownMenu"
import { ModulesId } from "../../Module"
import { getOrderDate } from "../transformers"

const MedicationOrderDetailHeader: FC<Props> = ({
  serviceRequest,
  invoices,
  activeActions,
  menuIsLoading,
  className,
  hiddenActions,
  hideNavigateAction,
  hideEditedOrderDetails,
  headerText,
}) => {
  const { organizationPractitionersInfo } = useOrganizationContext()
  const { showModule } = useChartContext()
  const navigate = useNavigate()

  const medOrderStatus = serviceRequest?.status
  const medOrderType = getOrderType(serviceRequest)

  const lifeFileId = serviceRequest?.identifier?.find(
    ({ system }) => system === SYSTEM_VALUES.LIFEFILE_MEDICATION_ORDER,
  )
  const medOrderIdentifier = serviceRequest?.identifier?.find(({ system }) => system === SYSTEM_VALUES.MEDICATION_ORDER)
  const showActions = !hiddenActions

  const requester = organizationPractitionersInfo.find(
    ({ practitioner }) => practitioner.id === serviceRequest?.requester?.id,
  )?.practitioner
  const requesterEmail = getFirstEmail(requester?.telecom)
  const requesterDisplay = serviceRequest?.requester?.display ?? "unspecified"

  const goBack = () => {
    if (serviceRequest?.id)
      showModule({
        module:
          medOrderType === srCategoryCodes["nutraceutical-order"].code ? ModulesId.MEDICATIONR : ModulesId.EPRESCRIBE,
        moduleParams: {
          ...(medOrderStatus ? { subview: medOrderStatus === "active" ? "orders" : "history" } : {}),
        },
      })
    else navigate(-1)
  }

  const { totalPrice, identifier } = getInvoicesInfo(invoices)

  return (
    <div className={className}>
      <div className="flex justify-between items-center p-1">
        <div className="flex items-baseline gap-1">
          {!hideNavigateAction && (
            <span onClick={goBack} className="cursor-pointer text-gray-900 h-6 w-6 text-center">
              <FontAwesomeIcon icon={faChevronLeft} />
            </span>
          )}
          {headerText}
          <span title="Order number" className="text-primary font-medium text-lg">
            {medOrderIdentifier?.value ?? "Unspecified number"}
          </span>
        </div>
        {showActions && (
          <div className="inline-flex items-center gap-2">
            {activeActions.slice(0, 3).map(({ label, icon, command, disabled, loading }, index) => (
              <Button
                key={index}
                className="border-0 p-button-outlined p-button-sm px-2.5 py-1.5 ring-1 ring-inset ring-primary"
                label={label}
                icon={icon}
                onClick={command}
                disabled={disabled}
                loading={loading}
                pt={{
                  label: { className: "font-medium" },
                }}
              />
            ))}

            {activeActions.length > 3 && (
              <DropdownMenu dropdownMenuItems={activeActions.slice(3)} loading={menuIsLoading} />
            )}
          </div>
        )}
      </div>
      <div className="flex flex-wrap gap-1 justify-between text-sm px-1">
        <div className="flex gap-1">
          <label className="text-gray-900">Requester:</label>
          {requesterEmail ? (
            <a className="text-gray-400 underline" href={`mailto:${requesterEmail}`}>
              {requesterDisplay}
            </a>
          ) : (
            <span className="text-gray-400">{requesterDisplay}</span>
          )}
        </div>
        <div className="flex gap-1">
          <label className="text-gray-900">Invoice:</label>
          <span className="text-gray-400">{`#${identifier.join(", #") ?? "unspecified number"}`}</span>
        </div>
        {!hideEditedOrderDetails && (
          <>
            <div className="flex gap-1">
              <label className="text-gray-900">Date:</label>
              <span className="text-gray-400">{getOrderDate(serviceRequest)}</span>
            </div>
            <div className="flex gap-1">
              <label className="text-gray-900">Status:</label>
              <span className="text-gray-400 capitalize">
                {(serviceRequest?.status !== "revoked" ? serviceRequest?.status : "cancelled") ?? "unspecified"}
              </span>
            </div>
          </>
        )}
        {!!lifeFileId && (
          <div className="flex gap-1">
            <label className="text-gray-900">LifeFile ID:</label>
            <span className="text-gray-400 capitalize">{lifeFileId?.value}</span>
          </div>
        )}
        {!!totalPrice && (
          <div className="flex gap-1">
            <label className="text-gray-900">Price:</label>
            <span className="text-gray-400">{`${totalPrice.value.isLessThan(0) ? "-" : ""}${getMoneyCurrencyAlt(totalPrice.currency)}${totalPrice.value.isLessThan(0) ? (totalPrice.value.toNumber() * -1).toFixed(2) : totalPrice.value.toFixed(2)}`}</span>
          </div>
        )}
      </div>
    </div>
  )
}

type Props = {
  serviceRequest?: ServiceRequest
  invoices?: Invoice[]
  menuIsLoading?: boolean
  className?: string
  hideNavigateAction?: boolean
  hideEditedOrderDetails?: boolean
  headerText?: string | JSX.Element
} & ConditionalActions

type ConditionalActions =
  | {
      hiddenActions: true
      activeActions?: (MenuItem & { command(): void; loading?: boolean })[]
    }
  | {
      hiddenActions?: false
      activeActions: (MenuItem & { command(): void; loading?: boolean })[]
    }

export { MedicationOrderDetailHeader }
