import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getResource, type Bundle, type Parameters, type ServiceRequest } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { medsQueryKeys } from "commons/meds"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { usePatientContext } from "patients"
import { displayNotificationSuccess } from "utils"

const useResubmitOrder = (onSettled?: (srId?: string) => void) => {
  const { operationRequest } = useClient()
  const { patientId } = usePatientContext()
  const queryClient = useQueryClient()

  const resubmit = async ({ orderId, resubmitReason }: { orderId: string; resubmitReason: string }) => {
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "order",
          value: {
            Reference: { resourceType: "ServiceRequest", id: orderId },
          },
        },
        ...(resubmitReason
          ? [
              {
                name: "reason",
                value: {
                  string: resubmitReason,
                },
              },
            ]
          : []),
      ],
    }

    const bundle = await operationRequest<Bundle>({
      endpoint: `Patient/${patientId}/cpoe`,
      method: "POST",
      operation: "resubmit",
      parameters,
    })
    const serviceRequest = getResource<ServiceRequest>(bundle, "ServiceRequest")
    return serviceRequest
  }

  const { mutate: resubmitOrder, isPending } = useMutation({
    mutationFn: resubmit,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: medsQueryKeys.ordersResources(patientId, "medication-order"),
      })
      displayNotificationSuccess("Order resubmitted successfully!")
    },
    onSettled: (sr) => onSettled?.(sr?.id),
  })

  return { resubmitOrder, isResubmitting: isPending }
}

export { useResubmitOrder }
