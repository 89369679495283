import type { Reference } from "fhir"
import { type FieldProps, type FormikValues, useFormikContext } from "formik"
import type { DropdownProps } from "primereact/dropdown"
import { type RadioButtonChangeEvent, RadioButton } from "primereact/radiobutton"
import { classNames } from "primereact/utils"
import { type FC, useCallback, useEffect, useId } from "react"

import { BILLING_TYPES_CODES } from "data"
import { useOrganizationContext } from "organization"

import type { LaboratoryOrder } from "../../labs"
import { FormField } from "../FormField"
import { InsuranceField } from "./InsuranceField"

const BillingField: FC<Props> = ({
  billingLabel = "Bill to",
  showBillingLabel = true,
  insuranceLabel = "Insurance Plan",
  insuranceField = BILLING_TYPES_CODES.INSURANCE,
  insuranceValidation,
  onBillingChange,
  className,
  disabled,
  horizontal,
  labelClassName,
  radioButtonLabelClassName,
  hideInsuranceOption,
  hideInsuranceField,
  hideNoInsuranceOptions,
  ...dropdownProps
}) => {
  const billingFieldId = useId()
  const { currentOrganizationBillingTypes } = useOrganizationContext()
  const { setFieldValue, values } = useFormikContext<LaboratoryOrder>()

  const filteredBillingTypes = hideInsuranceOption
    ? currentOrganizationBillingTypes.filter(({ code }) => code !== BILLING_TYPES_CODES.INSURANCE)
    : hideNoInsuranceOptions
      ? currentOrganizationBillingTypes.filter(({ code }) => code === BILLING_TYPES_CODES.INSURANCE)
      : currentOrganizationBillingTypes

  const {
    values: { billingType },
  } = useFormikContext<FormikValues & { billingType: string | undefined }>()

  const showInsuranceField = values.billingType === BILLING_TYPES_CODES.INSURANCE

  const defValidation = useCallback(
    (value: Reference) =>
      billingType === BILLING_TYPES_CODES.INSURANCE && !value?.id ? `${insuranceLabel} is required` : undefined,
    [insuranceLabel, billingType],
  )

  const handleChange = (e: RadioButtonChangeEvent) => {
    onBillingChange?.(e.value)
  }

  useEffect(() => {
    if (hideNoInsuranceOptions && !hideInsuranceOption) {
      setFieldValue("billingType", BILLING_TYPES_CODES.INSURANCE)
      onBillingChange?.(BILLING_TYPES_CODES.INSURANCE)
    } else if (hideInsuranceOption && billingType === BILLING_TYPES_CODES.INSURANCE) {
      setFieldValue("billingType", BILLING_TYPES_CODES.BILL_PATIENT)
      onBillingChange?.(BILLING_TYPES_CODES.BILL_PATIENT)
    }
  }, [hideNoInsuranceOptions, hideInsuranceOption])

  return (
    <>
      <FormField
        field="billingType"
        label={showBillingLabel ? billingLabel : undefined}
        horizontal={horizontal}
        labelClassName={labelClassName}
        className={className}
      >
        {({ field: { name, value, onChange } }: FieldProps) => (
          <div className="flex space-x-4 w-full">
            {filteredBillingTypes.map((coding, index) => (
              <div key={index} className="text-xs flex items-center mt-2">
                <RadioButton
                  inputId={`${coding.code}-${billingFieldId}`}
                  name={name}
                  value={coding.code}
                  checked={coding.code === value}
                  onChange={(e) => {
                    onChange(e)
                    handleChange(e)
                  }}
                  disabled={disabled}
                />
                <label
                  htmlFor={`${coding.code}-${billingFieldId}`}
                  className={classNames("flex w-full cursor-pointer mx-2", {
                    "text-gray-500 cursor-auto": disabled,
                  })}
                >
                  {coding.display}
                </label>
              </div>
            ))}
          </div>
        )}
      </FormField>

      {showInsuranceField && !(hideInsuranceField || hideInsuranceOption) && (
        <InsuranceField
          field={insuranceField}
          label={insuranceLabel}
          validation={insuranceValidation ?? defValidation}
          horizontal={horizontal}
          className={className}
          labelClassName={labelClassName}
          {...dropdownProps}
        />
      )}
    </>
  )
}

type Props = {
  billingLabel?: string
  insuranceLabel?: string
  insuranceField?: string
  horizontal?: boolean
  showBillingLabel?: boolean
  className?: string
  labelClassName?: string
  radioButtonLabelClassName?: string
  hideInsuranceOption?: boolean
  hideNoInsuranceOptions?: boolean
  hideInsuranceField?: boolean
  onBillingChange?(value: string): void
  insuranceValidation?(value: Reference): string
} & Omit<DropdownProps, "options">

export { BillingField }
