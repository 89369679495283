import { type FC } from "react"

import { Button, type Action } from "commons"
import { useOpenEncounter } from "encounter"

const StartEncounterAction: FC<Props> = ({ patientId, className, command, ...actionProps }) => {
  const { openEncounterRef, isLoading } = useOpenEncounter(patientId)

  if (openEncounterRef?.id) return null

  return (
    <Button {...actionProps} className={className} loading={isLoading} size="sm" buttonStyle="text" onClick={command} />
  )
}

type Props = {
  patientId: string
  className?: string
} & Omit<Action, "style">

export { StartEncounterAction }
