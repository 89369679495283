import { useState, type FC } from "react"
import { faMinusSquare, type IconDefinition } from "@fortawesome/pro-solid-svg-icons"
import { faSquare, faTrashCan } from "@fortawesome/pro-regular-svg-icons"

import { Button } from "commons"
import type { MedicationRequestData } from "commons/meds"

import { useDeleteMultipleMedicationRequests } from "../../hooks"
import { getBulkActionsItems } from "../../utils"
import { ConfirmDiscardDialog } from "./ConfirmDiscardDialog"
import { showBulkDiscardNotification } from "../helpers"

const PrescriptionsBulkActions: FC<Props> = ({ selectedItems, toggleSelectAll }) => {
  const [showDiscardDialog, setShowDiscardDialog] = useState(false)
  const { selectedItemsIds, itemsIdsToBeDiscarded } = getBulkActionsItems(selectedItems)

  const { deleteMedicationRequests, isDeleting } = useDeleteMultipleMedicationRequests(() => {
    toggleSelectAll()
    setShowDiscardDialog(false)
    showBulkDiscardNotification(selectedItems.length, itemsIdsToBeDiscarded.length)
  })

  const handleDiscard = () => {
    deleteMedicationRequests(itemsIdsToBeDiscarded)
  }

  const bulkActionItems: ActionButtonProps[] = [
    {
      icon: faTrashCan,
      title: "Discard",
      onClick: () => setShowDiscardDialog(true),
      disabled: !selectedItems.length,
      loading: isDeleting,
    },
  ]

  return (
    <div className="flex items-center gap-6">
      <div className="flex gap-3 items-center">
        <Button
          icon={selectedItems.length ? faMinusSquare : faSquare}
          iconClassName="text-lg"
          className="p-0"
          buttonStyle="text"
          onClick={toggleSelectAll}
          style={{ padding: 0 }}
        />
        <div className="text-sm text-gray-500 flex gap-1">
          <span className="min-w-3">{selectedItems.length}</span>
          <span>selected</span>
        </div>
      </div>
      {
        <ul className="flex items-center gap-4">
          {bulkActionItems.map((item, index) => (
            <li key={index}>
              <ActionButton {...item} />
            </li>
          ))}
        </ul>
      }
      <ConfirmDiscardDialog
        selectedItemsCount={selectedItemsIds.length}
        visible={showDiscardDialog || isDeleting}
        loading={isDeleting}
        onAccept={handleDiscard}
        onCancel={() => setShowDiscardDialog(false)}
      />
    </div>
  )
}

type ActionButtonProps = {
  icon: IconDefinition
  title: string
  onClick: () => void
  disabled: boolean
  loading: boolean
}

const ActionButton: FC<ActionButtonProps> = ({ icon, title, onClick, disabled, loading }) => (
  <Button
    icon={icon}
    title={title}
    buttonStyle="text"
    className="border border-gray-200"
    onClick={onClick}
    loading={loading}
    disabled={disabled}
  />
)

type Props = {
  selectedItems: MedicationRequestData[]
  toggleSelectAll(): void
}

export { PrescriptionsBulkActions }
