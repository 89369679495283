import type { MEDICATION_CATALOG } from "data"

const medsQueryKeys = {
  all: ["patient/medication-requests"] as const,
  catalogs: ["medicationCatalogs"],
  medicationKnowledge: (catalogId?: string, filter = "", medCodes = "", category?: MEDICATION_CATALOG) => [
    "medicationRequests/medication-knowledge",
    ...(catalogId ? [catalogId] : []),
    ...(filter ? [filter] : []),
    ...(medCodes ? [medCodes] : []),
    ...(category ? [category] : []),
  ],
  medicationRequestList: (
    patientId: string,
    category: "medication" | "nutraceutical",
    statusFilter?: string[],
    searchText?: string,
    perPage?: number,
    page?: number,
    encounter?: string,
    autoship?: boolean,
  ) => [
    ...medsQueryKeys.all,
    patientId,
    category,
    ...(statusFilter?.length ? [statusFilter] : []),
    ...(searchText ? [searchText] : []),
    ...(perPage ? [perPage] : []),
    ...(page ? [page] : []),
    ...(encounter ? [encounter] : []),
    ...(autoship !== undefined ? [autoship] : []),
  ],
  monograph: (monographId?: string) => ["medicationRequests/monograph", ...(monographId ? [monographId] : [])],
  orderDetails: {
    all: ["medicationRequests/medication-order-details"] as const,
    details: (orderId: string) => [...medsQueryKeys.orderDetails.all, orderId],
  },
  draft: {
    all: ["patient/medication-requests"] as const, // ask why this is the queryKey and not "medicationRequests/draft-medication-requests"
    list: (patientId: string, category: string, mrId?: string) => [
      ...medsQueryKeys.draft.all,
      patientId,
      category,
      ...(mrId ? [mrId] : []),
    ],
  },
  detectedIssue: (filter = "") => ["detectedIssue", filter],
  ordersResources: (
    patientId: string,
    subcategory: "nutraceutical-order" | "medication-order",
    statusFilter?: string[],
    searchText?: string,
    encounter?: string,
  ) => [
    "medicationRequests/orders-resources",
    patientId,
    subcategory,
    ...(statusFilter?.length ? [statusFilter] : []),
    ...(searchText ? [searchText] : []),
    ...(encounter ? [encounter] : []),
  ],
  auditEvents: (organizationId: string, patientId: string, orderId: string) => [
    "patient-medication-order-history",
    organizationId,
    patientId,
    orderId,
  ],
  medAvailability: (mkId: string, medQuantity: number) => ["medicationRequests/availability", mkId, medQuantity],
  orders: (patientId: string, medRequestIds?: string[]) => ["patient-medication-orders", patientId, medRequestIds],
}

export { medsQueryKeys }
