import { faPencil, faPills, faRotateRight, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { MenuItem } from "primereact/menuitem"
import { useReducer, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { MedicationCancelDialog, MedicationOrderListItem, useCancelMrOrder, usePrescriptionMrOrder } from "commons/meds"
import type { MedicationRequestOrderData } from "commons/types"
import { MEDICATION_PRODUCT_TYPE } from "data"
import { usePatientContext } from "patients"

import { useResubmitOrder } from "../../hooks"
import { MedicationOrdersResubmitConfirmation } from "./MedicationOrdersResubmitConfirmation"

const MedicationOrdersList = ({ mrOrderData, reloadOrders }: Props) => {
  const [params, setParams] = useSearchParams()
  const { patientId } = usePatientContext()
  const { selectedOrder, selectOrder, showResubmitDialog, hideResubmitDialog, showResubmit } = useReducerState()
  const [cancelOrderIndex, setCancelOrderIndex] = useState<number>()
  const [cancelReason, setCancelReason] = useState<string>()
  const [cancelFutureOrder, setCancelFutureOrder] = useState<string>()
  const [refundItemsAsWell, setRefundItemsAsWell] = useState(false)

  const { getPrescription, isGettingPrescription } = usePrescriptionMrOrder()

  const { cancelMrOrder, isCancelling } = useCancelMrOrder({
    context: MEDICATION_PRODUCT_TYPE.RX,
    onSuccess: reloadOrders,
    onSettled: () => {
      setCancelFutureOrder(undefined)
      setRefundItemsAsWell(false)
      setCancelOrderIndex(undefined)
    },
  })

  const onResubmitOrder = (srId?: string) => {
    hideResubmitDialog()
    srId && goToOrderDetails(srId)
  }

  const { resubmitOrder: resubmit, isResubmitting } = useResubmitOrder(onResubmitOrder)

  const goToOrderDetails = (orderId: string) => {
    params.append("order", orderId)
    setParams(params)
  }

  const goToEditOrder = (orderId: string) => {
    params.delete("subview")
    params.append("edit-order", orderId)
    setParams(params)
  }

  const onPrescription = (order: MedicationRequestOrderData) => {
    selectOrder(order)
    getPrescription({ serviceRequestId: order.serviceRequest.id as string })
  }

  const onResubmit = ({ orderId, resubmitReason }: { orderId: string; resubmitReason: string }) => {
    resubmit({ orderId, resubmitReason })
  }

  const activeActions = (mrOrderData: MedicationRequestOrderData, orderIndex: number): MenuItem[] => [
    {
      label: "Resubmit",
      icon: <FontAwesomeIcon icon={faRotateRight} size="sm" className="mr-2" />,
      disabled: !mrOrderData.serviceRequest.id || !mrOrderData.invoices?.length || !mrOrderData.isEditable,
      command: () => {
        selectOrder(mrOrderData)
        showResubmitDialog()
      },
    },
    {
      label: "Edit",
      icon: <FontAwesomeIcon icon={faPencil} size="sm" className="mr-2" />,
      disabled: !mrOrderData.serviceRequest.id || !mrOrderData.invoices?.length || !mrOrderData.isEditable,
      command: () => goToEditOrder(mrOrderData.serviceRequest.id!),
    },
    {
      label: "Prescription",
      icon: <FontAwesomeIcon icon={faPills} size="sm" className="mr-2" />,
      command: () => onPrescription(mrOrderData),
    },
    {
      label: "Cancel",
      icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />,
      disabled: !(
        mrOrderData.serviceRequest.status === "active" ||
        (mrOrderData.serviceRequest.status === "completed" && mrOrderData.hasInvalidMD)
      ),
      command: () => setCancelOrderIndex(orderIndex),
    },
  ]

  return (
    <>
      <div className="overflow-hidden bg-white">
        <ul className="divide-y divide-gray-200">
          {mrOrderData.map((orderData, index) => (
            <MedicationOrderListItem
              key={orderData.serviceRequest.id}
              mrOrderData={orderData}
              onItemClicked={() => goToOrderDetails(orderData.serviceRequest.id as string)}
              isLoading={
                selectedOrder?.serviceRequest.id === orderData.serviceRequest.id &&
                (isGettingPrescription || isResubmitting)
              }
              dropdownMenuItems={() => activeActions(orderData, index)}
            />
          ))}
        </ul>
      </div>
      <MedicationOrdersResubmitConfirmation
        orderId={selectedOrder?.serviceRequest.id as string}
        visible={showResubmit}
        onHide={hideResubmitDialog}
        onResubmit={onResubmit}
        isResubmitting={isResubmitting}
      />
      <MedicationCancelDialog
        invoice={mrOrderData?.[cancelOrderIndex as number]?.invoices?.[0]}
        visible={cancelOrderIndex !== undefined}
        cancelFutureOrder={cancelFutureOrder}
        isCancelling={isCancelling}
        onHide={() => setCancelOrderIndex(undefined)}
        cancelReason={cancelReason}
        updateRefundItemsAsWell={setRefundItemsAsWell}
        onConfirm={() =>
          cancelMrOrder({
            serviceRequestId: mrOrderData?.[cancelOrderIndex as number]?.serviceRequest?.id as string,
            patientId,
            cancelReason: cancelReason as string,
            cancel_mode: cancelFutureOrder as string,
            refundItemsAsWell: refundItemsAsWell,
          })
        }
        updateCancelFutureOrder={setCancelFutureOrder}
        updateCancelReason={setCancelReason}
      />
    </>
  )
}

const initialState: State = {
  showReschedule: false,
  rescheduleDate: new Date(),
  showCancel: false,
  showResubmit: false,
}

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type:
      | "select-order"
      | "show-reschedule-dialog"
      | "hide-reschedule-dialog"
      | "update-reschedule-date"
      | "show-prescription"
      | "hide-prescription"
      | "show-cancel-dialog"
      | "hide-cancel-dialog"
      | "update-cancel-reason"
      | "show-resubmit-dialog"
      | "hide-resubmit-dialog"
    payload?: string | Date | boolean | MedicationRequestOrderData
  },
) => {
  switch (type) {
    case "select-order":
      return { ...state, selectedOrder: payload as MedicationRequestOrderData }
    case "show-reschedule-dialog":
      return { ...state, showReschedule: true }
    case "hide-reschedule-dialog":
      return { ...state, showReschedule: false }
    case "update-reschedule-date":
      return { ...state, rescheduleDate: payload as Date }
    case "show-cancel-dialog":
      return { ...state, showCancel: true }
    case "hide-cancel-dialog":
      return { ...state, showCancel: false, cancelReason: undefined }
    case "update-cancel-reason":
      return { ...state, cancelReason: payload as string }
    case "show-resubmit-dialog":
      return { ...state, showResubmit: true }
    case "hide-resubmit-dialog":
      return { ...state, showResubmit: false }
    default:
      return state
  }
}

const useReducerState = () => {
  const [{ selectedOrder, showReschedule, rescheduleDate, showCancel, cancelReason, showResubmit }, dispatch] =
    useReducer(reducer, initialState)

  const selectOrder = (order: MedicationRequestOrderData) => dispatch({ type: "select-order", payload: order })
  const showRescheduleDialog = () => dispatch({ type: "show-reschedule-dialog" })
  const hideRescheduleDialog = () => dispatch({ type: "hide-reschedule-dialog" })
  const updateRescheduleDate = (newDate: Date) => dispatch({ type: "update-reschedule-date", payload: newDate })
  const showCancelDialog = () => dispatch({ type: "show-cancel-dialog" })
  const hideCancelDialog = () => dispatch({ type: "hide-cancel-dialog" })
  const updateCancelReason = (reason: string) => dispatch({ type: "update-cancel-reason", payload: reason })
  const showResubmitDialog = () => dispatch({ type: "show-resubmit-dialog" })
  const hideResubmitDialog = () => dispatch({ type: "hide-resubmit-dialog" })

  return {
    selectedOrder,
    showReschedule,
    showCancel,
    rescheduleDate,
    cancelReason,
    updateCancelReason,
    selectOrder,
    showRescheduleDialog,
    showCancelDialog,
    hideCancelDialog,
    hideRescheduleDialog,
    updateRescheduleDate,
    showResubmitDialog,
    hideResubmitDialog,
    showResubmit,
  }
}

type State = {
  selectedOrder?: MedicationRequestOrderData
  showReschedule: boolean
  showCancel: boolean
  rescheduleDate: Date
  cancelReason?: string
  showResubmit: boolean
}

type Props = {
  mrOrderData: MedicationRequestOrderData[]
  reloadOrders(): void
}

export { MedicationOrdersList }
