import { faAdd, faSearch } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type CodeableConcept, type Coding, codeableConceptAsString } from "fhir"
import { type FieldProps } from "formik"
import { AutoComplete } from "primereact/autocomplete"
import { classNames } from "primereact/utils"
import { type FC, useDeferredValue, useState } from "react"
import { IconField } from "primereact/iconfield"
import { InputIcon } from "primereact/inputicon"

import { useValueSet } from "value-set"
import { SYSTEM_VALUES } from "system-values"

import type { ValueSetIds } from "../types"
import { FormField } from "./FormField"

const AutocompleteCodeableConceptField: FC<Props> = ({
  field,
  label,
  className,
  valueSetId,
  readonly,
  disabled,
  multiple,
  placeholder,
  validation,
  onSelect,
  allowCustomValue = false,
}) => {
  const [filter, setFilter] = useState<string | undefined>()
  const deferredFilter = useDeferredValue(filter)
  const { refetch } = useValueSet({ valueSetId, filter: deferredFilter, enabled: false })
  const [suggestionList, setSuggestionList] = useState<Coding[]>([])

  const itemTemplate = (item: CodeableConcept) => (
    <span className="whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">{codeableConceptAsString(item)}</span>
  )

  const itemCodeTemplate = (item: Coding) => (
    <>
      {item.system === SYSTEM_VALUES.INPUT_TYPES ? (
        <span className="whitespace-nowrap overflow-hidden text-ellipsis max-w-xs gap-2">
          <FontAwesomeIcon icon={faAdd} className="fa-fw" /> <span className="italic">Add code as free text</span>
        </span>
      ) : (
        <span className="whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">{`${item.display} - ${item.code}`}</span>
      )}
    </>
  )

  const searchCodes = async (currentFilter: string) => {
    const { data: codes = [] } = await refetch()

    const filteredCodes = allowCustomValue
      ? codes.length > 0
        ? codes
        : [{ code: "free-text", system: SYSTEM_VALUES.INPUT_TYPES, display: currentFilter }]
      : codes ?? []

    setSuggestionList(filteredCodes)
  }

  return (
    <FormField field={field} className={className} label={label} validation={validation}>
      {({ field: { name, value, onChange }, form: { setFieldValue }, meta: { touched, error } }: FieldProps) => {
        return (
          <IconField iconPosition="left">
            <InputIcon className="z-10 self-center items-center">
              <FontAwesomeIcon className="text-slate-400" icon={faSearch} title="Type to search" />
            </InputIcon>
            <AutoComplete
              id={name}
              name={name}
              field="display"
              disabled={disabled}
              itemTemplate={itemCodeTemplate}
              readOnly={readonly}
              suggestions={suggestionList}
              delay={400}
              minLength={3}
              multiple={multiple}
              selectedItemTemplate={multiple && itemTemplate}
              completeMethod={(e) => searchCodes(e.query)}
              onBlur={() => {
                if (!multiple && !(value as CodeableConcept)?.coding?.[0]?.code)
                  setFieldValue(name, { coding: undefined, text: undefined })
              }}
              onChange={(e) => {
                multiple
                  ? onChange(e)
                  : setFieldValue(name, { coding: [e.value], text: e.value.display } as CodeableConcept)
                if (typeof e.value !== "string") return
                setFilter(e.value.trim())
              }}
              onSelect={(e) => {
                const newCodeableConcept = { coding: [e.value], text: e.value?.display } as CodeableConcept
                onSelect?.(newCodeableConcept)
                multiple
                  ? setFieldValue(name, [...(value ?? []), newCodeableConcept])
                  : setFieldValue(name, newCodeableConcept)
              }}
              value={multiple ? value : value?.coding?.[0]}
              className={classNames("p-inputtext-sm h-10", { "p-invalid": touched && error })}
              placeholder={placeholder}
              appendTo="self"
              panelClassName="w-full"
            />
          </IconField>
        )
      }}
    </FormField>
  )
}

type Props = {
  field: string
  label?: string
  className?: string
  readonly?: boolean
  disabled?: boolean
  multiple?: boolean
  valueSetId: ValueSetIds
  validation?(code: CodeableConcept): void
  placeholder?: string
  onSelect?(_: CodeableConcept): void
  allowCustomValue?: boolean
}

export { AutocompleteCodeableConceptField }
