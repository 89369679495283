import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { List, Parameters } from "fhir"

import { useClient } from "api"
import { MEDICATION_PRODUCT_TYPE } from "data"
import { eprescribeQueryKeys } from "eprescribe/query-keys"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { SYSTEM_VALUES } from "system-values"
import { displayNotificationSuccess } from "utils"

import { type CustomError } from "../../types"
import { medsQueryKeys } from "../meds_query_keys"

const useCancelMrOrder = ({
  context,
  onSettled,
  onSuccess,
}: {
  context: MEDICATION_PRODUCT_TYPE
  onSuccess?: () => void
  onSettled?: () => void
}) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const cancelOrder = ({
    serviceRequestId,
    patientId,
    cancelReason,
    cancel_mode,
    //refundItemsAsWell,
  }: {
    serviceRequestId: string
    patientId: string
    cancelReason: string
    cancel_mode: string
    refundItemsAsWell: boolean
  }) => {
    const list: List = {
      resourceType: "List",
      mode: "working",
      status: "current",
      entry: [
        {
          item: {
            resourceType: "ServiceRequest",
            id: serviceRequestId,
          },
          flag: {
            coding: [
              {
                code: cancel_mode,
                system: SYSTEM_VALUES.ORDER_CANCEL_MODE,
              },
            ],
          },
          /* itemCharacteristic: [
            {
              value: { boolean: refundItemsAsWell },
              type: {
                coding: [
                  {
                    display: "Refund",
                    system: SYSTEM_VALUES.LIST_ITEM_CHARACTERISTIC_SYSTEM,
                    code: "refund",
                  },
                ],
              },
            },
          ], */
        },
      ],
    }

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "orders",
          resource: list,
        },
        {
          name: "reason",
          value: {
            string: cancelReason,
          },
        },
      ],
    }

    return operationRequest({ endpoint: `Patient/${patientId}/cpoe`, method: "POST", operation: "cancel", parameters })
  }

  const { mutate: cancelMrOrder, isPending } = useMutation({
    mutationFn: cancelOrder,
    onError: (error: CustomError, serviceRequestId) => {
      displayNotificationError(registerErrorTrace(error, { serviceRequestId }))
    },
    onSuccess: async (_, { serviceRequestId }) => {
      queryClient.refetchQueries({
        queryKey: (context === MEDICATION_PRODUCT_TYPE.RX ? eprescribeQueryKeys : medsQueryKeys).orderDetails.details(
          serviceRequestId,
        ),
        type: "all",
      })
      displayNotificationSuccess("Order cancelled successfully")
      // datadogLogs.logger.info(`Order with id ${serviceRequestId} cancelled successfully`)
      onSuccess?.()
    },
    onSettled: onSettled,
  })

  return { cancelMrOrder, isCancelling: isPending }
}

export { useCancelMrOrder }
