import {
  faCalendarCheck,
  faCalendarDays,
  faFileInvoiceDollar,
  faPills,
  faSearch,
  faUser,
  faUserDoctor,
  faVials,
} from "@fortawesome/pro-solid-svg-icons"
import { format, parseISO } from "date-fns"
import { type Reference, type ServiceRequest, humanNameAsString, isMedicationRequest, isServiceRequest } from "fhir"
import pluralize from "pluralize"
import { useCallback, useEffect, useId, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import {
  type StackedListItemProps,
  EmptyMessage,
  InfiniteScroll,
  ModulesId,
  SkeletonLoader,
  StackedListContainer,
} from "commons"
import { getStatus } from "commons/labs"
import { formatsByTypes, isLabOrder, isMedicationOrder } from "data"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import type { Order, OrderFilter } from "organization-orders/types"
import { usePatient } from "patients"
import { SYSTEM_VALUES } from "system-values"
import {
  formatDate,
  getBadgeColor,
  getInvoicesInfo,
  getLabOrderIdentifier,
  getMoneyCurrencyAlt,
  getServiceRequestBillingType,
  strCapitalize,
} from "utils"

import { useOrganizationOrders } from "../hooks"
import { getCleanDate, getCleanType, getStatusesByType, getStatusesClean } from "../utils"
import { OrderSearchWithFilters } from "./OrderSearchWithFilters"
import { OrdersFiltersFormContiner } from "./OrdersFiltersFormContainer"

const OrdersView = () => {
  const loaderKey = useId()
  const navigate = useNavigate()
  const [params, setParams] = useSearchParams()
  const { currentOrganizationId } = useOrganizationContext()
  const { organizationPractitionersInfo } = useOrganizationPractitioners({ organizationId: currentOrganizationId })
  const searchText = params.get("search") ?? undefined
  const type = getCleanType(params.get("type") ?? "") ?? undefined
  const patientId = params.get("patientId") ?? undefined
  const status = getStatusesClean(params.get("status")?.split(",") ?? [], getStatusesByType(type ?? "")) ?? undefined
  const authored = params.get("authored") ? getCleanDate(params.get("authored") ?? "") : undefined
  const occurrence = params.get("occurrence") ? getCleanDate(params.get("occurrence") ?? "") : undefined
  const [patient, setPatient] = useState<Reference>()

  useEffect(() => {
    status?.length ? params.set("status", status.map((status) => status).join(",")) : params.delete("status")
    !type && params.delete("type")
    !authored && params.delete("authored")
    !occurrence && params.delete("occurrence")
    setParams(params)
  }, [])

  const { orders, count, total, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useOrganizationOrders(
    currentOrganizationId,
    type,
    patientId,
    status?.join(","),
    authored,
    occurrence,
    searchText,
  )

  const { patient: patientData, isLoading: isLoadingPatient } = usePatient(patientId, !patient?.display)

  useEffect(() => {
    if (!isLoadingPatient) {
      if (patientId && !patient?.display && patientData?.name?.[0]) {
        setPatient({ id: patientId, display: humanNameAsString(patientData?.name?.[0]) })
      } else if (!patient) {
        params.delete("patientId")
        setPatient(undefined)
        setParams(params)
      }
    }
  }, [patient?.display, patientData?.name, patientId, isLoadingPatient])

  const practitionerRefs = useMemo(
    () =>
      organizationPractitionersInfo
        .reduce<
          Reference[]
        >((acc, pi) => [...acc, { id: pi.practitionerRoleRef?.id as string, display: humanNameAsString(pi.practitioner.name?.[0]) }], [])
        .sort((a, b) => a?.display?.localeCompare(b?.display ?? "") ?? -1),
    [organizationPractitionersInfo],
  )

  const setFilters = ({ searchText, type, patient, status, authored, occurrence }: OrderFilter) => {
    searchText ? params.set("search", encodeURI(searchText)) : params.delete("search")
    type ? params.set("type", type) : params.delete("type")
    patient?.id ? params.set("patientId", patient?.id) : params.delete("patientId")
    status?.length ? params.set("status", status.map((status) => status).join(",")) : params.delete("status")
    authored
      ? params.set("authored", formatDate(authored, formatsByTypes.ISO_8601_DATE) ?? "")
      : params.delete("authored")
    occurrence
      ? params.set("occurrence", formatDate(occurrence, formatsByTypes.ISO_8601_DATE) ?? "")
      : params.delete("occurrence")
    setPatient(patient)
    setParams(params)
  }

  const loader = () => <SkeletonLoader key={loaderKey} repeats={8} loaderType="two-lines" />

  const showOrder = useCallback(
    (order: ServiceRequest) => {
      const commonBaseUrl = `/orgs/${currentOrganizationId}/patients/${order.subject.id}`
      if (isMedicationOrder(order)) {
        const isNutraOrder = order.orderDetail?.some(({ coding }) =>
          coding?.some(({ code }) => code === "nutraceutical-order"),
        )

        navigate(
          `${commonBaseUrl}?view=${isNutraOrder ? ModulesId.MEDICATIONR : ModulesId.EPRESCRIBE}&subview=${order?.status === "active" ? "orders" : "history"}&order=${order?.id}`,
        )
      } else if (isLabOrder(order)) navigate(`${commonBaseUrl}?view=labs&order=${order?.id}`)
    },
    [currentOrganizationId],
  )

  const handleSearch = (searchText?: string) => {
    setFilters({
      searchText: searchText,
      type,
      patient,
      status,
      authored,
      occurrence,
    })
  }

  return (
    <OrderSearchWithFilters
      countDisplay={
        <p className="text-sm text-gray-500">
          Showing {count} {pluralize("order", count)} of {total} found
        </p>
      }
      onTextFilter={handleSearch}
      isLoading={isLoading || isFetchingNextPage}
      formContent={
        <OrdersFiltersFormContiner
          initialValues={{
            type,
            patient,
            searchText,
            status,
            authored,
            occurrence,
          }}
          onSearch={(filters, formikHelpers) => {
            setFilters(filters)
            formikHelpers.setSubmitting(false)
          }}
          onClearFilters={() => {
            setFilters({})
          }}
        />
      }
    >
      {isLoading ? (
        loader()
      ) : !total ? (
        <EmptyMessage icon={faSearch} message="No Orders Found" subMessage={false} />
      ) : (
        <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
          <StackedListContainer
            data={orders}
            itemModelBuilder={(item) => modelBuilder(item, () => showOrder(item.serviceRequest), practitionerRefs)}
          />
        </InfiniteScroll>
      )}
    </OrderSearchWithFilters>
  )
}

const modelBuilder = (order: Order, showOrder: () => void, practitionerRefs: Reference[]): StackedListItemProps => {
  const isMedsOrder = isMedicationOrder(order.serviceRequest)
  const orderStatus = isMedsOrder ? order.serviceRequest.status : getStatus(order.serviceRequest)?.display
  const itemsByOrderTypeCount =
    order.serviceRequest.basedOn?.filter((ref) => (isMedsOrder ? isMedicationRequest(ref) : isServiceRequest(ref))) ??
    []

  const orderRequester =
    practitionerRefs.find((practitionerRef) => practitionerRef.id === order.serviceRequest.requester?.id)?.display ??
    order.serviceRequest.requester?.display ??
    "Unspecified requester"
  const orderBillingTypeDisplay = strCapitalize(getServiceRequestBillingType(order.serviceRequest).replace("bill-", ""))

  const lifeFileId = order.serviceRequest.identifier?.find(
    ({ system }) => system === SYSTEM_VALUES.LIFEFILE_MEDICATION_ORDER,
  )
  const medOrderIdentifier = order.serviceRequest.identifier?.find(
    ({ system }) => system === SYSTEM_VALUES.MEDICATION_ORDER,
  )

  const labOrderIdentifier = getLabOrderIdentifier(order.serviceRequest, order.replacedOrders ?? [])

  const patientName = humanNameAsString(order.subject.name?.[0])

  const { identifier: invoiceNumber } = getInvoicesInfo(order.invoices)

  const data = isMedsOrder
    ? [
        {
          lineItems: [
            {
              name: "Order number",
              value: `${medOrderIdentifier?.value ?? "Unspecified number"}${lifeFileId ? ` (${lifeFileId.value})` : ""}`,
            },
          ],
        },
        {
          lineItems: [
            { name: "Patient", value: patientName, icon: faUser },
            { name: "Requester", value: orderRequester, icon: faUserDoctor },
            { name: "Bill to", value: orderBillingTypeDisplay, icon: faFileInvoiceDollar },
            {
              name: "Authored On",
              value: order.serviceRequest.authoredOn
                ? format(new Date(parseISO(order.serviceRequest.authoredOn)), formatsByTypes.LONG_DATE)
                : "Unspecified",
              icon: faCalendarDays,
            },
            ...(order.serviceRequest.occurrence?.dateTime
              ? [
                  {
                    name: "Occurrence",
                    icon: faCalendarCheck,
                    value: format(parseISO(order.serviceRequest.occurrence?.dateTime), formatsByTypes.LONG_DATE),
                  },
                ]
              : []),

            { name: "Medication Requests", value: `${itemsByOrderTypeCount.length}`, icon: faPills },
            ...(invoiceNumber.length
              ? [
                  {
                    name: "Invoice number",
                    icon: faFileInvoiceDollar,
                    value: `#${invoiceNumber.join(", #")}`,
                  },
                ]
              : []),
          ],
        },
      ]
    : [
        {
          lineItems: [
            {
              name: "Laboratory",
              value: `${strCapitalize(order.serviceRequest.performer?.[0]?.display as string)} (${labOrderIdentifier})`,
            },
          ],
        },
        {
          lineItems: [
            { name: "Patient", value: patientName, icon: faUser },
            { name: "Requester", value: orderRequester, icon: faUserDoctor },
            { name: "Bill to", value: orderBillingTypeDisplay, icon: faFileInvoiceDollar },
            {
              name: "Authored on",
              value: order.serviceRequest.authoredOn
                ? format(parseISO(order.serviceRequest.authoredOn), formatsByTypes.LONG_DATE)
                : "Unspecified",
              icon: faCalendarDays,
            },
            ...(order.serviceRequest.occurrence?.dateTime
              ? [
                  {
                    name: "Occurrence",
                    icon: faCalendarCheck,
                    value: format(parseISO(order.serviceRequest.occurrence?.dateTime), formatsByTypes.LONG_DATE),
                  },
                ]
              : []),
            { name: "Tests", value: `${itemsByOrderTypeCount.length}`, icon: faVials },
            ...(invoiceNumber.length
              ? [
                  {
                    name: "Invoice number",
                    icon: faFileInvoiceDollar,
                    value: `#${invoiceNumber.join(", #")}`,
                  },
                ]
              : []),
          ],
        },
      ]

  return {
    leftData: data,
    ...(order.price?.value
      ? {
          rightData: [
            {
              lineItems: [
                {
                  name: "Price",
                  value: `${getMoneyCurrencyAlt(order.price.currency)}${order.price.value?.toFixed(2)} ${
                    order.price.currency
                  }`,
                },
              ],
            },
          ],
        }
      : {}),
    badge: getBadgeColor(orderStatus ?? "unspecified"),
    onClick: showOrder,
  }
}

export { OrdersView }
