import { type FC, useCallback, useDeferredValue, useState } from "react"
import { AutoComplete } from "primereact/autocomplete"
import { classNames } from "primereact/utils"

import type { LabPanel } from "administration/types"
import { StackedListItem } from "commons"

import { labPanelModelBuilder } from "./labPanelModelBuilder"
import { useLabPanels } from "../../../hooks/labs"

const NO_FOUND_PANELS = "No lab tests found"

const TestAutocomplete: FC<Props> = ({
  organizationId,
  performerLab,
  unselectablePanels,
  disabled,
  placeholder,
  error,
  className,
  handleSelect,
}) => {
  const [filter, setFilter] = useState<string | undefined>()
  const deferredFilter = useDeferredValue(filter)

  const { refetch } = useLabPanels(
    organizationId,
    {
      searchText: deferredFilter,
      performerLabs: performerLab ? [performerLab] : undefined,
    },
    !!deferredFilter,
  )
  const [suggestionList, setSuggestionList] = useState<(LabPanel | string)[]>([])

  const itemTemplate = (item: LabPanel) => (
    <StackedListItem
      modelData={labPanelModelBuilder(item)}
      itemPadding={false}
      className="py-0 w-full"
      contentClassName="flex justify-between"
      customTag="div"
    />
  )

  const handleUpdateSuggestions = useCallback(
    (panels: LabPanel[]) => {
      const filteredSuggestions = panels.filter(
        ({ planDefinition: newPD }) =>
          !unselectablePanels?.some(({ planDefinition }) => planDefinition.id === newPD.id),
      )
      setSuggestionList(filteredSuggestions)
    },
    [unselectablePanels],
  )

  const searchPanels = async () => {
    const { data } = await refetch()
    const panels = data?.pages.flatMap((page) => page.panels ?? []) ?? []
    handleUpdateSuggestions([...panels])
  }

  return (
    <AutoComplete
      id="test-autocomplete"
      field="display"
      disabled={disabled}
      itemTemplate={itemTemplate}
      suggestions={suggestionList}
      delay={400}
      minLength={1}
      completeMethod={searchPanels}
      onSelect={({ value }) => handleSelect(value)}
      forceSelection
      className={classNames("p-inputtext-sm", { "p-invalid": error }, className)}
      placeholder={placeholder}
      value={filter}
      showEmptyMessage
      emptyMessage={NO_FOUND_PANELS}
      onChange={(e) => {
        if (typeof e.value !== "string") return
        setFilter(e.value)
      }}
      onBlur={() => setFilter(undefined)}
      appendTo="self"
      panelClassName="w-full"
    />
  )
}

type Props = {
  organizationId: string
  performerLab?: string
  error?: unknown
  placeholder?: string
  disabled?: boolean
  className?: string
  unselectablePanels?: LabPanel[]
  handleSelect(test?: LabPanel): void
}

export default TestAutocomplete
