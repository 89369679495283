import { isMedicationKnowledge, type Money } from "fhir"
import { useFormikContext } from "formik"
import { Skeleton } from "primereact/skeleton"
import type { FC } from "react"

import { getFeeType } from "commons/meds"
import { getMedsProductConfigurations, getPriceByCode } from "commons/utils"
import { MED_FEE_TYPE } from "data"
import { useOrganizationContext } from "organization"
import { getMoneyCurrencyAlt } from "utils"
import { useProductPrices } from "commons/hooks"
import { PRODUCT_CONFIGURATION_BILLING_TYPE } from "commons/types"

import type { MedicationRequestFormData } from "../../types"

const PrescriptionPrices = () => {
  const { values } = useFormikContext<MedicationRequestFormData>()
  const { medicationField, dispenseRequest } = values
  const { currentOrganizationId, hasCreditCard } = useOrganizationContext()
  const isMK = isMedicationKnowledge(medicationField)

  const medsProductConfigurations = isMK
    ? getMedsProductConfigurations({
        meds: [{ ...values, medication: { CodeableConcept: medicationField.code } }],
        specifiedQuantity: true,
        specifiedMedFrequency: true,
        referenceMedFrequency: dispenseRequest?.dispenseInterval,
        billingType: PRODUCT_CONFIGURATION_BILLING_TYPE.BOTH,
      })
    : []

  const { productPrices, isLoading: isLoadingPrices } = useProductPrices({
    organizationId: currentOrganizationId,
    productsConfigurations: medsProductConfigurations,
  })

  const mkCode = isMK ? medicationField.code?.coding : []
  const mkPracticePrice = getPriceByCode({
    productPrices,
    medCoding: mkCode,
    shippingAddressState: values?.dispenseRequest?.shippingAddress?.state,
    quantity: dispenseRequest?.quantity?.value,
    billingType: PRODUCT_CONFIGURATION_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE,
  })
  const mkPatientPrice = getPriceByCode({
    productPrices,
    medCoding: mkCode,
    shippingAddressState: values?.dispenseRequest?.shippingAddress?.state,
    quantity: dispenseRequest?.quantity?.value,
    billingType: PRODUCT_CONFIGURATION_BILLING_TYPE.BILL_PATIENT,
    ...(getFeeType(Object.values(productPrices ?? {})) === MED_FEE_TYPE.ByFrequency
      ? { specifyMedFee: true, productFrequency: dispenseRequest?.dispenseInterval }
      : {}),
  })

  if (!medicationField) return null

  if (isLoadingPrices) return <PriceLoadingSkeleton multipleCards={hasCreditCard} />

  return (
    <div className="flex justify-end space-x-4">
      {hasCreditCard && <Price label="Practice Price" price={mkPracticePrice} />}
      <Price label={!hasCreditCard ? "Price" : "Patient Price"} price={mkPatientPrice} />
    </div>
  )
}

const PriceLoadingSkeleton = ({ multipleCards }: { multipleCards: boolean }) => (
  <div className="flex justify-end space-x-4">
    <Skeleton className="rounded-full" width="10rem" />
    {multipleCards && <Skeleton className="rounded-full" width="10rem" />}
  </div>
)

const Price: FC<{ label: string; price?: Money }> = ({ label, price }) =>
  price ? (
    <span className="flex space-x-2 text-sm">
      <span>{label}:</span>
      <strong>
        {getMoneyCurrencyAlt(price?.currency)}
        {price?.value?.toFixed(2)}
      </strong>
    </span>
  ) : null

export { PrescriptionPrices }
