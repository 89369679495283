import type { Attachment } from "fhir"
import { type FieldProps } from "formik"
import { FileUpload } from "primereact/fileupload"
import { classNames } from "primereact/utils"
import { type FC, useRef } from "react"

import { useAzureContainer } from "../hooks"
import { FormField } from "./FormField"

const ONE_MB = 1048576 // in bytes

const FileUploaderField: FC<Props> = ({
  field,
  autoupload = true,
  azureContainer,
  label,
  accept = "application/pdf",
  className,
  disabled,
  maxFileSize,
  labelClassName,
  validation,
}) => {
  const fileInput = useRef<FileUpload>(null)
  const clearFiles = () => fileInput.current?.clear()
  const { uploadFile } = useAzureContainer(azureContainer ?? "")

  const handleFileSelect =
    (name: string, setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void) =>
    (event: { files: File[] }) => {
      const file = event.files[0]
      const { name: fileName, size: fileSize, type: fileType } = file
      if (autoupload)
        uploadFile({ file }).then((url) => {
          setFieldValue(name, { url, size: fileSize, title: fileName, contentType: fileType } as Attachment)
        })
      else setFieldValue(name, file)
    }

  return (
    <FormField
      field={field}
      className={className}
      labelClassName={labelClassName}
      label={label}
      validation={validation}
    >
      {({
        field: { name },
        meta: { touched, error },
        form: { setFieldValue, setFieldError, setFieldTouched },
      }: FieldProps) => {
        const addValidationError = (f: File) => {
          if (maxFileSize && f.size > maxFileSize) {
            setFieldError(name, `Invalid file. Max file size is ${Math.floor(maxFileSize / ONE_MB)}MB.`)
          }

          setFieldTouched(name, true, false)
        }

        return (
          <FileUpload
            id={name}
            name={name}
            accept={accept}
            maxFileSize={maxFileSize}
            disabled={disabled}
            customUpload
            ref={fileInput}
            uploadHandler={clearFiles}
            onSelect={handleFileSelect(name, setFieldValue)}
            onValidationFail={addValidationError}
            mode="basic"
            className={classNames("p-button-sm", { "p-invalid": touched && error })}
          />
        )
      }}
    </FormField>
  )
}

type Props = {
  field: string
  label?: string
  accept?: "application/pdf" | "image/*"
  className?: string
  disabled?: boolean
  maxFileSize?: number
  labelClassName?: string
  validation?: (val: File | Attachment | undefined) => string | undefined
} & OptionalUpload

type OptionalUpload =
  | {
      autoupload: true
      azureContainer: string
    }
  | { autoupload?: false; azureContainer?: string }

export { FileUploaderField }
