import type { AccountBETACreditCardArray } from "fhir"
import { type FieldProps, useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import type { FC } from "react"
import { Calendar } from "primereact/calendar"
import { isDate } from "date-fns"

import { FormField } from "commons"

const ExpirationDateField: FC<Props> = ({ field, label, className, disabled = false }) => {
  const { values, setFieldTouched } = useFormikContext<AccountBETACreditCardArray>()

  return (
    <FormField
      field={field}
      className={className}
      label={label}
      validation={() => {
        if (!values.expirationYear || !values.expirationMonth) return "Invalid expiration date"
        const currentDate = new Date()

        if (
          parseInt(values.expirationYear) < currentDate.getFullYear() ||
          (parseInt(values.expirationYear) === currentDate.getFullYear() &&
            parseInt(values.expirationMonth) < currentDate.getMonth() + 1)
        )
          return "Expired date"
      }}
    >
      {({ field: { name }, meta: { touched, error }, form: { setFieldValue, values } }: FieldProps) => {
        const dateValue = new Date(parseInt(values["expirationYear"]), parseInt(values["expirationMonth"]) - 1, 1)
        return (
          <Calendar
            id={name}
            name={name}
            value={dateValue}
            readOnlyInput
            onChange={(e) => {
              if (isDate(e.target.value)) {
                setFieldTouched("expirationMonth", false, true)
                setFieldValue("expirationMonth", ((e.target.value as Date).getMonth() + 1).toString())
                setFieldValue("expirationYear", (e.target.value as Date).getFullYear().toString())
              }
            }}
            showIcon
            dateFormat="MM-yy"
            view="month"
            className={classNames("p-inputtext-sm", { "p-invalid": touched && error })}
            disabled={disabled}
          />
        )
      }}
    </FormField>
  )
}

type Props = {
  field: string
  label?: string
  className?: string
  disabled?: boolean
}

export { ExpirationDateField }
