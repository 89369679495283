import { useMutation, useQueryClient, type QueryKey } from "@tanstack/react-query"
import type { Observation } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons/types"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

const usePatchObservation = (queryKey: QueryKey) => {
  const { patch } = useClient()
  const queryClient = useQueryClient()

  const updateFn = async (observation: Partial<Observation>) =>
    patch("Observation", observation.id as string, observation)

  const { mutate, isPending } = useMutation({
    mutationFn: updateFn,
    onError: async (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey })
    },
  })

  return { patchObservation: mutate, isUpdating: isPending }
}

export { usePatchObservation }
