import type { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { isCarePlan, type ServiceRequest } from "fhir"
import type { FC } from "react"
import { classNames } from "primereact/utils"

import { strCapitalize } from "utils"
import { ModulesId } from "commons"
import { useChartContext } from "chart-view"
import { getStatus } from "commons/labs"

import { WrapUpSection } from "./WrapUpSection"
import { OrderItem } from "./OrderItem"
import { useEncounterContext } from "../../hooks"

const LabsSection: FC<Props> = ({ icon }) => {
  const { showModule } = useChartContext()

  const goToOrderDetails = (order: string) => {
    showModule({ module: ModulesId.LABSORDER, moduleParams: { order } })
  }

  const { encounterData, totalLabOrders: total, planRefs } = useEncounterContext()

  const renderDetails = ({
    panels,
    combo,
    className,
  }: {
    panels: { profile: ServiceRequest }[]
    combo?: { profile: ServiceRequest; panels: { profile: ServiceRequest }[] }
    className?: string
  }) => (
    <>
      {combo && (
        <div className={classNames("flex flex-col text-sm mb-2", className)}>
          <span className="font-medium text-gray-400 inline-flex w-full items-center mb-2">
            {combo.profile.code?.coding?.[0].display}
            <hr className="flex-1 ml-1" />
          </span>
          <div className="py-1 grid grid-flow-row-dense gap-1 @lg:grid-cols-2">
            {combo.panels.map(({ profile }) => (
              <p key={profile.id} className="truncate">
                {profile.code?.coding?.[0]?.display}
              </p>
            ))}
          </div>
        </div>
      )}
      {!!panels.length && (
        <div className={classNames("flex flex-col text-sm mb-2", className)}>
          <span className="font-medium text-gray-400 inline-flex w-full items-center mb-2">
            Additional tests
            <hr className="flex-1 ml-1" />
          </span>
          <div className="py-1 grid grid-flow-row-dense gap-1 @lg:grid-cols-2">
            {panels.map(({ profile }) => (
              <p key={profile.id} className="truncate">
                {profile.code?.coding?.[0]?.display}
              </p>
            ))}
          </div>
        </div>
      )}
    </>
  )

  return (
    <WrapUpSection
      sectionTitle="Lab orders"
      isEmpty={!total}
      icon={icon}
      emptyMesage="No orders placed during this encounter"
      className="w-full self-end pb-2 gap-6"
    >
      {[...(encounterData?.laboratoryOrders ?? [])].map(({ order: sr, panels, combo, orderIdentifier }, index) => {
        const isDraft = sr?.status === "draft"
        const cp = planRefs?.[sr.basedOn?.find(isCarePlan)?.id as string]
        const title = isDraft
          ? index === 0
            ? "Pending for checkout"
            : ""
          : `${strCapitalize(sr?.performer?.[0]?.display as string)} ${sr?.status !== "draft" ? `(${orderIdentifier})` : ""}`

        return (
          <OrderItem
            key={sr?.id}
            requester={sr?.requester}
            status={getStatus(sr)?.display}
            title={title}
            date={sr?.authoredOn ? new Date(sr.authoredOn) : undefined}
            seeOrderDetails={!isDraft ? () => goToOrderDetails(sr?.id as string) : undefined}
            className="first:mt-6"
            badge={
              cp?.display
                ? {
                    text: cp.display,
                    colorStyle: "gray",
                    title: cp.display,
                    className: "truncate max-w-24 !block",
                  }
                : undefined
            }
          >
            {renderDetails({
              panels,
              combo: combo && { profile: combo.laboratoryCombo, panels: combo.panels },
              className: "text-sm pr-2 @container",
            })}
          </OrderItem>
        )
      })}
    </WrapUpSection>
  )
}

type Props = {
  encounterId: string
  patientId: string
  icon: IconDefinition
}

export { LabsSection }
