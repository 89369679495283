import type { Coding } from "fhir"
import { type FieldProps } from "formik"
import { MultiSelect } from "primereact/multiselect"
import { classNames } from "primereact/utils"
import type { FC } from "react"

import { FormField } from "./FormField"

const MultiSelectField: FC<Props> = ({
  field,
  options,
  label,
  className,
  optionLabel = "label",
  optionValue = "code",
  horizontal,
  showClear,
  inputClassName,
}) => (
  <FormField field={field} className={className} horizontal={horizontal} label={label}>
    {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => {
      const validSelectedValues = (value as string[])?.reduce((prev, current) => {
        if (options.some((option) => option.code === current)) return [...prev, current]

        return prev
      }, [] as string[])

      return (
        <MultiSelect
          id={name}
          name={name}
          optionLabel={optionLabel}
          optionValue={optionValue}
          options={options}
          onChange={onChange}
          value={validSelectedValues}
          showClear={showClear}
          className={classNames(
            "p-inputtext-sm",
            {
              "p-invalid": touched && error,
              horizontal: horizontal,
            },
            inputClassName,
          )}
        />
      )
    }}
  </FormField>
)

type Props = {
  field: string
  options: Coding[]
  label?: string
  optionLabel?: string
  optionValue?: string
  className?: string
  horizontal?: boolean
  showClear?: boolean
  inputClassName?: string
}

export { MultiSelectField }
