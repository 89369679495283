import { faPlus, faSearch } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type MedicationKnowledge, type MedicationRequest, type Reference, asReference } from "fhir"
import { useFormikContext } from "formik"
import { Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import { type FC, useCallback, useEffect, useMemo, useReducer, useState } from "react"

import { MEDICATION_CATALOG } from "data"
import { MedicationRequestCardForm } from "medication-requests"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { SkeletonLoader } from "../../../components/SkeletonLoader"
import { FormField } from "../../../forms"
import { useProductPrices } from "../../../hooks"
import {
  type MedicationRequestData,
  type MedicationRequestInfo,
  MedicationKnowledgeDetails,
  MedicationRequestItem,
  getMRInitialValues,
  getMedCommonCode,
  sanitizeMR,
  useMedicationKnowledge,
  useMedicationRequestDataBind,
} from "../../../meds"
import type { PractitionerInfo } from "../../../types"
import { getMedsProductConfigurations } from "../../../utils"
import { useMedsConfigData } from "../../hooks"
import { type PlanData, PLAN_ACTION_CODES } from "../../types"
import { getGroupedActionMedCodes } from "../../utils"

const NutrasFormSection: FC<Props> = ({ practitionersInfo, openEncounter, contentClassName }) => {
  const { currentOrganizationId, loggedInPractitionerRole } = useOrganizationContext()
  const { patient } = usePatientContext()

  const {
    values: { configureActions, nutraceutical, requester },
    setFieldValue,
    getFieldMeta,
    validateForm,
  } = useFormikContext<PlanData>()

  const medAction = configureActions[PLAN_ACTION_CODES.CONFIGURE_NUTRAS]
  const { groups: medPacks, medCodes, requiredMeds } = getGroupedActionMedCodes(medAction)

  const { itemActionClicked, onActionCliked } = useReducerState()
  const [selectedMK, setSelectedMK] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()

  const {
    medicationKnowledge: medications,
    isLoading,
    catalogs,
    mksBySku,
  } = useMedicationKnowledge({
    enabled: !!medPacks.length,
    medCodes: medCodes.join(","),
    category: MEDICATION_CATALOG.NUTRA,
    hasCIDInOrg: currentOrganizationId,
  })

  const { productPrices } = useProductPrices({
    organizationId: currentOrganizationId,
    productsConfigurations: getMedsProductConfigurations({
      meds: medications,
      specifiedQuantity: true,
    }),
  })

  const newMrs = useMemo(() => {
    return medications.reduce((acc, mk) => {
      const performerCatalogOrg = catalogs?.[mk.catalogHeader?.[0]?.id as string]
      const newMr = performerCatalogOrg && {
        medicationRequestInfo: getMRInitialValues(
          mk,
          1,
          patient,
          loggedInPractitionerRole,
          practitionersInfo,
          asReference(performerCatalogOrg),
          openEncounter,
          requester,
        ),
        medicationKnowledge: mk,
      }
      if (newMr && performerCatalogOrg) return [...acc, newMr]
      return [...acc]
    }, Array<MedicationRequestData>())
  }, [medications])

  const { medicationRequestData } = useMedicationRequestDataBind({
    medicationRequests: nutraceutical,
    medicationKnowledges: mksBySku,
    medicationsCIDs: productPrices,
  })

  const { allRequiredConfigured, configuredMedCodes, allowMultiple, minToConfig } =
    useMedsConfigData<MedicationRequestData>(medAction, requiredMeds, medicationRequestData)

  const onUpdateMR = (mr: MedicationRequestInfo, index: number) => {
    onActionCliked(index)
    const updatedMR = sanitizeMR({ ...mr, encounter: openEncounter }, !mr.id)
    setFieldValue("nutraceutical", [...(nutraceutical ?? [])].toSpliced(index > 0 ? index : 0, 1, updatedMR)).finally(
      () => onActionCliked(-1),
    )
  }

  const validate = useCallback(
    () => (!allRequiredConfigured ? "Please configure required nutraceuticals" : ""),
    [allRequiredConfigured],
  )

  useEffect(() => {
    validateForm()
  }, [allRequiredConfigured])

  const onRemoveMR = useCallback(
    (deleteIndex: number) => {
      setFieldValue("nutraceutical", nutraceutical?.toSpliced(deleteIndex, 1))
    },
    [nutraceutical],
  )

  const addMR = useCallback(
    ({ medicationRequestInfo }: MedicationRequestData) => {
      const updatedMR = sanitizeMR({ ...medicationRequestInfo, encounter: openEncounter }, !medicationRequestInfo.id)
      setFieldValue("nutraceutical", [...(nutraceutical ?? []), updatedMR])
    },
    [nutraceutical],
  )

  const recommendedMeds = useMemo(
    () =>
      newMrs.filter(({ medicationKnowledge, medicationRequestInfo: { medication } }) => {
        const code = getMedCommonCode({ medication, medicationKnowledge })
        return !configuredMedCodes?.[code]
      }),
    [newMrs, configuredMedCodes],
  )

  const addAll = useCallback(() => {
    const sanitized = recommendedMeds.reduce((acc, { medicationRequestInfo }) => {
      const updatedMR = sanitizeMR({ ...medicationRequestInfo, encounter: openEncounter }, !medicationRequestInfo.id)
      return [...acc, updatedMR]
    }, Array<MedicationRequest>())

    setFieldValue("nutraceutical", [...(nutraceutical ?? []), ...sanitized])
  }, [recommendedMeds, nutraceutical])

  const { error, touched } = getFieldMeta("nutraceutcal")

  const { canAddMore, canAddOne } = useMemo(() => {
    const canAddMore =
      (typeof allowMultiple === "number" && (nutraceutical?.length ?? 0) < allowMultiple) || allowMultiple === true
    const canAddOne = minToConfig > (nutraceutical?.length ?? 0)

    return { canAddOne, canAddMore }
  }, [nutraceutical?.length, allowMultiple, minToConfig])

  return (
    <>
      <FormField
        field="nutraceutical"
        validation={validate}
        label="Nutraceuticals"
        labelAlign="items-start"
        showInvalidState
        labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-700 font-medium"
        className={contentClassName?.full}
      >
        <Dropdown
          options={recommendedMeds}
          onChange={(e) => addMR(e.value)}
          className="p-inputtext-sm text-sm gap-x-[0.5625rem] py-0 no-focusable border-0 small-trigger font-medium flex-row-reverse trigger-dashed items-center"
          placeholder="Add recommended nutraceuticals"
          pt={{
            input: {
              className: "!px-0",
            },
            trigger: {
              className: "!mx-0 !w-8 !h-8",
            },
          }}
          dropdownIcon="pi pi-plus"
          panelClassName=""
          itemTemplate={({ medicationKnowledge, medicationRequestInfo }: MedicationRequestData) => (
            <MedicationRequestItem
              medicationKnowledge={medicationKnowledge as MedicationKnowledge}
              medicationRequest={medicationRequestInfo}
            />
          )}
          disabled={!canAddMore && !canAddOne}
          panelFooterTemplate={
            recommendedMeds.length <= (allowMultiple as number)
              ? (_, hide) => (
                  <button
                    className="flex flex-1 w-full items-center space-x-1 font-medium px-5 py-3 hover:bg-gray-600/10"
                    onClick={() => {
                      addAll()
                      hide()
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    <p>Add all</p>
                  </button>
                )
              : undefined
          }
        />

        {isLoading ? (
          <SkeletonLoader loaderType="two-lines" repeats={medPacks.length} />
        ) : (
          <div className="flex flex-col divide-y-2 divide-gray-200 gap-6">
            {!medicationRequestData.length ? (
              <div
                className={classNames("flex flex-col items-center justify-center w-full py-4", {
                  "border border-red-600 rounded-lg": !!error && touched,
                })}
              >
                <FontAwesomeIcon icon={faSearch} size="lg" className="text-slate-500" />
                <p className="text-slate-500 text-xs pt-1">{`No nutraceuticals added`}</p>
              </div>
            ) : (
              medicationRequestData.map((medication, index) => (
                <MedicationRequestCardForm
                  key={index}
                  variant="simple"
                  medicationData={medication}
                  isSaving={itemActionClicked === index}
                  onSave={(medInfo) => onUpdateMR(medInfo, index)}
                  onSelectMK={() =>
                    setSelectedMK({ mk: medication.medicationKnowledge, mr: medication.medicationRequestInfo })
                  }
                  customTag="div"
                  collapsibleForm
                  onDelete={() => onRemoveMR(index)}
                />
              ))
            )}
          </div>
        )}
      </FormField>

      <MedicationKnowledgeDetails
        selectedMK={selectedMK?.mk}
        onHide={() => setSelectedMK(undefined)}
        mr={selectedMK?.mr}
      />
    </>
  )
}

const initialState = {
  itemActionClicked: -1,
}

const reducer = (
  state: typeof initialState,
  {
    type,
    payload,
  }: {
    type: "reset" | "action-cliked"
    payload: number | undefined
  },
) => {
  switch (type) {
    case "reset":
      return { ...initialState }

    case "action-cliked":
      return { ...state, itemActionClicked: payload as number }
    default:
      return state
  }
}

const useReducerState = () => {
  const [{ itemActionClicked }, dispatch] = useReducer(reducer, initialState)

  const reset = () => {
    dispatch({ type: "reset", payload: -1 })
  }

  const onActionCliked = (medicationId: number) => {
    dispatch({ type: "action-cliked", payload: medicationId })
  }

  return {
    itemActionClicked,
    reset,
    onActionCliked,
  }
}

type Props = {
  practitionersInfo: PractitionerInfo[]
  openEncounter?: Reference
  contentClassName?: { full: string; half: string; fullContentHalfItem: string }
}

export { NutrasFormSection }
