import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { type Questionnaire, type QuestionnaireResponse, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import type { QuestionnaireData } from "commons"

import { SurveyStatus } from "../data"
import { surveysQueryKeys } from "../query-keys"

const useQuestionnaireResponses = ({
  patientId,
  gender,
  key,
  status,
  searchText,
  encounter,
}: QuestionnaireResponsesArgs) => {
  const { search } = useClient()
  const queryKey = surveysQueryKeys.inProgress.list(patientId, key, status, searchText, encounter)
  const infiniteQueryKey = surveysQueryKeys.completed.list(patientId, key, status, searchText, encounter)

  const getQuestionnaireData = async (filters: URLSearchParams, signal: AbortSignal) => {
    const bundle = await search({ endpoint: `Patient/${patientId}/QuestionnaireResponse`, filters, signal })

    const qrs = getResources<QuestionnaireResponse>(bundle, "QuestionnaireResponse")
    const questionnaires = getResources<Questionnaire>(bundle, "Questionnaire")

    const qData = questionnaires
      .filter(({ useContext }) => {
        const questionnaireGender = useContext?.find(({ code }) => code?.code === "gender")?.value?.CodeableConcept
          ?.coding?.[0]?.code
        const isLabQ = useContext?.find(
          ({ value, code }) => code?.code === "scope" && value?.CodeableConcept?.coding?.[0].code === "labs",
        )

        return (!questionnaireGender || !gender || questionnaireGender === gender) && !isLabQ
      })
      .reduce<QuestionnaireData[]>((prev, questionnaire) => {
        const qResponses = qrs.filter((qr) => qr.questionnaire === `${questionnaire.url}|${questionnaire.version}`)

        return [...prev, ...qResponses.map((r) => ({ questionnaire, qResponse: r }))]
      }, [])

    return { bundle, qData }
  }

  const { data: inProgressQRsData, isLoading: isLoadingInProgressQRs } = useQuery<QuestionnaireData[], Error>({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        ...(searchText ? { _ilike: searchText } : {}),
        _sort: "-authored",
        _include: "questionnaire:Questionnaire",
        "enabled:not": "false",
        status: SurveyStatus.progress,
        ...(encounter ? { encounter } : {}),
      })

      if (status?.length && !status?.includes(SurveyStatus.progress)) {
        return []
      }

      const { qData } = await getQuestionnaireData(filters, signal)

      return qData
    },
    meta: { context: { queryKey, patientId } },
  })

  const {
    data: completedQRsData,
    isLoading: isLoadingCompletedQRs,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery<QuestionnaireResponseCompletedQueryData, Error>({
    queryKey: infiniteQueryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        ...(searchText ? { _ilike: searchText } : {}),
        _count: "50",
        _page: `${pageParam}`,
        _sort: "-authored",
        _include: "questionnaire:Questionnaire",
        "enabled:not": "false",
        status: SurveyStatus.completed,
        ...(encounter ? { encounter } : {}),
      })

      if (status?.length && !status?.includes(SurveyStatus.completed)) {
        return { qData: [], next: undefined, total: 0 }
      }

      const { qData, bundle } = await getQuestionnaireData(filters, signal)

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { qData, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })

  const { qData } = useMemo(() => {
    const inProgressData = inProgressQRsData ?? []
    const completedData = completedQRsData?.pages.flatMap((page) => page.qData) ?? []
    const newData = [...inProgressData, ...completedData]

    return {
      qData: newData,
    }
  }, [completedQRsData?.pages, inProgressQRsData])

  return {
    questionnaireData: qData,
    isLoading: isLoadingInProgressQRs || isLoadingCompletedQRs,
    total: completedQRsData?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

interface QuestionnaireResponsesArgs {
  patientId: string
  key?: string
  status?: string[]
  searchText?: string
  encounter?: string
  gender?: string
}

type QuestionnaireResponseCompletedQueryData = { qData: QuestionnaireData[]; next: number | undefined; total: number }

export { useQuestionnaireResponses }
