import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type DatesSetArg, type EventContentArg, createPlugin } from "@fullcalendar/core"
import type { ViewProps } from "@fullcalendar/core/internal"
import { isSameDay } from "date-fns"
import { type Appointment } from "fhir"
import { classNames } from "primereact/utils"
import type { CalendarMonthChangeEvent } from "primereact/calendar"
import { type FC, createRef, useMemo, useReducer, useState } from "react"
import type FullCalendar from "@fullcalendar/react"

import {
  type AppointmentFormData,
  AppointmentFormOverlay,
  type CalendarAppointment,
  INITIAL_VALUES,
  getMonthDateBounds,
  sanitize,
  useBookAppointment,
  usePractitionerAppointments,
  useUnbookAppointment,
  useUpdateAppointment,
} from "appointments"
import { Button, ConfirmDialog } from "commons"
import { useParamsFilters } from "commons/hooks"
import { useOrganizationContext } from "organization"

import { getAppointmentsDateRangeFromDatesSet, getCalendarDateFromDatesSet, getEvents } from "../utils"
import { AgendaView } from "./AgendaView"
import { AppointmentCalendar } from "./AppointmentCalendar"
import { AppointmentFilters } from "./AppointmentFilters"
import "./CalendarView.css"
import { AppointmentPopup } from "./AppointmentPopup"
import { CalendarContainer } from "./CalendarContainer"

const CalendarView: FC = () => {
  const { filters, updateFilters } = useParamsFilters(["patientId", "practitionerId", "appointmentType"])
  const { currentOrganizationId } = useOrganizationContext()
  const calendarRef = createRef<FullCalendar>()

  const [datesSet, setDatesSet] = useState<DatesSetArg>()
  const [showFilters, setShowFilters] = useState(false)

  const datesRange = getAppointmentsDateRangeFromDatesSet(datesSet)
  const calendarDate = getCalendarDateFromDatesSet(datesSet)

  const {
    initialValues,
    isNew,
    showOverlayForm,
    confirmUnbookItem,
    reset,
    add: addAppointmentAction,
    unbookItem,
  } = useReducerState()

  const { appointments, isLoading } = usePractitionerAppointments({
    currentOrganizationId,
    start: datesRange?.start,
    end: datesRange?.end,
    patientId: filters.patientId?.split(","),
    practitionerId: filters.practitionerId?.split(","),
    appointmentType: filters.appointmentType?.split(","),
  })

  const events = useMemo(() => getEvents(appointments ?? []), [appointments])

  const { bookAppointment } = useBookAppointment(reset)
  const { updateAppointment } = useUpdateAppointment(reset)
  const { unbookAppointment, isUnbooking } = useUnbookAppointment({ onSuccess: reset })

  const getCurrentAppointmentsDate = (date: Date) =>
    events.filter((ev) => ev.start && isSameDay(new Date(ev.start.toString()), date))

  const onSubmit = (appointment: AppointmentFormData) => {
    isNew ? bookAppointment(appointment) : updateAppointment(sanitize(appointment))
  }
  const onUnbook = (appointment: Appointment) => unbookAppointment(appointment.id)

  const handleCalendarMonthChange = ({ month, year }: CalendarMonthChangeEvent) => {
    const { start } = getMonthDateBounds({ month, year })
    calendarRef.current?.getApi().gotoDate(start)
    calendarRef.current?.getApi().changeView("dayGridMonth")
  }

  const handleDateSelection = (date?: Date) => {
    if (date) {
      calendarRef.current?.getApi().gotoDate(date)
      calendarRef.current?.getApi().changeView("agenda")
    }
  }

  // passes props to AgendaPlugin
  class MorePropsToView {
    transform(viewProps: ViewProps) {
      return {
        ...viewProps,
        unbook: unbookItem,
      }
    }
  }

  const AgendaPlugin = useMemo(
    () =>
      createPlugin({
        name: "AgendaView",
        views: {
          agenda: (props: ViewProps) => <AgendaView {...props} />,
        },
        viewPropsTransformers: [MorePropsToView],
      }),
    [MorePropsToView],
  )

  const loadingOverlay = (
    <div className="absolute w-full h-full z-10 pt-10 pb-2 pr-2">
      <div className="bg-gray-300/70 rounded-md backdrop-blur-sm flex m-auto w-full h-full items-center justify-center">
        <span className="text-center text-white">
          <FontAwesomeIcon icon={faCircleNotch} className="h-5 w-5 mr-1" spin />
          <span>Loading appointments...</span>
        </span>
      </div>
    </div>
  )

  const renderEventContent = (eventInfo: EventContentArg) => {
    const appointment = eventInfo.event._def.extendedProps.appointment as Appointment
    const weekViewVariant = eventInfo.view.type === "timeGridWeek"

    return (
      <div
        className={classNames("flex flex-1 justify-between relative group", { "items-center": weekViewVariant })}
        id={`appt_${appointment.id}`}
      >
        <div
          className={classNames(
            "grid items-center gap-1",
            weekViewVariant ? "grid-flow-row-dense" : "grid-flow-col-dense",
          )}
        >
          {!weekViewVariant && (
            <span
              className="col-span-1 border-[3px] rounded-full h-1.5 w-1.5"
              style={{ borderColor: eventInfo.borderColor }}
            />
          )}
          <p className={classNames("col-span-3 truncate text-xs", weekViewVariant ? "text-white" : "text-gray-600")}>
            {eventInfo.event.title}
          </p>
          <span className={classNames("col-span-1 text-xs", weekViewVariant ? "text-white" : "text-gray-400 self-end")}>
            {eventInfo.timeText}
          </span>
        </div>

        <AppointmentPopup
          appointmentInfo={
            eventInfo.event._def.extendedProps as CalendarAppointment & {
              patientName: string
              practitionerName: string
              appointmentType: string
            }
          }
          onUnbook={() => unbookItem(appointment)}
          isUnbooking={isUnbooking}
        />
      </div>
    )
  }

  return (
    <div className="p-2 bg-white h-full flex">
      <div
        className={classNames("flex-1 pl-2 pt-2 relative", {
          "active-calendar-filters": !!Object.values(filters).length,
        })}
      >
        {isLoading && loadingOverlay}
        <CalendarContainer
          calendarRef={calendarRef}
          AgendaPlugin={AgendaPlugin}
          events={events}
          renderEvent={renderEventContent}
          onShowFilters={() => setShowFilters(true)}
          handleUpdateDateRange={(dates) =>
            setDatesSet((datesSet) => (dates?.startStr !== datesSet?.startStr ? dates : datesSet))
          }
        />
      </div>
      <div className="flex flex-col pl-3 flex-none w-2/6 md:w-[35%] 2xl:w-1/4">
        <div className="relative flex">
          {isLoading && loadingOverlay}
          <AppointmentCalendar
            currentDate={calendarDate}
            selectDate={handleDateSelection}
            currentDateAppointments={getCurrentAppointmentsDate}
            onMonthChange={handleCalendarMonthChange}
          />
        </div>
        <Button
          label="Schedule appointment"
          className="block outline-none ring-0 w-full text-white p-3 mt-4 button-primary"
          onClick={addAppointmentAction}
        />

        {showFilters && (
          <AppointmentFilters
            visible={showFilters}
            onCancel={() => setShowFilters(false)}
            filteredHS={filters.appointmentType?.split(",") ?? []}
            patients={filters.patientId?.split(",") ?? []}
            practs={filters.practitionerId?.split(",") ?? []}
            updateFilters={(appointmentType, patientId, practitionerId) =>
              updateFilters({ appointmentType, patientId, practitionerId })
            }
          />
        )}

        <AppointmentFormOverlay
          visible={showOverlayForm}
          isEditing={!isNew}
          appointment={initialValues}
          onHide={reset}
          onSubmit={onSubmit}
        />

        <ConfirmDialog
          confirmText="Are you sure you want to unbook this appointment"
          actionName="Unbook"
          visible={confirmUnbookItem !== undefined}
          onConfirm={() => onUnbook(confirmUnbookItem as Appointment)}
          hideDialog={reset}
        />
      </div>
    </div>
  )
}

const initialState: State = {
  showOverlayForm: false,
  initialValues: INITIAL_VALUES,
  isNew: false,
  selectedDate: new Date(),
  confirmUnbookItem: undefined,
  agendaDate: undefined,
}

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: "reset" | "add" | "edit" | "unbook" | "selectDate" | "filterByPatient" | "filterByPractitioner"
    payload?: Date | Appointment | string
  },
) => {
  switch (type) {
    case "reset":
      return {
        ...initialState,
        initialValues: { ...initialState.initialValues, start: state.selectedDate },
        selectedDate: state.selectedDate,
      }
    case "add":
      return { ...state, showOverlayForm: true, isNew: true, agendaDate: undefined, confirmUnbookItem: undefined }
    case "edit":
      return {
        ...state,
        showOverlayForm: true,
        initialValues: payload as Appointment,
        isNew: false,
        agendaDate: undefined,
      }
    case "unbook":
      return { ...state, confirmUnbookItem: payload as Appointment }
    case "selectDate":
      return {
        ...state,
        initialValues: { ...state.initialValues, start: payload as Date },
        selectedDate: payload as Date,
        confirmUnbookItem: undefined,
        agendaDate: payload as Date,
      }
    default:
      return state
  }
}

const useReducerState = () => {
  const [{ initialValues, isNew, showOverlayForm, agendaDate, confirmUnbookItem }, dispatch] = useReducer(
    reducer,
    initialState,
  )

  const reset = () => {
    dispatch({ type: "reset" })
  }

  const add = () => {
    dispatch({ type: "add" })
  }

  const edit = (appointment: Appointment) => {
    dispatch({ type: "edit", payload: appointment })
  }

  const unbookItem = (appointment: Appointment) => {
    dispatch({ type: "unbook", payload: appointment })
  }

  const selectDate = (selectedDate: Date) => {
    dispatch({ type: "selectDate", payload: selectedDate })
  }

  return {
    initialValues,
    isNew,
    showOverlayForm,
    confirmUnbookItem,
    reset,
    add,
    edit,
    selectDate,
    unbookItem,
    agendaDate,
  }
}

type State = {
  showOverlayForm: boolean
  initialValues: Appointment
  isNew: boolean
  selectedDate: Date
  confirmUnbookItem?: Appointment
  agendaDate?: Date
  practitionerFilterId?: string
  patientFilterId?: string
}

export { CalendarView }
