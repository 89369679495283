import { useQuery } from "@tanstack/react-query"
import { type Composition, type Money, type PlanDefinition, type Questionnaire, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { type BILLING_TYPES_CODES, COMBO_PROMO_CODE } from "data"
import { convertIdentifiersToCodings, getBasePrice, mergeSort } from "utils"

import { useProductPricesQueryFunction } from "../../hooks"
import { commonsQueryKeys } from "../../query-keys"
import { type LaboratoryComboTest, type LaboratoryTest, PRODUCT_CONFIGURATION_BILLING_TYPE } from "../../types"
import { buildBulkProductConfigurationsToRequest, getProductKey } from "../../utils"

const useLaboratoryOrderCombos = (
  organizationId: string,
  catalogAuthorId: string,
  gender?: string,
  billingType?: BILLING_TYPES_CODES,
) => {
  const { search } = useClient()
  const queryKey = commonsQueryKeys.comboTests(organizationId, catalogAuthorId)

  const fetchProductPrices = useProductPricesQueryFunction()

  const { data, isLoading, isError, error } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _query: "laboratory-combo-tests",
        catalogAuthor: catalogAuthorId,
        organization: organizationId,
        ...(gender ? { gender } : undefined),
        _count: "50",
      })

      const bundle = await search({ endpoint: "PlanDefinition", filters, signal })

      const planDefinitions = getResources<PlanDefinition>(bundle, "PlanDefinition")
      const compositions = getResources<Composition>(bundle, "Composition")
      const questionnaires = getResources<Questionnaire>(bundle, "Questionnaire")

      const codes = convertIdentifiersToCodings(planDefinitions)
      const productPrices = await fetchProductPrices({
        organizationId,
        productsConfigurations: buildBulkProductConfigurationsToRequest({
          codes,
          billingType: PRODUCT_CONFIGURATION_BILLING_TYPE.BOTH,
        }),
      })

      return {
        planDefinitions,
        compositions,
        productPrices,
        questionnaires,
        total: planDefinitions?.length ?? 0,
      }
    },
    enabled: !!catalogAuthorId,
    meta: { context: { queryKey, catalogAuthorId } },
  })

  const laboratoryCombos = useMemo(() => {
    const combos = data?.planDefinitions.filter((pd) => pd.type?.coding?.[0].code === "combo")

    const indexedQuestionnaires = data?.questionnaires?.reduce<Record<string, Questionnaire>>((acc, cur) => {
      return { ...acc, [`${cur?.url}|${cur.version}`]: cur }
    }, {})

    const indexedPlanDefinitions = data?.planDefinitions
      .filter((pd) => pd.type?.coding?.[0].code === "panel")
      .reduce<Record<string, PlanDefinition>>((acc, pd) => {
        return { ...acc, [`${pd.url}|${pd.version}`]: pd }
      }, {})

    const labCombos = combos?.reduce((acc, combo) => {
      const comboCodes = convertIdentifiersToCodings(combo ? [combo] : [])
      const productKey = getProductKey({
        code: comboCodes,
        billingType,
      })
      const comboCid = data?.productPrices?.[productKey]

      const comboPds = combo.action
        ?.filter(({ definition }) => !!definition?.canonical)
        .map((acction) => indexedPlanDefinitions?.[acction.definition?.canonical as string])

      const comboTestData =
        comboPds?.map((pd) => {
          const questionnaireActions = pd?.action?.filter((def) => def.definition?.canonical?.includes("Questionnaire"))
          const pdQuestionnaires = questionnaireActions?.flatMap((qa) =>
            indexedQuestionnaires?.[qa.definition?.canonical as string]
              ? indexedQuestionnaires?.[qa.definition?.canonical as string]
              : [],
          )

          return {
            planDefinition: pd,
            display: pd?.title ?? pd?.name ?? "Unknown",
            questionnaires: pdQuestionnaires,
          } as LaboratoryTest
        }) ?? []

      const newCombo: LaboratoryComboTest = {
        combo,
        price: getBasePrice(comboCid?.propertyGroup?.[0].priceComponent)?.amount as Money,
        laboratoryTests: comboTestData,
      }

      return [...acc, newCombo]
    }, new Array<LaboratoryComboTest>())

    const { withPromo, sortedOnes } = mergeSort(
      labCombos ?? [],
      "price",
      (a: Money | undefined, b: Money | undefined) => (a?.value ?? 0) - (b?.value ?? 0),
    ).reduce(
      (acc, labCombo) => {
        const isPromoCombo = labCombo.combo?.topic?.some((topic) => topic.coding?.[0]?.code === COMBO_PROMO_CODE)
        if (isPromoCombo) {
          acc.withPromo.push(labCombo)
        } else {
          acc.sortedOnes.push(labCombo)
        }
        return acc
      },
      { withPromo: [] as LaboratoryComboTest[], sortedOnes: [] as LaboratoryComboTest[] },
    )

    return [...sortedOnes, ...withPromo]
  }, [data, billingType])

  return { laboratoryCombos, isLoading, isError, error }
}

export { useLaboratoryOrderCombos }
