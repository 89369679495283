import { faCalendarCheck, faCalendarDays, faPencil, faTasks, faUser } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons"
import { codeableConceptAsString } from "fhir"

import { type BadgeProps, type StackedListItemProps, MenuStyles } from "commons"

import type { TaskData } from "../types"
import { getTaskDate, taskDateToString } from "../utils"

const taskModelBuilder = ({
  task,
  onShowTask,
  onEditTask,
  onCompleteTask,
  isCompletingTask,
}: {
  task: TaskData
  onShowTask: () => void
  onEditTask?: () => void
  onCompleteTask?: () => void
  isCompletingTask?: boolean
}): StackedListItemProps => {
  const { code, status, asignedTo, dependsOn } = task

  const getBadgeProps = (): BadgeProps => ({
    text: status,
    colorStyle:
      status === "completed"
        ? "green"
        : status === "ready"
          ? "blue"
          : status === "on-hold"
            ? "yellow"
            : ["entered-in-error", "cancelled"].includes(status)
              ? "red"
              : "gray",
  })

  return {
    leftData: [
      { lineItems: [{ name: "Code", value: codeableConceptAsString(code) }] },
      {
        lineItems: [
          { name: "Assigned to", value: asignedTo.name, icon: faUser },
          { name: "Authored on", value: taskDateToString(task.authoredOn as string), icon: faCalendarDays },
          { name: "Scheduled for", value: getTaskDate(task), icon: faCalendarCheck },
          ...(dependsOn ? [{ name: "Depends on", value: `${dependsOn.length}`, icon: faTasks }] : []),
        ],
      },
    ],
    badge: getBadgeProps(),
    menu: [
      ...(onEditTask && code?.coding?.[0].code === "organizational"
        ? [
            {
              label: "Edit task",
              command: onEditTask,
              icon: <FontAwesomeIcon icon={faPencil} size="sm" className="pr-2" />,
            },
          ]
        : []),
      ...(onCompleteTask && status !== "completed" && code?.coding?.[0].code === "organizational"
        ? [
            {
              label: "Complete task",
              command: onCompleteTask,
              icon: <FontAwesomeIcon icon={faCheckCircle} size="sm" className="pr-2" />,
              loading: isCompletingTask,
            },
          ]
        : []),
    ],
    menuStyle: onEditTask ? MenuStyles.Dropdown : undefined,
    onClick: onShowTask,
  }
}

export { taskModelBuilder }
