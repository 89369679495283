import { faBarcode, faHouseBuilding, faInfoCircle } from "@fortawesome/pro-regular-svg-icons"
import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type Composition, type Duration, type MedicationKnowledge, type Quantity, codeableConceptAsString } from "fhir"
import cloneDeep from "lodash/cloneDeep"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Tooltip } from "primereact/tooltip"
import { classNames } from "primereact/utils"
import { type FC, useId, useMemo } from "react"

import { InfiniteScroll, SkeletonLoader, useCrudReducer } from "commons"
import { commonsDispenseInterval } from "commons/meds"
import { MEDICATION_CATALOG, MED_FEE_TYPE } from "data"
import { useOrganizationContext } from "organization"
import { getCommonCode, sumPrice } from "utils"

import { useMksByCategory } from "../../hooks"
import {
  type MedItem,
  type MedicationsAdvanceFilter,
  type ReferencePriceItem,
  MEDICATIONS_SECTION_TYPE,
} from "../../types"
import { actionsBodyTemplate } from "../../utils/templates"
import { MedicationFormContainer } from "./MedicationFormContainer"

const MedicationsRxs: FC<Props> = ({ filters, isLoadingCatalogs, catalogsById }) => {
  const { currentOrganizationId } = useOrganizationContext()

  const { medsWithCID, isLoading, hasNextPage, fetchNextPage } = useMksByCategory({
    organizationId: currentOrganizationId,
    category: MEDICATION_CATALOG.RX,
    filters,
    enabled: !isLoadingCatalogs,
    specifyReferencePriceQuantities: true,
  })

  const {
    edit,
    showSlide: showForm,
    reset,
    initialValue: med,
  } = useCrudReducer({ defaultEntity: undefined as MedItem | undefined })

  const medDatas = useMemo(
    () =>
      medsWithCID.map((med) => {
        const feeType = med.fee[0]?.duration ? MED_FEE_TYPE.ByFrequency : MED_FEE_TYPE.Fixed
        const isFixedFee = feeType === MED_FEE_TYPE.Fixed
        const { num1: price, num2: fee, sum: total } = sumPrice(med.practicePrice, med.fee[0]?.value)
        const { mk } = med
        const sku = getCommonCode({ codes: mk.code?.coding })
        const display = codeableConceptAsString(mk.code)
        const catalogId = mk.catalogHeader?.[0]?.id ?? ""
        const catalog = catalogsById[catalogId]?.title
        const referencePrices = med.referencePrices

        return {
          medData: { display, sku, catalog, mk, referencePrices },
          inventory: med.inventory?.qty?.value,
          price: price.toNumber(),
          fee: isFixedFee ? [{ value: fee }] : med.fee,
          total: isFixedFee ? total.toFixed(2) : "-",
          referencePrices: med.referencePrices,
          externalAction: [
            {
              label: "Edit",
              icon: <FontAwesomeIcon icon={faEdit} size="sm" />,
              command: () => {
                edit(
                  cloneDeep({
                    ...med,
                    catalog,
                    display,
                    sku,
                    fee: med.fee,
                    feeType,
                  }),
                )
              },
            },
          ],
        }
      }),
    [medsWithCID],
  )

  const medDetailsBodyTemplate = ({
    medData,
  }: {
    medData: {
      display: string
      catalog: string
      sku: string
      mk: MedicationKnowledge
      referencePrices: ReferencePriceItem[]
    }
  }) => {
    const strength = medData.mk?.ingredient?.[0]?.strength?.numerator?.unit
    const packaging = medData.mk?.packaging?.type?.coding?.[0]?.display
    const doseForm = codeableConceptAsString(medData.mk.doseForm)

    const header = (
      <>
        {medData.catalog && (
          <p
            title="Medication"
            className="mr-2 flex items-baseline gap-2 font-medium truncate max-w-sm lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl 3xl:max-w-full"
          >
            <span className="min-w-0 truncate" title={medData.display}>
              {medData.display}
            </span>
            {strength && (
              <span className="min-w-0 text-gray-500 truncate" title={strength}>
                {strength}
              </span>
            )}
          </p>
        )}
      </>
    )

    const details = (
      <div className="mt-2 flex">
        <div className="flex items-center text-xs text-gray-400 divide-x divide-gray-400 gap-2 space-around">
          {medData.catalog && (
            <span title="catalog" className="flex items-center">
              <FontAwesomeIcon icon={faHouseBuilding} className="mr-1.5 text-gray-400" />
              {medData.catalog}
            </span>
          )}
          {medData.sku && (
            <span title="code" className="flex items-center">
              <FontAwesomeIcon icon={faBarcode} className="mr-1.5 ml-1.5 text-gray-400" />
              {medData.sku}
            </span>
          )}
          {medData?.mk?.doseForm?.coding?.[0]?.display && (
            <span title="form" className="flex items-center pl-1.5">
              {`Dose form: ${medData?.mk?.doseForm?.coding?.[0]?.display}`}
            </span>
          )}
          {medData.mk?.packaging?.type?.coding?.[0]?.display && medData.mk?.packaging?.quantity?.value && (
            <span title="packaging" className="flex items-center pl-1.5">
              {`Pkg: ${medData.mk?.packaging?.type?.coding?.[0]?.display}/${medData?.mk?.packaging?.quantity?.value}`}
            </span>
          )}
        </div>
      </div>
    )

    const referencePrices = !medData.referencePrices.length ? null : (
      <div className="mt-2 flex gap-1 text-xs text-gray-400">
        {`${packaging} Price:`}
        <div className="flex items-center divide-x divide-gray-400 gap-2 space-around">
          {medData.referencePrices.map(({ qty, price }, index) => (
            <div key={qty} className={classNames({ "pl-2": !!index })}>
              {[`${qty} ${doseForm}`, `$${price?.toFixed(2)}`].join(" - ")}
            </div>
          ))}
        </div>
      </div>
    )

    return (
      <span className="block">
        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="flex items-center w-full">
            <div className="flex">
              <div className="truncate">
                <div className="flex text-sm">{header}</div>
                {details}
                {referencePrices}
              </div>
            </div>
          </div>
        </div>
      </span>
    )
  }

  const medFeeBodyTemplate = ({
    medData: { sku },
    fee,
  }: {
    medData: {
      display: string
      catalog: string
      sku: string
      mk: MedicationKnowledge
      referencePrices: ReferencePriceItem[]
    }
    fee: { value: number; duration?: Duration | Quantity }[]
  }) => {
    const showCaseFee = fee[0]
    const isByFrequencyFee = !!showCaseFee?.duration

    const displayByFrequencyFeeItem = (fee: { value: number; duration?: Duration | Quantity }) =>
      `${commonsDispenseInterval.find((interval) => interval.value.value === fee.duration?.value)?.label} : $${fee?.value?.toFixed(2)}`

    return (
      <div className="flex items-center">
        <div className="px-2">
          {isByFrequencyFee ? displayByFrequencyFeeItem(showCaseFee) : `$${showCaseFee?.value.toFixed(2)}`}
        </div>
        {isByFrequencyFee && (
          <>
            <FontAwesomeIcon icon={faInfoCircle} className={classNames("fa-fw w-4", `fee-info-icon-${sku}`)} />
            <Tooltip
              className="text-xs p-0 m-0"
              target={`.fee-info-icon-${sku}`}
              position="top"
              event="hover"
              autoHide={false}
            >
              {fee.map((fee, index) => (
                <div key={index} className={classNames("pl-2", { "pt-2": !!index })}>
                  {displayByFrequencyFeeItem(fee)}
                </div>
              ))}
            </Tooltip>
          </>
        )}
      </div>
    )
  }

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <>
      <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
        <DataTable
          value={medDatas}
          loading={isLoading || isLoadingCatalogs}
          scrollable
          scrollHeight="calc(100vh - 300px)"
        >
          <Column field="medData" body={medDetailsBodyTemplate} rowSpan={6} headerClassName="bg-transparent" />
          <Column field="inventory" header="Inventory" bodyClassName="text-xs" headerClassName="default-header" />
          <Column field="price" header="Price" bodyClassName="text-xs" headerClassName="default-header" />
          <Column
            field="fee"
            header="Fee"
            body={medFeeBodyTemplate}
            bodyClassName="text-xs"
            headerClassName="default-header"
          />
          <Column field="total" header="Total" bodyClassName="text-xs" headerClassName="default-header" />
          <Column field="external_action" headerClassName="bg-transparent" body={actionsBodyTemplate} />
        </DataTable>
      </InfiniteScroll>
      {showForm && (
        <MedicationFormContainer med={med} medType={MEDICATIONS_SECTION_TYPE.MEDICATIONS_RX_SECTION} onHide={reset} />
      )}
    </>
  )
}

type Props = {
  filters: MedicationsAdvanceFilter & { searchText?: string }
  catalogsById: Record<string, Composition>
  filter(filters: MedicationsAdvanceFilter): void
  isLoadingCatalogs?: boolean
}

export { MedicationsRxs }
