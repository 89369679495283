import { organizationSettingId } from "data"

import type { ProductConfigurationToRequest } from "./types"

//uses the names of the functions there are been use to keep it `commons`
const commonsQueryKeys = {
  laboratoryTests: (
    organizationID: string,
    catalogAuthorID?: string,
    searchText?: string,
    count?: number,
    canonicals?: string[],
    withPrice?: boolean,
  ) => ["laboratory/tests", organizationID, catalogAuthorID, searchText, count, canonicals, withPrice],
  bloodDrawnTests: (organizationID: string, billingType?: string) => [
    "laboratory/blood-drawn-tests",
    organizationID,
    billingType,
  ],
  productPrices: (organizationId: string, productsConfigurations: ProductConfigurationToRequest[]) => [
    "organization/product-prices",
    organizationId,
    productsConfigurations,
  ],
  defaultOrgData: (organizationID: string) => [organizationSettingId.defaultOrgdata, organizationID],
  signed: ["signedUrl"] as const,
  signedWithUrl: (url: string) => [...commonsQueryKeys.signed, url],
  qDataByCanonical: (patientId: string, canonicals?: string[], status?: string[]) => [
    "questionnaire/data",
    patientId,
    ...(canonicals?.length ? [...canonicals] : []),
    ...(status?.length ? [...status] : []),
  ],
  comboTests: (organizationId: string, catalogAuthorId: string, billingType?: string) => [
    "laboratory/combotests",
    organizationId,
    catalogAuthorId,
    billingType,
  ],
  snippetNotes: (authorId: string) => ["snippetNotes", authorId],
  mentionsSuggestions: (authorsStringified: string, searchQuery?: string) => [
    "mentionsSuggestions",
    authorsStringified,
    ...(searchQuery ? [searchQuery] : []),
  ],
}

export { commonsQueryKeys }
/*  */
