import { useInfiniteQuery } from "@tanstack/react-query"
import { type Invoice, type PaymentReconciliation, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { invoicesQueryKeys } from "../query-keys"

const useInvoices = ({ patientId, statusFilter = [], searchText, key = "WK" }: InvoicesArgs) => {
  const { search } = useClient()

  const queryKey = invoicesQueryKeys.list(patientId, key, statusFilter, searchText)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery<
    InvoicesQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ signal, pageParam = 1 }) => {
      const filters = new URLSearchParams({
        ...(searchText ? { "identifier:text": searchText } : {}),
        ...(statusFilter.length > 0 ? { status: statusFilter.join(",") } : {}),
        _revinclude: "PaymentReconciliation:r5-request:Invoice",
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-date",
      })
      const bundle = await search({ endpoint: `Patient/${patientId}/Invoice`, filters, signal })
      const invoices = getResources(bundle, "Invoice") as Invoice[]
      const paymentReconciliations = getResources(bundle, "PaymentReconciliation") as PaymentReconciliation[]

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { invoices, paymentReconciliations, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })
  const { invoiceData, count } = useMemo(() => {
    if (!data?.pages) {
      return { invoiceData: [], count: 0 }
    }

    const newInvoices = data.pages.flatMap((page) => page.invoices)
    const newPaymentReconciliations = data.pages.flatMap((page) => page.paymentReconciliations)

    const payRecsByInvoiceId = new Map()
    newPaymentReconciliations.forEach((payRec) => {
      const invoiceId = payRec.R5_request?.id
      if (invoiceId) {
        const existing = payRecsByInvoiceId.get(invoiceId) || []
        existing.push(payRec)
        payRecsByInvoiceId.set(invoiceId, existing)
      }
    })

    const invoiceData = newInvoices.map((invoice) => ({
      invoice,
      payRecs: (payRecsByInvoiceId.get(invoice.id) || []) as PaymentReconciliation[],
    }))

    return {
      invoiceData,
      count: newInvoices.length,
    }
  }, [data?.pages])

  return {
    invoiceData,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    reloadInvoices: refetch,
  }
}

interface InvoicesArgs {
  patientId: string
  statusFilter?: string[]
  searchText?: string
  key?: string
}

type InvoicesQueryData = {
  invoices: Invoice[]
  paymentReconciliations: PaymentReconciliation[]
  next: number | undefined
  total: number
}

export { useInvoices }
