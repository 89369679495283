import { type QueryKey, useMutation, useQueryClient } from "@tanstack/react-query"
import type { MedicationRequest } from "fhir"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import type { CustomError } from "../types"

const useUpdateMedicationRequest = (onSettled: () => void, queryKey: QueryKey) => {
  const { patch } = useClient()
  const queryClient = useQueryClient()

  const patchMedicationRequest = async (mr: Partial<MedicationRequest>) =>
    patch("MedicationRequest", mr.id as string, mr)

  const { mutate: updateMedicationRequest, isPending: isUpdating } = useMutation({
    mutationFn: patchMedicationRequest,
    onError: async (error: CustomError, context) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        queryClient.refetchQueries({ queryKey })
      }
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey,
      })
      displayNotificationSuccess("Update applied successfully!")
    },
    onSettled: onSettled,
  })

  return { updateMedicationRequest, isUpdating }
}

export { useUpdateMedicationRequest }
