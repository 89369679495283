import { faExclamationTriangle, faTrashCan } from "@fortawesome/pro-regular-svg-icons"
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type MedicationKnowledge, codeableConceptAsString } from "fhir"
import { type FieldProps, type FormikProps, ErrorMessage, Field, Form, Formik } from "formik"
import isEqual from "lodash/isEqual"
import { Button } from "primereact/button"
import { Checkbox } from "primereact/checkbox"
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { Tooltip } from "primereact/tooltip"
import { classNames } from "primereact/utils"
import { type ReactHTML, useEffect, useState } from "react"

import { ModulesId, Notes, NotesUseContext } from "commons"
import {
  type MedicationRequestData,
  type MedicationRequestInfo,
  DfoCheckbox,
  MedicationKnowledgeImage,
  MedicationRequestItem,
  allDispenseInterval,
  medsQueryKeys,
  treatmentFrequencies,
} from "commons/meds"
import { useAppModuleContext } from "internals"
import { getMoneyCurrencyAlt } from "utils"

import { getDraftValues, getRecommendedUnitsForTreatment } from "../validations"
import { MedicationRequestUnitsDropdown } from "./MedicationRequestUnitsDropdown"

const MedicationRequestCardForm = ({
  medicationData: { medicationRequestInfo, medicationKnowledge, patientPrice },
  isDeleting,
  isSaving,
  onSave,
  onDelete,
  onSelectMK,
  className,
  customTag,
  collapsibleForm = false,
  variant = "default",
}: Props) => {
  const { isModuleActive } = useAppModuleContext()
  const [isTyping, setIsTyping] = useState(false)
  const [showCollapsed, setShowCollapsed] = useState(collapsibleForm)
  const toogleCollapse = () => setShowCollapsed((state) => !state)

  const Tag = customTag ?? Form

  const MRForm = ({ setFieldValue, initialValues, values, submitForm }: FormikProps<MedicationRequestInfo>) => {
    const defaultAutoship =
      initialValues?.dispenseRequest?.dispenseInterval?.unit !== allDispenseInterval[0].value.unit ||
      initialValues?.dispenseRequest?.dispenseInterval?.value !== allDispenseInterval[0].value.value

    const recommendedDispenseUnits = getRecommendedUnitsForTreatment(
      medicationKnowledge?.amount?.value ?? 1,
      medicationRequestInfo.dosageInstruction?.[0],
      medicationRequestInfo.dispenseRequest?.dispenseInterval,
    )
    const orientedDispenseUnits = medicationRequestInfo.dispenseRequest?.quantity?.value ?? 1
    const showUnitsWarning = recommendedDispenseUnits > orientedDispenseUnits

    const prescriptionWarnMessage = `The specified units of ${codeableConceptAsString(
      medicationRequestInfo.medication?.CodeableConcept,
    )} are not enough to comply with the targeted treatment. Consider increasing the units to ${recommendedDispenseUnits}.`

    const [autoship, setAutoship] = useState(defaultAutoship)

    useEffect(() => {
      if (!isEqual(initialValues, values) && !isTyping) {
        submitForm()
      }
    }, [values, submitForm, isTyping])

    const onKeyDown = () => {
      setIsTyping(true)
    }

    const onBlur = () => {
      setIsTyping(false)
    }

    const updateAutoship = (state: boolean) => {
      if (!state) {
        setFieldValue("dispenseRequest.numberOfRepeatsAllowed", 0)
        setFieldValue("dispenseRequest.dispenseInterval", allDispenseInterval[0].value)
      } else {
        setFieldValue("dispenseRequest.numberOfRepeatsAllowed", undefined)
        setFieldValue(
          "dispenseRequest.dispenseInterval",
          defaultAutoship ? initialValues?.dispenseRequest?.dispenseInterval : allDispenseInterval[1].value,
        )
      }
      setAutoship(state)
    }

    const showPrescriptionWarning = (target: HTMLElement) => {
      confirmPopup({
        target,
        message: prescriptionWarnMessage,
        icon: "pi pi-exclamation-triangle",
        acceptClassName: "button-primary",
        rejectClassName: "button-default",
        style: { width: "25vw" },
        accept: () => setFieldValue("dispenseRequest.initialFill.quantity.value", recommendedDispenseUnits),
      })
    }

    const locked = isSaving || isDeleting

    return (
      <Tag
        className={classNames(
          "flex flex-col relative p-3",
          { "gap-y-6": variant === "simple" },
          className,
          medicationRequestInfo.id,
        )}
      >
        <section className="inline-flex w-full">
          <div
            role="button"
            className="cursor-pointer rounded-md flex items-center justify-center border border-solid border-gray-200 w-16 h-16"
            onClick={() => {
              onSelectMK(medicationKnowledge)
            }}
          >
            <MedicationKnowledgeImage
              drugCharacteristic={medicationKnowledge?.drugCharacteristic}
              className="rounded-lg w-16 h-16"
              fallbackIconSize="3x"
            />
          </div>
          <div className="product-list-item grid grid-cols-2 gap-3 w-full">
            <div className="flex w-full">
              <MedicationRequestItem
                medicationKnowledge={medicationKnowledge ?? {}}
                medicationRequest={medicationRequestInfo}
                className="ml-2 xl:ml-3 flex-1"
              />
            </div>

            <div className={classNames("flex flex-col w-full", { "items-end": showCollapsed })}>
              {collapsibleForm && (
                <span className="flex items-center space-x-3 self-end">
                  {showCollapsed && onDelete && (
                    <Button
                      type="button"
                      icon={<FontAwesomeIcon icon={faTrashCan} size="1x" />}
                      className="p-button-icon text-gray-400 hover:bg-slate-50 bg-transparent border-none ring-0 p-button-sm py-1 px-2 rounded-full w-max justify-end"
                      loading={isSaving || isDeleting}
                      disabled={locked}
                      onClick={() => {
                        onDelete(medicationRequestInfo?.id as string)
                      }}
                    />
                  )}
                  <FontAwesomeIcon
                    icon={showCollapsed ? faChevronDown : faChevronUp}
                    size="sm"
                    onClick={toogleCollapse}
                    className={classNames("cursor-pointer", {
                      "text-gray-400": variant === "simple",
                    })}
                  />
                </span>
              )}
              <div
                className={classNames("flex flex-wrap w-full gap-2 @lg:gap-3", {
                  hidden: showCollapsed,
                })}
              >
                <div className="flex flex-nowrap items-center flex-1">
                  <MedicationRequestUnitsDropdown
                    field="dispenseRequest.initialFill.quantity.value"
                    optionUnits={Array.from({ length: 20 }, (_, i) => i + 1)}
                    unitName={medicationRequestInfo.dispenseRequest?.quantity?.unit}
                    disabled={locked}
                  />
                  {showUnitsWarning && (
                    <>
                      <span
                        id={`warning${medicationRequestInfo.id}`}
                        className={classNames({ "p-disabled": locked })}
                        data-pr-tooltip="Warning: Units are not enough to comply with the targeted treatment. Click here to take action."
                        onClick={(e) => {
                          e.stopPropagation()
                          !isSaving && !isDeleting && showPrescriptionWarning(e.currentTarget)
                        }}
                      >
                        <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2 cursor-pointer text-lg" />

                        <Tooltip target={`#warning${medicationRequestInfo.id}`} position="bottom" />
                      </span>
                      <ConfirmPopup key={medicationRequestInfo.id} />
                    </>
                  )}
                </div>

                <span className="flex justify-end gap-x-5 items-center">
                  {patientPrice?.value && (
                    <span className="text-gray-700 text-sm">
                      {getMoneyCurrencyAlt(patientPrice.currency)}
                      {patientPrice.value.toFixed(2)}
                    </span>
                  )}

                  {onDelete && (
                    <Button
                      type="button"
                      icon={<FontAwesomeIcon icon={faTrashCan} size="1x" />}
                      className="p-button-icon text-gray-400 hover:bg-slate-50 bg-transparent border-none ring-0 p-button-sm py-1 px-2 rounded-full w-max justify-end"
                      loading={isSaving || isDeleting}
                      disabled={locked}
                      onClick={() => {
                        onDelete(medicationRequestInfo?.id as string)
                      }}
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
        </section>

        <section
          className={classNames("pl-[4.5rem] space-y-6", {
            hidden: showCollapsed,
            "mt-[1.9375rem]": variant === "default",
          })}
        >
          <div className="flex gap-y-6 gap-x-3">
            <div className="flex flex-col w-full">
              <label
                htmlFor="doseQuantity"
                className={classNames("text-sm font-medium mb-2 flex items-center justify-between w-full pb-1", {
                  "text-gray-500": variant === "simple",
                  "text-gray-700": variant === "default",
                })}
              >
                <span>Dosage</span>
                <hr className="flex-grow text-gray-200 ml-1 w-full" />
              </label>
              <div className="p-inputgroup product-dosage flex items-center relative max-w-[75%]">
                <Field
                  title="Units"
                  name="doseQuantity"
                  validate={(value: string) => (/^\d(-\d)?$/.test(value) ? undefined : "Dose quantity is invalid.")}
                >
                  {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
                    <InputText
                      type="text"
                      id={name}
                      name={name}
                      onChange={onChange}
                      value={value}
                      className={classNames("p-inputtext-sm h-10 text-center min-w-8 max-w-min", {
                        "p-invalid": touched && error,
                      })}
                      disabled={locked}
                      onKeyDown={onKeyDown}
                      onBlur={onBlur}
                    />
                  )}
                </Field>
                <ErrorMessage name="doseQuantity">
                  {(msg) => <small className="p-error absolute -bottom-6">{msg}</small>}
                </ErrorMessage>
                <span
                  title={medicationRequestInfo.medicationUnit}
                  className="flex items-center line-clamp-1 text-sm h-9 m-auto capitalize px-2 cursor-default border border-slate-300 flex-[30%]"
                >
                  {medicationRequestInfo.medicationUnit}
                </span>
                <Field title="Frequency" name="treatmentFrequency">
                  {({ field: { name, value, onChange } }: FieldProps) => (
                    <Dropdown
                      options={treatmentFrequencies}
                      optionLabel="label"
                      optionValue="value"
                      id={name}
                      name={name}
                      value={value}
                      onChange={onChange}
                      className="p-inputtext-sm items-center h-10 min-w-fit flex-[40%]"
                      disabled={locked}
                    />
                  )}
                </Field>
                {/* <Field title="Duration" name="dispenseRequest.initialFill.duration">
              {({ field: { name, value, onChange } }: FieldProps) => (
                <Dropdown
                  options={treatmentDurations}
                  optionLabel="label"
                  optionValue="value"
                  id={name}
                  name={name}
                  value={value}
                  onChange={onChange}
                  className="p-inputtext-sm h-10"
                  disabled={isSaving}
                />
              )}
            </Field> */}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <span
              className={classNames("font-medium text-sm w-full flex items-center justify-between pb-1", {
                "!text-gray-500": variant === "simple",
                "!text-gray-700": variant === "default",
              })}
            >
              <span className="flex-shrink-0">Dispense Type</span>
              <hr className="flex-grow text-gray-200 ml-1 w-full" />
            </span>
            <div className="flex flex-1 flex-row items-stretch gap-x-3">
              {isModuleActive(ModulesId.CHECKOUT_INVENTORY) && (
                <DfoCheckbox
                  initialChecked={initialValues?.dispenseRequest?.initialFill?.isDfo ?? false}
                  handleCheck={(val) => setFieldValue("dispenseRequest.initialFill.isDfo", val)}
                  medData={{
                    code: medicationKnowledge?.code?.coding,
                    qty: medicationRequestInfo?.dispenseRequest?.initialFill?.quantity?.value,
                  }}
                  availabilityUnit={medicationRequestInfo?.dispenseRequest?.initialFill?.quantity?.unit}
                  disabled={locked}
                  label="Dispense from office"
                  labelClassName={classNames("text-sm", {
                    "text-gray-500": variant === "simple",
                    "text-gray-700": variant === "default",
                  })}
                  className="flex-1 border p-2 gap-2 rounded-lg justify-between"
                  key={medicationRequestInfo.meta?.versionId}
                />
              )}
              <div className="flex flex-col gap-2 flex-1 border p-2 rounded-lg @4xl:max-w-[49%]">
                <div className="inline-flex items-center gap-2">
                  <Checkbox
                    checked={autoship}
                    trueValue={true}
                    falseValue={false}
                    onChange={(e) => updateAutoship(e.checked ?? false)}
                    disabled={locked}
                  />
                  <span
                    className={classNames("text-sm", {
                      "text-gray-500": variant === "simple",
                      "text-gray-700": variant === "default",
                    })}
                  >
                    Drop Ship
                  </span>
                </div>
                <Field title="Interval" name="dispenseRequest.dispenseInterval">
                  {({ field: { name, value, onChange } }: FieldProps) => (
                    <Dropdown
                      options={autoship ? allDispenseInterval.slice(1) : allDispenseInterval}
                      optionLabel="label"
                      optionValue="value"
                      id={name}
                      name={name}
                      value={value}
                      onChange={onChange}
                      className="p-inputtext-sm items-center w-full h-10"
                      disabled={locked || !autoship}
                    />
                  )}
                </Field>
              </div>
            </div>
          </div>
          <Notes
            notes={values.note}
            className="w-full"
            editProps={{
              parentId: medicationRequestInfo.id as string,
              context: NotesUseContext.NUTRA,
              notShowFullDataNotFound: true,
              queriesToInvalidate: medsQueryKeys.draft.all,
            }}
            label="Additional Instructions"
          />
        </section>
      </Tag>
    )
  }

  return (
    <Formik
      key={medicationRequestInfo.id}
      enableReinitialize
      initialValues={getDraftValues(medicationRequestInfo)}
      onSubmit={onSave}
    >
      {MRForm}
    </Formik>
  )
}

type Props = {
  medicationData: MedicationRequestData
  isDeleting?: boolean
  isSaving?: boolean
  onSave(medInfo: MedicationRequestInfo): void
  onDelete?(medicationRequestId: string): void
  onSelectMK(_?: MedicationKnowledge): void
  className?: string
  imageClassName?: string
  customTag?: keyof ReactHTML
  collapsibleForm?: boolean
  variant?: "default" | "simple"
}

export { MedicationRequestCardForm }
