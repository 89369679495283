import type { Address, MedicationRequest } from "fhir"
import isEqual from "lodash/isEqual"
import { type FC, type ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useProductPrices } from "commons/hooks"
import { useMrOrderDetails } from "commons/meds"
import { getGenericBillingType, getMedsProductConfigurations, getPriceByCode } from "commons/utils"
import { BILLING_TYPES_CODES } from "data"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { useMedicationRequestDataBind } from "../../../hooks"
import { MrOrderContext } from "../../../hooks/useMrOrderContext"
import { type InvoiceData, EDIT_ORDER_STEPS } from "../../../types"

const MrOrderProvider: FC<Props> = ({ children }) => {
  const { patientId, patient } = usePatientContext()
  const { currentOrganizationId, currentOrganization } = useOrganizationContext()

  const [currentStep, setCurrentStep] = useState<EDIT_ORDER_STEPS>(EDIT_ORDER_STEPS.CONFIG)
  const [invoiceData, setInvoiceData] = useState<InvoiceData>()

  const [params] = useSearchParams()
  const orderId = params.get("edit-order")

  const {
    serviceRequest,
    medicationKnowledges,
    medicationRequests,
    medicationDispenses,
    billingTypeCode,
    invoice,
    isLoading,
    isEditable,
  } = useMrOrderDetails(patientId, orderId as string)

  const [tempMedicationRequests, setTempMedicationRequests] = useState(medicationRequests ?? [])
  const [markedToDeleteMeds, setMarkedToDeleteMeds] = useState<string[]>([])
  const medsProductConfigurations = useMemo(
    () => getMedsProductConfigurations({ meds: tempMedicationRequests, specifiedQuantity: true }),
    [tempMedicationRequests],
  )
  const orderShippingAddress = tempMedicationRequests[0]?.dispenseRequest?.shippingAddress

  const editedMedicationRequests = useMemo(() => {
    const edited = new Map<string, MedicationRequest>()
    tempMedicationRequests.forEach((tempMR, index) => {
      const { nextRefillDate: _a, ...tempDispenseRequest } = tempMR.dispenseRequest ?? {}
      const tempMRWithoutRefillDate: MedicationRequest = {
        ...tempMR,
        dispenseRequest: tempDispenseRequest,
      }
      const { nextRefillDate: _b, ...dispenseRequest } = medicationRequests?.[index].dispenseRequest ?? {}
      const mrWithoutRefillDate: MedicationRequest = {
        ...(medicationRequests?.[index] ?? ({} as MedicationRequest)),
        dispenseRequest,
      }

      if (!isEqual(tempMRWithoutRefillDate, mrWithoutRefillDate) || markedToDeleteMeds.includes(tempMR.id as string)) {
        edited.set(tempMR.id!, tempMR)
      }
    })
    return edited
  }, [medicationRequests, tempMedicationRequests, markedToDeleteMeds])

  useEffect(() => {
    setTempMedicationRequests(medicationRequests ?? [])
    setMarkedToDeleteMeds([])
  }, [medicationRequests])

  const { productPrices } = useProductPrices({
    organizationId: currentOrganizationId,
    productsConfigurations: medsProductConfigurations,
  })

  const { medicationRequestData } = useMedicationRequestDataBind({
    medicationRequests: tempMedicationRequests,
    medicationKnowledges,
    medicationsCIDs: productPrices,
    medicationDispenses,
  })

  const medicationRequestDataWithPrice = medicationRequestData.map((item) => ({
    ...item,
    [billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT ? "patientPrice" : "practicePrice"]: getPriceByCode({
      productPrices,
      medCoding: item.medicationKnowledge?.code?.coding,
      quantity: item.medicationRequestInfo?.dispenseRequest?.quantity?.value,
      billingType: getGenericBillingType(billingTypeCode ?? BILLING_TYPES_CODES.BILL_PRACTICE),
    }),
  }))

  const updateMRWithOrderAddress = (mr: MedicationRequest, orderAddress?: Address) => ({
    ...mr,
    dispenseRequest: { ...mr.dispenseRequest, shippingAddress: orderAddress },
  })

  const updateMedicationRequest = (updatedMR: MedicationRequest) => {
    setTempMedicationRequests((tempMRs) => tempMRs.map((tempMR) => (tempMR.id === updatedMR.id ? updatedMR : tempMR)))
  }

  const moveStep = useCallback(
    (arg?: { previous: boolean; invoiceData?: InvoiceData }) => {
      switch (currentStep) {
        case EDIT_ORDER_STEPS.CONFIG:
          if (!arg?.previous) setCurrentStep(EDIT_ORDER_STEPS.PREVIEW)

          break
        case EDIT_ORDER_STEPS.PREVIEW:
          if (arg?.previous) setCurrentStep(EDIT_ORDER_STEPS.CONFIG)
          break
      }

      setInvoiceData(arg?.invoiceData)
    },
    [currentStep],
  )

  const updateOrderShippingAddress = (newAddress: Address) => {
    setTempMedicationRequests((prevTempRequests) =>
      prevTempRequests.map((request) => updateMRWithOrderAddress(request, newAddress)),
    )
  }

  const handleDeleteMed = (mrId: string) => {
    setMarkedToDeleteMeds((toDelete) => {
      if (!toDelete.includes(mrId)) return [...toDelete, mrId]
      return toDelete
    })
  }

  const handleRestoreMed = (mrId: string) => {
    setMarkedToDeleteMeds((toDelete) => {
      const index = toDelete.findIndex((id) => id === mrId)
      if (index !== -1) return toDelete.toSpliced(index, 1)
      return toDelete
    })
  }

  return (
    <MrOrderContext.Provider
      value={{
        serviceRequest,
        medicationRequestData,
        invoice,
        isLoading,
        editedMedicationRequests,
        updateMedicationRequest,
        currentStep,
        moveStep,
        medicationRequestDataWithPrice,
        invoiceData,
        patient,
        currentOrganization,
        isEditable,
        orderShippingAddress,
        updateOrderShippingAddress,
        markedToDeleteMeds,
        handleDeleteMed,
        handleRestoreMed,
      }}
    >
      {children(currentStep)}
    </MrOrderContext.Provider>
  )
}

type Props = {
  children(_: EDIT_ORDER_STEPS): ReactNode
}

export { MrOrderContext, MrOrderProvider }
