import { useQuery } from "@tanstack/react-query"
import { parseISO } from "date-fns"
import {
  type DiagnosticReport,
  type Observation,
  type ServiceRequest,
  getResources,
  getResourcesByTypeAsIndex,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { formatsByTypes } from "data"
import { formatDate, getLabOrderIdentifier } from "utils"

import { encounterQueryKeys } from "../query-keys"

const useLabObservations = (patientId: string, encounterId: string) => {
  const { search } = useClient()
  const queryKey = encounterQueryKeys.labResultsNotes(patientId, encounterId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _category: "laboratory",
        subject: patientId,
        encounter: encounterId,
        status: "final",
        _count: "1000",
        _revinclude: "DiagnosticReport:result:Observation",
        _include: "DiagnosticReport:based-on:ServiceRequest",
        _sort: "-date",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Observation`, filters, signal })

      const drs = getResources<DiagnosticReport>(bundle, "DiagnosticReport")
      const observations = getResources<Observation>(bundle, "Observation")
      const orders = getResourcesByTypeAsIndex<ServiceRequest>(bundle, "ServiceRequest")

      return { diagnosticReports: drs, observations, orders, total: bundle?.total ?? 0 }
    },
    meta: { context: { queryKey, patientId } },
  })

  const { observations, count } = useMemo(() => {
    let currentOrder: ServiceRequest | undefined
    let observationDr: DiagnosticReport | undefined

    const drs = (data?.diagnosticReports ?? []).filter(({ code }) => code.coding?.[0]?.code !== "entered")
    const orders = data?.orders ?? {}

    const observationsWithNotes = [...(data?.observations ?? [])]
      .sort((a, b) => ((a.effective?.dateTime ?? 0) < (b.effective?.dateTime ?? 0) ? 1 : -1))
      .reduce<Record<string, { observations: Observation[]; order?: ServiceRequest }>>((acc, cur) => {
        observationDr = drs?.find(({ result }) => result?.some(({ id }) => id === cur.id))
        currentOrder =
          currentOrder && currentOrder.id === observationDr?.basedOn?.[0]?.id
            ? currentOrder
            : orders?.[observationDr?.basedOn?.[0]?.id as string]

        const date = currentOrder?.authoredOn && formatDate(parseISO(currentOrder.authoredOn), formatsByTypes.LONG_DATE)
        const labOrderIdentifier = currentOrder ? getLabOrderIdentifier(currentOrder, []) : "Unspecified"
        const groupKey = `${currentOrder?.performer?.[0]?.display} (${labOrderIdentifier}) - ${date}`
        const group = acc[groupKey]

        if (cur.value?.Quantity && date) {
          return {
            ...acc,
            [groupKey]: { observations: [...(group?.observations ?? []), cur], order: currentOrder },
          }
        }

        return acc
      }, {})

    return {
      observations: observationsWithNotes,
      count: data?.observations.length,
    }
  }, [data?.observations, data?.diagnosticReports, data?.orders])

  return {
    observations: Array.from(Object.entries(observations)).map(([groupKey, items]) => ({
      key: groupKey,
      name: groupKey,
      items: items.observations,
      order: items.order,
    })),
    isLoading,
    count,
    total: data?.total ?? 0,
  }
}

export { useLabObservations }
