import { type Composition, type Reference } from "fhir"
import * as Yup from "yup"

const getSnippetInitialValues = (authorReference: Reference): Composition => ({
  author: [authorReference],
  resourceType: "Composition",
  date: "",
  status: "final",
  title: "Note",
  r5_name: "",
  type: {
    text: "Snippet notes",
    coding: [
      {
        code: "snippets-note",
        display: "Snippets notes",
        system: "http://chartedhealth.com/fhir/composition-type",
      },
    ],
  },
  r5_note: {
    text: "",
  },
})

const sanitize = ({ ...composition }: Composition) => {
  const currentDate = new Date().toISOString()

  composition.date = currentDate

  return composition
}

const assessmentValidationSchema = Yup.object().shape({
  name: Yup.string().required("Key is required"),
  note: Yup.object().shape({
    text: Yup.string().required("Note is required"),
  }),
})

export { assessmentValidationSchema, getSnippetInitialValues, sanitize }
