import { useMemo, type FC } from "react"
import { humanNameAsString, type Practitioner } from "fhir"

import { DropdownField } from "commons"
import { useOrganizationContext } from "organization"
import { useFormikContext } from "formik"

const AssignPractitionerForm: FC = () => {
  const {
    values: { practitionerId },
  } = useFormikContext<{ practitionerId: string | undefined }>()
  const { organizationPractitionersStaffInfo: practitionersStaffs, loggedInPractitionerRoleRef } =
    useOrganizationContext()
  const practitionersStaffsListWithoutCurrentLoggedIn = useMemo(
    () =>
      practitionersStaffs.filter(
        (practitioner) => practitioner.practitionerRoleRef?.id !== loggedInPractitionerRoleRef?.id,
      ),
    [loggedInPractitionerRoleRef, practitionersStaffs],
  )
  const itemPRTemplate = (option: { practitioner: Practitioner }) => {
    return <>{option?.practitioner.name?.[0]?.text ?? humanNameAsString(option?.practitioner.name?.[0])}</>
  }

  return (
    <>
      <DropdownField
        field="practitionerId"
        label="Practitioners (Optional)"
        options={practitionersStaffsListWithoutCurrentLoggedIn}
        optionLabel="practitioner.name.0.family"
        optionValue="practitioner.id"
        itemTemplate={itemPRTemplate}
        valueTemplate={itemPRTemplate}
      />
      {!practitionerId && (
        <span className="text-sm text-gray-500">
          This task will be available to all practitioners with staff role if none is selected. Do you wish to proceed
          anyway?
        </span>
      )}
    </>
  )
}

export { AssignPractitionerForm }
