import { useQuery } from "@tanstack/react-query"
import { getResources, type Composition, type PlanDefinition } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { BILLING_TYPES_CODES } from "data"
import { convertIdentifiersToCodings, getBasePrice } from "utils"

import { useProductPricesQueryFunction } from "../hooks/useProductPrices"
import { commonsQueryKeys } from "../query-keys"
import { PRODUCT_CONFIGURATION_BILLING_TYPE, type LaboratoryTest } from "../types"
import { buildBulkProductConfigurationsToRequest, getGenericBillingType, getProductKey } from "../utils"
const useBloodDrawnTests = (organizationId: string, testIdentifiers?: string[], billingType?: BILLING_TYPES_CODES) => {
  const { search } = useClient()
  const queryKey = commonsQueryKeys.bloodDrawnTests(organizationId)

  const fetchProductPrices = useProductPricesQueryFunction()

  const { data, isLoading, isFetching } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        ...(testIdentifiers?.length ? { identifier: testIdentifiers.join(", ") } : {}),
        _include: "catalogHeader",
        _count: "20",
      })

      const respBundle = await search({ endpoint: "PlanDefinition", filters, signal })
      const planDefinitions = getResources<PlanDefinition>(respBundle, "PlanDefinition")
      const catalogAuthors = getResources<Composition>(respBundle, "Composition")
      const codes = convertIdentifiersToCodings(planDefinitions)

      const productPrices = await fetchProductPrices({
        organizationId,
        productsConfigurations: buildBulkProductConfigurationsToRequest({
          codes,
          billingType: PRODUCT_CONFIGURATION_BILLING_TYPE.BOTH,
        }),
      })

      return {
        planDefinitions,
        productPrices,
        catalogAuthors,
        total: planDefinitions?.length ?? 0,
      }
    },
    enabled: !!testIdentifiers?.length,
    throwOnError: true,
    meta: { context: { queryKey, testIdentifiers } },
  })

  const bloodDrawnTests = useMemo(() => {
    const genericBillingType = getGenericBillingType(billingType ?? BILLING_TYPES_CODES.BILL_PRACTICE)

    const result = data?.planDefinitions?.reduce(
      (acc, pd) => {
        if (data?.productPrices) {
          const codes = convertIdentifiersToCodings(pd ? [pd] : [])
          const productKey = getProductKey({
            code: codes,
            billingType: genericBillingType,
          })
          const cid = data.productPrices[productKey]

          const newLabTest: LaboratoryTest = {
            planDefinition: pd,
            price: getBasePrice(cid?.propertyGroup?.[0].priceComponent)?.amount,
            display: pd.title ?? pd.name ?? "Unknown",
          }
          const author = data?.catalogAuthors.find(({ id }) => pd.catalogHeader?.[0]?.id === id)?.author?.[0].id

          return {
            ...acc,
            [author as string]: [...(acc[author as string] ?? []), newLabTest],
          }
        }
        return acc
      },
      {} as Record<string, LaboratoryTest[] | undefined>,
    )

    return result
  }, [data, billingType])

  return {
    bloodDrawnTests,
    total: data?.total ?? 0,
    isLoading,
    isFetching,
  }
}

export { useBloodDrawnTests }
