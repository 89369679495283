import { type Encounter, type Reference } from "fhir"
import { ConfirmDialog } from "primereact/confirmdialog"
import { classNames } from "primereact/utils"
import { type FC, useCallback, useState } from "react"

import {
  type AppointmentFormData,
  AppointmentForm,
  INITIAL_VALUES,
  appointmentValidationSchema,
  initialValues,
  sanitize,
  useCreateAppointment,
} from "appointments"
import { type ActionProps, DialogFormContainer, SplitButton } from "commons"
import { useOrganizationContext } from "organization"

import { useAssignCheckoutTask, useFinishEncounter } from "../hooks"
import { AssignPractitionerForm } from "./AssignPractitionerForm"

const EncounterFinishButton: FC<Props> = ({
  encounter,
  patientId,
  patientRef,
  onEncounterFinished,
  showStopAction,
  buttonClassName,
  onStopClicked,
  showAssignCheckoutAction,
}) => {
  const { loggedInPractitionerRoleRef, location, organizationPractitionersStaffInfo } = useOrganizationContext()

  const [showAppointmentForm, setShowAppointmentForm] = useState(false)
  const [finishConfirmation, setFinishConfirmation] = useState(false)
  const [showPractitionerSelect, setShowPractitionerSelect] = useState(false)

  const closeFinishConfirmation = () => {
    setFinishConfirmation(false)
    setShowAppointmentForm(false)
  }

  const { finishEncounter, isFinishing } = useFinishEncounter(patientId, onEncounterFinished, closeFinishConfirmation)

  const onFinish = useCallback(() => {
    if (encounter) {
      finishEncounter(encounter)
    }
  }, [finishEncounter, encounter])

  const { assignCheckoutTask, isAssigning } = useAssignCheckoutTask()

  const onAssignCheckoutTask = (
    { practitionerId }: { practitionerId: string | undefined } = { practitionerId: undefined },
  ) => {
    if (encounter) {
      assignCheckoutTask({ encounterId: encounter.id as string, practitionerId })
    }
    setShowPractitionerSelect(false)
  }

  const actions: ActionProps[] = [
    ...(showStopAction
      ? [
          {
            label: "Assign finish checkout task",
            description: "Assign a task to someone else asking to process current checkout.",
            onSelectClick: () =>
              organizationPractitionersStaffInfo.length > 0 ? setShowPractitionerSelect(true) : onAssignCheckoutTask(),
            disabled: !showAssignCheckoutAction,
          },
          {
            label: "Stop & Schedule",
            description: showAssignCheckoutAction
              ? "You have items on the cart pending to checkout."
              : "Finish current encounter and schedule a new follow up appointment.",
            onSelectClick: () => {
              setShowAppointmentForm(true)
            },
            disabled: showAssignCheckoutAction,
          },
        ]
      : []),
    ...(onStopClicked
      ? [
          {
            label: "Encounter Summary",
            description: "See details and stop current encounter.",
            onSelectClick: onStopClicked,
          },
        ]
      : [
          {
            label: "Finish Encounter",
            description: showAssignCheckoutAction
              ? "You have items on the cart pending to checkout."
              : "Finish current encounter.",
            onSelectClick: () => {
              if (!showStopAction) {
                setFinishConfirmation(true)
              } else onFinish()
            },
            disabled: showAssignCheckoutAction,
          },
        ]),
  ]
  const { createAppointment, isAdding } = useCreateAppointment(onFinish)
  const onSubmit = (appointment: AppointmentFormData) => createAppointment(sanitize(appointment))

  return (
    <div className="relative">
      <SplitButton
        actions={actions}
        autoUpdateMainAction
        loading={isFinishing || isAssigning}
        className={buttonClassName}
        buttonClassName="pl-5"
      />
      {!isFinishing && !isAdding && (
        <span
          className={classNames(
            "rounded-full h-2 w-2 absolute left-2 bg-red-500 z-10",
            buttonClassName?.includes("button-banner") ? "top-3.5" : "top-3",
          )}
        />
      )}
      <DialogFormContainer
        onSubmit={onSubmit}
        onCancel={() => setShowAppointmentForm(false)}
        showForm={showAppointmentForm}
        saveLabel="Accept"
        title="Follow Up Encounter"
        initialValue={initialValues(
          {
            ...INITIAL_VALUES,
            participant: [
              { actor: patientRef, status: "tentative" },
              { actor: loggedInPractitionerRoleRef, status: "tentative" },
            ],
          },
          location,
        )}
        validationSchema={appointmentValidationSchema}
        useFormik
        enableReinitialize
        className="w-4/5 md:w-4/5 lg:w-4/5 xl:w-2/3"
      >
        <AppointmentForm hidePatientField />
      </DialogFormContainer>

      <DialogFormContainer
        showForm={showPractitionerSelect}
        onCancel={() => setShowPractitionerSelect(false)}
        onSubmit={onAssignCheckoutTask}
        title="Select a staff practitioner to assign:"
        saveLabel="Assign"
        initialValue={{ practitionerId: undefined }}
        useFormik
        enableReinitialize
        className="w-2/5 md:w-2/5 lg:w-2/5 xl:w-1/3"
      >
        <AssignPractitionerForm />
      </DialogFormContainer>

      <ConfirmDialog
        id="finish-confirm"
        header="Confirmation"
        acceptLabel="Finish"
        rejectLabel="Close"
        rejectClassName="button-default p-button-sm"
        acceptClassName="button-primary p-button-sm"
        onHide={closeFinishConfirmation}
        message="Are you sure you want to finish the open encounter?"
        accept={onFinish}
        visible={finishConfirmation || (isFinishing && !showAppointmentForm && !showStopAction)}
        acceptIcon={isFinishing ? "pi pi-spin pi-spinner" : undefined}
        reject={closeFinishConfirmation}
      />
    </div>
  )
}

type Props = {
  patientId: string
  patientRef: Reference
  encounter: Encounter
  onEncounterFinished?(): void
  showStopAction?: boolean
  buttonClassName?: string
  onStopClicked?(): void
  showAssignCheckoutAction: boolean
}

export { EncounterFinishButton }
