import { useQuery } from "@tanstack/react-query"
import { type PlanDefinition, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { BILLING_TYPES_CODES } from "data"
import { convertIdentifiersToCodings, getBasePrice } from "utils"

import { useProductPricesQueryFunction } from "../../hooks"
import { type LaboratoryTest } from "../../types"
import { buildBulkProductConfigurationsToRequest, getProductKey } from "../../utils"
import { plansQueryKeys } from "../query-keys"

const usePlanDefinitionTests = (organizationId: string, pdCanonicals?: string[]) => {
  const { search } = useClient()
  const queryKey = plansQueryKeys.testsDefinitions(pdCanonicals)

  const fetchProductPrices = useProductPricesQueryFunction()

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        canonical: pdCanonicals?.join(",") ?? "",
      })

      const bundle = await search({ endpoint: "PlanDefinition", filters, signal })
      const planDefinitions = getResources<PlanDefinition>(bundle, "PlanDefinition")
      const codes = convertIdentifiersToCodings(planDefinitions)
      const productPrices = await fetchProductPrices({
        organizationId,
        productsConfigurations: buildBulkProductConfigurationsToRequest({
          codes,
        }),
      })

      return {
        planDefinitions,
        productPrices,
      }
    },
    enabled: !!pdCanonicals?.length,
    meta: { context: { queryKey, organizationId, pdCanonicals } },
  })

  const labTests = useMemo(() => {
    const result = data?.planDefinitions?.reduce((acc, pd) => {
      if (data?.productPrices) {
        const codes = convertIdentifiersToCodings(pd ? [pd] : [])
        const productKey = getProductKey({
          code: codes,
          billingType: BILLING_TYPES_CODES.BILL_PRACTICE,
        })
        const cid = data.productPrices[productKey]

        return [
          ...acc,
          {
            planDefinition: pd,
            price: getBasePrice(cid?.propertyGroup?.[0].priceComponent)?.amount,
            display: pd.title ?? pd.name ?? "Unknown",
          },
        ]
      }
      return acc
    }, new Array<LaboratoryTest>())

    return result
  }, [data])

  return {
    labTests,
    isLoading,
  }
}

export { usePlanDefinitionTests }
