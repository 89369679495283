import { useInfiniteQuery } from "@tanstack/react-query"
import { isAfter } from "date-fns/isAfter"
import {
  type Invoice,
  type MedicationDispense,
  type MedicationKnowledge,
  type MedicationRequest,
  type PaymentReconciliation,
  type ServiceRequest,
  type Task,
  getResources,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import type { MedicationRequestOrderData } from "commons/types"
import { getCommonCode, hasMedAutoship } from "utils"
import { getMedsProductConfigurations } from "commons/utils"

import { medsQueryKeys } from "../meds_query_keys"
import { hasInvalidMedicationDispense } from "../utils"

const useMrOrdersResources = ({
  patientId,
  subcategory,
  statusFilter,
  searchText,
  encounter,
}: {
  patientId: string
  subcategory: "nutraceutical-order" | "medication-order"
  statusFilter: string[]
  searchText?: string
  encounter?: string
}) => {
  const { search } = useClient()
  const queryKey = medsQueryKeys.ordersResources(patientId, subcategory, statusFilter, searchText, encounter)

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery<
    MedicationRequestOrdersResourcesData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        _query: "patient-medication-orders",
        _patient: patientId,
        _subcategory: subcategory,
        ...(searchText ? { _filter: searchText.toUpperCase() } : {}),
        ...(statusFilter.length > 0 ? { _status: statusFilter.join(",") } : {}),
        ...(encounter ? { _encounter: encounter } : {}),
        _count: "20",
        _order: "desc",
        _page: `${pageParam}`,
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/ServiceRequest`, filters, signal })

      const serviceRequests = getResources<ServiceRequest>(bundle, "ServiceRequest")
      const medicationRequests = getResources<MedicationRequest>(bundle, "MedicationRequest")
      const medicationDispenses = getResources<MedicationDispense>(bundle, "MedicationDispense")
      const medicationKnowledges = getResources<MedicationKnowledge>(bundle, "MedicationKnowledge")
      const tasks = getResources<Task>(bundle, "Task")
      const invoices = getResources<Invoice>(bundle, "Invoice")
      const payRec = getResources<PaymentReconciliation>(bundle, "PaymentReconciliation")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return {
        serviceRequests,
        tasks,
        payRec,
        medicationRequests,
        medicationDispenses,
        medicationKnowledges,
        invoices,
        next,
        total: bundle?.total ?? 0,
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId, statusFilter, searchText } },
  })

  const { mrOrderData, medicationRequests, mksBySku, medicationDispenses } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.serviceRequests)
    const tasks = data?.pages.flatMap((page) => page.tasks)
    const invoices = data?.pages
      .flatMap((page) => page.invoices)
      .reduce((acc, inv) => ({ ...acc, [inv.id as string]: inv }), {} as Record<string, Invoice>)
    // Payrecs are sorted from recent to oldest
    const payRecs = data?.pages
      .flatMap((page) => page.payRec)
      .toSorted((a, b) => (isAfter(a.created, b.created) ? -1 : 1))
    const medicationRequests = data?.pages.flatMap((page) => page.medicationRequests)
    const medicationKnowledges = data?.pages.flatMap((page) => page.medicationKnowledges)
    const medicationDispenses = data?.pages.flatMap((page) => page.medicationDispenses)
    const mksBySku = (medicationKnowledges ?? [])?.reduce(
      (acc, mk) => {
        const code = getCommonCode({ codes: mk.code?.coding })
        return { ...acc, [code]: mk }
      },
      {} as Record<string, MedicationKnowledge>,
    )

    const initialTasks: {
      dispenseTasksByFocus: Record<string, Task>
      tasksById: Record<string, Task>
    } = { dispenseTasksByFocus: {}, tasksById: {} }
    const { dispenseTasksByFocus, tasksById } =
      tasks?.reduce((acc, task) => {
        if (task.code?.coding?.some(({ code }) => code === "dispense-medications") && task.focus?.id) {
          return { ...acc, dispenseTasksByFocus: { ...acc.dispenseTasksByFocus, [task.focus.id]: task } }
        }
        return {
          ...acc,
          tasksById: {
            ...acc.tasksById,
            ...(task.id ? { [task.id]: task } : {}),
          },
        }
      }, initialTasks) ?? initialTasks

    const result = newData?.reduce((acc, sr) => {
      // Find dispense task for the order or for the replaced(after edit) order
      const taskSR = dispenseTasksByFocus?.[sr.id as string]
      const replacedTaskSR = dispenseTasksByFocus?.[sr.replaces?.[0]?.id as string]

      const taskInvoice = tasksById[taskSR?.dependsOn?.[0]?.id as string]
      const replacedInvoiceTask = tasksById[replacedTaskSR?.dependsOn?.[0]?.id as string]

      const orderInvoices = [
        ...(invoices?.[taskInvoice?.focus?.id as string] ? [invoices[taskInvoice?.focus?.id as string]] : []),
        ...(invoices?.[replacedInvoiceTask?.focus?.id as string]
          ? [invoices[replacedInvoiceTask?.focus?.id as string]]
          : []),
      ]
      // Get the actual order payrec or fallback to the replaced order payrec
      const payRec = payRecs?.find((pay) => pay?.R5_request.id === orderInvoices?.[0]?.id)

      // Get MDs related to the current order excluding those of the prev invalid order
      const medicationDispense = medicationDispenses
        ?.filter(({ supportingInformation }) => supportingInformation?.some(({ id }) => sr.id === id))
        .toSorted((a, b) => (isAfter(a.whenPrepared ?? "", b.whenPrepared ?? "") ? 1 : -1))

      const hasInvalidMD = hasInvalidMedicationDispense(medicationDispense)

      const medications = medicationRequests?.filter(({ id }) => sr.basedOn?.some((ref) => ref.id === id))

      acc = [
        ...acc,
        {
          serviceRequest: sr,
          invoices: orderInvoices,
          payRec,
          hasAutoship: hasMedAutoship(medications),
          hasInvalidMD,
          isEditable: hasInvalidMD && !sr.replaces?.length && sr.status === "completed",
          medicationDispense,
          medicationRequest: medications,
        },
      ]
      return acc
    }, new Array<MedicationRequestOrderData>())

    return {
      mrOrderData: result,
      medicationRequests,
      medicationDispenses,
      mksBySku,
      total: data?.pages?.[0]?.total ?? 0,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    mrOrderData,
    medicationRequests,
    medsProductConfigurations: getMedsProductConfigurations({ meds: medicationRequests, specifiedQuantity: true }),
    medicationKnowledges: mksBySku,
    medicationDispenses,
    isLoading,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    reloadOrders: refetch,
  }
}

type MedicationRequestOrdersResourcesData = {
  serviceRequests: ServiceRequest[]
  payRec: PaymentReconciliation[]
  tasks: Task[]
  medicationRequests: MedicationRequest[]
  medicationDispenses: MedicationDispense[]
  medicationKnowledges: MedicationKnowledge[]
  invoices: Invoice[]
  next: number | undefined
  total: number
}

export { useMrOrdersResources }
