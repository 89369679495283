import { useMutation, useQueryClient, type QueryKey } from "@tanstack/react-query"
import type { Task } from "fhir"

import { useClient } from "api"
import type { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { tasksQueryKeys } from "../query-keys"

const usePatchTask = (args?: UpdateTaskArgs) => {
  const { patch } = useClient()
  const queryClient = useQueryClient()

  const newTask = async ({ id, ...task }: Partial<Task>) => patch("Task", id as string, task)

  const { mutate: updateTask, isPending: isUpdating } = useMutation({
    mutationFn: newTask,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: tasksQueryKeys.all,
      })
      args?.queryKeysToInvalidate?.forEach(async (queryKey) => {
        await queryClient.invalidateQueries({
          queryKey,
        })
      })
      displayNotificationSuccess(args?.successMessage ?? "Success!")
      // datadogLogs.logger.info("Task updated successfully!", { task })
    },
    onSettled: args?.hideForm,
  })

  return { updateTask, isUpdating }
}

type UpdateTaskArgs = { hideForm?: () => void; queryKeysToInvalidate?: QueryKey[]; successMessage?: string }

export { usePatchTask }
