import { SelectButton } from "primereact/selectbutton"
import { startTransition } from "react"

import { useChartContext } from "chart-view"
import { SearchWithStatus, useCrudReducer } from "commons"
import { useMedReducerState } from "commons/meds"
import { medicationRequestStatus } from "data"
import { useOpenEncounter } from "encounter"
import { usePatientContext } from "patients"

import { prescriptionViewOptions } from "../data"
import { useCreateMedicationRequest, useUpdateMedicationRequest } from "../hooks"
import type { Contents, MedicationRequestFormData } from "../types"
import { sanitize } from "../utils/formatters"
import { PrescriptionFormContainer } from "./form"
import { PrescriptionList } from "./prescriptions/PrescriptionList"

const PrescriptionsContainer = ({ activeContent, onUpdateContent }: Props) => {
  const { patientId } = usePatientContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { statusFilter, searchFilter, updateFilters, updateSearchText } = useMedReducerState()
  const chartContext = useChartContext()
  const {
    initialValue,
    showSlide: showForm,
    state: { isRenew },
    edit: editMR,
    reset,
    dispatch,
  } = useCrudReducer({
    defaultEntity: {} as MedicationRequestFormData,
    extraState: { isRenew: false },
    extraStateReducer: (state, { type, payload }) => {
      if (type === "renew") return { ...state, isRenew: payload as boolean }

      return state
    },
  })

  const edit = (mr: MedicationRequestFormData) => {
    editMR(mr)
    chartContext.setSearchData({ showSearch: false })
  }

  const renew = (mr: MedicationRequestFormData) => {
    editMR(mr)
    dispatch({ type: "renew", payload: true })
    chartContext.setSearchData({ showSearch: false })
  }

  const handleCancel = () => {
    reset()
    dispatch({ type: "renew", payload: false })
  }
  const { createMedicationRequest } = useCreateMedicationRequest(handleCancel)
  const { updateMedicationRequest } = useUpdateMedicationRequest(reset)

  const handleSubmit = (values: MedicationRequestFormData) => {
    const sanitizedMed = sanitize({ mr: { ...values, encounter: openEncounterRef } })
    if (isRenew) createMedicationRequest(sanitizedMed)
    else updateMedicationRequest(sanitizedMed)
  }

  return showForm ? (
    <PrescriptionFormContainer
      initialValues={initialValue}
      isEditing={!isRenew}
      isRenewing={isRenew}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  ) : (
    <>
      <div className="inline-flex justify-between h-12 w-full my-3 px-2">
        <SelectButton
          value={activeContent}
          options={prescriptionViewOptions}
          optionLabel="name"
          optionValue="value"
          allowEmpty={false}
          onChange={(e) => {
            onUpdateContent(e.value as Contents)
          }}
        />
        <SearchWithStatus
          placeholder="Search prescriptions"
          options={medicationRequestStatus}
          selectedItems={statusFilter}
          onStatusCheck={updateFilters}
          onSearch={(filter) => {
            startTransition(() => {
              updateSearchText(filter ?? "")
            })
          }}
        />
      </div>
      <PrescriptionList searchFilter={searchFilter} statusFilter={statusFilter} onEdit={edit} onRenew={renew} />
    </>
  )
}

type Props = {
  activeContent: Contents
  onUpdateContent(activeContent: Contents): void
}

export { PrescriptionsContainer }
