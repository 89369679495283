import { useQuery } from "@tanstack/react-query"
import { type CarePlan, type Reference, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { plansQueryKeys } from "../query-keys"

const useCarePlanAssessments = (patientId: string, mcAssessments?: string[]) => {
  const { search } = useClient()
  const queryKey = plansQueryKeys.assessments(patientId, mcAssessments?.join(","))

  const { data, isLoading } = useQuery({
    queryKey,
    enabled: !!mcAssessments?.length,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _id: mcAssessments?.join(",") as string,
        intent: "order",
        _sort: "-createdAt",
        _revinclude: "CarePlan:replaces:CarePlan",
        _elements: "status,activity,title,author,created",
      })
      const bundle = await search({ endpoint: `Patient/${patientId}/CarePlan`, filters, signal })

      const careplans = getResources<CarePlan>(bundle, "CarePlan")

      return {
        careplans,
      }
    },
    meta: { context: { queryKey, patientId } },
  })

  const { assessments } = useMemo(() => {
    const cpas = data?.careplans.reduce((acc, cp) => {
      const medRefs = cp.activity?.reduce((acc, a) => {
        const newMed = a.reference?.resourceType === "MedicationRequest" ? a.reference : undefined
        return [...acc, ...(newMed ? [newMed] : [])]
      }, Array<Reference>())

      return [...acc, { assessment: cp, medRefs }]
    }, Array<CPA>())

    return { assessments: cpas }
  }, [data?.careplans])

  return {
    isLoading,
    assessments,
  }
}

type CPA = {
  assessment: CarePlan
  medRefs?: Reference[]
}

export { useCarePlanAssessments }
