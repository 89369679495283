import type { Questionnaire } from "fhir"
import { useId, useState } from "react"

import { SkeletonLoader, InfiniteScroll } from "commons"

import { useQuestionnaire } from "../hooks"
import { QuestionnaireItem } from "./QuestionnaireItem"

const QuestionnaireList = ({ onAssign, checkQAssigned, isAssigning, gender }: Props) => {
  const loaderKey = useId()
  const [itemClicked, setItemClicked] = useState("")
  const { questionnaires, isLoading, hasNextPage, fetchNextPage } = useQuestionnaire({ gender })

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="one-line" />

  if (isLoading) return loader()

  return (
    <div className="bg-white h-full overflow-auto">
      <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
        {questionnaires &&
          questionnaires.length &&
          questionnaires.map((questionnaire, index) => (
            <QuestionnaireItem
              key={questionnaire.id ?? index}
              questionnaire={questionnaire}
              assign={() => {
                onAssign(questionnaire)
                setItemClicked(questionnaire.id as string)
              }}
              isAssigning={itemClicked === questionnaire.id && isAssigning}
              isAssigned={checkQAssigned(questionnaire.id as string)}
            />
          ))}
      </InfiniteScroll>
    </div>
  )
}

type Props = {
  onAssign(questionnaireId: Questionnaire): void
  onClose(): void
  checkQAssigned(qId: string): boolean
  isAssigning: boolean
  gender?: string
}

export { QuestionnaireList }
