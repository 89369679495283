import { faExclamationTriangle, faTimesCircle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import type { Reference } from "fhir"
import { type FormikValues, useFormikContext } from "formik"
import { Button } from "primereact/button"
import { classNames } from "primereact/utils"
import { type FC, useState } from "react"

import {
  AddressField,
  AddressVerificationFeedback,
  BirthdateField,
  CheckBoxField,
  EmailField,
  GenderField,
  InputField,
  PhoneField,
  ProfileImageField,
  ReferenceDropdownField,
} from "commons"
import { useSmartyAddressVerificationContext } from "commons/hooks"

import { PatientConsents } from "./PatientConsents"

enum OPTIONAL_FIELDS {
  ADDRESS = "address",
  GENDER = "gender",
}

const PatientForm: FC<Props> = ({ practitioners, preferredPractitioner, toggleValidateAddress }: Props) => {
  const [optionalFields, setOptionalFields] = useState<Array<OptionalField>>([])
  const { initialValues, values, setFieldValue, setFieldTouched, getFieldMeta } = useFormikContext<FormikValues>()
  const { addressVerificationInfo, autoCompleteRecommendedAddress, bypassAddressValidation } =
    useSmartyAddressVerificationContext()

  const addField = (field: OptionalField, initialFieldValue?: unknown) => {
    setOptionalFields([...optionalFields, field])
    if (initialFieldValue && field.path) {
      setFieldValue(field.path, initialFieldValue)
      setFieldTouched(field.path, true)
    }
  }

  const removeField = (field: OptionalField) => {
    if (field.name === OPTIONAL_FIELDS.ADDRESS) {
      toggleValidateAddress()
    }

    setOptionalFields(optionalFields.filter(({ name }) => name !== field.name))
    setFieldValue(field.path, getFieldMeta(field.path)?.initialValue)
  }

  const getButtonHidden = (field: OPTIONAL_FIELDS) =>
    optionalFields.findIndex((optional) => optional.name === field) !== -1

  const address: OptionalField = {
    name: OPTIONAL_FIELDS.ADDRESS,
    path: "patient.address[0]",
    component: (_, onRemove) => (
      <div className="flex items-center p-fluid">
        <AddressField
          parentFieldName="patient.address[0]"
          showTypeUseField={false}
          className="grid grid-flow-row-dense gap-y-4 p-fluid"
        />

        <Button
          type="button"
          title="Remove field"
          icon={<FontAwesomeIcon icon={faTimesCircle} className="text-[20px]" />}
          className="p-button-text p-button-rounded p-button-sm"
          onClick={onRemove}
        />
      </div>
    ),
  }

  return (
    <>
      <fieldset>
        <legend className="mb-3">Contact Information</legend>
        <div className="grid grid-flow-row-dense grid-cols-2 gap-x-4 gap-y-4 p-fluid">
          <ProfileImageField
            fieldParent="patient"
            imageClassName="w-28 h-28 rounded-full ring-4 ring-slate-100/70 shadow-sm"
            className="col-span-full w-fit"
          />

          <InputField field="patient.name[0].given[0]" label="First Name" className="col-span-1" />
          <InputField field="patient.name[0].family" label="Last Name" className="col-span-1" />

          <EmailField
            field="patient.telecom[0].value"
            label="Email Address"
            initialValue={initialValues.telecom?.[0].value}
            validateDuplicate={true}
          />
          <PhoneField unmask={true} field="patient.telecom[1].value" label="Phone" />
          <BirthdateField field="patient.birthDate" label="Date of Birth" className="col-span-full" />
          <GenderField field="patient.gender" label="Biological Sex" className="col-span-full" />
          <ReferenceDropdownField
            field="patient.generalPractitioner[0]"
            label="Practitioner"
            className="col-span-full"
            options={practitioners}
            validation={(value) => (!value?.id ? "Practitioner is required" : undefined)}
            preferredReference={preferredPractitioner}
          />
          {initialValues.id && !values.generalPractitioner?.[0].id && (
            <div className="text-sm text-red-400">
              <FontAwesomeIcon icon={faExclamationTriangle} />
              <span className="ml-1">Missing general practitioner</span>
            </div>
          )}
        </div>
      </fieldset>

      <fieldset className="px-0">
        <legend className="mb-3">Additional information</legend>
        {optionalFields.map((optional) => {
          const isAddressComponent = optional.name === OPTIONAL_FIELDS.ADDRESS
          return (
            <>
              {!isAddressComponent && optional.component(optional.name, () => removeField(optional))}
              {isAddressComponent && (
                <div className="flex flex-col justify-between divide-y divide-gray-300" key={optional.name}>
                  {optional.component(optional.name, () => removeField(optional))}
                  <AddressVerificationFeedback
                    addressVerificationInfo={addressVerificationInfo}
                    handleAutoCompleteRecommendedAddress={() =>
                      autoCompleteRecommendedAddress?.(setFieldValue, "patient.address[0]")
                    }
                    handleBypassAddressValidation={bypassAddressValidation}
                  />
                </div>
              )}
            </>
          )
        })}

        <Button
          type="button"
          label="+ Address"
          className={classNames("p-button-rounded p-button-outlined p-button-sm max-w-fit h-6 mr-2 mb-3", {
            hidden: getButtonHidden(OPTIONAL_FIELDS.ADDRESS),
          })}
          onClick={() => {
            toggleValidateAddress()
            addField(address, { ...initialValues?.patient?.address?.[0] })
          }}
        />
      </fieldset>
      <PatientConsents field="consents" label="Consents" />
      <fieldset className="px-0">
        <legend className="mb-3">Invite</legend>
        <CheckBoxField field="invite" label="Invite patient to the platform" />
      </fieldset>
    </>
  )
}

type OptionalField = {
  name: OPTIONAL_FIELDS
  path: string
  component(key: string, onRemove: () => void): JSX.Element
}

type Props = {
  showBirthDate?: boolean
  practitioners: Reference[]
  toggleValidateAddress(): void
  preferredPractitioner?: Reference
}

export { PatientForm }
