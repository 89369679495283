import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { type FC, useId, useMemo } from "react"
import { useParams } from "react-router-dom"

import { InfiniteScroll, SkeletonLoader, useCrudReducer } from "commons"

import { useAppointmentTypes } from "../../hooks"
import type { AppointmentType } from "../../types"
import { SectionContainer } from "../../utils/SectionContainer"
import { actionsBodyTemplate, booleanBodyTemplate, durationBodyTemplate } from "../../utils/templates"
import { AppointmentTypeFormContainer } from "./AppointmentTypeFormContainer"
import { getInitialValues } from "./validations"

const AppointmentTypesContainer: FC = () => {
  const { orgId } = useParams()
  const {
    add,
    initialValue,
    edit,
    //deleteIndex,
    //isNew,
    showSlide: showForm,
    reset,
    dispatch,
    state: { searchText },
  } = useCrudReducer({
    extraState: { searchText: "" as string | undefined },
    extraStateReducer: (state, { type, payload }) => {
      if (type === "search") return { ...state, searchText: payload as string | undefined }

      return state
    },
    defaultEntity: getInitialValues(),
  })

  const { appointmentTypes, isLoading, hasNextPage, fetchNextPage } = useAppointmentTypes({
    organizationId: orgId as string,
    filters: { searchText },
  })

  const addOptions = [
    {
      label: "New appointment type",
      command: () => add(),
    },
  ]

  const onSearch = (payload: string | undefined) => dispatch({ type: "search", payload })

  const onReset = () => {
    reset()
    dispatch({ type: "search", payload: undefined })
  }

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  const flagBodyTemplate = ({
    values,
    field,
  }: {
    values: Pick<AppointmentType, "telemedicine" | "patientCanBook" | "withLabs" | "withQuestionnaires">
    field: keyof Pick<AppointmentType, "telemedicine" | "patientCanBook" | "withLabs" | "withQuestionnaires">
  }) => booleanBodyTemplate(values?.[field])

  const typeBodyTemplate = ({ name, color }: Partial<AppointmentType>) => (
    <span className="flex items-center gap-3 min-w-32 relative">
      <span
        className="absolute inset-0"
        style={{
          background: `linear-gradient(to right, ${color}1A, transparent)`,
        }}
      />
      <span className="w-1.5 h-10 rounded-l-lg shrink-0" style={{ background: color }} />
      <span style={{ color }}>{name}</span>
    </span>
  )

  const appointmentTypeItems = useMemo(
    () =>
      appointmentTypes.flatMap((appType) => ({
        ...appType,
        ...{
          externalAction: [
            {
              label: "Edit",
              icon: <FontAwesomeIcon icon={faEdit} size="sm" />,
              command: () => edit(appType),
            },
          ],
        },
      })),
    [appointmentTypes],
  )

  return (
    <SectionContainer
      actions={addOptions}
      onReset={onReset}
      onSearch={onSearch}
      placeholder="Search types"
      searchText={searchText}
      containerClassName="grow overflow-auto"
    >
      <>
        <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
          <DataTable value={appointmentTypeItems} loading={isLoading}>
            <Column
              field="name"
              header="Type"
              bodyClassName="text-xs"
              headerClassName="default-header"
              body={typeBodyTemplate}
            />
            <Column
              field="telemedicine"
              header="Telemedicine"
              bodyClassName="text-xs"
              headerClassName="default-header"
              body={(values) => flagBodyTemplate({ values, field: "telemedicine" })}
            />
            <Column
              field="patientCanBook"
              header="Patient Can Book"
              bodyClassName="text-xs"
              headerClassName="default-header"
              body={(values) => flagBodyTemplate({ values, field: "patientCanBook" })}
            />
            <Column
              field="notAvailable"
              header="Time In Advance"
              bodyClassName="text-xs"
              headerClassName="default-header"
              body={({ notAvailable }: AppointmentType) =>
                durationBodyTemplate({ duration: notAvailable?.[0]?.timeInAdvance })
              }
            />
            <Column
              field="duration"
              header="Duration"
              bodyClassName="text-xs"
              headerClassName="default-header"
              body={({ duration }: AppointmentType) =>
                durationBodyTemplate({ duration: { value: duration, unit: "minutes" } })
              }
            />
            <Column
              field="withQuestionnaires"
              header="Questionnaires"
              bodyClassName="text-xs"
              headerClassName="default-header"
              body={(values) => flagBodyTemplate({ values, field: "withQuestionnaires" })}
            />
            {/* <Column
              field="withLabs"
              header="Labs"
              bodyClassName="text-xs"
              headerClassName="default-header"
              body={(values) => flagBodyTemplate({ values, field: "withLabs" })}
            /> */}
            <Column
              field="external_action"
              headerClassName="bg-transparent whitespace-nowrap"
              body={actionsBodyTemplate}
            />
          </DataTable>
        </InfiniteScroll>
        {showForm && <AppointmentTypeFormContainer initialValue={initialValue} onHide={reset} />}
      </>
    </SectionContainer>
  )
}

export { AppointmentTypesContainer }
