import { useMutation, useQueryClient } from "@tanstack/react-query"
import { asDeleteBundleEntry, type Bundle, type BundleEntryArray, type Composition } from "fhir"

import { useClient } from "api"
import { commonsQueryKeys, type CustomError } from "commons"
import type { NoteSnippetsQueryResponse } from "commons/hooks"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

const useDeleteSnippetsNote = ({ onSuccess, onSettled }: { onSuccess?: () => void; onSettled?: () => void } = {}) => {
  const { transaction } = useClient()
  const queryClient = useQueryClient()

  const deleteSnippetsNoteMutationFn = (snippets: Composition[]) => {
    const noteSnippetsToDelete =
      snippets.reduce<BundleEntryArray[]>((acc, snippet) => [...acc, asDeleteBundleEntry(snippet)], []) ?? []

    const bundle: Bundle = {
      resourceType: "Bundle",
      type: "transaction",
      entry: [...noteSnippetsToDelete],
    }

    return transaction(bundle)
  }

  const { mutate: deleteNoteSnippets, isPending: isAdding } = useMutation({
    mutationFn: deleteSnippetsNoteMutationFn,
    onMutate: async (snippets) => {
      const queryKey = commonsQueryKeys.snippetNotes(snippets?.[0]?.author?.[0]?.id as string)

      await queryClient.cancelQueries({
        queryKey,
      })

      const previousNoteSnippets = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, (old: NoteSnippetsQueryResponse) => {
        const snippetIdsToDelete = new Set(snippets.map((snippet) => snippet.id))

        return {
          ...old,
          pages: old.pages.map((page) => ({
            ...page,
            compositions: page.compositions.filter(({ id }) => !snippetIdsToDelete.has(id)),
          })),
        }
      })

      return { previousNoteSnippets, queryKey }
    },
    onSuccess: onSuccess,
    onError: (error: CustomError, newSnippet, context) => {
      const { previousNoteSnippets, queryKey } = context ?? { previousNoteSnippets: [], queryKey: [""] }

      queryClient.setQueryData(queryKey, previousNoteSnippets)
      displayNotificationError(registerErrorTrace(error, newSnippet))
    },
    onSettled: (_, __, ___, context) => {
      const { queryKey } = context ?? { previousNoteSnippets: [], queryKey: [""] }

      queryClient.invalidateQueries({ queryKey })
      onSettled?.()
    },
  })

  return { deleteNoteSnippets, isAdding }
}
export { useDeleteSnippetsNote }
