import type { FC, PropsWithChildren } from "react"
import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { classNames } from "primereact/utils"

import { Button } from "../components/Buttons"

const FooterActions: FC<PropsWithChildren<Props>> = ({ actions, className, actionsDisabled = false, children }) => (
  <div className={classNames("flex justify-between px-3 py-4 border-gray-100 border-t-2", className)}>
    <div>{children}</div>
    <div className="flex gap-3 flex-shrink-0">
      {actions.map(
        ({ id, label, command, disabled, style = "primary", title, loading = false, visible = true }, key) =>
          visible && (
            <Button
              key={key}
              id={id}
              buttonStyle={style as "default" | "primary" | "floating" | "outlined" | "text"}
              label={label}
              size="lg"
              disabled={disabled || actionsDisabled}
              title={title}
              onClick={command}
              loading={loading}
            />
          ),
      )}
    </div>
  </div>
)

type Props = {
  actions: Action[]
  actionsDisabled?: boolean
  className?: string
}

export type Action = {
  label: string
  command: () => void
  disabled?: boolean
  style?: string
  icon?: IconDefinition
  title?: string
  loading?: boolean
  visible?: boolean
  id?: string
}

export { FooterActions }
