import type { Composition, Reference } from "fhir"
import { useMemo, type FC } from "react"
import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"
import type { QueryKey } from "@tanstack/react-query"
// eslint-disable-next-line import/no-named-as-default
import DOMPurify from "dompurify"

import { useCrudReducer, DataContainerForm, MentionTextAreaField } from "commons"
import { useOrganizationContext } from "organization"

import { useCreatePatientComposition, useUpdatePatientComposition } from "../hooks"
import { Assessment_Default_Template, getAssessmentInitialValues, sanitize } from "./validations"

const AssessmentNote: FC<Props> = ({
  assessment,
  patientRef,
  encounterRef,
  queriesToInvalidate,
  className,
  contentClassName,
  buttonClassName,
}) => {
  const { loggedInPractitionerRoleRef } = useOrganizationContext()
  const {
    initialValue,
    reset,
    showSlide: showForm,
    edit,
  } = useCrudReducer({
    defaultEntity:
      assessment ?? getAssessmentInitialValues(loggedInPractitionerRoleRef, patientRef, encounterRef, true),
  })

  const assessmentId = initialValue.id
  const assessmentNote = initialValue.section?.[0]?.text?.div
  const emptyNote = useMemo(() => assessmentNote?.localeCompare(Assessment_Default_Template) === 0, [assessmentNote])

  const { createAssessment } = useCreatePatientComposition(reset, queriesToInvalidate)
  const { updateAssessment } = useUpdatePatientComposition(reset, queriesToInvalidate)

  const onSubmit = (data: Composition) => {
    if (data.section?.[0]?.text?.div === assessmentNote) {
      reset()
    } else {
      if (!data.section?.[0]?.text?.div) data.section = data.section?.slice(1)
      if (!data.id) createAssessment(sanitize(data))
      else updateAssessment(sanitize(data))
    }
  }

  return (
    <DataContainerForm
      form={<MentionTextAreaField field="section[0].text.div" rows={10} className="py-1" autoFocus />}
      formInitialValue={{ ...initialValue, id: assessmentId }}
      onSubmit={onSubmit}
      onCancel={reset}
      enableReinitialize
      disableSave={false}
      showForm={showForm}
      hasData={!emptyNote}
      itemTitle="assessment"
      itemTitleNotFound="assessment"
      cancelButtonLabel="Cancel"
      containerClassName={className}
      onButtonAddClick={() => edit(initialValue)}
      subMessageDataNotFound=""
      messageDataNotFound="No assessment found"
      customAddButtonText="Add assessment"
      customAddButton={<></>}
      emptyAddButtonStyle="outlined"
      saveButtonStyle="outlined"
    >
      {assessmentNote && (
        <div className={classNames("inline-flex w-full space-x-3 items-start", contentClassName)}>
          <div
            className="text-sm w-full whitespace-pre-line editor-note"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(assessmentNote, { ALLOWED_ATTR: ["class", "style", "src", "href"] }),
            }}
          />
          <FontAwesomeIcon
            icon={faEdit}
            className={classNames(
              "mt-1 text-lg text-gray-400 hover:text-primary-hover cursor-pointer",
              buttonClassName,
            )}
            onClick={() => edit(initialValue)}
          />
        </div>
      )}
    </DataContainerForm>
  )
}

type Props = {
  patientRef: Reference
  assessment?: Composition
  encounterRef?: Reference
  className?: string
  contentClassName?: string
  queriesToInvalidate: QueryKey
  buttonClassName?: string
}

export { AssessmentNote }
