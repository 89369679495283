import { faPrint } from "@fortawesome/pro-solid-svg-icons"
import { classNames } from "primereact/utils"
import type { FC } from "react"

import { Button, ModulesId, SkeletonLoader } from "commons"
import { useAppModuleContext } from "internals"

import IntakeWrapUp from "../components/details/IntakeWrapUp"
import { PlansSection } from "../components/details/PlansSection"
import { QuestionnairesSection } from "../components/details/QuestionnairesSection"
import { EncounterDataProvider } from "../context/EncounterContext"
import { useEncounterData, usePrintEncounter } from "../hooks"
import { LabsSection } from "./LabsSection"
import { NutrasSection } from "./NutraceuticalsSection"
import { MedsSection } from "./PharmaceuticalsSection"
import { ProceduresSection } from "./ProcedureSection"

const EncounterKpWrapUp: FC<Props> = ({ encounterId, patientId, organizationId, className }) => {
  const { appModules, isModuleActive } = useAppModuleContext()
  const { printEncounter, isPrintingEncounter } = usePrintEncounter()

  const {
    encounterData,
    totalAllergies,
    totalConditions,
    totalEnteredLabs,
    totalFH,
    totalLabOrders,
    totalLabResultsWithNotes,
    totalMedStatements,
    totalPlans,
    totalProcedures,
    totalQs,
    totalSocialHistory,
    totalVitals,
    isLoading,
  } = useEncounterData(patientId, encounterId, organizationId)

  if (isLoading) return <SkeletonLoader loaderType="two-lines" repeats={5} />

  return (
    <EncounterDataProvider
      encounterData={encounterData}
      totalAllergies={totalAllergies}
      totalConditions={totalConditions}
      totalEnteredLabs={totalEnteredLabs}
      totalFH={totalFH}
      totalMedStatements={totalMedStatements}
      totalSocialHistory={totalSocialHistory}
      totalVitals={totalVitals ?? 0}
      totalLabOrders={totalLabOrders}
      totalPlans={totalPlans}
      totalProcedures={totalProcedures}
      totalQs={totalQs}
      totalLabResultsWithNotes={totalLabResultsWithNotes}
    >
      <div className={classNames("flex flex-col divide-y gap-y-6 px-2 @container", className)}>
        <div className="flex flex-row self-end">
          <Button
            label="Print"
            loading={isPrintingEncounter}
            icon={faPrint}
            buttonStyle="default"
            onClick={() => printEncounter(encounterId)}
          />
        </div>
        {isModuleActive(ModulesId.INTAKE) && <IntakeWrapUp hideSpacer />}
        {isModuleActive(ModulesId.LABSORDER) && (
          <LabsSection
            laboratoryOrders={encounterData?.laboratoryOrders}
            total={totalLabOrders}
            icon={appModules[ModulesId.LABSORDER]?.getIcon()}
          />
        )}
        {isModuleActive(ModulesId.SURVEY) && (
          <QuestionnairesSection icon={appModules[ModulesId.SURVEY]?.getIcon()} hideSpacer />
        )}
        {isModuleActive(ModulesId.PROCEDURES) && (
          <ProceduresSection
            procedures={encounterData?.procedures}
            total={totalProcedures}
            icon={appModules[ModulesId.PROCEDURES]?.getIcon()}
          />
        )}
        {isModuleActive(ModulesId.EPRESCRIBE) && (
          <MedsSection
            encounterId={encounterId}
            patientId={patientId}
            icon={appModules[ModulesId.EPRESCRIBE]?.getIcon()}
          />
        )}
        {isModuleActive(ModulesId.MEDICATIONR) && (
          <NutrasSection
            encounterId={encounterId}
            patientId={patientId}
            icon={appModules[ModulesId.MEDICATIONR]?.getIcon()}
          />
        )}
        {isModuleActive(ModulesId.PLANS) && <PlansSection icon={appModules[ModulesId.PLANS]?.getIcon()} hideSpacer />}
      </div>
    </EncounterDataProvider>
  )
}

type Props = {
  encounterId: string
  patientId: string
  organizationId: string
  className?: string
}

export { EncounterKpWrapUp }
