import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { Composition } from "fhir"

import { useClient } from "api"
import { commonsQueryKeys, type CustomError } from "commons"
import type { NoteSnippetsQueryResponse } from "commons/hooks"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

const useCreateSnippetsNote = ({
  onSuccess,
  onSettled,
  onMutate,
}: { onSuccess?: () => void; onSettled?: () => void; onMutate?: () => void } = {}) => {
  const { create } = useClient()
  const queryClient = useQueryClient()

  const newNoteSnippetMutation = (snippet: Composition) => create("Composition", snippet)

  const { mutateAsync: createSnippetsNote, isPending: isAdding } = useMutation({
    mutationFn: newNoteSnippetMutation,
    onMutate: async (newSnippet) => {
      const queryKey = commonsQueryKeys.snippetNotes(newSnippet?.author?.[0]?.id as string)

      await queryClient.cancelQueries({
        queryKey,
      })

      onMutate?.()
      queryClient.setQueryData(queryKey, (old: NoteSnippetsQueryResponse): NoteSnippetsQueryResponse => {
        const firstPage = old?.pages[0]
        const updatedFirstPage = {
          ...firstPage,
          compositions: [newSnippet, ...firstPage.compositions],
        }
        return {
          ...old,
          pages: [updatedFirstPage, ...(old?.pages.slice(1) ?? [])],
        }
      })

      const previousNoteSnippets = queryClient.getQueryData(queryKey)

      return { previousNoteSnippets, queryKey }
    },
    onSuccess: onSuccess,
    onError: (error: CustomError, newSnippet, context) => {
      const { previousNoteSnippets, queryKey } = context ?? { previousNoteSnippets: [], queryKey: [""] }

      queryClient.setQueryData(queryKey, previousNoteSnippets)
      displayNotificationError(registerErrorTrace(error, newSnippet))
    },
    onSettled: (_, __, ___, context) => {
      const { queryKey } = context ?? { previousNoteSnippets: [], queryKey: [""] }

      queryClient.invalidateQueries({ queryKey })
      onSettled?.()
    },
  })

  return { createSnippetsNote, isAdding }
}

export { useCreateSnippetsNote }
