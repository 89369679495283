import { ErrorMessage, Field, type FieldProps } from "formik"
import { Mention, type MentionProps, type MentionSelectEvent } from "primereact/mention"
import { classNames } from "primereact/utils"
import { useState, type FC } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"
import { Tooltip } from "primereact/tooltip"

import { useMentionsSuggestions } from "commons"
import { useOrganizationContext } from "organization"
import { useLoginContext } from "security"

const MentionTextAreaField: FC<Props> = ({
  field,
  label,
  rows = 6,
  className,
  inputClassName,
  panelClassName,
  disabled,
  readOnly,
  labelClassName,
  trigger = "#",
  placeholder = `Enter ${trigger} to use snippets notes`,
  onBlur,
  validation,
  ...mentionProps
}) => {
  const helperText = `Enter ${trigger} to use snippets notes` as const
  const { loggedInPractitionerId } = useLoginContext()
  const { currentOrganizationId } = useOrganizationContext()

  const [searchQuery, setSearchQuery] = useState<string | undefined>()
  const [suggestions, setSuggestions] = useState<Suggestion[]>([])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const { refetch: search } = useMentionsSuggestions({
    authors: [currentOrganizationId, loggedInPractitionerId],
    searchQuery: searchQuery,
  })

  const searchSuggestions = async () => {
    const { data } = await search()
    setSuggestions([...((data?.suggestions as Suggestion[]) ?? [])])
  }

  const resetSuggestions = () => {
    setSearchQuery(undefined)
  }

  const itemTemplate = (item: Suggestion) => (
    <div className="flex gap-8 items-center w-[95%]">
      <span className="bg-gray-300 rounded-md px-2 py-1 text-sm font-bold min-w-16 max-w-fit">{item.key}</span>
      <span className="truncate text-sm">{item.value}</span>
    </div>
  )

  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!isDropdownOpen) {
      onBlur?.(e)
    }
  }

  return (
    <Field name={field} validate={validation}>
      {({ field: { name, value, onChange }, meta: { touched, error }, form: { setFieldValue } }: FieldProps) => (
        <div className={classNames("field flex flex-col relative", className)}>
          {label && (
            <div className="inline-flex items-center max-w-fit mb-2">
              <label htmlFor={name} className={classNames("text-sm font-medium text-gray-700", labelClassName)}>
                {label}
              </label>
              {value && (
                <>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="fa-fw w-1 h-1 ml-[0.07rem] info-icon-snippet text-primary cursor-help"
                  />
                  <Tooltip
                    className="text-xs p-0 m-0"
                    target=".info-icon-snippet"
                    position="right"
                    event="hover"
                    autoHide={false}
                  >
                    {helperText}
                  </Tooltip>
                </>
              )}
            </div>
          )}
          <Mention
            id={name}
            name={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              // Split by space or any line break
              const match = e.target.value
                ?.split(/[\s]/)
                .find((s) => s.startsWith(trigger as string))
                ?.slice(1)

              setSearchQuery(match || undefined)
              onChange(e)
            }}
            value={value}
            pt={{
              input: {
                className: classNames("p-inputtext-sm grow", { "p-invalid": touched && error }, inputClassName),
              },
              panel: {
                className: classNames("h-auto min-w-0 max-w-[50%] !-top-6 !left-6", panelClassName),
              },
              items: {
                className: "py-0",
              },
            }}
            rows={rows}
            placeholder={placeholder || helperText}
            disabled={disabled}
            readOnly={readOnly}
            trigger={trigger}
            suggestions={suggestions}
            onSearch={searchSuggestions}
            onSelect={(e: MentionSelectEvent) => {
              const fieldValue = value as string

              setFieldValue(field, fieldValue.replace(`${trigger}${searchQuery ?? ""}`, e.suggestion.value))

              resetSuggestions()
            }}
            onBlur={onBlurHandler}
            onShow={() => setIsDropdownOpen(true)}
            onHide={() => setIsDropdownOpen(false)}
            field="value"
            itemTemplate={itemTemplate}
            autoHighlight={false}
            scrollHeight="150px"
            {...mentionProps}
          />
          <div className="flex items-start p-error field-error-spacing">
            <ErrorMessage name={field}>{(msg) => <small>{msg}</small>}</ErrorMessage>
          </div>
        </div>
      )}
    </Field>
  )
}

type Props = {
  field: string
  label?: string
  className?: string
  inputClassName?: string
  labelClassName?: string
  rows?: number
  disabled?: boolean
  readOnly?: boolean
  panelClassName?: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  validation?(value: string): void
} & MentionProps

type Suggestion = {
  key: string
  value: string
}

export { MentionTextAreaField }
