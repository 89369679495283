import type { DatesSetArg, EventInput } from "@fullcalendar/core"
import { differenceInDays, endOfMonth, isFirstDayOfMonth, isSameDay, startOfMonth } from "date-fns"
import { humanNameAsString } from "fhir"

import { type CalendarAppointment, getAppointmentType } from "appointments"
import { APPOINTMENT_TYPE_DEFAULT_COLOR, UNIT_DAYS } from "data"
import { SYSTEM_VALUES } from "system-values"
import { getCodingBySystem } from "utils"

const getEvents = (appointments: CalendarAppointment[]) =>
  appointments.reduce<EventInput[]>(
    (events, { appointment, patient, healthcareService, practitioner, location, devices }) => {
      const event = {
        id: appointment.id,
        title: humanNameAsString(patient?.name?.[0]),
        start: new Date(appointment.start as Date),
        status: appointment.status,
        color:
          getCodingBySystem(
            healthcareService?.characteristic,
            SYSTEM_VALUES.HEALTHCARESERVICE_CHARACTERISTIC_COLOR_SYSTEM,
          )?.code ?? APPOINTMENT_TYPE_DEFAULT_COLOR,
        extendedProps: {
          patientId: patient?.id,
          patientName: humanNameAsString(patient?.name?.[0]),
          practitionerName: humanNameAsString(practitioner?.name?.[0]),
          appointment: appointment,
          appointmentType: healthcareService?.name ?? getAppointmentType(appointment),
          patient,
          practitioner,
          location,
          devices,
          healthcareService,
        },
      }

      return [...events, event]
    },
    [],
  )

const getAppointmentsDateRangeFromDatesSet = (datesSet?: DatesSetArg) => {
  if (!datesSet) return undefined

  const { start, end } = datesSet

  const monthStartDate = startOfMonth(start)
  const monthEndDate = endOfMonth(end)

  return { start: monthStartDate, end: monthEndDate }
}

const getCalendarDateFromDatesSet = (datesSet?: DatesSetArg) => {
  if (!datesSet) return undefined

  const { start, end } = datesSet

  if (!isSameDay(start, end)) {
    if (isFirstDayOfMonth(start) || differenceInDays(end, start) <= UNIT_DAYS.WEEK) return start

    const newDate = new Date(start.getFullYear(), start.getMonth(), 1)
    newDate.setMonth(start.getMonth() + 1)
    return newDate
  }

  return start
}

export { getAppointmentsDateRangeFromDatesSet, getCalendarDateFromDatesSet, getEvents }
