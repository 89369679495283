import { type Composition, type Reference } from "fhir"
import { Checkbox } from "primereact/checkbox"
import { Skeleton } from "primereact/skeleton"
import { classNames } from "primereact/utils"
import { type FC, useState, useEffect } from "react"

import { FooterActions, InfiniteScroll } from "commons"
import { useSnippetsNote } from "commons/hooks"

import { useCreateSnippetsNote, useDeleteSnippetsNote, useUpdateSnippetsNote } from "../../hooks/snippets"
import { SnippetNote } from "./SnippetNote"
import { getSnippetInitialValues, sanitize } from "./validations"

const SnippetsNoteContainer: FC<SnippetsNoteContainerProps> = ({ author }) => {
  const [deleteMode, setDeleteMode] = useState<"single" | "multiple">("single")
  const [snippetsToDelete, setSnippetsToDelete] = useState<Composition[]>([])
  const draftSnippetTemplate = getSnippetInitialValues(author)
  const [showDraftSnippet, setShowDraftSnippet] = useState(false)

  const {
    snippetNotes = [],
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useSnippetsNote({
    authorId: author.id as string,
  })

  useEffect(() => {
    if (snippetNotes.length === 0 && !isLoading) {
      setShowDraftSnippet(true)
    }
  }, [snippetNotes, isLoading])

  const { createSnippetsNote, isAdding } = useCreateSnippetsNote({
    onMutate: () => {
      setShowDraftSnippet(false)
    },
  })
  const { updateSnippetsNote } = useUpdateSnippetsNote()
  const { deleteNoteSnippets } = useDeleteSnippetsNote()

  const onUpdateOrCreateSnippet = (snippet: Composition) => {
    if (snippet.id) {
      updateSnippetsNote(snippet)
    } else if (snippet?.r5_name?.trim() !== "" && snippet?.r5_note?.text?.trim() !== "") {
      createSnippetsNote(sanitize(snippet))
    }
  }

  const toggleDeleteMode = () => {
    setDeleteMode((prev) => (prev === "single" ? "multiple" : "single"))
    setSnippetsToDelete([])
  }

  const addToDeleteQueue = (snippet: Composition) => {
    setSnippetsToDelete((prev) => {
      const isAlreadySelected = prev.some((s) => s.id === snippet.id)
      return isAlreadySelected ? prev.filter((s) => s.id !== snippet.id) : [...prev, snippet]
    })
  }

  const handleSelectAll = (checked: boolean) => {
    setSnippetsToDelete(checked ? [...snippetNotes] : [])
  }

  const handleBulkDelete = () => {
    deleteNoteSnippets(snippetsToDelete)
    setDeleteMode("single")
    setSnippetsToDelete([])
  }

  const addOptions = [
    {
      label: "Delete Snippet(s)",
      command: () => toggleDeleteMode(),
      disabled: snippetNotes.length === 0,
      style: "outlined",
      visible: deleteMode === "single",
    },
    {
      label: "Add New Snippet",
      command: () => setShowDraftSnippet(true),
      visible: deleteMode === "single",
      disabled: showDraftSnippet || isAdding,
    },
    {
      label: "Cancel",
      command: toggleDeleteMode,
      style: "outlined",
      visible: deleteMode === "multiple",
    },
    {
      label: `Delete Selected (${snippetsToDelete.length})`,
      command: handleBulkDelete,
      disabled: snippetsToDelete.length === 0,
      visible: deleteMode === "multiple",
    },
  ]

  const loader = (
    <>
      <div className="grid grid-cols-2 gap-6">
        {[1, 2].map((i) => (
          <div key={i} className="flex flex-row gap-2 items-center">
            <div className="flex flex-col gap-2 w-full">
              <div className="flex flex-row justify-between">
                <div>
                  <Skeleton width="8rem" height="1.25rem" />
                </div>
                <div className="w-8" />
              </div>
              <Skeleton height="6rem" />
            </div>
          </div>
        ))}
      </div>
    </>
  )

  return (
    <>
      <div className={classNames("flex justify-between", { "mb-1": deleteMode === "multiple" })}>
        {deleteMode === "multiple" && (
          <div className="flex items-center gap-2">
            <Checkbox
              className="checkbox-sm"
              checked={snippetsToDelete.length === snippetNotes.length}
              onChange={(e) => handleSelectAll(Boolean(e.checked))}
            />
            <span
              className={classNames("text-sm text-gray-500", {
                "text-gray-900": snippetsToDelete.length === snippetNotes.length,
              })}
            >
              Select All
            </span>
          </div>
        )}
      </div>
      <div className="flex flex-1 flex-col overflow-auto">
        <InfiniteScroll loadMore={fetchNextPage} hasMore={hasNextPage} loader={loader}>
          <div className="grid grid-cols-2 gap-6">
            {showDraftSnippet && (
              <SnippetNote
                key="draft-snippet"
                snippet={draftSnippetTemplate}
                deleteMode={deleteMode}
                isMarkedForDelete={false}
                onRemove={() => setShowDraftSnippet(false)}
                onSave={(newSnippet: Composition) => onUpdateOrCreateSnippet(newSnippet)}
              />
            )}
            {snippetNotes?.map((snippet, index) => (
              <SnippetNote
                key={snippet.id || index}
                snippet={snippet}
                deleteMode={deleteMode}
                isMarkedForDelete={snippetsToDelete.some((s) => s.id === snippet.id)}
                onRemove={() => (deleteMode === "single" ? deleteNoteSnippets([snippet]) : addToDeleteQueue(snippet))}
                onSave={(newSnippet: Composition) => onUpdateOrCreateSnippet(newSnippet)}
              />
            ))}
          </div>
        </InfiniteScroll>
      </div>
      <FooterActions actions={addOptions} className="mt-4" />
    </>
  )
}

type SnippetsNoteContainerProps = {
  author: Reference
}

export { SnippetsNoteContainer }
