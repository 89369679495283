import { useQuery } from "@tanstack/react-query"
import {
  type Practitioner,
  type PractitionerRole,
  type Reference,
  asReference,
  getResources,
  humanNameAsString,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import type { PractitionerInfo } from "commons"
import { PRACTITIONER_ROLE } from "data"
import { SYSTEM_VALUES } from "system-values"
import { isPRPractitioner, isStaffPractRole } from "utils"

import { organizationQueryKeys } from "../query-keys"

const useOrganizationPractitioners = ({
  organizationId,
  filterByIdentifier,
  onlyActivePracts = true,
}: OrganizationPractitionersArgs) => {
  const { search } = useClient()
  const queryKey = organizationQueryKeys.practitioners(organizationId, onlyActivePracts)

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _include: "PractitionerRole:practitioner:Practitioner",
        ...(onlyActivePracts ? { "practitioner:Practitioner.active:not": "false" } : {}),
        "active:not": "false",
        role: [PRACTITIONER_ROLE.PRACTITIONER, PRACTITIONER_ROLE.STAFF].join(","),
      })

      const bundle = await search({ endpoint: `Organization/${organizationId}/PractitionerRole`, filters, signal })
      const practitioners = getResources<Practitioner>(bundle, "Practitioner")
      const practRoles = getResources<PractitionerRole>(bundle, "PractitionerRole")

      return { practitioners, practRoles, total: bundle?.total ?? 0 }
    },
    enabled: !!organizationId,
    meta: { context: { queryKey, organizationId } },
  })

  const {
    organizationPractitionersInfo,
    organizationPractitionerRefs,
    organizationPractitionersStaffInfo,
    organizationPractitionersStaffRef,
  } = useMemo(() => {
    if (!data?.practitioners) {
      return {
        organizationPractitionersInfo: [],
        organizationPractitionerRefs: [],
        organizationPractitionersStaffInfo: [],
        organizationPractitionersStaffRef: [],
      }
    }

    const practitionerRolesMap = new Map<string, PractitionerRole[]>(
      data.practRoles.map((role) => [role.practitioner?.id as string, []]),
    )
    data.practRoles.forEach((role) => {
      const id = role.practitioner?.id
      if (id) practitionerRolesMap.get(id)?.push(role)
    })

    const matchesFilter = (practitioner: Practitioner) => {
      if (!filterByIdentifier) return true
      return practitioner.identifier?.some((p) => p.value?.toLowerCase().includes(filterByIdentifier.toLowerCase()))
    }

    const createPractitionerRef = (practitioner: Practitioner, role?: PractitionerRole): Reference | undefined => {
      if (!role) return undefined
      return {
        ...asReference(role),
        display: humanNameAsString(practitioner.name?.[0], "no name"),
      }
    }

    return data.practitioners.reduce<Reducer>(
      (acc, practitioner) => {
        if (!matchesFilter(practitioner)) return acc

        const roles = practitionerRolesMap.get(practitioner.id as string) ?? []
        const practitionerRole = roles.find(isPRPractitioner)
        const staffRole = roles.find(isStaffPractRole)
        const isOnlyStaff = staffRole && !practitionerRole

        const hasDosespotSetup =
          practitioner.identifier?.some(
            (identifier) => identifier.system === SYSTEM_VALUES.PRACTITIONER_DS_IDENTIFIER,
          ) ?? false

        if (!isOnlyStaff && practitionerRole) {
          acc.organizationPractitionersInfo.push({
            practitioner,
            practitionerRole,
            practitionerRoleRef: createPractitionerRef(practitioner, practitionerRole),
            practitionerRoles: roles,
            hasDosespotSetup,
          })
          acc.organizationPractitionerRefs.push(asReference(practitioner))
        }

        if (staffRole) {
          acc.organizationPractitionersStaffInfo.push({
            practitioner,
            practitionerRole: staffRole,
            practitionerRoleRef: createPractitionerRef(practitioner, staffRole),
            practitionerRoles: roles,
          })
          acc.organizationPractitionersStaffRef.push(asReference(practitioner))
        }

        return acc
      },
      {
        organizationPractitionersInfo: [],
        organizationPractitionerRefs: [],
        organizationPractitionersStaffInfo: [],
        organizationPractitionersStaffRef: [],
      },
    )
  }, [data?.practRoles, data?.practitioners, filterByIdentifier])

  return {
    organizationPractitionersInfo: organizationPractitionersInfo ?? [],
    organizationPractitionerRefs: organizationPractitionerRefs ?? [],
    organizationPractitionersStaffInfo: organizationPractitionersStaffInfo ?? [],
    organizationPractitionersStaffRef: organizationPractitionersStaffRef ?? [],
    total: data?.total,
    isLoading,
    refetch,
  }
}

interface OrganizationPractitionersArgs {
  organizationId: string
  filterByIdentifier?: string
  onlyActivePracts?: boolean
}

type Reducer = {
  organizationPractitionersInfo: PractitionerInfo[]
  organizationPractitionerRefs: Reference[]
  organizationPractitionersStaffInfo: Omit<PractitionerInfo, "hasDosespotSetup">[]
  organizationPractitionersStaffRef: Reference[]
}

export { useOrganizationPractitioners }
