import { useInfiniteQuery } from "@tanstack/react-query"
import { compareDesc } from "date-fns/compareDesc"
import {
  type MedicationDispense,
  type MedicationKnowledge,
  type MedicationRequest,
  getResources,
  isCarePlan,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { getCommonCode } from "utils"

import { getMedsProductConfigurations } from "../../utils"
import { medsQueryKeys } from "../meds_query_keys"

const useMedicationRequests = ({
  patientId,
  category,
  statusFilter,
  perPage,
  page,
  searchText,
  encounter,
  autoship,
}: MedicationRequestsArgs) => {
  const { search } = useClient()
  const queryKey = medsQueryKeys.medicationRequestList(
    patientId,
    category,
    statusFilter,
    searchText,
    perPage,
    page,
    encounter,
  )

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery<
    MedicationRequestsQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = page, signal }) => {
      const filters = new URLSearchParams({
        _query: "patient-prescriptions",
        _patient: patientId,
        category,
        ...(autoship ? { _autoship: `${autoship}` } : {}),
        ...(searchText ? { _filter: searchText } : {}),
        ...(statusFilter.length > 0 ? { _status: statusFilter.join(",") } : {}),
        _count: perPage?.toString() ?? "20",
        _page: `${pageParam}`,
        ...(encounter ? { encounter } : {}),
      })

      const bundle = await search({ endpoint: "MedicationRequest", filters, signal })

      const medicationRequests = getResources<MedicationRequest>(bundle, "MedicationRequest")
      const medicationDispenses = getResources<MedicationDispense>(bundle, "MedicationDispense")
      const medicationKnowledges = getResources<MedicationKnowledge>(bundle, "MedicationKnowledge")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return {
        medicationRequests,
        medicationDispenses,
        medicationKnowledges,
        next,
        total: bundle?.total ?? 0,
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId, statusFilter, searchText, perPage, page } },
  })

  const {
    medicationRequests,
    draftMedicationRequestsCount,
    medicationKnowledges,
    medicationDispenses,
    medsProductConfigurations,
  } = useMemo(() => {
    const mrs = data?.pages.flatMap((page) => page.medicationRequests)
    const mks = data?.pages.flatMap((page) => page.medicationKnowledges)
    const mds = data?.pages
      .flatMap((page) => page.medicationDispenses)
      .toSorted((a, b) => compareDesc(a.whenPrepared ?? "", b.whenPrepared ?? ""))

    const medsProductConfigurations = getMedsProductConfigurations({ meds: mrs, specifiedQuantity: true })

    const draftMRs = mrs?.filter(
      (medicationRequest) => medicationRequest.status === "draft" && !medicationRequest.basedOn?.some(isCarePlan),
    )?.length

    const medicationKnowledges = mks?.reduce(
      (acc, mk) => {
        const code = getCommonCode({ codes: mk.code?.coding })
        return { ...acc, [code]: mk }
      },
      {} as Record<string, MedicationKnowledge>,
    )
    return {
      medicationRequests: mrs,
      medicationKnowledges,
      medicationDispenses: mds,
      draftMedicationRequestsCount: draftMRs ?? 0,
      total: data?.pages?.[0]?.total ?? 0,
      medsProductConfigurations,
    }
  }, [data?.pages])

  const reloadMedications = () => {
    refetch()
  }

  if (isError) {
    throw error
  }

  return {
    medicationRequests,
    medicationDispenses,
    medicationKnowledges,
    medsProductConfigurations,
    isLoading,
    draftMedicationRequestsCount,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    reloadMedications,
  }
}

interface MedicationRequestsArgs {
  patientId: string
  category: "medication" | "nutraceutical"
  statusFilter: string[]
  perPage?: number
  page?: number
  searchText?: string
  encounter?: string
  autoship?: boolean
}

type MedicationRequestsQueryData = {
  medicationRequests: MedicationRequest[]
  medicationDispenses: MedicationDispense[]
  medicationKnowledges: MedicationKnowledge[]
  next: number | undefined
  total: number
}

export { useMedicationRequests }
