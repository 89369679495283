import { faEdit, faTrashCan } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type Annotation } from "fhir"
import { classNames } from "primereact/utils"
import { useState, type FC } from "react"

const NoteItem: FC<Props> = ({
  note,
  index,
  onRemoveNote,
  onEditNote,
  loggedInPractitionerId,
  renderNoteText,
  disabled,
  className = "gap-y-2",
  textClassName = "text-gray-900",
}) => {
  const [editActionsVisible, setEditActionsVisible] = useState(false)
  const canTakeAction = !disabled && note?.author?.Reference?.id === loggedInPractitionerId

  return (
    <div
      className={classNames("flex flex-col", className)}
      key={index}
      onMouseEnter={() => setEditActionsVisible(true)}
      onMouseLeave={() => setEditActionsVisible(false)}
    >
      <div className="flex flex-row items-center text-sm cursor-pointer flex-shrink-0 gap-6 w-full">
        {note.author?.Reference?.display && (
          <span className={classNames("flex-grow font-semibold", textClassName)}>{note.author.Reference.display}</span>
        )}

        {editActionsVisible && canTakeAction && (
          <span className="flex gap-2 text-primary hover:text-primary-hover">
            <FontAwesomeIcon className="fa-fw" icon={faEdit} onClick={() => onEditNote(note, index)} />
            <FontAwesomeIcon className="fa-fw" icon={faTrashCan} onClick={() => onRemoveNote(index)} />
          </span>
        )}
      </div>

      <p className={classNames("w-full whitespace-pre-line", textClassName)}>
        {renderNoteText?.(note.text) ?? note.text}
      </p>
    </div>
  )
}

type Props = {
  note: Annotation
  index: number
  onRemoveNote: (index: number) => void
  onEditNote: (note: Annotation, index: number) => void
  loggedInPractitionerId: string
  className?: string
  textClassName?: string
  renderNoteText?(text?: string): JSX.Element
  disabled?: boolean
}

export { NoteItem }
