import { faBuilding, faInfoCircle, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  type CarePlan,
  type CarePlanActivityArray,
  type ChargeItemDefinition,
  type CodeableConcept,
  type Coding,
  type Composition,
  type Money,
  type Organization,
  type PlanDefinition,
  type PlanDefinitionActionArrayActionArray,
  type Reference,
  type UsageContext,
  asReference,
} from "fhir"

import { classNames } from "primereact/utils"

import type { MedicationGroup, MedicationRequestInfo } from "commons/meds"
import { getProductKey } from "commons/utils"
import { COMBO_PROMO_CODE, PD_ACTION_DYNAMIC_VALUE } from "data"
import { SYSTEM_VALUES } from "system-values"
import { convertIdentifiersToCodings, getBasePrice, getCommonCodeForAllSku, getMoneyCurrencyAlt } from "utils"

import { LabComboTitle } from "commons/labs"
import type { StackedListItemProps } from "../components/StackedListItem"
import { type QuestionnaireData, MenuStyles } from "../types"

import { type CarePlanHistory, type ComboDefinition, type PanelItemDisplay, type PlanData, PlanContext } from "./types"

const getCPDate = (data: CarePlanHistory | PlanData) =>
  new Date(data.appointment?.start ?? (data.carePlan.created as string))

const panelItemModelBuilder = ({ pItem, showPrice, isInsurance }: PanelItemModelBuilderArgs): StackedListItemProps => {
  const sku = pItem.planDefinition.identifier?.find(({ system }) => system === SYSTEM_VALUES.SKU)
  const chLabIdentifier = sku?.value

  const info = (
    <span className="flex text-sm text-gray-300 space-x-2 divide-x divide-gray-200">
      {chLabIdentifier && (
        <>
          <span title="Performer" className="text-sm">
            <FontAwesomeIcon icon={faBuilding} size="xs" className="mr-2" />
            {pItem.planDefinition.publisher}
          </span>
          <span title="Lab Identifier" className="text-sm pl-2">
            {chLabIdentifier}
          </span>
        </>
      )}
    </span>
  )

  return {
    leftData: [
      {
        lineItems: [
          {
            name: "Test",
            value: pItem?.display,
            component: pItem.isCombo ? <LabComboTitle combo={pItem.planDefinition} /> : undefined,
          },
        ],
      },
      { lineItems: [{ name: "Identifier", component: info }] },
      { lineItems: [{ name: "Info", value: pItem.info }] },
    ],
    rightData: showPrice
      ? [
          {
            lineItems: [
              {
                component: (
                  <span
                    title="Price"
                    className={classNames("font-medium text-gray-500 text-sm self-start", {
                      "line-through": isInsurance,
                    })}
                  >
                    {pItem?.price
                      ? `${getMoneyCurrencyAlt(pItem.price.currency)}${pItem.price.value?.toFixed(2)}`
                      : "No price"}
                  </span>
                ),
              },
            ],
          },
        ]
      : undefined,
  }
}

interface PanelItemModelBuilderArgs {
  pItem: PanelItemDisplay
  showPrice: boolean
  isInsurance: boolean
}

/* Templated function to construct an Adhoc activity based in a resource ref. This will be used to Add an activity for backend services to POST the contained resource */
const getAdhocActivity = (resourceRef: Reference, enabled = true) => {
  const adhocActivity: CarePlanActivityArray = {
    enabled,
    outcomeReference: [
      {
        localRef: resourceRef.localRef ?? resourceRef.id,
        display: resourceRef.display,
        resourceType: resourceRef.resourceType,
      },
    ],
    outcomeCodeableConcept: [
      {
        text: "Adhoc Resource",
        coding: [
          {
            code: "adhoc-resource",
            system: SYSTEM_VALUES.CARE_PLAN_OUTCOME,
            display: "Adhoc Resource",
          },
        ],
      },
    ],
  }

  return adhocActivity
}

const getPDQuestionnaireCanonical = (pd: PlanDefinition) => {
  return pd.action
    ?.find((a) => a.definition?.canonical?.includes("Questionnaire"))
    ?.definition?.canonical?.split("|")?.[0]
}

const getContextLabel = (context: PlanContext) => (context === PlanContext.MC ? "order" : "treatment plan")

const getAssessmentStatusText = (status: string) => ({ active: "ready", draft: "pending" })[status] ?? status

const panelModelBuilder = ({ item, readOnly, onDelete, onDetails, showPrice, isInsurance }: PanelModelBuilderArgs) => {
  const model = panelItemModelBuilder({
    pItem: item,
    showPrice: showPrice as boolean,
    isInsurance: isInsurance as boolean,
  })
  model.menu = [
    ...(model.menu ? model.menu : []),
    ...(item.isCombo && !!onDetails
      ? [
          {
            label: "Info",
            icon: <FontAwesomeIcon icon={faInfoCircle} size="sm" />,
            command: () => onDetails(item),
          },
        ]
      : []),
    ...(!readOnly
      ? [
          {
            label: "Delete",
            icon: (
              <FontAwesomeIcon icon={faTrashCan} size="sm" className={classNames({ "mr-1": model.menu?.length })} />
            ),
            command: () => onDelete?.(item),
          },
        ]
      : []),
  ]
  if (model.menu.length === 1) model.menuStyle = MenuStyles.ActionItems
  return model
}

export interface PanelModelBuilderArgs {
  item: PanelItemDisplay
  readOnly?: boolean
  onDelete?: (item: PanelItemDisplay) => void
  onDetails?: (item: PanelItemDisplay) => void
  showPrice?: boolean
  isInsurance?: boolean
}

const isPDType = (pd: PlanDefinition, type: "panel" | "combo" | "mc" | string) =>
  pd.type?.coding?.some(({ code }) => code === type)

const getComboDefinition = ({
  comboPanel,
  cids,
  panelDefinitions,
  catalogs,
  organizations,
}: {
  comboPanel: PlanDefinition
  panelDefinitions: Record<string, PlanDefinition>
  cids: Record<string, ChargeItemDefinition>
  catalogs: Record<string, Composition>
  organizations: Record<string, Organization>
}): ComboDefinition => {
  const canonicalPanels =
    comboPanel.action?.reduce<Array<string>>(
      (panels, pdAction) => [...panels, pdAction.definition?.canonical as string],
      [],
    ) ?? []

  const promoCoding = comboPanel.topic?.find((topic) => topic.coding?.[0]?.code === COMBO_PROMO_CODE)?.coding?.[0]

  const comboPanels =
    canonicalPanels?.reduce<Array<PlanDefinition>>(
      (cpanels, canonical) =>
        panelDefinitions[canonical as string] ? [...cpanels, panelDefinitions[canonical as string]] : cpanels,
      [],
    ) ?? []

  const comboCode = convertIdentifiersToCodings(comboPanel ? [comboPanel] : [])
  const productKey = getProductKey({ code: comboCode })
  const comboCid = cids[productKey]

  return {
    canonical: `${comboPanel.url}|${comboPanel.version}`,
    canonicalPanels,
    definition: comboPanel,
    promoCoding,
    panels: comboPanels,
    price: getBasePrice(comboCid?.propertyGroup?.[0]?.priceComponent)?.amount as Money,
    performer: getPDPerformer(comboPanel, catalogs, organizations),
  }
}

const getPDPerformer = (
  pd: PlanDefinition,
  catalogs: Record<string, Composition>,
  organizations: Record<string, Organization>,
) => {
  const performer = organizations[catalogs[pd.catalogHeader?.[0]?.id as string]?.author?.[0]?.id as string]
  return performer ? asReference(performer) : {}
}

const getActionCode = (cc: CodeableConcept | undefined) =>
  cc?.coding?.find(({ system }) => system === SYSTEM_VALUES.PLAN_ACTION)?.code

const getActionMedCodes = (medAction: PlanDefinitionActionArrayActionArray) =>
  medAction.action?.reduce(
    (acc, action) => {
      const code = [...(action.output?.flatMap(({ codeFilter }) => codeFilter?.[0]?.code ?? []) ?? [])]
      const sku = getCommonCodeForAllSku({ codes: code })
      const required = action.requiredBehavior === "must"
      return {
        ...acc,
        codes: [...acc.codes, ...code],
        sku: [...acc.sku, ...sku],
        requiredMeds: [...acc.requiredMeds, ...(required ? sku : [])],
      }
    },
    { codes: Array<Coding>(), sku: Array<string>(), requiredMeds: Array<string>() },
  ) ?? { codes: [], sku: [], requiredMeds: [] }

const getGroupedActionMedCodes = <T extends MedicationRequestInfo>(medAction: PlanDefinitionActionArrayActionArray) =>
  medAction.action?.reduce(
    (acc, action, index) => {
      const code = [...(action.output?.flatMap(({ codeFilter }) => codeFilter?.[0]?.code ?? []) ?? [])]
      const sku = getCommonCodeForAllSku({ codes: code })
      const actionDisplay = action.description
      const requiredMeds = action.requiredBehavior === "must" ? sku : []
      const catalog = action.dynamicValue
        ?.find((v) => v.path === "catalog")
        ?.expression?.expression?.replace("Composition/", "")
      return {
        ...acc,
        groups: [
          ...acc.groups,
          {
            name: actionDisplay ?? `Group ${index + 1}`,
            codes: [...code],
            sku: [...sku],
            requiredBehavior: action.requiredBehavior,
            selectionBehavior: action.selectionBehavior,
            catalog,
          },
        ],
        medCodes: [...acc.medCodes, ...code.flatMap(({ code }) => code as string)],
        requiredMeds: [...acc.requiredMeds, ...requiredMeds],
      }
    },
    { groups: Array<MedicationGroup<T>>(), medCodes: Array<string>(), requiredMeds: Array<string>() },
  ) ?? { groups: Array<MedicationGroup<T>>(), medCodes: Array<string>(), requiredMeds: Array<string>() }

const isValidGenderContext = (patientGender: string, useContext?: UsageContext[]) => {
  const hasGenderContext = useContext?.some(({ code }) => code.code === "gender")
  return (
    !hasGenderContext ||
    useContext?.some(
      ({ code, value }) =>
        code.code === "gender" && value?.CodeableConcept?.coding?.some(({ code }) => code === patientGender),
    )
  )
}

const getMailTaskOutput = (carePlan: CarePlan, action: PlanDefinitionActionArrayActionArray) => {
  const reusable =
    action.dynamicValue?.find((v) => v.path === PD_ACTION_DYNAMIC_VALUE.REUSE)?.expression?.expression === "true"

  const daysBeforeToMail = action.dynamicValue?.find((v) => v.path === PD_ACTION_DYNAMIC_VALUE.DAYS_BEFORE_APPOINTEMENT)
    ?.expression?.expression

  const activityIndex = carePlan.activity?.findIndex(({ actionId }) => actionId === action.id)

  const subactionValue =
    activityIndex !== undefined && activityIndex >= 0
      ? carePlan.activity?.[activityIndex]?.outcomeReference?.[0]
      : undefined

  return {
    reusable,
    canonical: action.definition?.canonical,
    label: action.description ?? action.title ?? "",
    activityIndex,
    subactionValue,
    daysBeforeToMail,
  }
}

const getQuestionnaireCanonicalsFromPanels = (panels: PlanDefinition[] | undefined) =>
  panels?.reduce((acc, pItem) => {
    const canonicals = pItem.action?.reduce((qCanonicals, { definition }) => {
      const newCanonical = definition?.canonical?.includes("Questionnaire") ? definition.canonical : undefined
      return [...qCanonicals, ...(newCanonical ? [newCanonical] : [])]
    }, Array<string>())
    return [...acc, ...(canonicals ?? [])]
  }, Array<string>())

// Allows get specific codes of the questions in a Q(canonical ref) that are related to the action data
// Ex: When defining allergies in some Qs there are questions that have info about this.
const getActionQuestionnaireInfo = (
  action: PlanDefinitionActionArrayActionArray,
  questionnaireData?: QuestionnaireData[],
) => {
  const info = action.action?.reduce(
    (acc, { definition, dynamicValue }) => {
      const qCanonical = definition?.canonical
      const qData = questionnaireData?.find(
        ({ qResponse, questionnaire }) =>
          qResponse?.questionnaire === qCanonical || `${questionnaire.url}|${questionnaire.version}` === qCanonical,
      )
      const qId = qData?.qResponse?.id
      const qTitle = qData?.questionnaire.title
      const questionCodes = dynamicValue?.reduce((codes, dValue) => {
        if (dValue.path === PD_ACTION_DYNAMIC_VALUE.QUESTION_ID)
          return [...codes, ...(dValue.expression?.expression ? [dValue.expression.expression] : [])]
        return codes
      }, Array<string>())

      if (qId && questionCodes?.length) return { ...acc, [qId]: { questionCodes, title: qTitle } }

      return { ...acc }
    },
    {} as Record<string, { questionCodes: string[]; title?: string }>,
  )

  return Object.keys(info ?? {}).length ? info : undefined
}

const getPDCanonical = (item: PlanDefinition) => `${item.url}|${item.version}`

export {
  getActionCode,
  getActionMedCodes,
  getActionQuestionnaireInfo,
  getAdhocActivity,
  getAssessmentStatusText,
  getCPDate,
  getComboDefinition,
  getContextLabel,
  getGroupedActionMedCodes,
  getMailTaskOutput,
  getPDPerformer,
  getPDQuestionnaireCanonical,
  isPDType,
  isValidGenderContext,
  panelItemModelBuilder,
  panelModelBuilder,
  getQuestionnaireCanonicalsFromPanels,
  getPDCanonical,
}
