import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { type Coverage, type Organization, type Reference, asReference } from "fhir"
import { type FieldProps, ErrorMessage, Field, useFormikContext } from "formik"
import pluralize from "pluralize"
import { type AutoCompleteCompleteEvent, AutoComplete } from "primereact/autocomplete"
import { classNames } from "primereact/utils"
import { type FC, useDeferredValue, useEffect, useMemo, useRef, useState } from "react"

import { DialogFormContainer } from "commons"
import { useReplaceFormContext } from "commons/hooks"
import { emptyAddress } from "data"
import { getStringAddress } from "utils"

import { useCreateCarrier, useInsuranceProviders } from "../hooks"
import type { InsuranceProviderInfo } from "../types"
import { getInsuranceProvidersInfo } from "../utils"
import { CarrierForm } from "./CarrierForm"
import { carrierValidationSchema } from "./validations"

const AutocompleteInsuranceProvidersField: FC<Props> = ({
  field,
  label = "carrier",
  className,
  readonly,
  disabled,
  multiple,
  validation,
}) => {
  const {
    setFieldValue,
    values: { payor },
  } = useFormikContext<Coverage>()
  const [payorFilter, setPayorFilter] = useState<string>()
  const deferredPayorFilter = useDeferredValue(payorFilter)
  const { refetch } = useInsuranceProviders(deferredPayorFilter)
  const [suggestionList, setSuggestionList] = useState<InsuranceProviderInfo[]>([])
  const [carrierNameToAdd, setCarrierNameToAdd] = useState("")
  const tempCarrierNameRef = useRef("")

  const reset = () => {
    tempCarrierNameRef.current = ""
    setCarrierNameToAdd("")

    if (!multiple && !payor[0].id) setFieldValue(field, { id: undefined, display: undefined, resourceType: undefined })
  }

  const { createCarrier, isAdding } = useCreateCarrier((carrier) => {
    setFieldValue(field, { ...asReference(carrier) })
  }, reset)

  const searchCodes = async (e: AutoCompleteCompleteEvent) => {
    const { data } = await refetch()
    tempCarrierNameRef.current = (e.query.length >= 1 && e.query) as string
    setSuggestionList(getInsuranceProvidersInfo(data?.insuranceProviders))
  }

  const itemTemplate = (item: InsuranceProviderInfo) => (
    <div className="flex flex-col w-full gap-1">
      <span className="whitespace-nowrap overflow-hidden text-ellipsis max-w-xs text-gray-900">{item.display}</span>
      <span className="whitespace-nowrap text-xs overflow-hidden text-ellipsis max-w-xs text-gray-400">
        {getStringAddress(item.address)}
      </span>
    </div>
  )

  const selectedItemTemplate = (item: InsuranceProviderInfo) => (
    <span className="whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">{item.display}</span>
  )

  const onSubmit = (data?: Organization) => data && createCarrier(data)

  const dialogFormProps = useMemo(() => {
    return {
      title: label,
      showForm: !!carrierNameToAdd || isAdding,
      useFormik: true,
      initialValue: { name: carrierNameToAdd, address: [{ ...emptyAddress, use: undefined }] },
      validationSchema: carrierValidationSchema,
      saveLabel: "Add",
      children: <CarrierForm />,
      onCancel: reset,
      onSubmit: onSubmit,
    }
  }, [carrierNameToAdd, isAdding])

  const dialogContext = useReplaceFormContext<Organization>()

  useEffect(() => {
    if (dialogContext) {
      dialogContext.setReplacementContent?.({ ...dialogFormProps })
    }
  }, [dialogFormProps])

  return (
    <>
      <Field name={field} validate={validation}>
        {({ field: { name, value, onChange }, form: { setFieldValue }, meta: { touched, error } }: FieldProps) => {
          return (
            <div className={classNames("field flex flex-col relative", className)}>
              {label && (
                <label htmlFor={name} className="text-sm font-medium text-gray-700 mb-2">
                  {label}
                </label>
              )}
              <span className="left-icon absolute inset-y-0 left-0 flex items-center pl-3 pt-4 z-10">
                <FontAwesomeIcon className="text-slate-400 bg-white" icon={faSearch} title="Type to search" />
              </span>
              <AutoComplete
                id={name}
                name={name}
                field="display"
                disabled={disabled}
                readOnly={readonly}
                suggestions={[...suggestionList]}
                delay={400}
                minLength={1}
                multiple={multiple}
                selectedItemTemplate={multiple && selectedItemTemplate}
                itemTemplate={(item) => itemTemplate(item)}
                completeMethod={searchCodes}
                onBlur={() => {
                  if (!multiple && !(value as Reference)?.id)
                    setFieldValue(name, { id: undefined, display: undefined, resourceType: undefined })
                }}
                onChange={(e) => {
                  multiple ? onChange(e) : setFieldValue(name, { ...value, display: e.value })
                  if (typeof e.value === "string") setPayorFilter(e.value.trim())
                }}
                onSelect={(e) => {
                  if ((e.value as Reference)?.id) {
                    const newRef = {
                      id: e.value.id,
                      display: e.value.display,
                      resourceType: e.value.resourceType,
                    } as Reference

                    multiple ? setFieldValue(name, [...(value ?? []), newRef]) : setFieldValue(name, newRef)
                  }
                }}
                value={multiple ? value : value?.display}
                className={classNames("p-inputtext-sm", { "p-invalid": touched && error })}
                appendTo="self"
                panelClassName="w-full"
                panelFooterTemplate={
                  !suggestionList.length
                    ? (_, hide) => (
                        <button
                          type="button"
                          className="flex flex-1 w-full items-center space-x-1 font-medium px-5 py-3 hover:bg-gray-600/10"
                          onClick={() => {
                            hide?.()
                            setCarrierNameToAdd(tempCarrierNameRef.current)
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                          <p>Add {label.toLowerCase()}</p>
                        </button>
                      )
                    : undefined
                }
                emptyMessage={`No matching ${pluralize(label.toLowerCase(), 2)}`}
                showEmptyMessage
              />

              <div className="flex items-start p-error field-error-spacing">
                <ErrorMessage name={name}>{(msg) => <small>{msg}</small>}</ErrorMessage>
              </div>
            </div>
          )
        }}
      </Field>
      {!dialogContext && <DialogFormContainer {...dialogFormProps} />}
    </>
  )
}

type Props = {
  field: string
  label?: string
  className?: string
  readonly?: boolean
  disabled?: boolean
  multiple?: boolean
  validation?(InsuranceProviderInfo: InsuranceProviderInfo): void
}

export { AutocompleteInsuranceProvidersField }
