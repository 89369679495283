import { useInfiniteQuery } from "@tanstack/react-query"
import {
  type ChargeItemDefinition,
  type Coding,
  type InventoryItem,
  type MedicationKnowledge,
  getResources,
  isMedicationKnowledge,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { useProductPricesQueryFunction } from "commons/hooks"
import { getMedFee } from "commons/meds"
import { GENERIC_BILLING_TYPE, PRODUCT_CONFIGURATION_BILLING_TYPE } from "commons/types"
import { getMedsProductConfigurations, getProductKey } from "commons/utils"
import { MEDICATION_CATALOG } from "data"
import { useOrganizationContext } from "organization"
import { getBasePrice, getCommonCode, getCommonCoding } from "utils"

import { allowedMKDoseFormsToGetReferencePrices, referencePriceQuantities } from "../../data"
import { settingsQueryKeys } from "../../query-keys"
import type { MedicationsAdvanceFilter, MedItem, ReferencePriceItem } from "../../types"

const useMksByCategory = ({
  category,
  enabled = true,
  filters,
  organizationId,
  specifyReferencePriceQuantities,
}: {
  organizationId: string
  category: MEDICATION_CATALOG
  filters: Filters
  enabled: boolean
  specifyReferencePriceQuantities?: boolean
}) => {
  const { search } = useClient()
  const { location } = useOrganizationContext()
  const { mkCatalogs: catalogs, searchText, medsClassificationCodes } = filters
  const queryKey = settingsQueryKeys.meds({
    organizationId,
    category,
    catalogs,
    medsClassificationCodes,
    archived: false,
    searchText,
  })

  const fetchProductPrices = useProductPricesQueryFunction({
    includeAllFees: true,
  })

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        ...(catalogs?.length ? { catalogHeader: catalogs.join(",") } : {}),
        ...(searchText ? { termsearch: searchText } : {}),
        "catalogHeader:Composition.author:Organization.type": category,
        ...(medsClassificationCodes?.length ? { classification: medsClassificationCodes.join(",") } : {}),
        _count: "50",
        _page: `${pageParam}`,
        _sort: "-code",
        status: "active",
        "has-cid-in-org": organizationId,
        _revinclude: "InventoryItem:medication",
      })

      const bundle = await search({ endpoint: "MedicationKnowledge", filters, signal })
      const medicationKnowledge = getResources<MedicationKnowledge>(bundle, "MedicationKnowledge")
      const inventoryItems = getResources<InventoryItem>(bundle, "InventoryItem")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined
      const total = bundle.total ?? 0

      const mkCodes = medicationKnowledge.reduce((acc, mk) => {
        const commonCoding = getCommonCoding({ codes: mk.code?.coding })
        if (commonCoding) {
          acc.push(commonCoding)
        }
        return acc
      }, [] as Coding[])

      const medsProductConfigurations = getMedsProductConfigurations({
        meds: medicationKnowledge,
        specifiedQuantity: true,
        referenceQuantities: specifyReferencePriceQuantities ? referencePriceQuantities : [],
        billingType: PRODUCT_CONFIGURATION_BILLING_TYPE.BOTH,
      })

      const productPrices = await fetchProductPrices({
        organizationId,
        productsConfigurations: medsProductConfigurations,
      })

      return {
        medicationKnowledge,
        productPrices,
        mkCodes,
        next,
        total,
        inventoryItems,
      }
    },
    refetchOnWindowFocus: false,
    meta: { context: { queryKey, ...filters } },
    getNextPageParam: (lastPage) => lastPage.next,
    initialPageParam: 1,
    enabled,
  })

  const { medicationKnowledge, mkCodes, medsWithCID } = useMemo(() => {
    const { medicationKnowledge, mkCodes, productPrices, inventoryItems } = data?.pages.reduce(
      (acc, page) => {
        return {
          ...acc,
          medicationKnowledge: [...acc.medicationKnowledge, ...page.medicationKnowledge],
          mkCodes: [...acc.mkCodes, ...page.mkCodes],
          productPrices: { ...acc.productPrices, ...page.productPrices },
          inventoryItems: [...acc.inventoryItems, ...page.inventoryItems],
        }
      },
      {
        category: MEDICATION_CATALOG,
        medicationKnowledge: Array<MedicationKnowledge>(),
        mkCodes: Array<Coding>(),
        productPrices: {} as Record<string, ChargeItemDefinition>,
        inventoryItems: Array<InventoryItem>(),
      },
    ) ?? {
      category: MEDICATION_CATALOG.RX,
      medicationKnowledge: [],
      mkCodes: [],
      productPrices: {} as Record<string, ChargeItemDefinition>,
      inventoryItems: [],
    }

    const inventory = inventoryItems.reduce<Record<string, InventoryItem>>((acc, item) => {
      // TODO: Change this to bind by SKU when migrated in meds
      //const medId = item.code?.[0]?.coding && getCommonCode({ codes: item.code?.[0]?.coding })
      const medId = item.association?.find((a) => isMedicationKnowledge(a.relatedItem))?.relatedItem?.id

      return medId && item.instance?.location?.id === location?.id ? { ...acc, [medId]: item } : acc
    }, {})

    const medsWithCID = medicationKnowledge.reduce((acc, mk) => {
      const mkCoding = mk?.code?.coding
      const mkCode = getCommonCode({ codes: mkCoding })

      const allMatchingProductConfigurations = []
      for (const [productKey, chargeItemDefinition] of Object.entries(productPrices)) {
        if (productKey?.startsWith(mkCode)) {
          allMatchingProductConfigurations.push(chargeItemDefinition)
        }
      }

      const patientProductKey = getProductKey({
        code: mkCoding,
        billingType: GENERIC_BILLING_TYPE.BILL_PATIENT,
      })

      const practiceProductKey = getProductKey({
        code: mkCoding,
        billingType: GENERIC_BILLING_TYPE.BILL_PRACTICE_OR_INSURANCE,
      })

      const patientProductPrice = productPrices[patientProductKey]
      const practiceProductPrice = productPrices[practiceProductKey]

      const patientPc = patientProductPrice?.propertyGroup?.[0]?.priceComponent
      const practicePc = practiceProductPrice?.propertyGroup?.[0]?.priceComponent

      const patientBasePrice = getBasePrice(patientPc)?.amount?.value
      const practiceBasePrice = getBasePrice(practicePc)?.amount?.value

      const fee = getMedFee(allMatchingProductConfigurations)

      const isAllowedToGetReferencePrices =
        !!allowedMKDoseFormsToGetReferencePrices[mk.doseForm?.coding?.[0]?.code ?? ""]

      const referencePrices =
        isAllowedToGetReferencePrices && specifyReferencePriceQuantities
          ? referencePriceQuantities.map<ReferencePriceItem>((qty) => {
              const productKey = getProductKey({
                code: mkCoding,
                quantity: qty,
              })

              const productPrice = productPrices[productKey]

              const pc = productPrice?.propertyGroup?.[0]?.priceComponent

              return { qty, price: getBasePrice(pc)?.amount?.value ?? 0 }
            })
          : []

      const inventoryItem = inventory[mk.id as string]

      return [
        ...acc,
        {
          mk,
          practicePrice: practiceBasePrice ?? 0,
          ...(category === MEDICATION_CATALOG.NUTRA && { patientPrice: patientBasePrice ?? 0 }),
          fee: fee ?? [
            {
              value: 0,
            },
          ],
          referencePrices,
          inventory: inventoryItem && {
            item: inventoryItem,
            qty: inventoryItem.netContent ?? { value: 0, unit: "unit" },
            location: inventoryItem.instance?.location,
          },
        } as MedItem,
      ]
    }, Array<MedItem>())

    return { medicationKnowledge, mkCodes, medsWithCID }
  }, [data?.pages])

  return {
    medicationKnowledge: medicationKnowledge ?? [],
    medicationKnowledgeCodes: mkCodes,
    medsWithCID,
    isLoading: isLoading,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type Filters = MedicationsAdvanceFilter & { searchText?: string }

export { useMksByCategory }
