import type { FC } from "react"
import { asReference } from "fhir"
import { useOrganizationContext } from "organization"

import { SectionContainer } from "commons"

import { SnippetsNoteContainer } from "./SnippetsNoteContainer"

const NoteSnippetsSectionContainer: FC = () => {
  const { currentOrganization } = useOrganizationContext()
  return (
    <SectionContainer>
      <SnippetsNoteContainer author={asReference(currentOrganization)} />
    </SectionContainer>
  )
}

export { NoteSnippetsSectionContainer }
