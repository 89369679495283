import { useQuery } from "@tanstack/react-query"
import {
  asReference,
  type Composition,
  type Encounter,
  getResource,
  type HealthcareService,
  isHealthcareService,
} from "fhir"
import { useMemo } from "react"

import { useAllergies } from "allergies"
import { useClient } from "api"
import { useCarePlans } from "commons/care-plans"
import { useConditions } from "conditions"
import { laboratoryOrderStatusCodes, requestCommonsStatusCodes } from "data"
import { useFamilyHistory } from "family-history"
import { useLabs } from "laboratory"
import { useLaboratoryOrders } from "laboratory-orders"
import { useMedicationsStatement } from "medications-statement"
import { usePatientObservations } from "patient360"
import { usePatientProcedures } from "procedures"
import { useSocialHistory } from "social-history"
import { useQuestionnaireResponses } from "surveys"
import { useVitals } from "vitals"

import { encounterQueryKeys } from "../query-keys"
import type { EncounterData } from "../types"
import { useLabObservations } from "./useLabObservations"

const useEncounterData = (patientId: string, encounterId: string, organizationId: string) => {
  const { search } = useClient()
  const queryKey = encounterQueryKeys.data(patientId, encounterId)

  const {
    allergies,
    isLoading: iLoadingAllergies,
    total: totalAllergies,
  } = useAllergies({ patientId, key: "KP", encounter: encounterId })

  const {
    conditions,
    isLoading: isLoadingConditions,
    total: totalConditions,
  } = useConditions({
    patientId,
    key: "KP",
    enabled: true,
    encounter: encounterId,
  })

  const { familyMemberHistories, isLoading: isLoadingFH, total: totalFH } = useFamilyHistory(patientId, encounterId)

  const { labs, isLoading: isLoadingEnteredLabs, total: totalEnteredLabs } = useLabs(patientId, encounterId)
  const {
    observations,
    isLoading: isLoadingLabobservations,
    total: totalLabObservations,
  } = usePatientObservations({
    patientId,
    category: "laboratory",
    encounter: encounterId,
    hasQuantity: true,
    loadCount: 100,
  })

  const {
    socialHistory,
    isLoading: isLoadingSocialHistory,
    total: totalSocialHistory,
  } = useSocialHistory(patientId, encounterId)

  const {
    medications: medStatements,
    isLoading: isLoadingMedStatements,
    total: totalMedStatements,
  } = useMedicationsStatement({ patientId, encounter: encounterId })

  const {
    questionnaireData,
    isLoading: isLoadingQs,
    total: totalQs,
  } = useQuestionnaireResponses({
    patientId,
    key: "WK",
    status: ["in-progress", "completed"],
    encounter: encounterId,
  })

  const {
    proceduresData,
    isLoading: isLoadingProcedures,
    total: totalProcedures,
  } = usePatientProcedures({ patientId, encounter: encounterId })

  const { plans, isLoading: isLoadingPlans, total: totalPlans } = useCarePlans({ patientId, encounter: encounterId })

  const orderStatus = [
    ...laboratoryOrderStatusCodes.map(({ code }) => code),
    ...(requestCommonsStatusCodes.find(({ code }) => code === "draft")?.code ? ["draft"] : []),
  ]

  const {
    laboratoryOrders,
    isLoading: isLoadingLabOrders,
    total: totalLabOrders,
  } = useLaboratoryOrders({
    organizationId,
    patientId,
    statusFilter: orderStatus,
    enabled: true,
    encounter: encounterId,
  })

  const { vitals, isLoading: isLoadingVitals, totalData: totalVitals } = useVitals(patientId, encounterId)

  const {
    data,
    isLoading: isLoadingEncounter,
    refetch,
  } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _count: "1",
        _id: encounterId,
        _include: "actor:HealthcareService",
        _revinclude: "Composition:encounter:Encounter",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Encounter`, filters, signal })

      const encounter = getResource<Encounter>(bundle, "Encounter")
      const type = getResource<HealthcareService>(bundle, "HealthcareService")
      const assessment = getResource<Composition>(bundle, "Composition")

      return encounter
        ? {
            encounter: {
              ...encounter,
              participant: [
                ...(type?.id ? [{ r5_actor: asReference(type) }] : []),
                ...(encounter.participant?.filter((part) => !isHealthcareService(part.r5_actor)) ?? []),
              ],
            },
            assessment,
          }
        : null
    },
    meta: { context: { queryKey, patientId } },
  })

  const {
    observations: labResultsWithNotes,
    isLoading: isLoadingLabResultsWithNotes,
    total: totalLabResultsWithNotes,
  } = useLabObservations(patientId, encounterId)

  const encounterData: EncounterData | undefined = useMemo(
    () =>
      data?.encounter && {
        ...data.encounter,
        intake: {
          allergies,
          conditions,
          familyHistory: familyMemberHistories,
          socialHistory,
          medications: medStatements,
          vitals,
          labs: [...observations, ...(labs?.flatMap(({ results }) => results) ?? [])],
        },
        assessment: data.assessment,
        questionnaires: questionnaireData,
        plans,
        laboratoryOrders,
        procedures: proceduresData,
        labResultsWithNotes,
      },
    [
      data,
      allergies,
      conditions,
      familyMemberHistories,
      labs,
      observations,
      medStatements,
      socialHistory,
      questionnaireData,
      proceduresData,
      plans,
      laboratoryOrders,
      labResultsWithNotes,
      vitals,
    ],
  )

  return {
    encounterData,
    encounterRef: data?.encounter ? asReference(data.encounter) : undefined,
    isLoading:
      isLoadingEncounter ||
      iLoadingAllergies ||
      isLoadingConditions ||
      isLoadingFH ||
      isLoadingEnteredLabs ||
      isLoadingMedStatements ||
      isLoadingSocialHistory ||
      isLoadingQs ||
      isLoadingProcedures ||
      isLoadingPlans ||
      isLoadingVitals ||
      isLoadingLabOrders ||
      isLoadingLabobservations ||
      isLoadingLabResultsWithNotes,
    reloadEncounter: refetch,
    totalAllergies,
    totalConditions,
    totalEnteredLabs: totalEnteredLabs + totalLabObservations,
    totalFH,
    totalLabOrders,
    totalMedStatements,
    totalPlans,
    totalProcedures,
    totalQs,
    totalSocialHistory,
    totalVitals,
    totalLabResultsWithNotes,
  }
}

export { useEncounterData }
